import styled from 'styled-components';

import { Box } from '@chakra-ui/react';

export const ContainerLoad = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #104066;
`;