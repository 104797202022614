import React, { useEffect, useState, useRef } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import {
    Stack,
    Box,
    Text,
    Button,
    Radio, 
    RadioGroup,
    Tag,
    TagLabel,
    TagLeftIcon,
    Input,
    Textarea,
    Grid,
    GridItem,
    Flex,
    FormLabel,
    CircularProgress,
    Checkbox,
    CheckboxGroup,
    useDisclosure
} from '@chakra-ui/react'

import chroma from 'chroma-js';

import { toast } from "react-toastify"

import jwt_decode from "jwt-decode"

import { useSelector } from 'react-redux'

import Select, { SingleValue, StylesConfig } from 'react-select'

import { ref, set } from "@firebase/database"

import { RootState } from '../../../store'

import { ModalComponent } from '../../../components/Modal'

import { BoxWithBorder } from '../../../components/BoxWithBorder'

import api from '../../../services/api'

import bookingRequest, { setCurrentBookingRequest } from '../../../store/reducers/booking-request'

import { FirebaseRealtimeDatabase } from '../../../services/firebase'

import { IUserData } from '../../../interfaces/IUserData'

import { SelectContacts } from '../../../components/SelectContacts'

import { ImageUpload } from '../../../components/ImageUpload'

import { PropsContatos } from '../../../interfaces/IContacts';

import { DateOrHoursEvent } from './components/DateOrHoursEvent';

interface IUnits {
    id: string;
    name: string;
}

interface IOptions {
    readonly value: string;
    readonly label: string;
}

interface IOptionsTypeEvent {
    readonly value: string;
    readonly label: string;
    readonly guestType: string;
}

type PropsLocals = {
    allDay: boolean
    allDayAllowed: boolean
    availableTimes: any;
    enableVirtualDoorman: boolean;
    value: string;
    label: string;
}

type PropsMilestone = {
    guestType: 'EXTERNAL' | 'INTERNAL' | 'BOTH';
    value: string;
    label: string;
}

/**
 * 
 * Regra do AllDay e AllDayAllowed
 * 
 * AllDayAllowed for falso, o usuário só pode selecionar apenas 1 horário
 * AllDayAllowed for true, o usuário só pode selecionar apenas N horários
 * 
 * AllDay é enviado como true caso (AllDayAllowed e AllDay forem true) e o usuário selecionar todos os horários
 * ai nesse caso o é enviado assim: timesheets = []
 * 
 */

export function BookingRequestForm () {
    const location = useLocation()

    const currentTimeSelectRef = useRef<any>(null);
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { isOpen, onClose, onOpen } = useDisclosure();
    
    const currentBookingRequest = useSelector((state: RootState) => state.bookingRequest.data)

    const [units, setUnits] = useState<readonly IOptions []>([])
    
    const [areas, setAreas] = useState<readonly IOptions []>([])
    
    const [locals, setLocals] = useState<PropsLocals[]>([])

    const [milestones, setMilestones] = useState<PropsMilestone[]>([])
    
    const [commonAreaRuleList, setCommonAreaRuleList] = useState([])

    const [valueStartHourFilter, onChangeStartHourFilter] = useState<any>('');

    const [valueEndHourFilter, onChangeEndHourFilter] = useState<any>('');
    
    const [title, setTitle] = useState('')

    const [description, setDescription] = useState('')

    const [currentContactList, setCurrentContactList] = useState<PropsContatos[]>([])

    const [needApproval, setNeedApproval] = useState(true) //Precisa de aprovação

    const [showRuleModal, setShowRuleModal] = useState(false)
    
    const [ruleMessageModal, setRuleMessageModal] = useState('')
    
    const [waitEndpointResponse, setWaitEndpointResponse] = useState(false)

    const [showApprovalNeedAlertMessage, setShowApprovalNeedAlertMessage] = useState(false)

    const [subModuleId, setSubModuleId] = useState('0')

    const [messageNoLocals, setMessageNoLocals] = useState('')

    const [loading, setLoading] = useState(true)

    const [disabledButton, setDisabledButton] = useState(false)

    const [singlePersonEvent, setSinglePersonEvent] = useState<"true" | "false" | "both">("both")

    const [showButtonChangeReleaseType, setShowButtonChangeReleaseType] = useState(false)

    const [imageEvent, setImageEvent] = useState("")

    const [isCheckedFilterByDateAndHour, setIsCheckedFilterByDateAndHour] = useState(false)

    const [moreThanOneLocation, setMoreThanOneLocation] = useState(false)

    const [reloadLocalEHorarioDaReserva, setReloadLocalEHorarioDaReserva] = useState(false)

    const [allDay, setAllDay] = useState(false)

    useEffect(() => {
        async function getDetails() {
            const id = location && location.state && location.state.id;

            setSinglePersonEvent(location.state.singlePersonEvent)

            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
                }
            }

            if(id && !isCheckedFilterByDateAndHour) {
                const { data } = await api.get(`/CommonAreaRequest/getByid/${id}`, config)
    
                const { data: response2 } = await api.get(`event/getEventSpaceByUnitGuest?unitId=${data.unitId}&guest=${data.milestoneGuest}`, config)
    
                const commonAreaRuleId = response2.filter((v: any) => v.local.id === data.localId)[0].commonAreaRule.id;

                const commonAreaReservationRule = response2.filter((v: any) => v.local.id === data.localId)[0].commonAreaRule?.commonAreaReservationRule
                
                const { data: response3 } = await api.get(`CommonAreaRequest/GetAvailableBookingTimes/${commonAreaRuleId}/${data.reservationDate}`, config)
                
                dispatch(setCurrentBookingRequest({
                    ...data,
                    bookingType: data.milestoneGuest ? 'Coletivo' : 'Individual',
                    commonAreaRuleId,
                    commonAreaReservationRule,
                    availableBookingTimes: response3.timesheets.map((v: any) => {
                        return {
                            label: v.startTime + ' ' + v.endTime,
                            value: v.id,
                            startTime: v.startTime,
                            endTime: v.endTime,
                            ongoing: v.ongoing
                        }
                    })
                }))
            }


            setLoading(false)
        }

        getDetails()
    }, [location && location.state && location.state.id])
    
    useEffect(() => {
        const userToken = localStorage.getItem('access_portal:access_token')

        const decoded = jwt_decode(userToken as string) as IUserData

        setSubModuleId(decoded.subModuleId)
        
        loadUnits()
    }, [])

    useEffect(() => {
        if(currentBookingRequest.unitId === '0'){
            setAreas([])
            dispatch(setCurrentBookingRequest({
                ...currentBookingRequest, 
                department:{
                    ...currentBookingRequest.department,
                    id: '0'
                }}))
        }else{
            loadAreas()
        }
    }, [currentBookingRequest.unitId])

    useEffect(() => {
        if(currentBookingRequest.bookingType === '0') {
            dispatch(setCurrentBookingRequest({
                ...currentBookingRequest, 
                milestoneId: '0',
                localId: '0'
            }))
            
            setMilestones([])
        }else{
            loadMilestones()
        }
        
    }, [currentBookingRequest.bookingType])
  
    useEffect(() => {
        if(!isCheckedFilterByDateAndHour){
            setMessageNoLocals('');

            if(currentBookingRequest.milestoneId === '0') {
                dispatch(setCurrentBookingRequest({
                    ...currentBookingRequest, 
                    localId: '0'
                }));
                
                setLocals([]);
            }else{
                loadLocals();
            }
        }else{
            setMessageNoLocals('');

            if(currentBookingRequest.milestoneId === '0') {
                dispatch(setCurrentBookingRequest({
                    ...currentBookingRequest, 
                    localId: '0'
                }));
                
                setLocals([]);
            }
        }
    }, [currentBookingRequest.milestoneId])

    useEffect(() => {
        if(currentBookingRequest.localId === '0') {
            dispatch(setCurrentBookingRequest({
                ...currentBookingRequest, 
                commonAreaRuleId: '0',
                commonAreaReservationRule: {
                    id: 0,
                    noShowEnable: false,
                    noShowTimeLimit: 0,
                }
            }))
        }else{
            const ruleId: any = commonAreaRuleList.filter((v: any) => {
                return (
                    v.localId.toString() === currentBookingRequest.localId.toString()
                    && v.milestoneId.toString() === currentBookingRequest.milestoneId.toString()
                )
            })[0]

            if(currentBookingRequest.milestoneId && milestones.length > 0) {
            
                const guest_type = milestones.filter(res => String(res.value) === String(currentBookingRequest.milestoneId))[0].guestType

                const enable_virtual_doorman = locals
                    .filter(res => String(res.value) === String(currentBookingRequest.localId))[0]
                    .enableVirtualDoorman || false;
    
                const enableVirtualDoorman = ((guest_type === 'EXTERNAL' || guest_type === 'BOTH') && enable_virtual_doorman);
             
                setShowButtonChangeReleaseType(enableVirtualDoorman)
            }

            if(ruleId){
                setNeedApproval(ruleId.request)

                setRuleMessageModal(ruleId.details)

                dispatch(setCurrentBookingRequest({
                    ...currentBookingRequest, 
                    commonAreaRuleId: ruleId.id,
                    commonAreaReservationRule: ruleId.commonAreaReservationRule,
                }))
            }
        }
    }, [currentBookingRequest.localId])

    useEffect(() => {
        if(currentBookingRequest.reservationDate && !isCheckedFilterByDateAndHour){
            getAvailableBookingTimes()
            loadLocals()
        }
    }, [currentBookingRequest.reservationDate])

    useEffect(() => {
        setTitle("")
        setDescription("")
    }, [
        currentBookingRequest.unitId,
        currentBookingRequest.department.id,
        currentBookingRequest.localId,
        currentBookingRequest.bookingType,
        currentBookingRequest.milestoneId,
    ])

    //clears
    useEffect(() => {
        if(currentBookingRequest.timesheets.length === 0){
            setCurrentContactList([])
            setTitle("")
            setDescription("")
        }
    }, [currentBookingRequest.timesheets])

    useEffect(() => {
        if(currentBookingRequest.localId.toString() === '0'){
            setCurrentContactList([])
            setTitle("")
            setDescription("")
            dispatch(setCurrentBookingRequest({
                ...currentBookingRequest, 
                timesheets: [],

                reservationDate: isCheckedFilterByDateAndHour ? '' : currentBookingRequest.reservationDate,
                availableBookingTimes: isCheckedFilterByDateAndHour ? [] : currentBookingRequest.availableBookingTimes,
                availableBookingTimesBackup: isCheckedFilterByDateAndHour ? [] : currentBookingRequest.availableBookingTimesBackup
            }))

        }
    }, [currentBookingRequest.localId])

    useEffect(() => {
        if(currentBookingRequest.milestoneId.toString() === '0'){
            setCurrentContactList([]);

            setTitle("");

            setDescription("");

            setIsCheckedFilterByDateAndHour(false);

            dispatch(setCurrentBookingRequest({
                ...currentBookingRequest, 
                localId: '0',
                timesheets: [],
                reservationDate: '',
                availableBookingTimes: [],
                availableBookingTimesBackup: []
            }));
        }
    }, [currentBookingRequest.milestoneId, currentBookingRequest.bookingType])

    useEffect(() => { 
        
        setCurrentContactList([])
        setTitle("")
        setDescription("")
        dispatch(setCurrentBookingRequest({
            ...currentBookingRequest, 
            localId: '0',
            timesheets: [],
            reservationDate: '',
            availableBookingTimes: [],
            availableBookingTimesBackup: []
        }))

        
    }, [isCheckedFilterByDateAndHour])

    useEffect(() => { 
        
        setCurrentContactList([])
        setTitle("")
        setDescription("")
        setIsCheckedFilterByDateAndHour(false)
        dispatch(setCurrentBookingRequest({
            ...currentBookingRequest, 
            localId: '0',
            timesheets: [],
            reservationDate: '',
            availableBookingTimes: [],
            availableBookingTimesBackup: []
        }))

        
    }, [currentBookingRequest.bookingType])

    useEffect(() => {
        if(currentBookingRequest.milestoneId.toString() === '0'){
            setCurrentContactList([])
            setTitle("")
            setDescription("")
            setIsCheckedFilterByDateAndHour(false)
            dispatch(setCurrentBookingRequest({
                ...currentBookingRequest, 
                bookingType: '0',
                milestoneId: '0',
                localId: '0',
                timesheets: [],
                reservationDate: '',
                availableBookingTimes: [],
                availableBookingTimesBackup: []
            }))

        }
    }, [currentBookingRequest.unitId])  

    async function loadUnits() {
        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        
        try{
            const decoded = jwt_decode(userToken as string) as IUserData
                    
            const { data } = await api.get(`unit/GetByClient/${decoded.client_id}`, config)
            
            setUnits(data.map((v: IUnits) => {
                return {
                    label: v.name,
                    value: v.id
                }
            })) 
            
        }catch(e: any) {
        }
    }

    async function loadAreas() {
        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        
        try{
            const { data } = await api.get(`departmentArea/GetByUnit/${currentBookingRequest.unitId}`, config)
            
            setAreas(data.map((v: any) => {
                return {
                    label: v.department.name + ' - ' + v.name,
                    value: v.department.id
                }
            }))
            
        }catch(e: any) {
        }
    }

    async function loadMilestones() {
        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        
        try{
            const { data } = await api.get(`/milestone/GetName?ownLocation=false&scheduled=true&guest=${currentBookingRequest.bookingType === 'Coletivo'}`, config)

            setMilestones(data.map((v: any) => {
                return {
                    label: v.name,
                    value: v.id,
                    guestType: v.guestType,
                }
            }))
        }catch(e: any) {
            
        }
    }

    async function loadLocals() {
        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        
        try{
            // só irá trazer o allday caso passe a data no endpoint
            
            let url = `event/getEventSpaceByMilestone/${currentBookingRequest.milestoneId}`

            if(currentBookingRequest.reservationDate){
                url = `event/getEventSpaceByMilestone/${currentBookingRequest.milestoneId}/${currentBookingRequest.reservationDate}`
            }

            const { data } = await api.get(url, config);

            setMoreThanOneLocation(data.length > 1);

            setLocals(data.map((v: any) => {
                return {
                    label: v.local.name,
                    value: v.local.id,
                    enableVirtualDoorman: v.enableVirtualDoorman,
                    allDayAllowed: currentBookingRequest.reservationDate ? v.allDayAllowed : true,
                    allDay: currentBookingRequest.reservationDate ? v.allDay : true ,
                }
            }));

            const data2 = await api.get(`event/getEventSpaceByUnitGuest?unitId=${currentBookingRequest.unitId}&guest=${currentBookingRequest.bookingType === 'Coletivo'}`, config)

            setCommonAreaRuleList(data2.data.map((v: any) => {
                return {
                    ...v.commonAreaRule,
                    localId: v.local.id,
                    localName: v.local.name,
                    milestoneId: v.milestone.id
                }
            }));
        }catch(e: any) {
            toast.warn('Não existem espaços cadastrados para este tipo de reserva.');

            setLocals([]);
            
            dispatch(setCurrentBookingRequest({
                ...currentBookingRequest, 
                localId: '0'
            }));
        }
    }

    async function getAvailableBookingTimes() {
        
        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }

        try{
            const { data } = await api.get(`CommonAreaRequest/GetAvailableBookingTimes/${currentBookingRequest.commonAreaRuleId}/${currentBookingRequest.reservationDate}`, config)

            const val = data.timesheets.map((v: any) => {
                return {
                    label: v.startTime.substring(0, 5) + ' - ' + v.endTime.substring(0, 5),
                    value: v.id,
                    startTime: v.startTime,
                    endTime: v.endTime,
                    ongoing: v.ongoing
                }
            })
            dispatch(setCurrentBookingRequest({
                ...currentBookingRequest, 
                availableBookingTimesBackup: val,
                availableBookingTimes: val
            }))
        }catch(e: any) {
            if(
                e &&
                e.response &&
                e.response.data &&
                e.response.data.Message
            ){
                toast.error(e.response.data.Message)
            }
        }
    }

    function validation() {
        //unidade
        if(isNaN(parseInt(currentBookingRequest.unitId)) || currentBookingRequest.unitId === '0'){
            return toast.error('É obrigatório selecionar a sua unidade.')
        }
        //área
        // if(subModuleId === '5' && (isNaN(parseInt(currentBookingRequest.department.id)) || currentBookingRequest.department.id === '0')) {
        //     return toast.error('É obrigatório selecionar a área.')
        // }
        //tipo
        if(currentBookingRequest.bookingType === "0"){
            return toast.error('É obrigatório definir se a reserva é do tipo coletivo ou individual.')
        }
        //local
        if(isNaN(parseInt(currentBookingRequest.localId)) || currentBookingRequest.localId === '0'){
            return toast.error('É obrigatório selecionar um local.')
        }
        //data
        if(currentBookingRequest.reservationDate === ''){
            return toast.error('É obrigatório definir uma data.')
        }
        //horario

        if(currentBookingRequest.timesheets.length === 0){
            return toast.error('É obrigatório definir um horário.')
        }
        //se não precisar de aprovação
        if((!needApproval || currentBookingRequest.id !== 0) && currentBookingRequest.bookingType === "Coletivo"){
            if(title === '' || title.length < 5){
                return toast.error('É obrigatório o título conter pelo menos 5 caracteres.')
            }
            if(description === '' || description.length < 20){
                return toast.error('É obrigatório a descrição conter pelo menos 20 caracteres.')
            }
            if(currentContactList.length < 1 && currentBookingRequest.bookingType === 'Coletivo'){
                return toast.error('É obrigatório selecionar pelo menos um contato.')
            }
        }

        const ruleId: any = commonAreaRuleList.filter((v: any) => {
            return (
                v.localId.toString() === currentBookingRequest.localId.toString()
                && v.milestoneId.toString() === currentBookingRequest.milestoneId.toString()
            )
        })[0]

        if(ruleId){
            setNeedApproval(ruleId.request)

            if(currentBookingRequest.bookingType === 'Individual'){
                if(title.length < 1) {
                    setTitle(`Reserva Individual - ${ruleId.localName}`)
                }

                if(description.length < 1) {
                    setDescription('Durante a criação da reserva, o responsável optou por não inserir qualquer descrição.')
                }
            }
        }

        if(currentBookingRequest.commonAreaReservationRule.noShowEnable) {
            onOpen();
        } else {
            setShowRuleModal(true)
        }

        return true                  
    }

    function getLabelByValue(val: any, options: any): string {
        const result = options.filter((v: any) => v.value.toString() === val.toString())

        if(result.length > 0){
            return result[0].label
        }

        return ''
    }

    function sendFirebaseEvent (data: any) {        
        let dataEventoSplit = currentBookingRequest.reservationDate
                
        let newAd = { 
          incident_id: data.id,
          status: 0,
          date: new Date().toString() 
        } 

        set(
            ref( 
                FirebaseRealtimeDatabase, 
                '/change-acess-control-janitor/' + `${dataEventoSplit[2]}-${dataEventoSplit[1]}-${dataEventoSplit[0]}`
            ),
            newAd
        )

        set(
            ref(
                FirebaseRealtimeDatabase, 
                '/event-creation-or-change/' + currentBookingRequest.unitId
            ),
            newAd
        )

        set(
            ref(
                FirebaseRealtimeDatabase, 
                '/calendar-creation-or-change/' + currentBookingRequest.unitId
            ),
            newAd
        )
    
    }

    function sendFirebaseRequest (data: any) {
        let idRequest: any = '';
    
        idRequest = data.split('número')[1];
        idRequest = idRequest.split(' ');
        idRequest = idRequest[1];

        const userToken = localStorage.getItem('access_portal:access_token')
    
        const decoded = jwt_decode(userToken as string) as IUserData
        
        let selectedClient = decoded.client_id
    
        let clientId = selectedClient.split('#')[0]
        let date = new Date()
        let dia = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate()
        let mes = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
        let ano = date.getFullYear()
    
        let dataFormatted = dia + '-' + mes + '-' + ano
    

        set(
            ref(
                FirebaseRealtimeDatabase, 
                '/new-commom-area-request/' + clientId + '/' + dataFormatted
            ),
            new Date().toString()
        )

        set(
            ref(
                FirebaseRealtimeDatabase, 
                '/commom-area-request-creation-or-change/' + clientId + '/' + idRequest
            ),
            'OPEN'
        )

        set(
            ref(
                FirebaseRealtimeDatabase, 
                '/event-creation-or-change/' + clientId
            ),
            'OPEN'
        )
    }

    function handleChangeContactGuest(data: any) {
        setCurrentContactList(data)
    }

    function getValueFile(data: string) {
        setImageEvent(data)
    }

    async function save() {
        setDisabledButton(true)

        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        
        setWaitEndpointResponse(true)

        const decoded = jwt_decode(userToken as string) as IUserData

        const userId = decoded.user_id

        const clientId = decoded.client_id

        const startDate = currentBookingRequest.reservationDate + ' ' + currentBookingRequest.timesheets[0].startTime.substring(0, 5)
        
        const endDate = currentBookingRequest.reservationDate + ' ' + currentBookingRequest.timesheets[currentBookingRequest.timesheets.length - 1].endTime.substring(0, 5)

        let timesheetsFormatted = currentBookingRequest.timesheets.map((v: any) => {
            return {
                commonAreaTimesheetId: v.value,
                date: currentBookingRequest.reservationDate
            }
        })

        //valida o AllDay
        let allDayToSend = false

        if(
            locals.filter((v) => {
                return (
                    v.value.toString() === currentBookingRequest.localId.toString()
                    && v.allDayAllowed
                    && v.allDay
                )
            }).length > 0 
            && 
            currentBookingRequest.timesheets.length === currentBookingRequest.availableBookingTimes.length
        ) {
            allDayToSend = true
            timesheetsFormatted = []
        }
        /*console.log("currentBookingRequest",
            currentBookingRequest,
            currentBookingRequest.allTimesReserved,
            currentBookingRequest.availableBookingTimes,
            currentBookingRequest.timesheets);
        return false;*/
        let toSend: any = {
            localId: currentBookingRequest.localId,
            milestoneId: currentBookingRequest.milestoneId,
            allDay: allDayToSend,
            //timesheets: timesheetsFormatted,
            automatic: !needApproval,
            allTimesReserved: currentBookingRequest.availableBookingTimes.length === currentBookingRequest.timesheets.length,// || currentBookingRequest.allTimesReserved,
        }

        if (needApproval && currentBookingRequest.id !== 0) {
            toSend.timesheets = timesheetsFormatted;
        }

        if(imageEvent){
            toSend.fileKind = imageEvent.replace('data:image/jpeg;base64,', '').length > 0
                    ? "base64"
                    : null
                    toSend.fileData = imageEvent.replace('data:image/jpeg;base64,', '').length > 0
                ? imageEvent.replace('data:image/jpeg;base64,', '')
                : null
        }

        if(!needApproval || currentBookingRequest.id !== 0){
            toSend = {
                ...toSend,
                userId: userId,
                clientId: clientId,
                unitId: currentBookingRequest.unitId,
                name: title,
                description: description,
                startDate: startDate,
                endDate,
                contact: currentContactList.filter(res => res.guestType === 'EXTERNAL').map(v => {
                    return {
                        contactId: v.id,
                        guestReleaseType: v.guestReleaseType
                    };
                }),
                internalGuests: currentContactList.filter(res => res.guestType === 'INTERNAL').map(v => {
                    return {
                        userId: v.id,
                        guestReleaseType: v.guestReleaseType
                    };
                }),
                automatic: !needApproval,
                commonAreaRequest: { 
                    id: currentBookingRequest.id !== 0 ? currentBookingRequest.id : 0,
                    allDay: allDayToSend,
                    automatic: !needApproval,
                    date: startDate,
                    localId: currentBookingRequest.localId,
                    milestoneId: currentBookingRequest.milestoneId,
                    requestById: userId,
                    timesheets: timesheetsFormatted,
                },
                deviceCalendarId: null,
                fileKind: imageEvent.replace('data:image/jpeg;base64,', '').length > 0
                    ? "base64"
                    : null,
                fileData: imageEvent.replace('data:image/jpeg;base64,', '').length > 0
                  ? imageEvent.replace('data:image/jpeg;base64,', '')
                  : null,
            }

            

            // if(subModuleId === '5'){
            //     toSend = {
            //         ...toSend,
            //         CommonAreaRequest: {
            //             ...toSend.CommonAreaRequest,
            //             DepartmentId: currentBookingRequest.department.id,
            //         }
            //     }
            // }
        }

        // toSend = {
        //     ...toSend,
        //     commonAreaRuleId: currentBookingRequest.commonAreaRuleId
        // }

        // return console.log('toSend', toSend)
         
        try{
            let url = `/event/create`
            
            let messgae = "Reserva cadastrada com sucesso."

            if(needApproval && currentBookingRequest.id === 0) {
                url = `/commonAreaRequest/register`
                messgae = "Reserva solicitada com sucesso."
                if (toSend.allDay) {
                    toSend.date = currentBookingRequest.reservationDate;
                }
            } else {
                toSend.commonAreaRequest.date = currentBookingRequest.reservationDate;
            }


            if(!(needApproval && currentBookingRequest.id === 0)) {
                toSend.timesheets = null;
            }

            if (!toSend.allDay) {
                toSend.timesheets = timesheetsFormatted;
            }

            //return false;

            //se automatic = false e allday = false, então timesheets deve estar do lado de fora
            //se automatic = true e allday = false, então timesheets deve estar do lado de dentro
            if (toSend.automatic === true && !toSend.allDay) {
                toSend.timesheets = undefined;
            }


            // setShowRuleModal(false);
            // setDisabledButton(false)
            // return console.log('allTimesReserved', toSend);

            //toSend adicionar o currentBookingRequest.commonAreaRuleId na root
            const { data } = await api.post(url, toSend, config)

            if(needApproval && currentBookingRequest.id === 0) {
                sendFirebaseRequest(data)
            }else{
                sendFirebaseEvent(data)
            }
            
            setTimeout(() => {
                setWaitEndpointResponse(false)
                setShowApprovalNeedAlertMessage(false)
                setShowRuleModal(false)

                setDisabledButton(false)

                toast.success(messgae)

                if (data?.message?.includes("Atenção: Você não possui a credencial")) {
                    let messageSplit = data.message.split("#");

                    toast.error(messageSplit[1]);
                }

                navigate(-1)
            }, 700);            
        }catch(e: any) {
            setDisabledButton(false)

            setWaitEndpointResponse(false)

            if(e?.response?.data?.Message){
                setTimeout(() => {
                    return toast.error(e?.response?.data?.Message)
                }, 1000) 
            }else{
                setTimeout(() => {
                    return toast.error('Algo deu errado enquanto tentavamos nos conectar aos nossos servidores, por favor entre em contato com o suporte.')
                }, 1000) 
            }
        }
    }

    if(loading) {
        return (
            <Flex
                position={"relative"}
                h={"calc(100vh - 175px)"}
                w={"100%"}
                top={"0px"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
            </Flex>
        )
    }

    return (
        <Grid templateColumns={["0% 100% 0%", "25% 50% 25%"]}>
            <GridItem colSpan={1} />
            <GridItem
                colSpan={1}
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <ModalComponent
                    title={'Já conhece as regras para o uso do espaço escolhido?'}
                    isOpen={showRuleModal}
                    onClose={() => { 
                        if(!waitEndpointResponse) {
                            setShowRuleModal(false);
                        }
                    }}
                    closeButtonLabel={'Recusar'}
                    confirmButtonLabel={'Aceitar e Solicitar'}
                    onConfirmButton={() => {
                        if(!waitEndpointResponse) {
                            if(needApproval && currentBookingRequest.id === 0){
                                setShowApprovalNeedAlertMessage(true);

                                setShowRuleModal(false);
                            }else{
                                save();
                            }
                        } 
                    }}
                    hideButtons={waitEndpointResponse}
                >
                    {waitEndpointResponse 
                        ?   (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <CircularProgress isIndeterminate size='120px' />    
                                </div>
                            )
                        
                        :   ruleMessageModal
                    }
                </ModalComponent>
                <ModalComponent
                    title={'Atenção'}
                    isOpen={isOpen}
                    closeButtonLabel={'Recusar'}
                    confirmButtonLabel={'Confirmar'}
                    onClose={onClose}
                    onConfirmButton={() => {
                        setShowRuleModal(true);

                        onClose();
                    }}
                >
                    <Text>Sua reserva poderá ser cancelada depois de {`${currentBookingRequest.commonAreaReservationRule.noShowTimeLimit}`} minutos sem uso do espaço!</Text>
                </ModalComponent>
                <ModalComponent
                    title={'Atenção!'}
                    isOpen={showApprovalNeedAlertMessage}
                    onClose={() => { 
                        if(!waitEndpointResponse) {
                            setShowApprovalNeedAlertMessage(false)
                        }
                    }}
                    closeButtonLabel={''}
                    confirmButtonLabel={'OK'}
                    onConfirmButton={() => {
                        if(!waitEndpointResponse) {
                            save()
                        } 
                    }}
                    hideButtons={waitEndpointResponse}
                >
                    {waitEndpointResponse 
                        ?   (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <CircularProgress isIndeterminate size='120px' />    
                                </div>
                            )
                        :   <span>Sua solicitação não garante a utilização da área. Por favor, aguarde a aprovação do responsável.</span>
                    }
                </ModalComponent>
                <Text
                    fontSize={[28, 20]}
                    fontWeight={600}
                    color={"#444"}
                    marginBottom={"10px"}
                >
                    {currentBookingRequest.id ? 'Edição' : 'Cadastro'} de Reserva
                </Text>
                <Flex
                   flexDir={"column"}
                   background={["inherit", "#fff"]}
                   w={"100%"}
                   paddingBottom={["0px", "20px !important"]}
                   borderRadius={"10px"}
                >
                    <BoxWithBorder
                        title={'Dados da Unidade'}
                        padding={["0px", "20px"]}
                        margin={["0px", "20px"]}
                        borderWidth={["0px", "1px"]}
                    >
                        <>
                            <Box w={["100%", "50%"]}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Unidade
                                </FormLabel>
                                <Select<IOptions>
                                    placeholder={'Selecione...'}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(val: SingleValue<IOptions>) => {
                                        if(val){
                                            if(val.value.toString() !== currentBookingRequest.unitId){
                                                dispatch(setCurrentBookingRequest({
                                                    ...currentBookingRequest,
                                                    unitId: val.value.toString(),
                                                    localId: "0",
                                                    department: {
                                                        ...currentBookingRequest.department,
                                                        id: ""
                                                    },
                                                    milestoneId: "0",
                                                    timesheets: [],
                                                    reservationDate: "",
                                                    bookingType: "0"
                                                }));
                                            }
                                        }else{
                                            dispatch(setCurrentBookingRequest({
                                                ...currentBookingRequest,
                                                unitId: '0',
                                                localId: "0",
                                                department: {
                                                    ...currentBookingRequest.department,
                                                    id: ""
                                                },
                                                milestoneId: "0",
                                                timesheets: [],
                                                reservationDate: "",
                                                bookingType: "0"
                                            }));

                                            setCurrentContactList([]);

                                            setImageEvent("");
                                        }
                                    }}
                                    value={currentBookingRequest.unitId ? { value: currentBookingRequest.unitId, label: getLabelByValue(currentBookingRequest.unitId, units)}: { value: '', label: '' }}
                                    isClearable={true}
                                    name="color"
                                    options={units}
                                    isDisabled={currentBookingRequest.id !== 0}
                                />
                            </Box>
                        </>
                    </BoxWithBorder>
                    
                    {currentBookingRequest.unitId !== '0' && (
                        <BoxWithBorder
                            title={'Dados da Reserva'}
                            padding={["0px", "20px"]}
                            margin={["0px", "20px"]}
                            borderWidth={["0px", "1px"]}
                            marginTop={["50px", "0px"]}
                            display={"flex"}
                            flexDirection={["column", "column"]}
                        >
                            <>
                                <Box marginTop={["10px", "0px"]}>
                                    <RadioGroup 
                                        onChange={(e: any) => {
                                            dispatch(setCurrentBookingRequest({
                                                ...currentBookingRequest, 
                                                bookingType: e,
                                                availableBookingTimes: [],
                                                timesheets: [],
                                                reservationDate: "",
                                                localId: "0",
                                                milestoneId: "0"
                                            }));
                                        }} 
                                        value={currentBookingRequest.bookingType}
                                        isDisabled={currentBookingRequest.id !== 0}
                                    >
                                        <Stack direction='row'>
                                            {(singlePersonEvent === "both" || singlePersonEvent === "false") && (
                                                <Radio value='Coletivo' borderColor={"#777"}>Coletivo</Radio>
                                            )}
                                            {(singlePersonEvent === "both" || singlePersonEvent === "true") && (
                                                <Radio value='Individual' borderColor={"#777"}>Individual</Radio>
                                            )}
                                        </Stack>
                                    </RadioGroup>
                                </Box>
                                
                                {currentBookingRequest.unitId !== '0' && currentBookingRequest.bookingType !== "0" && (
                                    <Flex
                                        w={"100%"}
                                        flexDir={["column", "row"]}
                                        alignItems={'flex-end'}
                                        marginTop={"10px"}
                                    >
                                        <Box
                                            w={["100%", "50%"]}
                                            padding={["0px 2.5px", "0px 2.5px"]}
                                        >
                                            <FormLabel
                                                fontSize={"0.8em"}
                                                color={"#777"}
                                                marginBottom={"3px"}
                                            >
                                                Tipo de Reserva
                                            </FormLabel>
                                            <Select<IOptions>
                                                placeholder={'Selecione...'}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                onChange={(val: SingleValue<IOptions>) => {
                                                    if(val){
                                                        if(val.value.toString() !== currentBookingRequest.milestoneId) {
                                                            setLocals([]);

                                                            dispatch(setCurrentBookingRequest({
                                                                ...currentBookingRequest, 
                                                                milestoneId: val.value.toString(),
                                                                guestType: milestones.filter(res => res.value.toString() === val.value.toString())[0]!.guestType,
                                                                localId: '0',
                                                                reservationDate: "",
                                                                availableBookingTimes: []
                                                            }));

                                                            onChangeStartHourFilter('');

                                                            onChangeEndHourFilter('');
                                                        }
                                                    } else {
                                                        dispatch(setCurrentBookingRequest({
                                                            ...currentBookingRequest, 
                                                            milestoneId: '0',
                                                            guestType: 'EXTERNAL',
                                                            localId: '0',
                                                            reservationDate: ""
                                                        }));

                                                        onChangeStartHourFilter('');

                                                        onChangeEndHourFilter('');
                                                    }
                                                }}
                                                isClearable={true}
                                                name="color"
                                                value={{ value: currentBookingRequest.milestoneId, label: getLabelByValue(currentBookingRequest.milestoneId, milestones)}}
                                                options={milestones}
                                                isDisabled={currentBookingRequest.id !== 0}
                                            />
                                            <span className='message-error'>{ messageNoLocals }</span>
                                        </Box>
                                        {
                                            !isCheckedFilterByDateAndHour &&
                                            <Box
                                                w={["100%", "50%"]}
                                                marginTop={["10px", "0px"]}
                                            >
                                                <FormLabel
                                                    fontSize={"0.8em"}
                                                    color={"#777"}
                                                    marginBottom={"3px"}
                                                >
                                                    Espaço para Reserva
                                                </FormLabel>
                                                <Select<IOptions>
                                                    placeholder={'Selecione...'}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    onChange={(val: SingleValue<IOptions>) => {
                                                        if(val){
                                                            if(val.value.toString() !== currentBookingRequest.localId) {
                                                                dispatch(setCurrentBookingRequest({
                                                                    ...currentBookingRequest, 
                                                                    localId: val.value.toString(),
                                                                    availableBookingTimes: [],
                                                                    timesheets: [],
                                                                    reservationDate: "",
                                                                }));

                                                                setReloadLocalEHorarioDaReserva(true);

                                                                setTimeout(() => {
                                                                    setReloadLocalEHorarioDaReserva(false);
                                                                }, 500);
                                                            }
                                                        } else {
                                                            dispatch(setCurrentBookingRequest({
                                                                ...currentBookingRequest, 
                                                                localId: '0',
                                                                reservationDate: "",
                                                            }));
                                                        }
                                                    }}
                                                    isClearable={true}
                                                    name="color"
                                                    value={{
                                                        value: currentBookingRequest.localId,
                                                        label: getLabelByValue(currentBookingRequest.localId, locals)
                                                    }}
                                                    options={locals}
                                                    isDisabled={currentBookingRequest.id !== 0}
                                                />
                                            </Box>
                                        }
                                        
                                    </Flex>
                                )}
                                {currentBookingRequest.milestoneId && moreThanOneLocation && (
                                    <Box
                                        w={["100%", "100%"]}
                                        padding={["0px 2.5px", "0px 2.5px"]}
                                        marginTop={'5'}
                                        marginBottom={'5'}
                                        style={{backgroundColor: '#FFF', padding: 10, borderRadius: 10}}
                                    >
                                        <Checkbox 
                                            isChecked={isCheckedFilterByDateAndHour}
                                            onChange={(e) => {
                                                setIsCheckedFilterByDateAndHour(e.target.checked);
                                                
                                                if(e.target.checked){
                                                    setLocals([]);
                                                }else{
                                                    loadLocals();
                                                }
                                            }}
                                        >Filtrar locais por data e horário?</Checkbox>
                                    </Box>
                                )}
                            </>
                        </BoxWithBorder>
                    )}

                    {(
                        currentBookingRequest.localId !== '0'
                        ||
                        (
                            isCheckedFilterByDateAndHour
                            &&
                            currentBookingRequest.milestoneId
                            && currentBookingRequest.milestoneId !== '0'
                        )
                    ) && (
                        <DateOrHoursEvent
                            disabledButton={disabledButton}
                            isCheckedFilterByDateAndHour={isCheckedFilterByDateAndHour}
                            locals={locals}
                            setLocals={setLocals}
                            onChangeEndHourFilter={onChangeEndHourFilter}
                            onChangeStartHourFilter={onChangeStartHourFilter}
                            reloadLocalEHorarioDaReserva={reloadLocalEHorarioDaReserva}
                            setCommonAreaRuleList={setCommonAreaRuleList}
                            setMessageNoLocals={setMessageNoLocals}
                            setReloadLocalEHorarioDaReserva={setReloadLocalEHorarioDaReserva}
                            valueEndHourFilter={valueEndHourFilter}
                            valueStartHourFilter={valueStartHourFilter}
                        />
                    )}        

                    {currentBookingRequest.reservationDate !== '' &&
                        (currentBookingRequest.timesheets.length > 0) && 
                        (!needApproval || currentBookingRequest.id !== 0) && (
                            <BoxWithBorder
                                title={'Dados do Evento'}
                                padding={["0px", "20px"]}
                                margin={["0px", "20px"]}
                                borderWidth={["0px", "1px"]}
                                display={"flex"}
                                marginTop={["50px", "0px"]}
                                flexDir={["column"]}
                            >
                                <>
                                    <Box marginTop={"10px"}>
                                        <FormLabel
                                            fontSize={"0.8em"}
                                            color={"#777"}
                                            marginBottom={"3px"}
                                        >
                                            Título
                                        </FormLabel>
                                        <Input
                                            background={"#fff"}
                                            placeholder={"Informe o título da sua reserva"}
                                            onChange={(e) =>setTitle(e.target.value)}
                                            value={title}
                                        />
                                    </Box>
                                    <Box marginTop={"10px"}>
                                        <FormLabel
                                            fontSize={"0.8em"}
                                            color={"#777"}
                                            marginBottom={"3px"}
                                        >
                                            Descrição
                                        </FormLabel>
                                        <Textarea
                                            resize={"none"}
                                            background={"#fff"}
                                            placeholder={"Informe a descrição da sua reserva"}
                                            onChange={(e) => setDescription(e.target.value)}
                                            value={description}
                                            rows={5}
                                            maxLength={1000}
                                        />
                                    </Box>
                                    {currentBookingRequest.bookingType === "Coletivo" && (
                                        <Box marginTop={"10px"} w={"100%"}>
                                            <FormLabel
                                                fontSize={"0.8em"}
                                                color={"#777"}
                                                marginBottom={"3px"}
                                            >
                                                Adicione Convidado(s)
                                            </FormLabel>
                                            <SelectContacts
                                                guestType={currentBookingRequest.guestType}
                                                contactsSelecteds={currentContactList.map((res) => {
                                                    return {
                                                        ...res,
                                                        editable: false,
                                                        selected: true
                                                    }
                                                })}
                                                showButtonChangeReleaseType={showButtonChangeReleaseType}
                                                saveFunction={handleChangeContactGuest}
                                            />
                                        </Box>
                                    )}
                                </>
                            </BoxWithBorder>
                        )
                    }

                    {currentBookingRequest.bookingType === "Coletivo" && 
                        currentBookingRequest.reservationDate !== '' &&
                        (currentBookingRequest.timesheets.length > 0) && 
                        (!needApproval || currentBookingRequest.id !== 0) && (
                            <BoxWithBorder
                                title={'Imagem do Evento'}
                                padding={["0px", "20px"]}
                                margin={["0px", "20px"]}
                                borderWidth={["0px", "1px"]}
                                display={"flex"}
                                marginTop={["50px", "0px"]}
                                flexDir={["column"]}
                            >
                                <ImageUpload
                                    responseFunction={setImageEvent}
                                />
                            </BoxWithBorder>
                        )
                    }

                    <Flex
                        flexDir={["column-reverse", "row"]}
                        justifyContent={["row", "space-between"]}
                        marginTop={["20px", "0px"]}
                        padding={["0px", "0px 20px"]}
                    >
                        <Button 
                            colorScheme='teal' 
                            isDisabled={disabledButton}
                            onClick={() => navigate(-1)}
                        >
                            Voltar
                        </Button>
                        <Button 
                            isDisabled={disabledButton}
                            colorScheme='teal' 
                            onClick={validation}
                            marginBottom={["10px", "0px"]}
                        >
                            {needApproval ? "Confirmar Reserva" : "Finalizar"}
                        </Button>
                    </Flex>
                </Flex>
            </GridItem>
            <GridItem colSpan={1} />
        </Grid>
    )
}

interface ColourOption {
    readonly value: string;
    readonly label: string;
    readonly color: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
}

const colourStyles: StylesConfig<any, boolean> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);

        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor:
                    !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.color ?? 'black');

        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
};