import styled from 'styled-components'

import {
    Flex,
} from '@chakra-ui/react'

export const ContainerLoad = styled.div`
    height: calc(100vh - 175px);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Container = styled.div`
    background-color: #FFF;
    border-radius: 10px;
    padding: 10px;
    max-height: calc(100vh - 175px);
    overflow-y: auto;   
`

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
`

export const NoDataMessageContainer = styled.div`
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ButtonsListContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ContentHeader = styled(Flex)`
    > p:nth-child(2) {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
    }
`