import { createGlobalStyle } from 'styled-components';

import "react-datepicker/dist/react-datepicker.css";

import 'react-calendar/dist/Calendar.css';


export default createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  * {
    outline: none !important;
  }
  
  .drawerHeader {
    > div {
      > button {
        background-color: red;
      }
    }
  }

  .chakra-modal__content-container {
    padding: 0px 20px;
  }

  .react-calendar.calendar {
    border: 1px solid #cccccc;
    border-radius: 8px;
  }

  .react-calendar__tile:disabled {
    background-color: transparent !important;
    opacity: 0.2 !important;
  }

  .react-calendar__tile--now > abbr {
    background: #63c3c1;
    padding: 0px !important;
    border-radius: 50%;
    color: #fff;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-calendar__tile--now , .react-calendar__tile--active {
    background: transparent !important;
  }

  button.react-calendar__tile.react-calendar__month-view__days__day {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .react-calendar__tile--active > abbr {
    background: #319795;
    padding: 0px !important;
    border-radius: 50%;
    color: #fff;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-calendar__tile--active > abbr {
    background: rgb(49, 151, 149);
    padding: 0px !important;
    border-radius: 50%;
    color: rgb(255, 255, 255);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-calendar__month-view__weekdays__weekday > abbr[title] {
    text-decoration: none;
    color: #777;
  }

  span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    color: #777;
    font-weight: bold;
  }

  button.react-calendar__tile.react-calendar__month-view__days__day > abbr {
    font-size: 12px;
  }

  .react-confirm-alert {
    position: relative !important;

    button.closeTimes {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .react-confirm-alert-body {
    width: auto !important;
  }

  > .calendar-events {
    background: red !important;
    width: 100%;
    height: 100%;

    > .rbc-toolbar {
      > .rbc-toolbar-label {
        order: 3 !important;
      }
    }
  }

  a.page-link {
    color: #3eb7c0 !important;
  }

  .active>.page-link, .page-link.active {
    z-index: 3;
    color: #fff;
    background-color: #3eb7c0;
    border-color: #3eb7c0;
  }

  p{
        
        margin-bottom: 0px;
    }
`