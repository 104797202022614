import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IData {
    id: number,
    name: string,
    cellPhone: string,
    email: string,
    favorite: boolean,
    userId: number,
    clientId: number
}

const initialState = { 
    data: { 
        id: 0,
        name: '',
        cellPhone: '',
        email: '',
        favorite: false,
        userId: 0,
        clientId: 0
  } as IData
}
  
export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        resetCurrentContact: (state) => {
            state.data = initialState.data
        },
        setCurrentContact: (state, action: PayloadAction<IData>) => {
            state.data = action.payload
        },
    },
})

export const { resetCurrentContact, setCurrentContact } = contactSlice.actions

export default contactSlice.reducer