import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import jwt_decode from "jwt-decode";

import { ref, set } from "@firebase/database";

import { 
  Box,
  Text,
  Button,
  Grid,
  GridItem,
  Flex,
  FormLabel,
  Input,
  Select,
  Checkbox,
  Stack,
  CircularProgress,
} from '@chakra-ui/react';

import { FirebaseRealtimeDatabase } from '../../../../../services/firebase';

import api from '../../../../../services/api';

import { RootState } from '../../../../../store';

import { IUserData } from '../../../../../interfaces/IUserData'

type Props = {
  id: number;
  manufacturer: string;
  model: string;
  year: string;
  licensePlate: string;
  color: string;
  isDeleted?: boolean;
  privateLocalId?: number;
  owner: boolean;
  userId: number;
  integer?: boolean;
  uhfRecognition: null | string;
}

type PropsList = {
  label: string;
  value: number;
}

type PropsGetList = {
  data: {
    id: number;
    name: string;
    segment: string;
    iotMiddleware: boolean;
    iotMiddlewareId: number;
    submodule: {
      id: number;
      name: string
    };
    units: number;
  }
}

export function VehiclesDetails() {
  const navigate = useNavigate();

  const location = useLocation().state as {
    data: Props
    vehiclesType: 'CARRO' | 'MOTO'
    index: number
  };

  const { index } = location;

  const user = useSelector((state: RootState) => state.user.data)

  const [currentVehicles, setCurrentVehicles] = useState<Props>({
    id: 0,
    manufacturer: "",
    model: "",
    year: "",
    licensePlate: "",
    color: "",
    owner: true,
    userId: 0,
    uhfRecognition: '',
  });
  const [semParar, setSemParar] = useState<boolean>(false);
  const [manufacturerList, setManufacturerList] = useState<PropsList[]>([]);
  const [modelList, setModelList] = useState<PropsList[]>([]);
  const [yearOfManufactureList, setYearOfManufactureList] = useState<PropsList[]>([]);
  const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(false);
  const [disabledFields, setDisabledFields] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function configInit() {
      const userToken = localStorage.getItem('access_portal:access_token');

      const decoded = jwt_decode(userToken as string) as IUserData

      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }

      const { data } = await api.get(`vehicle/types/${location.vehiclesType === 'CARRO' ? 0 : 1}/manufacturers`, config);

      let loadVehicles = {
        ...JSON.parse(JSON.stringify(currentVehicles)),
        id: location.data.id || 0,
        manufacturer: '',
        model: '',
        integer: location.data.integer,
        year: location.data.year || "",
        licensePlate: location.data.licensePlate || "",
        color: location.data.color || "",
        owner: location.data.owner || "",
        uhfRecognition: location.data.uhfRecognition || "",
        userId: Number(decoded.user_id) || 0,
      }

      if(!location.data.id) {
        setIsLoading(false)
      }

      if(location.data.id) {
        if(data.filter((res: { name: string, id:number }) => res.name === location.data.manufacturer)[0]?.id) {
          const { data: model } = await api.get(`vehicle/types/${location.vehiclesType === 'CARRO' ? 0 : 1}/manufacturers/${data.filter((res: { name: string, id:number }) => res.name === location.data.manufacturer)[0]?.id}/models`);
          
          loadVehicles.model = model.filter((res: { name: string, id:number }) => res.name === location.data.model)[0]?.id;
        }

        loadVehicles.manufacturer = data.filter((res: { name: string, id:number }) => res.name === location.data.manufacturer)[0]?.id || ""
      }
      
      setCurrentVehicles(loadVehicles);

      setManufacturerList(data.map((res: { name: string, id:number }) => {
        return {
          label: res.name,
          value: res.id
        }
      }));

      type UserGeneralRuleProps = {
        semParar: boolean;
      }

      const { data: dataSemParar } : { data: UserGeneralRuleProps[] } = await api.get(`UserGeneralRule/UserGeneralRuleList/${decoded.client_id}`, config);

      setSemParar(dataSemParar[0].semParar);

      if(!currentVehicles.manufacturer) {
        setIsLoading(false)
      }
    }

    configInit();
  }, []);

  useEffect(() => {
    async function getModel() {
      const { data } = await api.get(`vehicle/types/${location.vehiclesType === 'CARRO' ? 0 : 1}/manufacturers/${currentVehicles.manufacturer}/models`);

      setModelList(data.map((res: { name: string, id:number }) => {
        return {
          value: res.id,
          label: res.name
        }
      }))
    }

    if(currentVehicles.manufacturer) {
      getModel();
    }
  }, [currentVehicles.manufacturer]);

  useEffect(() => {
    async function getYers() {
      try {
        const { data } = await api.get(`vehicle/types/${location.vehiclesType === 'CARRO' ? 0 : 1}/manufacturers/${currentVehicles.manufacturer}/models/${currentVehicles.model}/years`);

        setYearOfManufactureList(data.map((res: { year: string, fuelType: any[] }) => {
          return {
            value: res.year,
            label: res.year
          }
        }));

        setIsLoading(false)
      } catch(err: any) {
        toast.error(
          err &&
          err.response &&
          err.response.data &&
          err.response.data.Message
        );
      }
    }

    if(currentVehicles.model) {
      getYers();
    }
  }, [currentVehicles.model]);

  useEffect(() => {
    setDisabledFields(!!(user.cpf && currentVehicles.integer && semParar && currentVehicles.uhfRecognition))
  }, [user.cpf, currentVehicles.integer, semParar, currentVehicles.uhfRecognition])

  function validaPlaca() {
    const placa = currentVehicles.licensePlate;

    if(placa.length < 7){
      return false
    }

    let er = /^[0-9]+$/;
    let qtNumeros = 0;
    let qtLetras = 0;

    for(let i = 0; i < placa.length; i++){
      if(er.test(placa[i])){
        qtNumeros++
      }else{
        qtLetras++
      }
    }

    if(qtNumeros<3 || qtLetras<3){
      return false
    }

    return true
  }

  async function handlerUpdate() {
    if(currentVehicles.manufacturer.length < 1) {
      return toast.error("O campo marca é obrigatório.")
    }

    if(currentVehicles.model.length < 1) {
      return toast.error("O campo modelo é obrigatório.")
    }

    if(currentVehicles.year.length < 1) {
      return toast.error("O campo ano de fabricação é obrigatório.")
    }

    if(currentVehicles.licensePlate.length < 1) {
      return toast.error("O campo placa é obrigatório.")
    }
    
    if(!validaPlaca()) {
      return toast.error("Placa inválida!")
    }

    if(currentVehicles.color.length < 1) {
      return toast.error("O campo cor é obrigatório.")
    }

    setDisabledSaveButton(true);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
      }
    }

    const url = currentVehicles.id ? "/vehicle/update" : "/vehicle/create";

    const { id, ...rest } = currentVehicles;

    const manufacturer: string = manufacturerList.filter(res => res.value === Number(currentVehicles.manufacturer))[0].label as string;

    const model: string = modelList.filter(res => res.value === Number(currentVehicles.model))[0].label as string

    let post = {} as {
      id?: number,
      pedingActive: boolean;
      manufacturer: string;
      type: 'CARRO' | 'MOTO';
      model: string;
      year: string;
      licensePlate: string;
      color: string;
      isDeleted?: boolean | undefined;
      privateLocalId?: number | undefined;
      owner: boolean;
      userId: number;
      integer?: boolean | undefined;
      uhfRecognition: string | null;
    };

    if(id) {
      post = {
        ...rest,
        id,
        manufacturer,
        model,
        pedingActive: semParar,
        type: location.vehiclesType,
      };
    } else {
      post = {
        ...rest,
        manufacturer,
        model,
        pedingActive: semParar,
        type: location.vehiclesType,
      };
    }

    try {      
      const { data: response } = await api.post(url, post, config);

      if(!semParar) {
        const { data } : PropsGetList = await api.get('client/getlist', config);

        const userToken = localStorage.getItem('access_portal:access_token');

        const decoded = jwt_decode(userToken as string) as IUserData;
            
        const selectedClient = decoded.client_id;

        const clientId = selectedClient.split('#')[0];

        const newPack = { 
          date: new Date().toString(),
          userId: parseInt(decoded.user_id),
          source: 'user'
        };

        if(data.iotMiddleware) {
          set(
            ref( 
              FirebaseRealtimeDatabase, 
              "/middleware-creation-or-change/"+clientId+'/' + parseInt(decoded.user_id)
            ),
            newPack
          );
        };
      } else {
        await api.post(
          'vehicle/userVehicleTemplate',
          {
            UserVehicleId: response.id,
          },
          config
        );
      }

      navigate('/my-profile', {
        state: {
          index
        }
      });

      toast.success(`Veículo ${currentVehicles.id ? "atualizado": "cadastrado" } com sucesso!`);
    } catch(err :any) {
      toast.error(err?.response?.data?.Message);

      if(err?.response?.data?.Message === 'Placa está ativo para mais de um cliente.' ||
      err?.response?.data?.Message === 'Placa não encontrada.') {
        navigate('/my-profile', {
          state: {
            index
          }
        });
      }
      
      setDisabledSaveButton(false);
    }
  }

  if(isLoading) {
    return (
      <Flex
        position={"relative"}
        h={"calc(100vh - 175px)"}
        w={"100%"}
        top={"0px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
      </Flex>
    )
  }

  return (
    <Grid templateColumns={["0% 100% 0%", "30% 40% 30%"]}>
      <GridItem colSpan={1} />
      <GridItem
        colSpan={1}
        paddingTop={"20px"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Text
          fontSize={[28, 20]}
          fontWeight={600}
          color={"#444"}
          marginBottom={"10px"}
        >
          {currentVehicles.id ? "Editar" : "Cadastrar"} veículo
        </Text>

        <Flex
          flexDir={"column"}
          background={["inherit", "#fff"]}
          w={"100%"}
          padding={["0px", "20px"]}
          borderRadius={"10px"}
        >
          {currentVehicles.uhfRecognition && (
            <Box marginBottom={"10px"}>
              <FormLabel
                fontSize={"0.8em"}
                color={"#777"}
                marginBottom={"3px"}
              >
                TAG vinculada {location.vehiclesType === 'CARRO' ? 'ao carro' : 'a moto'}?
              </FormLabel>
              <Input
                width={"100%"}
                background={"#fff"}
                onChange={() => {}}
                isDisabled
                value={currentVehicles.uhfRecognition}
              />
            </Box>
          )}
          <Box marginBottom={"10px"}>
            <FormLabel
              fontSize={"0.8em"}
              color={"#777"}
              marginBottom={"3px"}
            >
              Qual a marca {location.vehiclesType === 'CARRO' ? 'do carro' : 'da moto'}?
            </FormLabel>
            <Select
              width={"100%"}
              background={"#fff"}
              onChange={async (e) => {
                setCurrentVehicles({
                  ...JSON.parse(JSON.stringify(currentVehicles)),
                  manufacturer: e.target.value,
                  model: "",
                  year: ""
                });
              }}
              value={currentVehicles.manufacturer}
              isDisabled={disabledFields}
            >
              <option value='' disabled>Selecionar marca</option>
              {manufacturerList.map((res: PropsList) => {
                return <option key={res.value} value={res.value}>{res.label}</option>
              })}  
            </Select>
          </Box>
          <Box marginBottom={"10px"}>
            <FormLabel
              fontSize={"0.8em"}
              color={"#777"}
              marginBottom={"3px"}
            >
              Qual o modelo {location.vehiclesType === 'CARRO' ? 'do carro' : 'da moto'}?
            </FormLabel>
            <Select
              width={"100%"}
              background={"#fff"}
              onChange={async (e) => {
                setCurrentVehicles({
                  ...JSON.parse(JSON.stringify(currentVehicles)),
                  model: e.target.value,
                  year: ''
                });
              }}
              value={currentVehicles.model}
              isDisabled={disabledFields}
            >
              <option value='' disabled>Selecionar o modelo</option>
              {modelList.map((res: PropsList) => {
                return <option key={res.value} value={res.value}>{res.label}</option>
              })}  
            </Select>
            {location.data.model && modelList.length > 0 && !location.data.integer && (
              <Box>
                <Text color={"red"}>Modelo anterior: {location.data.model}</Text>
              </Box>
            )}
          </Box>
          <Box marginBottom={"10px"}>
            <FormLabel
              fontSize={"0.8em"}
              color={"#777"}
              marginBottom={"3px"}
            >
              Qual o ano de fabricação {location.vehiclesType === 'CARRO' ? 'do carro' : 'da moto'}?
            </FormLabel>
            <Select
              width={"100%"}
              background={"#fff"}
              onChange={async (e) => {
                setCurrentVehicles({
                  ...JSON.parse(JSON.stringify(currentVehicles)),
                  year: e.target.value
                });
              }}
              value={currentVehicles.year}
              isDisabled={disabledFields}
            >
              <option value='' disabled>Selecionar o ano</option>
              {yearOfManufactureList.map((res: PropsList) => {
                return <option key={res.value} value={res.value}>{res.label}</option>
              })}  
            </Select>
          </Box>
          {currentVehicles.manufacturer && currentVehicles.model && currentVehicles.year && (
            <>  
              <Box marginBottom={"10px"}>
                <FormLabel
                  fontSize={"0.8em"}
                  color={"#777"}
                  marginBottom={"3px"}
                >
                  Qual a placa {location.vehiclesType === 'CARRO' ? 'do carro' : 'da moto'}?
                </FormLabel>
                <Input
                  style={{ background: "#fff", borderRadius: "4px", width: "100%" }}
                  placeholder={'Ex: BRA2E23'}
                  maxLength={7}
                  onChange={(e: any) =>{
                    setCurrentVehicles({
                      ...JSON.parse(JSON.stringify(currentVehicles)),
                      licensePlate: e.target.value.toUpperCase()
                    })
                  }}
                  value={currentVehicles.licensePlate}
                  isDisabled={disabledFields}
                />
              </Box>
              <Box marginBottom={"20px"}>
                <FormLabel
                  fontSize={"0.8em"}
                  color={"#777"}
                  marginBottom={"3px"}
                >
                  Qual cor {location.vehiclesType === 'CARRO' ? 'do carro' : 'da moto'}?
                </FormLabel>
                <Input
                  background={"#fff"}
                  placeholder={'Ex: Preto'} 
                  onChange={(e) => {
                    setCurrentVehicles({
                      ...JSON.parse(JSON.stringify(currentVehicles)),
                      color: e.target.value
                    })
                  }}
                  value={currentVehicles.color}
                  isDisabled={disabledFields}
                />
              </Box>
              {(((!location.data?.id && semParar) || (semParar && currentVehicles.uhfRecognition && location.data?.id)) && !disabledFields) && (
                <Stack direction='row' marginBottom={'20px'}>
                  <Checkbox
                    isChecked={checkedTerms}
                    onChange={(e) => setCheckedTerms(e.target.checked)}
                    isDisabled={disabledFields}
                  >
                    <Text
                      fontSize='13px'
                      color={'#696969'}
                      fontWeight={500}
                      fontStyle={'italic'}
                    >
                      Para efetuar o cadastro desse veículo no seu empreendimento, devido a tecnologia SEM PARAR, concordo em compartilhar os dados necessários com a empresa SEM PARAR INSTITUIÇÃO DE PAGAMENTO LTDA.
                    </Text>
                  </Checkbox>
                </Stack>
              )}
            </>
          )}
          <Flex
            flexDir={["column-reverse", "row"]}
            justifyContent={["initial", "space-between"]}
          >
            <Button 
              colorScheme='teal' 
              onClick={() => {                
                navigate('/my-profile', {
                state: {
                  index
                }
              })}}
              isDisabled={disabledSaveButton}
            >
              Voltar
            </Button>
            <Button 
              colorScheme='teal' 
              onClick={handlerUpdate}
              marginBottom={["10px", "0px"]}
              isDisabled={semParar
                ? (!currentVehicles.id && !checkedTerms)
                : disabledSaveButton
              }
            >
              Salvar
            </Button>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={1} />
    </Grid>
  );
}
