
export const TrataGuest = (val: any) => {
    let value = val.GuestId || val.contactId
    if(typeof value === 'undefined') {
      value = val.guestId
      if(typeof value === 'undefined') {
        value = val
      }
    }
    return value.id
}