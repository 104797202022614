import React, { useEffect, useState } from 'react'

import {
  MdModeEdit,
  MdRemoveRedEye,
  MdLocationPin,
} from "react-icons/md"

import {
  FaUser,
  FaUsers,
  FaClock
} from "react-icons/fa"

import { useNavigate, useLocation } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import {
  Box,
  Flex,
  Text,
  Button,
  CircularProgress,
  Grid,
  GridItem,
} from '@chakra-ui/react'

import api from '../../../services/api'

import { resetCurrentBookingRequest } from '../../../store/reducers/booking-request'

import { resetEvent } from '../../../store/reducers/event'

import { hasPermission } from '../../../utils/check-permission'

import { expiredEventCheck } from '../../../utils/expiredEventCheck'

import { NewBookingRequestButton } from "../../../components/NewBookingRequestButton"

import { ContainerLoad, NoDataMessageContainer, ContentHeader } from './styles'

import { format, parseISO } from 'date-fns'

import jwt_decode from "jwt-decode"

import { IUserData } from '../../../interfaces/IUserData'

function getMoth(moth: number) {
  switch(moth) {
    case 1:            
      return 'Jan'
      break
    case 2:            
      return 'Fev'
      break
    case 3:
      return 'Mar'
      break
    case 4:
      return 'Abr'
      break
    case 5:
      return 'Mai'
      break
    case 6:
      return 'Jun'
      break
    case 7:
      return 'Jul'
      break
    case 8:
      return 'Ago'
      break
    case 9:
      return 'Set'
      break
    case 10:
      return 'Out'
      break
    case 11:
      return 'Nov'
      break
    case 12:
      return 'Dez'
      break
    }
  }

export function EventList () {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const [data, setData] = useState<any[]>([])

  const [loading, setLoading] = useState(true)

  const [userId, setUserId] = useState(0)

  useEffect(() => {
    initContent()
  }, [])

  async function initContent() {
    const date = location.state.date

    const userToken = localStorage.getItem('access_portal:access_token')
    const decoded = jwt_decode(userToken as string) as IUserData

    setUserId(parseInt(decoded.user_id))

    dispatch(resetCurrentBookingRequest())
    dispatch(resetEvent())

    if(!date) {
      navigate(-1)
    }

    if(!(await hasPermission(1, 'Visualizar'))){
      navigate('/home')
    }
              
    try{
      const config = {
        headers: {
          Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
        }
      }

      const { data } = await api.get(`event/getbydate/${date}`, config)

      if(Array.isArray(data)){
        setData(data)
        
        // setTotalPages(data.totalPages)
      }

      setLoading(false)
    }catch(e) {

    }
  }
    
  async function initFirebase(){
      // const decoded = jwt_decode(userToken as string) as IUserData
      // let selectedClient = decoded.client_id
  
      // let clientId = selectedClient.split('#')[0]
      // let date = new Date()
      // let dia = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate()
      // let mes = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
      // let ano = date.getFullYear()
  
      // let dataFormatted = dia + '-' + mes + '-' + ano

      // for(let i in decoded.unit_id) {
      //     onValue(ref(
      //         FirebaseRealtimeDatabase, 
      //         '/event-creation-or-change/' + decoded.unit_id[i]
      //     ), (snapshot) => {
      //         setPage(1)
              
      //         setTimeout(() => {
      //             initContent()
      //         }, 200)
      //     })
      // }

      // onValue(ref(
      //     FirebaseRealtimeDatabase, 
      //     '/new-commom-area-request/' + clientId + '/' + dataFormatted
      // ), (snapshot) => {
      //     setPage(1)

      //     setTimeout(() => {
      //         initContent()
      //     }, 200)
      // })
  }

  if(loading) {
    return (
      <ContainerLoad>
        <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
      </ContainerLoad>
    )
  }

  if(data.length < 1) {
    return (
      <Box>
        <Flex
          marginTop={["10px", "40px"]}
          marginBottom={"10px"}
          flexDir={["column", "row"]}
          justifyContent={["center", "space-between"]}
          alignItems={["flex-end", "flex-end"]}
        >
          <Flex
            justifyContent={["center", "flex-start"]}
            alignItems={["", "flex-end"]}
            width={["100%", "auto"]}
            marginBottom={["40px", "0px"]}
          >
            <Text
              fontSize={[28, 20]}
              fontWeight={600}
              color={"#444"}
            >
              Reservas de Espaço
            </Text>
          </Flex>
          <NewBookingRequestButton />
        </Flex>
        <NoDataMessageContainer>
          <span
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              color: "#818181"
            }}
          >
            Nenhum registro cadastrado ainda!
          </span>
        </NoDataMessageContainer>
      </Box>
    )
  }

  return (
    <Grid templateColumns={["0% 100% 0%", "35% 30% 35%"]}>
      <GridItem colSpan={1} />
      <GridItem colSpan={1}>
        <Box>
          <Flex
            marginTop={["10px", "40px"]}
            marginBottom={"10px"}
            flexDir={["column", "row"]}
            justifyContent={["center", "space-between"]}
            alignItems={["flex-end", "flex-end"]}
          >
            <Flex
              justifyContent={["center", "flex-start"]}
              alignItems={["", "flex-end"]}
              width={["100%", "auto"]}
              marginBottom={["40px", "0px"]}
            >
              <Text
                fontSize={[28, 20]}
                fontWeight={600}
                color={"#444"}
              >
                Reservas de Espaço
              </Text>
            </Flex>
            {/* <NewBookingRequestButton /> */}
            
          </Flex>
          <Flex flexDirection={"column"}>
            {data.map((v, i) => {
              
              return (
                <Flex
                  flexDirection={"row"}
                  background={"#fff"}
                  borderRadius={"8px"}
                  marginBottom={"10px"}
                  padding={"20px"}
                  key={"item_" + i}
                >
                  <Flex
                    flexDir={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <span style={{
                      color: '#9E9E9E',
                      width: 50,
                      fontSize: 18,
                      fontWeight: 'bold'
                    }}>{v.startDate.substr(8, 2)}</span>
                    <span style={{
                      color: '#9E9E9E',
                      width: 50,
                      fontSize: 18,
                      fontWeight: 'bold'
                    }}>{getMoth(parseInt(v.startDate.substr(5, 2)))}</span>
                  </Flex>
                  <Flex
                    flexDir={"column"}
                    flexGrow={1}
                    paddingLeft={"10px"}
                    w={"calc(100% - 50px)"}
                    borderLeft={"2px solid #4EBC7A"}
                  >
                    <Flex>
                      {v.qtyGuest > 0 && (
                        <ContentHeader
                          alignItems={"center"}
                          maxW={["50%", "initial"]}
                        >
                          <span style={{ marginRight: 7 }}>
                            {v.qtyGuest > 1
                              ? <FaUsers
                                  cursor="pointer" 
                                  fontSize={18}
                                  color={"#fe7013"} 
                                />
                              :   <FaUser
                                  cursor="pointer" 
                                  fontSize={18}
                                  color={"#fe7013"} 
                                />
                            }
                          </span>
                          <Text style={{ fontSize: 12 }}>
                            {v.qtyGuest > 0 && v.qtyGuest > 1 
                              ? `${v.qtyGuest} Convidados` 
                              : `${v.qtyGuest} Convidado` 
                            }
                          </Text>
                        </ContentHeader>     
                      )}
                      <ContentHeader
                        alignItems={"center"}
                        maxW={["50%", "initial"]}
                      >
                        <span>
                          <MdLocationPin size={18} color={"#fe7013"} />
                        </span>
                        <Text style={{
                          marginLeft: 5,
                          fontSize: 12,
                          textTransform: "capitalize"
                        }}>                                    
                          {v.local.name.toLowerCase()}
                        </Text>
                      </ContentHeader>
                    </Flex>
                    <Flex justify={"space-between"}>
                      <Box
                        width={"200px"}
                        display={"inline-block"}
                        flexGrow={1}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                      >
                        <Text
                          alignItems={"center"}
                          fontWeight={"700"}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          marginTop={"10px"}
                        >
                          {v.name}
                          {v.title}
                        </Text>
                        <Text
                          fontSize={"12px"}
                          marginTop={"10px"}
                          display={"flex"}
                          alignItems={"center"}
                          fontWeight={"700"}
                          color={"#9E9E9E"}
                        >
                          <FaClock color={"#fe7013"} style={{ marginRight: 5 }} />
                          {`${format(parseISO(v.startDate), "HH:mm")} - ${format(parseISO(v.endDate), "HH:mm")}`}
                        </Text>
                      </Box>
                      <Button
                        variant='link'
                        onClick={() => v.qtyGuest == 0
                          ? navigate("/event/details", { state: { id: v.id, userId: v.userId }})
                          : navigate("/event/details", { state: { id: v.id, userId: v.userId }})
                        }
                      >
                        {v.userId != userId ? 
                        (
                          <MdRemoveRedEye 
                            cursor="pointer" 
                            fontSize={25}
                            color={"#444"}
                          />
                        )
                        :
                        !expiredEventCheck(v.startDate)?
                           (
                              <MdModeEdit 
                                cursor="pointer" 
                                fontSize={25}
                                color={"#444"} 
                              />
                            )
                          : (
                              <MdRemoveRedEye 
                                cursor="pointer" 
                                fontSize={25}
                                color={"#444"}
                              />
                            )
                        }
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              )
            })}
          </Flex>
          <Flex>
            <Button
              colorScheme={"teal"}
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
          </Flex>
        </Box>
      </GridItem>
    </Grid>
  )
}
