import React, { useEffect, useState, useRef } from 'react';

import { useSelector } from 'react-redux';

import {
  Grid,
  GridItem,
  Flex,
  CircularProgress,
  Text,
  Box,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  TableContainer,
  Table,
  Thead,
  Td,
  Tr,
  Th,
  Tbody,
  useDisclosure,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';

import { MdModeEdit, MdDelete } from "react-icons/md";

import jwt_decode from 'jwt-decode'

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { ref, set, onValue, off } from "@firebase/database";

import { FirebaseRealtimeDatabase } from '../../../../../services/firebase';

import api from '../../../../../services/api';

import { hasPermission } from '../../../../../utils/check-permission';

import { RootState } from '../../../../../store';
import { IUserData } from '../../../../Notifications/Details';

type Props = {
  id: number;
  model: string;
  licensePlate: string;
  color: string;
  isDeleted: boolean;
  privateLocalId: number;
  owner: boolean;
  integer: boolean;
  uhfRecognition: string;
  type: 'CARRO' | 'MOTO';
  pendingOptIn: boolean;
}

interface IUserDataToken {
  user_id: number
  function: string
  client_id: string
  parking_slots: string
}

interface VehiclesList {
  setIndex: (value: number) => void
  index: number
}

export function List({ setIndex, index }: VehiclesList) {
  const navigate = useNavigate(); 

  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();

  const { isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4 } = useDisclosure();

  const { isOpen: isOpen5, onOpen: onOpen5, onClose: onClose5 } = useDisclosure();

  const user = useSelector((state: RootState) => state.user.data)

  const [vehiclesList, setVehiclesList] = useState<Props[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [limite, setLimit] = useState(0);

  const [id, setId] = useState(0);

  const [semParar, setSemParar] = useState(false);

  const [vehiclesType, setVehiclesType] = useState('CARRO');

  const [canAdd, setCanAdd] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [canDelete, setCanDelete] = useState(true);

  const cancelRef =  useRef<HTMLButtonElement>(null);

  const cancelRef2 =  useRef<HTMLButtonElement>(null);

  const cancelRef3 =  useRef<HTMLButtonElement>(null);

  const cancelRef4 =  useRef<HTMLButtonElement>(null);

  const cancelRef5 =  useRef<HTMLButtonElement>(null);

  useEffect(() => {
    configInit()
    let checkPermissions = async () => {
      let hasPermissionAdd = await hasPermission(6, 'Adicionar');
      let hasPermissionEdit = await hasPermission(6, 'Editar');
      let hasPermissionDelete = await hasPermission(6, 'Deletar');

      setCanAdd(hasPermissionAdd);
      setCanEdit(hasPermissionEdit);
      setCanDelete(hasPermissionDelete);
    }

    checkPermissions();
  }, []);

  async function configInit() {
    const userToken = localStorage.getItem('access_portal:access_token');

    const decoded = jwt_decode(userToken as string) as IUserDataToken

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }

    type UserGeneralRuleProps = {
      semParar: boolean;
    }

    const { data } : { data: UserGeneralRuleProps[] } = await api.get(`UserGeneralRule/UserGeneralRuleList/${decoded.client_id}`, config);

    setSemParar(data[0].semParar);

    const vagas = Number(decoded.parking_slots);

    setLimit(vagas);

    const { data: dataGetByUserVehicle } = await api.get('Vehicle/GetByUserVehicle', config);

    if(
      dataGetByUserVehicle.length > 0 &&
      dataGetByUserVehicle.filter((res: Props) => res.owner === false && res.integer === false).length > 0 &&
      user?.cpf &&
      data[0].semParar
    ) {
      toast.error(
        `Os dados do veículo ${
          dataGetByUserVehicle.filter((res: Props) => res.owner === false && res.integer === false)[0]?.model ||
          dataGetByUserVehicle.filter((res: Props) => res.owner === false && res.integer === false)[0]?.licensePlate
        } estão em ` +
        `desacordo com o esperado pelo SEM PARAR, para utilizar esse veículo é necessário acionar o seu responsável para ajustar o cadastro.`
      );
    }

    setVehiclesList(dataGetByUserVehicle);

    setIsLoading(false);
  }

  async function getVehicles() {
    const userToken = localStorage.getItem('access_portal:access_token');

    const decoded = jwt_decode(userToken as string) as IUserDataToken

    const vagas = Number(decoded.parking_slots);

    setLimit(vagas);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
      }
    };

    const { data } = await api.get('Vehicle/GetByUserVehicle', config);

    setVehiclesList(data);

    setIsLoading(false);
  }

  async function handlerDelete() {    
    onClose();

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
        }
      };

      const post = { isDeleted: true, id };

      await api.post('vehicle/delete', post, config);

      setId(0);

      getVehicles()

      firebase()

      toast.success("Veículo excluído com sucesso!");
    } catch(err) {
      toast.error("Ocorreu um erro");
    }
  }

  async function firebase() {
    try {
      const userToken = localStorage.getItem('access_portal:access_token');

      const decoded = jwt_decode(userToken as string) as IUserData
      
      const selectedClient = decoded.client_id;

      const clientId = selectedClient.split('#')[0];

      const newPack = { 
        date: new Date().toString(),
        userId: parseInt(decoded.user_id),
        source: 'user'
      };

      set(
        ref( 
          FirebaseRealtimeDatabase, 
          "/middleware-creation-or-change/"+clientId+'/' + parseInt(decoded.user_id)
        ),
        newPack
      )
    }catch(e) {
      console.log('sendFirebase-error->', e)
    }
  }
  
  if(isLoading) {
    return (
      <Flex
        position={"relative"}
        h={"calc(100vh - 175px)"}
        w={"100%"}
        top={"0px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
      </Flex>
    )
  }

  return (
    <Box
      paddingLeft={'15px'}
      paddingRight={'15px'}
      paddingTop={'17px'}
    >
      {/** Modal para confirmacao de exclusao */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Atenção!
            </AlertDialogHeader>
            <AlertDialogBody>
              Deseja realmente excluir este veículo?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                background={"#d36262"}
                color={"#fff"}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button 
                colorScheme='teal'
                onClick={handlerDelete} 
                ml={3}
              >
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/** Modal para selecionar tipo de veiculo */}
      <AlertDialog
        isOpen={isOpen2}
        leastDestructiveRef={cancelRef2}
        onClose={onClose2}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Atenção!
            </AlertDialogHeader>
            <AlertDialogBody>
              Qual o tipo do veículo?
                            
              <Box marginTop={["20px"]} marginBottom={["10px"]}>
                <RadioGroup 
                  onChange={(val) => setVehiclesType(val)} 
                  value={vehiclesType}
                >
                <Stack direction='row'>
                  <Radio value='CARRO' borderColor={"#777"}>Carro</Radio>
                  <Radio value='MOTO' borderColor={"#777"}>Moto</Radio>
                </Stack>
                </RadioGroup>
              </Box>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                background={"#d36262"}
                color={"#fff"}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
                onClick={onClose2}
              >
                Cancelar
              </Button>
              <Button 
                colorScheme='teal'
                onClick={() => navigate('/vehicles/register', { state: { data: id ? vehiclesList.filter(res => res.id === id)[0] : {} , vehiclesType, index }})} 
                ml={3}
              >
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/** Modal para informar sobre cadsatro de CPF */}
      <AlertDialog
        isOpen={isOpen3}
        leastDestructiveRef={cancelRef3}
        onClose={onClose3}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Atenção!
            </AlertDialogHeader>
            <AlertDialogBody>
              Para editar um veículo é necessário informar seu CPF, pois ele é mandatório para uso do SEM PARAR, deseja informá-lo agora?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                background={"#d36262"}
                color={"#fff"}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
                onClick={onClose3}
              >
                Não
              </Button>
              <Button 
                colorScheme='teal'
                onClick={() => {
                  onOpen3();
                  setIndex(0)
                }} 
                ml={3}
              >
                Sim
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/** Modal para alertar veiculos com cadastro inrregular */}
      <AlertDialog
        isOpen={isOpen4}
        leastDestructiveRef={cancelRef4}
        onClose={onClose4}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Atenção!
            </AlertDialogHeader>
            <AlertDialogBody>
              Os dados do veículo{' '}
              {id && (vehiclesList.filter(res => res.id === id)[0].model || vehiclesList.filter(res => res.id === id)[0].licensePlate)}
              {' '}estão em desacordo com o esperado, para utilizar esse veículo é necessário ajustar esse cadastro, deseja fazer isso agora?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                background={"#d36262"}
                color={"#fff"}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
                onClick={onClose4}
              >
                Não
              </Button>
              <Button 
                colorScheme='teal'
                onClick={() => {
                  onClose4();
                  onOpen2();
                }} 
                ml={3}
              >
                Sim
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/** Modal para Re-enviar termos do sem parar */}
      <AlertDialog
        isOpen={isOpen5}
        leastDestructiveRef={cancelRef5}
        onClose={onClose5}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Atenção!
            </AlertDialogHeader>
            <AlertDialogBody>
              O aceite do "Termo de Uso" do SEM PARAR está pendente, deseja reenviá-lo para o seu e-mail?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                background={"#d36262"}
                color={"#fff"}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
                onClick={() => {
                  onClose5();

                  navigate('/vehicles/register', {
                    state: {
                      data: id ? vehiclesList.filter(res => res.id === id)[0] : {},
                      vehiclesType,
                      index
                    }}
                  )
                }}
              >
                Não
              </Button>
              <Button 
                colorScheme='teal'
                onClick={async () => {
                  await api.post(`vehicle/userVehicleTemplate`, { "UserVehicleId": id });

                  onClose5();

                  navigate('/vehicles/register', {
                    state: {
                      data: id ? vehiclesList.filter(res => res.id === id)[0] : {},
                      vehiclesType,
                      index
                    }}
                  )

                  setId(0);
                }} 
                ml={3}
              >
                Sim
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Flex
        marginBottom={"10px"}
        flexDir={["column", "row"]}
        justifyContent={["center", "space-between"]}
        alignItems={["flex-end", "flex-end"]}
      >
        <Flex
          marginTop={["0px", "40px"]}
          justifyContent={["center", "flex-start"]}
          alignItems={["", "flex-end"]}
          width={["100%", "auto"]}
          marginBottom={["0px", "0px"]}
          opacity={["0", "1"]}
        >
          <Text
            fontSize={[28, 20]}
            fontWeight={600}
            color={"#444"}
          >
            Meus Veículos
          </Text>
        </Flex>
        <Button 
          colorScheme='teal' 
          size='md'
          /*isDisabled={vehiclesList.length >= limite}
          _disabled={{
            background: "#ccc !important",
            _hover: {
              background: "#ccc !important"
            }
          }}*/
          onClick={async () => {
            if(vehiclesList.length < limite){
              if(canAdd){
                if(semParar && !user?.cpf) {
                  onOpen3();
                } else {
                  onOpen2();
                }
              }else{
                toast.error("Por favor, verificar o perfil de acesso!")
              }
            } else {
              toast.error("Limite de carro(s) cadastrado(s) atingido.")
            }
          }}
        >
          + Novo Veículo
        </Button>
      </Flex>

      <Box
        background={["#fff", "#fff"]}
        borderRadius={"10px"}
        display={["none", "inherit"]}
      >
        <TableContainer display={["none", "initial"]}>
            <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>Modelo</Th>
                    <Th>Placa</Th>
                    <Th>Cor</Th>
                    <Th padding={0} maxW={"min-content"}></Th>
                    <Th padding={0} maxW={"min-content"}></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {vehiclesList.map((v, i) => {
                    return (
                      <Tr key={v.id} _hover={{ background: "#eee"}}>
                          <Td fontSize={12}>
                            <Flex alignItems={"center"}>
                              {v.model}
                            </Flex>
                          </Td>
                          <Td fontSize={12}>{v.licensePlate}</Td>
                          <Td fontSize={12}>{v.color}</Td>
                          <Td padding={0} maxW={"min-content"}>
                            <Flex justifyContent={"center"}>
                              {v.owner && (
                                <Button
                                  variant='link'
                                  onClick={async() => {
                                    setId(v.id);
      
                                    if(canEdit){
                                      if(semParar && !user?.cpf) {
                                        onOpen3();
                                      } else {
                                        if(!v.integer) {
                                          onOpen4();
                                        } else {
                                          if(semParar && user?.cpf && v.integer && v.uhfRecognition && v.pendingOptIn) {
                                            onOpen5();
                                          } else {
                                            navigate("/vehicles/edit", {
                                              state: {
                                                data: v,
                                                vehiclesType: v.type,
                                                index
                                              }
                                            })
                                          }
                                        }
                                      }
                                    }else{
                                      toast.error("Por favor, verificar o perfil de acesso!")
                                    }
                                  }}
                                >
                                  <MdModeEdit 
                                    cursor="pointer" 
                                    fontSize={25}
                                  />
                                </Button>
                              )}
                            </Flex>
                          </Td>
                          <Td padding={0} maxW={"min-content"}>
                            <Flex justifyContent={"center"}>
                              {v.owner && (
                                <Button variant='link'>
                                  <MdDelete 
                                    cursor="pointer" 
                                    fontSize={25} 
                                    onClick={async () => {
                                      if(canDelete){
                                        setId(v.id);

                                        onOpen();
                                      }else{
                                        toast.error("Por favor, verificar o perfil de acesso!")
                                      }
                                    }}
                                  />
                                </Button>
                              )}
                            </Flex>
                          </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
            </Table>
        </TableContainer>
      </Box>
      <Grid templateColumns={["0% 100% 0%", "35% 30% 35%"]}>
        <GridItem colSpan={1} />
        <GridItem
          colSpan={1}
          paddingTop={"0px"}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Flex
            display={["flex", "none"]}
            flexDirection={"column"}
          >
            {vehiclesList.map(res => {
              return (
                <Flex
                  key={res.id}
                  flexDirection={"row"}
                  background={"#fff"}
                  borderRadius={"8px"}
                  marginBottom={"10px"}
                  padding={"20px"}
                  flexDir={"column"}
                  justifyContent={"center"}
                >
                  <Flex justifyContent={"space-between"}>
                    <Text fontSize={"12px"}>Placa: {res.licensePlate}</Text>
                    <Box fontSize={"12px"}>Cor: {res.color}</Box>
                  </Flex>
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    marginTop={"10px"}
                  >
                    <Text fontWeight={"bold"}>{res.model}</Text>
                    <Box>
                      {res.owner && (
                        <>
                          <Button
                            variant={"link"}
                            onClick={async() => {
                              setId(res.id);

                              if(canEdit){
                                if(semParar && !user?.cpf) {
                                  onOpen3();
                                } else {
                                  if(!res.integer) {
                                    onOpen4();
                                  } else {
                                    if(semParar && user?.cpf && res.integer && res.uhfRecognition && res.pendingOptIn) {
                                      onOpen5();
                                    } else {
                                      navigate("/vehicles/edit", {
                                        state: {
                                          data: res,
                                          vehiclesType: res.type,
                                          index
                                        }
                                      })
                                    }
                                  }
                                }
                              }else{
                                toast.error("Por favor, verificar o perfil de acesso!")
                              }
                            }}
                          >
                            <MdModeEdit
                              cursor="pointer" 
                              fontSize={25}
                              color={"#444"}
                            />
                          </Button>
                          <Button
                            variant={"link"}
                            onClick={async () => {
                              if(canDelete){
                                setId(res.id);

                                onOpen();
                              }else{
                                toast.error("Por favor, verificar o perfil de acesso!")
                              }
                            }}
                          >
                            <MdDelete
                              cursor="pointer" 
                              fontSize={25}
                              color={"#444"}
                            />
                          </Button>
                        </>
                      )}
                    </Box>
                  </Flex>
                </Flex>
              )
            })}
          </Flex>
        </GridItem>
        <GridItem colSpan={1} />
      </Grid>    
    </Box>
  );
}
