import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IData {
    show: boolean,
    title: string,
    description: string,
    name: string,
    subModuleId: string,
    email: string
}

const initialState = { 
    data: { 
        show: false,
        title: '',
        description: '',
        name: '',
        subModuleId: '',
        email: ''
  } as IData
}
  
export const endpointErrorSlice = createSlice({
name: 'endpointError',
initialState,
reducers: {
    setEndpointError: (state, action: PayloadAction<IData>) => {
        state.data = action.payload
    },
},
})

export const { setEndpointError } = endpointErrorSlice.actions

export default endpointErrorSlice.reducer