import styled from 'styled-components';

import {
  Flex,
} from '@chakra-ui/react'

export const Content = styled(Flex)`
  > button {
    > span:nth-child(1) {
      font-size: 14px;
      color: #7b7b7b !important;
    }

    > span:nth-child(2) {
      margin-top: 5px;
    }
  }

  > button + button {
    margin-top: 10px;
  }
`;
