import styled from 'styled-components'

import { 
    Box,
    Text
} from '@chakra-ui/react'

export const Container = styled(Box)`
    position: relative;
    border-radius: 5px;
    border: 1px solid rgb(64, 187, 202);
    padding: 20px;
    margin: 20px;
`

export const Title = styled(Text)`
    position: absolute;
    background-color: white;
    top: -16px;
    left: 10px;
    padding: 3px;
    color: rgb(64, 187, 202);
`

export const SubContainer = styled.div`
    display: flex;
    /* flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-between; */
    width: 100%;

    .box-item-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
        width: 30%;

        @media only screen and (max-width: 800px) {
            width: 48%;
        }
        
        @media only screen and (max-width: 500px) {
            width: 100%;
        }
          
    }
    
    

`
