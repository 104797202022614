import styled from 'styled-components'

import { Box } from "@chakra-ui/react"

export const Container = styled.div`
    .box-item-container-booking-type {
        display: flex;
        justify-content: flex-start;
        width: 50%;
        margin-bottom: 20px;
        @media (max-width: 700px){
            width: 100%;
        }
    }
`

export const ContainerSelectLocal = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .box-item-container {
        width: 40%;

        @media (max-width: 700px){
            width: 100%;
        }
    }

    .message-error {
        font-size: 10px;
        padding: 10px;
        color: red;
    }
`

export const ContainerTimes = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: auto;
    overflow-y: auto;
`

export const ContainerCalendar = styled.div`
    width: 440px;
    overflow-x: scroll;
    border: 1px #49c5c3 solid;
    padding: 10px;
    border-radius: 5px;

    @media (max-width: 1080px){
        width: 340px;
    }

    @media (max-width: 900px){
        width: 260px;
    }

    @media (max-width: 700px){
        width: 165px;
    }

    @media (max-width: 480px){
        width: 320px;
    }
`

export const SubContainerCalendar = styled.div`
    width: 418px;
    @media (max-width: 1080px){
        width: 318px;
    }
    @media (max-width: 900px){
        width: 298px;
    }
`

export const ContainerHoursFilters = styled.div`
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    justify-content: space-between;
    margin-top: 10px;
    border: 1px #49c5c3 solid;
    padding: 10px;
    align-items: center;
    border-radius: 5px;
`

export const ContainerWeekDays = styled(Box)`
    position: relative;
    border-radius: 5px;
    border: 1px solid rgb(64, 187, 202);
    padding: 10px;
    margin: 10px;
    background-color: #eee;
`