import styled from 'styled-components';

import InputMask, { Props } from 'react-input-mask';

export const Container = styled(InputMask).attrs({
  mask: '+55 (99) 99999-9999'
})<Props>`
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 50px;
  background-color: #fff;
  width: 100%;
  padding: 26px 16px;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  @media screen and (min-width: 30em) {
    width: auto;
    padding: 19px 16px;
  }
`;
