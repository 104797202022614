import React, { useEffect, useState } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import { 
    Box,
    Text,
    Button,
    Flex,
    Grid,
    GridItem,
    CircularProgress,
    FormLabel,
} from '@chakra-ui/react'

import { isBefore, isAfter, parseISO } from 'date-fns';

import api from '../../../services/api'

import { RootState } from '../../../store'

import { setNotification } from '../../../store/reducers/notification'

import { Container, Description, InputTime } from './styles';

export interface IUserData {
  user_id: string;
  user_name: string;
  client_id: string;
  subModuleId: string;
  private_local_id: string;
  unit_id: any[];
}

interface INotify {
  id: number;
  unitId: number;
  unit: number;
  userId: number;
  user: number;
  typeNotification: string;
  read: boolean;
  readingDate: string;
  notificationDate: string;
  messageDescription: string;
  internalCode: number;
  notificationGroupId: number;
  notificationGroup: number;
  cancellationAccepted: string;
  cancellationGuestEngineEvent: string
}

export function NotifictaionsDetails () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  const notification = useSelector((state: RootState) => state.notification.data)
  const currentPage = useSelector((state: RootState) => state.notification.data.currentPage);
  const [notify, setNotify] = useState<INotify>({
    id: 0,
    unitId: 0,
    unit: 0,
    userId: 0,
    user: 0,
    typeNotification: "",
    read: false,
    readingDate: '',
    notificationDate: '',
    messageDescription: "",
    internalCode: 0,
    notificationGroupId: 0,
    notificationGroup: 0,
    cancellationAccepted: '',
    cancellationGuestEngineEvent: 'true',
  });

  useEffect(() => {
    dispatch(setNotification({
      ...notification,
      keepCurrent:false,
    }))
  }, [])

  const [isLoading, setIsLoading] = useState(true)
  
  const [twoButtons, setTwoButtons] = useState(true);

  const [informOutputForecastContainer, setInformOutputForecastContainer] = useState<boolean>(false);

  //const [informOutputForecastContainerValue, setInformOutputForecastContainerValue] = useState<string>('00:00');
  const [informOutputForecastContainerValue, setInformOutputForecastContainerValue] = useState<string>('');

  useEffect(() => {
    const item: INotify = location.state.item;

    setNotify(item)

    setTimeout(() => setIsLoading(false), 1000)
  }, [])
  
  useEffect(() =>{
    if(notify.notificationGroupId === 5 &&
    !notify.cancellationAccepted
    ){
      setTwoButtons(true)
    }

  }, [notify]);

  useEffect(() => {
    async function readNotification() {
      const userToken = localStorage.getItem('access_portal:access_token')

      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }

      await api.post(`PersonalNotification/UpdateNotification?id=${notify.id}`, {}, config)

      const { data } = await api.get('PersonalNotification/getallnotread', config);

      dispatch(setNotification({
        ...notification,
        count: data,
      }))
    }

    if (!notify.read && notify.id) {
      readNotification()
    }
  }, [notify])

  async function sendResponse(value: any){
    const userToken = localStorage.getItem('access_portal:access_token')

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }
    
    try{
      await api.post(`/commonAreaRequestEngineEvent/ValidationUseReservation?personalNotificationId=${notify.id}&cancellationAccepted=${value}`, {}, config)
    
      toast.success("A confirmação foi enviada com sucesso!")

      navigate('/notifications')

    }catch(e: any){      
      toast.error(e.response.data.Message)
    }
  }

  function getDescription(item: INotify) {
    if(item.messageDescription && item.messageDescription.includes('motorista')){
      let primeiroParte = item.messageDescription?.split('motorista')[0];
      let segundaParte = item.messageDescription?.split('motorista')[1];
      let nomeMotorista = segundaParte.split(' e ')[0];

      let numeroDaPlaca: string | any[] = segundaParte.split(',')[0].split(' ');
      numeroDaPlaca = numeroDaPlaca[numeroDaPlaca.length - 1];
      
      let ultimaParte = segundaParte.split(',');

      return (
        <>
          <span>{primeiroParte}motorista</span>
          <span style={{fontWeight: "bold" }}>{nomeMotorista}</span>
          <span> e de placa </span>
          <span style={{fontWeight: "bold" }}>{numeroDaPlaca}</span>
          <span>,{ultimaParte[1]}</span>
        </>
      )
    }

    if(item.messageDescription) {
      return item.messageDescription?.split('?')[0]
    }
    
    return item.messageDescription
  }

  function ButtonType() {
    const is_after = isAfter(new Date(), parseISO(notify.notificationDate));
   
    const beforeData = notify && notify.notificationDate
      ? isBefore(parseISO(notify.notificationDate), new Date())
      : false

    if(notify.notificationGroupId === 5 || notify.notificationGroupId === 6 || notify.notificationGroupId === 7) {
      if(
        notify.internalCode && (
        notify.cancellationAccepted?.trim() !== 'PENDING' &&
        notify.notificationGroupId === 5
      )) {
        return (
          <Button
            colorScheme='teal' 
            color={"#fff"}
            onClick={async () => {
              dispatch(setNotification({
                ...notification,
                keepCurrent: true,
              }))
                navigate('/booking-request/details', {
                  state: {
                    id: notify.internalCode,
                    isAdmin: false
                  }
                })
            }}
          >
            {notify.notificationGroupId === 5
              ? 'Ver reserva'
              : 'Ver solicitação'
            }
          </Button>
        )
      } else if(notify.internalCode && (notify.notificationGroupId === 6)) {
        return (
          <Button
            colorScheme='teal' 
            color={"#fff"}
            onClick={async () => {    
              dispatch(setNotification({
                ...notification,
                keepCurrent: true,
              }))          
              navigate("/pre-registration/details",
                {
                  state: {
                    id: notify.internalCode
                  }
                }
              )
            }}
          >
            Ver detalhes do convidado
          </Button>
        )
      }else if(
      notify.notificationGroupId === 5 &&
      notify.cancellationAccepted?.trim() === 'PENDING' 
      //!notify.read &&
      //!beforeData
      ){
        return(
          <Flex
            justifyContent={"space-between"}
            w={"100%"}
          >
            <Button
              colorScheme='teal' 
              color={"#fff"}
              onClick={() => sendResponse(true)}
              background={"#d36262"}
              _hover={{
                _disabled: {
                  background: "#d36262"
                }
              }}
            >
              Não Usar
            </Button>
            <Button
              colorScheme='teal' 
              color={"#fff"}
              onClick={async () => sendResponse(false)}
            >
              Usar
            </Button>
          </Flex>
        )
      } else if (/*is_after &&*/ (notify.notificationGroupId === 6 || notify.notificationGroupId === 7) && /*!notify.read &&*/ notify.cancellationGuestEngineEvent === 'false') {
        return (
          <Flex
            justifyContent={"space-between"}
            w={"100%"}
          >
            <Button
              colorScheme='teal' 
              color={"#fff"}
              onClick={async () => {
                try {
                  const config = {
                    headers: {
                      Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                    }
                  }
      
                  const { data } =  await api.post(
                    `/guestEngineEvent/visitorStatusValidation`,
                    {
                      personalNotificationId: notify.id,
                      lateCheckOut: "FALSE",
                    },
                    config
                  );

                  if(data === 'Acompanhamento do visitante atualizado.') {
                    toast.success(data);

                    navigate(-1);
                  } else {
                    toast.error(data);
                  }
                } catch(err: any) {
                  toast.error(err?.response?.data?.Message || "Ocorreu um erro!");
                }
              }}
              background={"#d36262"}
              _hover={{
                _disabled: {
                  background: "#d36262"
                }
              }}
            >
              Não
            </Button>
            <Button
              colorScheme='teal' 
              color={"#fff"}
              onClick={async () => {
                if(informOutputForecastContainer) {
                  if(!informOutputForecastContainerValue || informOutputForecastContainerValue === ':undefined') {
                    return toast.error('Por favor, insira um horário de previsão saída');
                  }
                  if(!informOutputForecastContainerValue.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
                    return toast.error('Por favor, insira um horário de previsão saída válido');
                  }
                  //return false;

                  try {
                    const config = {
                      headers: {
                        Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                      }
                    }
      
                    let time = informOutputForecastContainerValue.split(':');
      
                    const timeAdded = (parseInt(time[0])*60) + parseInt(time[1]);
      
                    const { data } = await api.post(
                      `/guestEngineEvent/visitorStatusValidation`,
                      {
                        personalNotificationId: notify.id,
                        lateCheckOut: "TRUE",
                        timeAdded,
                      },
                      config
                    );

                    if(data === 'Acompanhamento do visitante atualizado.') {
                      toast.success(data);

                      navigate(-1);
                    } else {
                      toast.error(data);
                    }
                  } catch(err: any) {
                    toast.error(err?.response?.data?.Message || "Ocorreu um erro!");
                  }
                } else {
                  setInformOutputForecastContainer(true);
                }
              }}
            >
              {informOutputForecastContainer ? "Salvar" : "Sim"}
            </Button>
          </Flex>
        )
      }
    }

    return <></>
  }

  if(isLoading) {
    return (
      <Flex
        position={"relative"}
        h={"calc(100vh - 175px)"}
        w={"100%"}
        top={"0px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
      </Flex>
    )
  }

  return (
    <Grid templateColumns={["0% 100% 0%", "30% 40% 30%"]}>
      <GridItem colSpan={1} />
      <GridItem
        colSpan={1}
        paddingTop={"20px"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Box w={"100%"}>
          <Flex
            marginTop={["10px", "40px"]}
            flexDir={["column", "row"]}
            justifyContent={["center", "space-between"]}
            alignItems={["flex-end", "flex-end"]}
          >
            <Flex
              justifyContent={["center", "flex-start"]}
              alignItems={["", "flex-end"]}
              width={["100%", "auto"]}
              marginBottom={["40px", "0px"]}
            >
              <Box
                fontSize={[28, 20]}
                fontWeight={600}
                color={"#444"}
              >
                Detalhes da Notificação
              </Box>
            </Flex>
          </Flex>
          <Container
            flexDir={"column"}
            padding={["0px", "20px"]}
            borderRadius={"6px"}
            background={["transparent", "#fff"]}
          >
            <Flex flexDir={"column"}>
              <Text fontWeight={"600"}>{notify.typeNotification}</Text>
              <Description marginTop={"10px"}>
                {getDescription(notify)}
                {informOutputForecastContainer && (
                  <Box>
                    <FormLabel
                      fontSize={"0.8em"}
                      color={"#777"}
                      marginBottom={"3px"}
                    >
                      Informar previsão de saída
                    </FormLabel>
                    <InputTime
                      onChange={(e: any) => {
                        let time = e.target.value.split(':');

                        if (parseInt(time[0]) > 23) {
                          time[0] = 23;
                        }

                        if (parseInt(time[1]) > 59) {
                          time[1] = 59;
                        }
                        
                        const value = time[0] + ':' + time[1];

                        setInformOutputForecastContainerValue(value);
                      }}
                      placeholder='__:__'
                      value={informOutputForecastContainerValue}
                    />
                  </Box>
                )}
              </Description>
              <Flex flexDir={["column", "row"]} marginTop={"10px"}>
                {ButtonType()}
              </Flex>
            </Flex>
            <Flex flexDir={["column", "row"]} marginTop={"10px"}>
              <Button 
                colorScheme='teal' 
                onClick={() => navigate('/notifications', { state: { currentPage: currentPage }})}
              >
                Voltar
              </Button>
            </Flex>
          </Container>
        </Box>
      </GridItem>
      <GridItem colSpan={1} />
    </Grid>
  )
}