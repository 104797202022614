import react, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { toast } from 'react-toastify'

import { 
  Flex, 
  Box, 
  InputGroup, 
  Button, 
  Divider,
  Image,
} from '@chakra-ui/react'

import api from '../../services/api'

import { setLoginStatus } from '../../store/reducers/loginStatus'

import { InputPhone } from '../../components/InputPhone'

import { InputRg } from '../../components/InputRg'

import { ButtonContainer, Warning, Title, Description } from './styles'

import Frame from '../../assets/images/Frame1.png';

import Logo from '../../assets/images/logo.png';

export function RecoverLogin () {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [fields, setFields] = useState({
      phone: '',
      rg: '',
    })

    useEffect(() => {
      localStorage.removeItem('access_portal:access_token')

      dispatch(setLoginStatus({ isLogged: false }))
    }, [])

    const execAuth = async () => {
      if(!fields.phone){
        return toast.error("O campo Celular é obrigatório.")
      }

      if(fields.phone.replace(/[^0-9]/g,'').length !== 13) {
        return toast.error("O Celular informado é inválido.")
      }

      if(!fields.rg){
        return toast.error("O campo RG é obrigatório.")
      }
        
      const { phone, rg } = fields;

      try{
        await api.get(`login/forgotLogin?cellphone=${phone.replace(/[^0-9]/g,'')}&rg=${rg.replace(/[^0-9a-z]/gi, '')}`);

        toast.success("Recuperação de login solicitada com sucesso!")

      }catch(e) {
        let message = "Ocorreu um erro. Por favor, verifique os dados.";
        if ((e as any).response.data?.Message) {
          message = (e as any).response.data?.Message;
        }
        toast.error(message)
      }
    }

    return (
      <Flex
        direction={'column'} 
        align={'center'} 
        justify={'center'}
        bg={["linear-gradient(0deg, rgba(211,250,230,1) 0%, rgba(242,253,245,1) 100%)", ""]}
        h={["100%", 'calc(100vh)']}
      >
        <Flex
          direction={'column'} 
          align={'center'} 
          justify={'center'}
          h={["100%", 'calc(100vh)']}
          backgroundImage={[`url(${Frame})`, ""]}
          backgroundSize={"100% auto"}
          backgroundPosition={"0px 50px"}
          backgroundRepeat={"no-repeat"}
          w={["100%", "auto"]}
          justifyContent={["flex-end", "inherit"]}
          >
          <Box
            padding={'20px'}
            borderRadius='lg'
            overflow='hidden'
            border={["0", "1px solid #eee"]}
            position={"relative"}
            background={["", "#fff"]}
            width={["100%", "253px"]}
          >
            <Box
              padding={"0px 20px 20px 20px"}
              display={["none", "flex"]}
            >
              <Image
                src={Logo}
              />
            </Box>
            <Warning>
              <Title>Esqueceu seu login?</Title>
              <Description>Tudo bem. Informe seu celular e RG que logo lhe enviaremos seu login.</Description>
            </Warning>
            <InputGroup marginBottom={['8px', '20px']}>
               <InputPhone
                value={fields.phone}
                placeholder="Digite seu celular"
                onChange={(val) => {
                  setFields({
                    ...fields,
                    phone: val.target.value
                  })
                }} 
              />
            </InputGroup>
            <InputGroup marginBottom={'20px'}>
              <InputRg 
                placeholder='Digite seu RG'
                value={fields.rg}
                onChange={(val) => {
                  setFields({
                    ...fields,
                    rg: val.target.value
                  })
                }} 
              />
            </InputGroup>
            <ButtonContainer >
              <Button
                colorScheme='teal'
                size='md'
                onClick={() => execAuth()}
                width={["100%", "auto"]}
                padding={["26px 16px", "19px 16px"]}
                borderRadius={"50px"}
              >
                Recuperar login
              </Button>
            </ButtonContainer>
            <Divider
              marginTop={5}
              marginBottom={5}
              color={["transparent", "#ccc"]}
            />
            <Box
              display={["flex", "initial"]}
              flexDirection={["row", "initial"]}
              justifyContent={["space-between", ""]}
            >
              <ButtonContainer>
                <Button
                  colorScheme='teal'
                  variant='link'
                  onClick={() => navigate("/auth")}
                >
                  Fazer login
                </Button>
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  colorScheme='teal'
                  variant='link'
                  onClick={() => navigate("/password-recovery")}
                >
                  Esqueceu sua senha?
                </Button>
              </ButtonContainer>
            </Box>
          </Box>
        </Flex>
      </Flex>
    )
}