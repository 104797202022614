import  React, { useEffect, useState } from 'react'
import { Container, ContainerWeekDays, SubContainerWeekDays, Title, WeekDayButton, TimeFieldLabel, WeekFooterLabel, ContainerFieldTime } from './styles'
import {
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Heading,
    Stack,
    StackDivider,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Select,
    Text,
} from '@chakra-ui/react'

/*interface IWeekDays {
    diasDaSemana: any
}*/

const allowedPropsDaySpan = {selected: false}

export function FormCopyWeekTime ({setDiasDaSemana, diasDaSemana, copyDays, onClose }: any){
    const [days, setDays] = useState<any>([])
    const [daysSelected, setDaysSelected] = useState<any>([])
    const [diaSelecionadoLabel, setDiaSelecionadoLabel] = useState('');

    useEffect(() => {
        let arrDiasSelecionados = days.filter((val: any) => {
            return val?.selected;
        })

        if (arrDiasSelecionados && arrDiasSelecionados[0]) {
            if (arrDiasSelecionados[0].name) {
                setDiaSelecionadoLabel(arrDiasSelecionados[0].name.toLowerCase());
            } else {
                setDiaSelecionadoLabel(arrDiasSelecionados[0].days[0].toLowerCase());
            }
        }
    }, [days]);

    useEffect(() => {
        /*setDays([
            {index: 0, id: 0, name: "DOMINGO", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
            {index: 1, id: 0, name: "SEGUNDA-FEIRA", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
            {index: 2, id: 0, name: "TERÇA-FEIRA", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
            {index: 3, id: 0, name: "QUARTA-FEIRA", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
            {index: 4, id: 0, name: "QUINTA-FEIRA", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
            {index: 5, id: 0, name: "SEXTA-FEIRA", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
            {index: 6, id: 0, name: "SÁBADO", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'}
        ]);*/
        let dias = {diasSelecionados: diasDaSemana};
        let { diasSelecionados } = dias;
        //console.log("diasSelecionados", diasSelecionados);
        diasSelecionados[0].selected = true;
        setDays(diasSelecionados);
    }, []);

    useEffect(() => {
        let diasDaSemana_aux = [...diasDaSemana];
        let days_aux = [...days];
        if(
            diasDaSemana_aux
            && Array.isArray(diasDaSemana_aux)
            && diasDaSemana_aux.length > 0
        ) {
            let daysfromFor: any[] = []

            if (diasDaSemana_aux) {

                setDaysSelected(diasDaSemana.map((val: any)=> {
                    let startTime;
                    let endTime;

                    if (val.times) {
                        startTime = val.times.workStartTime.replace(":00", "");
                        endTime = val.times.workEndTime.replace(":00", "");
                    } else {
                        startTime = val.openTime;
                        endTime = val.closeTime;
                    }
                    return {
                        ...val,
                        id: val.id,
                        name: (val.workDays && Array.isArray(val.workDays)) && val.workDays.length > 0 ? val.workDays[0] : '',
                        openTime: startTime,
                        closeTime: endTime,
                    }
                }));
            }
        }
    }, [days]) 

    return (
        <Container>
            <Text>De qual dia deseja copiar os horários?</Text>
            <Select
                onChange={(e)=>{
                    let diasNovo = days;
                    for (let i = 0; i < diasNovo.length; i++) {
                        let labelDia;
                        if (diasNovo[i].name) {
                            labelDia = diasNovo[i].name;
                        } else {
                            labelDia = diasNovo[i].days[0];
                        }

                        diasNovo[i].selected = labelDia.toUpperCase() === e.target.value.toUpperCase();
                    }
                    setDays(diasNovo);
                }}
            >
                {
                    days.map((val: any, key: number) => {                        
                        return (
                            <option>
                                {val?.name ? val?.name : val?.days[0]}
                            </option>
                        )
                    })
                }
            </Select>
            <Text>
                Selecione o(s) dia(s) que receberá(ão) os horários de {diaSelecionadoLabel}
            </Text>
            {
                days.length > 0 &&
                <>
                <ContainerWeekDays>
                    <Title>Dias da semana</Title>
                    <SubContainerWeekDays>
                        <div className={'row1'}>
                        {
                            days.map((val: any, key: number) => {
                                if (!val?.selected) {
                                    return (
                                        <WeekDayButton
                                            selected={val?.selectedToCopy}
                                            key={key}
                                            onClick={() => {
                                                let newArr = days.map((v: any) => {
                                                    let label1;
                                                    let label2;

                                                    if (v.name) {
                                                        label1 = v.name;
                                                    } else {
                                                        label1 = v.days[0];
                                                    }

                                                    if (val.name) {
                                                        label2 = val.name;
                                                    } else {
                                                        label2 = val.days[0];
                                                    }

                                                    if (label1.toUpperCase() === label2.toUpperCase()) {
                                                        v.selectedToCopy = !v.selectedToCopy;
                                                    }

                                                    return v;
                                                });

                                                setDays(newArr);
                                            }}
                                        >
                                            {val?.name ? val?.name.substring(0, 3).toLowerCase() : val?.days[0].substring(0, 3).toLowerCase()}
                                        </WeekDayButton>
                                    )
                                }
                            })
                        }
                        </div>
                    </SubContainerWeekDays>
                </ContainerWeekDays>
                <Button
                  colorScheme='teal'
                  onClick={() => {
                      onClose();
                      return copyDays(
                          days.filter((v: any) => {return v.selected})[0],
                          days
                      )
                  }}
                  style={{
                      margin: '15px auto 0',
                      display: 'block',
                  }}
                >
                  Confirmar
                </Button>
                </>
            }
        </Container>
    )
}