import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IData {
    id: number
    typeNotification: string
    notificationDate: string
    read: Boolean
    notificationGroupId: number
}


interface IDataN {
    data: IData[]
    count: number
    currentPage: number
    totalPerPage: string
    qtdItens: string
    keepCurrent: boolean
} 

const initialState = {
    data: {
        data: [],
        count: 0,
        currentPage: 1,
        totalPerPage: "10",
        qtdItens: '10',
        keepCurrent: false
    } as IDataN
}
  
export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        resetNotification: (state) => {
            state = initialState
        },
        setNotification: (state, action: PayloadAction<IDataN>) => {
            state.data = action.payload
        },
    },
})

export const { resetNotification, setNotification } = notificationSlice.actions

export default notificationSlice.reducer