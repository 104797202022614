import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import jwt_decode from "jwt-decode"

import { toast } from 'react-toastify'

import { 
    Box,
    Text,
    Button,
    FormControl,
    FormLabel,
    Switch,
    Input,
    Grid,
    GridItem,
    Flex,
} from '@chakra-ui/react'

import { RootState } from '../../../store'

import { setCurrentContact } from '../../../store/reducers/contact'

import { validateEmail } from '../../../utils/validates'

import { InputPhone } from '../../../components/InputPhone'

import api from '../../../services/api'

import { IUserData } from '../../../interfaces/IUserData'

import 'react-phone-number-input/style.css'

import PhoneInput from 'react-phone-number-input'

import pt from 'react-phone-number-input/locale/pt-BR.json'

import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input/mobile'
// import ReactTooltip from "react-tooltip"
import { Container } from './styles'


export function ContactForm () {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const currentContact = useSelector((state: RootState) => state.contact.data)

    const [disabledSaveButton, setDisabledSaveButton] = useState(false)

    const [contactsList, setContactsList] = useState<any>([]);

    // useEffect(() => {
    //     var root = document.querySelector(':root');  

    //     root.style.setProperty('--main-color', '#88d8b0')
    // }, [])

    useEffect(() => {
        const fetchData = async () => {
            try{
                const config = {
                    headers: {
                        Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                    }
                }
                const { data } = await api.get('/contact/getContactsByUser', config)
                setContactsList(data)
            }catch(e) {

            }
        }
        fetchData();
    }, [])

    async function save() {
        if(currentContact.name.length === 0){
            return toast.error('O campo nome é obrigatório.')
        }

        if(currentContact.cellPhone?.length === 0 && currentContact.email?.length === 0){
            return toast.error('É obrigatório preencher o celular ou o e-mail.')
        }
        if(currentContact.cellPhone?.length > 0 && 
        !isPossiblePhoneNumber(currentContact.cellPhone)){
            return toast.error("O Celular informado é inválido.")
        }

        for (let i = 0; i < contactsList.length; i++) {
            if (currentContact.id) {
                if (contactsList[i].name?.toUpperCase() === currentContact.name.toUpperCase() && (contactsList[i].id !== currentContact.id)) {
                    return toast.error("Já existe um contato com esse nome.");
                }
            } else {
                if (contactsList[i].name?.toUpperCase() === currentContact.name.toUpperCase()) {
                    return toast.error("Já existe um contato com esse nome.");
                }
            }
        }
        // if(currentContact.cellPhone.length > 0 &&
        //    ( (currentContact.cellPhone.replace(/[^0-9]/g,'').length !== 13) || 
        //     (parseInt(currentContact.cellPhone.replace(/[^0-9]/g,'')[4]) !== 9))
        // ) {
        //     return toast.error("O Celular informado é inválido.")
        // }

        if(currentContact.email?.length > 0 && !validateEmail(currentContact.email)){
            return toast.error('O e-mail está inválido.')
        }

        try{
            setDisabledSaveButton(true)

            const userToken = localStorage.getItem('access_portal:access_token')

            const decoded = jwt_decode(userToken as string) as IUserData
  
            const config = {
                headers: {
                    Authorization: `Bearer ${ userToken }`
                }
            }

            let phoneToUse = null
            if(currentContact.cellPhone && currentContact.cellPhone.length>0){

                let phoneNotFormatted = currentContact.cellPhone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '')

                if(phoneNotFormatted.substr(0,3) == '+55'){
                    phoneToUse = phoneNotFormatted.substr(0,3) + ' (' + 
                    phoneNotFormatted.substr(3, 2) + ') ' + phoneNotFormatted.substr(5, 5)+'-'+phoneNotFormatted.substr(10)
                }else{
                    phoneToUse = phoneNotFormatted
                }
            }

            let toSend: any = {
                name: currentContact.name,
                cellPhone: phoneToUse,
                email: currentContact.email ? currentContact.email  : null,
                favorite: currentContact.favorite,
                userId: decoded.user_id,
                clientId: decoded.client_id
            }

            //Se for edição
            if(currentContact.id){
                toSend = {
                    ...toSend,
                    id: currentContact.id
                }
            }
            // setDisabledSaveButton(false)
            // return
            await api.post('/contact/createUpsert', toSend, config)

            setDisabledSaveButton(false)

            toast.success(`Contato ${currentContact.id ? 'atualizado' : 'cadastrado'} com sucesso.`)
            
            navigate('/contact/list')
        }catch(e: any) {
            setDisabledSaveButton(false)
            if(
                e &&
                e.response &&
                e.response.data &&
                e.response.data.Message
            ){
                toast.error(e.response.data.Message)
            }
        }
    }

    return (
        <Grid templateColumns={["0% 100% 0%", "25% 50% 25%"]}>
            <GridItem colSpan={1} />
            <GridItem
                colSpan={1}
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <Text
                    fontSize={[28, 20]}
                    fontWeight={600}
                    color={"#444"}
                    marginBottom={"10px"}
                >
                    {currentContact.id ? 'Editar' : 'Cadastrar'} Contato
                </Text>

                <Flex
                    flexDir={"column"}
                    background={["inherit", "#fff"]}
                    w={"100%"}
                    padding={["0px", "20px"]}
                    borderRadius={"10px"}
                >
                    <Box marginBottom={"10px"}>
                        <FormLabel
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"3px"}
                        >
                            Nome
                        </FormLabel>
                        <Input
                            placeholder={"Nome do contato"}
                            background={"#fff"}
                            onChange={(e) =>{
                                dispatch(setCurrentContact({
                                    ...currentContact,
                                    name: e.target.value
                                }))
                            }}
                            value={currentContact.name}
                        />
                    </Box>
              

                    <Box marginBottom={"10px"}>
                        <FormLabel
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"3px"}
                        >
                            Celular
                        </FormLabel>

                        <Container>

                        <PhoneInput
                        labels={pt}
                        withCountryCallingCode
                        defaultCountry='BR'
                        placeholder={'Número do contato'} 
                        value={currentContact.cellPhone}
                        limitMaxLength={true}
                       
                        onChange={(e: any) =>{
                            // if(e ){
                                dispatch(setCurrentContact({
                                    ...currentContact,
                                    cellPhone: e
                                }))
                            // }
                        }}/>
                        </Container>
                       


                    </Box>

                    <Box marginBottom={"20px"}>
                        <FormLabel
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"3px"}
                        >
                            E-mail
                        </FormLabel>
                        <Input
                            background={"#fff"}
                            placeholder={'exemplo@exemplo.com.br'} 
                            onChange={(e) =>{
                                dispatch(setCurrentContact({
                                    ...currentContact,
                                    email: e.target.value
                                }))
                            }}
                            value={currentContact.email}
                        />
                    </Box>
                    <Box marginBottom={"20px"}>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel
                                htmlFor='email-alerts'
                                mb='0'
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Favorito?
                            </FormLabel>
                            <Switch 
                                id='favorite' 
                                onChange={(e) => {
                                    dispatch(setCurrentContact({
                                        ...currentContact,
                                        favorite: e.target.checked
                                    }))
                                }} 
                                isChecked={currentContact.favorite}
                            />
                        </FormControl>
                    </Box>
                    
                    <Flex
                        flexDir={["column-reverse", "row"]}
                        justifyContent={["initial", "space-between"]}
                    >
                        <Button 
                            colorScheme='teal' 
                            onClick={() => navigate('/contact/list')}
                            isDisabled={disabledSaveButton}
                        >
                            Voltar
                        </Button>
                        <Button 
                            colorScheme='teal' 
                            onClick={save}
                            marginBottom={["10px", "0px"]}
                            isDisabled={disabledSaveButton}
                        >
                            Salvar
                        </Button>
                    </Flex>
                </Flex>
            </GridItem>
            <GridItem colSpan={1} />
        </Grid>
    )
}
