import React, { useEffect, useState } from 'react'

import {
    MdModeEdit,
    MdRemoveRedEye,
    MdPerson,
    MdLocationPin
} from "react-icons/md"

import { ref, onValue, off } from "@firebase/database"

import jwt_decode from "jwt-decode"

import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import {
    Box,
    Flex,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    CircularProgress,
} from '@chakra-ui/react'

import { ContainerLoad, NoDataMessageContainer, ButtonsListContainer } from './styles'

import api from '../../services/api'

import { FakeLoadProgress } from '../../components/FakeLoadProgress'

import { resetCurrentBookingRequest, setCurrentBookingRequest } from '../../store/reducers/booking-request'

import { formatData } from '../../utils/format-data'

import { Paginate } from '../../components/Paginate'

import { resetEvent, setEvent } from '../../store/reducers/event'

import { FirebaseRealtimeDatabase } from '../../services/firebase'

import { IUserData } from '../../interfaces/IUserData'
import { hasPermission } from '../../utils/check-permission'
import ReactJsAlert from "reactjs-alert"

const userToken = localStorage.getItem('access_portal:access_token')

export function BookingApproval () {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setData] = useState<any[]>([])

    const [page, setPage] = useState(1)

    const [totalPages, setTotalPages] = useState(1)

    const [showAlertPermissionError, setShowAlertPermissionError] = useState(false)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        initContent()
    }, [page])

    useEffect(() => {
        initFirebase()
    }, [])

    async function initContent() {
        dispatch(resetCurrentBookingRequest())
        dispatch(resetEvent())

        if(!(await hasPermission(1, 'Visualizar'))){
            navigate('/home')
        }
                
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }

            const { data } = await api.get(`CommonAreaRequest/listByUnit?page=${page}&itens=${10}`, config)

            if(Array.isArray(data.response)){
                setData(data.response)
                
                setTotalPages(data.totalPages)
            }

            setLoading(false)
        }catch(e) {

        }
    }
    
    async function initFirebase(){
        const decoded = jwt_decode(userToken as string) as IUserData
        let selectedClient = decoded.client_id
    
        let clientId = selectedClient.split('#')[0]
        let date = new Date()
        let dia = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate()
        let mes = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
        let ano = date.getFullYear()
    
        let dataFormatted = dia + '-' + mes + '-' + ano

        for(let i in decoded.unit_id) {
            onValue(ref(
                FirebaseRealtimeDatabase, 
                '/event-creation-or-change/' + decoded.unit_id[i]
            ), (snapshot) => {
                setPage(1)
                
                setTimeout(() => {
                    initContent()
                }, 200)
            })
        }

        onValue(ref(
            FirebaseRealtimeDatabase, 
            '/new-commom-area-request/' + clientId + '/' + dataFormatted
        ), (snapshot) => {
            setPage(1)

            setTimeout(() => {
                initContent()
            }, 200)
        })
    }

    if(loading) {
        return (
            <ContainerLoad>
                <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
            </ContainerLoad>
        )
    }

    if(data.length < 1) {
        return (
            <Box>
                <Flex
                    marginTop={["10px", "40px"]}
                    marginBottom={"10px"}
                    flexDir={["column", "row"]}
                    justifyContent={["center", "space-between"]}
                    alignItems={["flex-end", "flex-end"]}
                >
                    <Flex
                        justifyContent={["center", "flex-start"]}
                        alignItems={["", "flex-end"]}
                        width={["100%", "auto"]}
                        marginBottom={["40px", "0px"]}
                    >
                        <Text
                            fontSize={[28, 20]}
                            fontWeight={600}
                            color={"#444"}
                        >
                            Reservas de Espaço
                        </Text>
                    </Flex>
                    
                </Flex>
                <NoDataMessageContainer>
                    <span
                        style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            color: "#818181"
                        }}
                    >
                        Nenhum registro cadastrado ainda!
                    </span>
                </NoDataMessageContainer>
            </Box>
        )
    }

    return (
        <Box>
            <ReactJsAlert
                status={showAlertPermissionError}
                type={'error'}
                title={'Atenção!'}
                quotes={true}
                quote={'Por favor, verificar o perfil de acesso!'}
                autoCloseIn={20000}
                Close={() => setShowAlertPermissionError(false)}
                button={'OK'}
            />
            <Flex
                marginTop={["10px", "40px"]}
                marginBottom={"10px"}
                flexDir={["column", "row"]}
                justifyContent={["center", "space-between"]}
                alignItems={["flex-end", "flex-end"]}
            >
                <Flex
                    justifyContent={["center", "flex-start"]}
                    alignItems={["", "flex-end"]}
                    width={["100%", "auto"]}
                    marginBottom={["40px", "0px"]}
                >
                    <Text
                        fontSize={[28, 20]}
                        fontWeight={600}
                        color={"#444"}
                    >
                        Reservas de Espaço - Aprovação de Reserva
                    </Text>
                </Flex>
            </Flex>
            <Flex
                display={["flex", "none"]}
                flexDirection={"column"}
            >
                {data.map((v, i) => {
                    return (
                        <Flex
                            flexDirection={"column"}
                            background={"#fff"}
                            borderRadius={"8px"}
                            marginBottom={"10px"}
                            padding={"20px"}
                            key={"item_" + i}
                        >
                            <Flex>
                                <Flex
                                    flexDir={"column"}
                                    flexGrow={"1"}
                                    whiteSpace={"nowrap"}
                                    overflow={"hidden"}
                                    textOverflow={"ellipsis"}
                                    paddingRight={"18px"}
                                >
                                    <span style={{ color: v.approval === 'APROVADO' ? '#0b7d00' : '#000', fontSize: 14 }}>{v.approval}</span>
                                    <span style={{ fontSize: 14 }}>{v.milestone}</span>
                                    <span
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <MdPerson size={18} style={{ marginRight: 5 }} />
                                        {v.requestBy}
                                    </span>
                                </Flex>
                                <Flex
                                    justifyContent={"center"}
                                    alignContent={"center"}
                                >
                                    <ButtonsListContainer>
                                        <Button
                                            variant='link'
                                            onClick={() => v.approval === 'APROVADO' && 
                                                !v.changeToEvent &&
                                                v.requestNeeded
                                                    ? navigate("/booking-request/register", { state: { id: v.id }})
                                                    : navigate("/booking-request/details", { state: { id: v.id }})
                                            }
                                        >
                                            {v.approval === 'APROVADO' && 
                                                !v.changeToEvent &&
                                                v.requestNeeded
                                                ?   (
                                                        <MdModeEdit 
                                                            cursor="pointer" 
                                                            fontSize={25}
                                                            color={"#444"} 
                                                        />
                                                    )
                                                :   (
                                                        <MdRemoveRedEye 
                                                            cursor="pointer" 
                                                            fontSize={25}
                                                            color={"#444"}
                                                        />
                                                    )
                                            }
                                        </Button>
                                    </ButtonsListContainer>
                                </Flex>
                            </Flex>
                            <Flex
                                justifyContent={"space-between"}
                                padding={"0px"}
                                marginTop={"10px"}
                            >
                                <span
                                    style={{
                                        fontSize: 14,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <MdLocationPin size={18} style={{ marginRight: 5 }} />
                                    {v.local}
                                </span>
                                <span style={{ fontSize: 14 }}>{formatData(v.eventDate).date}</span>
                            </Flex>
                        </Flex>
                    )
                })}
            </Flex>
            <Box
                background={["#fff", "#fff"]}
                padding={"20px 0px"}
                borderRadius={"10px"}
                display={["none", "inherit"]}
            >
                <TableContainer display={["none", "initial"]}>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Solicitação</Th>
                                <Th>Espaço</Th>
                                <Th>Marco</Th>
                                <Th>Solicitante</Th>
                                <Th>Data do Evento</Th>
                                <Th textAlign={"center"}>Situação</Th>
                                <Th padding={0} w={"72px"}></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((v, i) => { 
                                return (
                                    <Tr key={"item_" + i} _hover={{ background: "#eee"}}>
                                        <Td
                                            fontSize={12}
                                            opacity={(v.approval === 'APROVADO' &&  !v.changeToEvent && v.requestNeeded)
                                                ? 1
                                                : 0.3
                                            }
                                        >
                                            {formatData(v.requestDate).date}
                                        </Td>
                                        <Td
                                            fontSize={12}
                                            opacity={(v.approval === 'APROVADO' &&  !v.changeToEvent && v.requestNeeded)
                                                ? 1
                                                : 0.3
                                            }
                                        >
                                            {v.local}
                                        </Td>
                                        <Td
                                            fontSize={12}
                                            opacity={(v.approval === 'APROVADO' &&  !v.changeToEvent && v.requestNeeded)
                                                ? 1
                                                : 0.3
                                            }
                                        >
                                            {v.milestone}
                                        </Td>
                                        <Td
                                            fontSize={12}
                                            opacity={(v.approval === 'APROVADO' &&  !v.changeToEvent && v.requestNeeded)
                                                ? 1
                                                : 0.3
                                            }
                                        >
                                            {v.requestBy}
                                        </Td>
                                        <Td
                                            fontSize={12}
                                            opacity={(v.approval === 'APROVADO' &&  !v.changeToEvent && v.requestNeeded)
                                                ? 1
                                                : 0.3
                                            }
                                        >
                                            {formatData(v.eventDate).date}
                                        </Td>
                                        <Td
                                            fontSize={12}
                                            opacity={(v.approval === 'APROVADO' &&  !v.changeToEvent && v.requestNeeded)
                                                ? 1
                                                : 0.3
                                            }
                                        >
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}> 
                                                <span style={{color: v.approval === 'APROVADO' ? '#0b7d00' : '#000'}}>{v.approval}</span>
                                                {
                                                    !v.changeToEvent &&
                                                    'PENDENTE EVENTO'
                                                }
                                            </div>
                                        </Td>
                                        <Td padding={0} w={"72px"}>
                                            <Flex justifyContent={"center"}>
                                                <Button
                                                    variant='link'
                                                    onClick={() => v.approval === 'APROVADO' && 
                                                        !v.changeToEvent &&
                                                        v.requestNeeded
                                                            ? navigate("/booking-request/register", { state: { id: v.id }})
                                                            : navigate("/booking-request/details", { state: { id: v.id }})
                                                    }
                                                >
                                                    {v.approval === 'APROVADO' && 
                                                        !v.changeToEvent &&
                                                        v.requestNeeded
                                                        ?   (
                                                                <MdModeEdit 
                                                                    cursor="pointer" 
                                                                    fontSize={25} 
                                                                />
                                                            )
                                                        :   (
                                                                <MdRemoveRedEye 
                                                                    cursor="pointer" 
                                                                    fontSize={25} 
                                                                />
                                                            )
                                                    }
                                                </Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            <Paginate
                onPageChange={(data) => setPage(data.selected + 1)}
                pageCount={totalPages}
            />
        </Box>
    )
}
