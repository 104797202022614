import { createSlice, PayloadAction } from '@reduxjs/toolkit'


interface IData {
    id: number,
    milestoneId: string,
    weekDays: any[],
    title: string,
    data: string,
    startTime: string,
    endTime: string,
    guests: any[],
    startDate: string,
    endDate: string,
    social: boolean,
    visitType: string,
    enableVirtualDoorman: boolean,
    guest: boolean

}

const initialState = {
    data: {
        id: 0,
        milestoneId: '',
        weekDays: [],
        title: '',
        data: '',
        startTime: '',
        endTime: '',
        guests: [],
        startDate: '',
        endDate: '',
        social: false,
        visitType: '',
        enableVirtualDoorman: false,
        guest: false
    } as IData
  } 


  export const visitCreateSlice = createSlice({
    name: 'visitCreate',
    initialState,
    reducers: {
        resetCurrentVisitCreate: (state) => {
            state.data = initialState.data
        },
        setCurrentVisitCreate: (state, action: PayloadAction<IData>) => {
            state.data = action.payload
        },
    },
    })
    
    export const { resetCurrentVisitCreate, setCurrentVisitCreate } = visitCreateSlice.actions
    
    export default visitCreateSlice.reducer