import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { toast } from 'react-toastify'

import { 
  Flex, 
  Box, 
  InputGroup, 
  Input, 
  Button, 
  Divider,
  Image
} from '@chakra-ui/react'

import api from '../../services/api'

import { setLoginStatus } from '../../store/reducers/loginStatus'

import Frame from '../../assets/images/Frame1.png';

import Logo from '../../assets/images/logo.png';

import {
  ButtonContainer,
  Warning,
  Title,
  Description,
} from './styles'

export function PasswordRecovery () {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [fields, setFields] = useState({
      email: '',
      login: '',
    })

    useEffect(() => {
      localStorage.removeItem('access_portal:access_token')

      dispatch(setLoginStatus({ isLogged: false }))
    }, [])

    const execAuth = async () => {
      if(!fields.email){
        return toast.error("O campo E-mail é obrigatório.")
      }

      if(!fields.login){
        return toast.error("O campo Login é obrigatório.")
      }
        
      const { login, email } = fields;

      try{
        await api.get(`login/forgotpassword?email=${email}&login=${login.replace(/[^0-9a-z]/gi, '')}`);

        toast.success("Recuperação de senha solicitada com sucesso!")

      }catch(e) {
        let message = "Usuário ou e-mail não cadastrados no sistema.";
        if ((e as any).response.data?.Message) {
          message = (e as any).response.data?.Message;
        }
        toast.error(message)
      }
    }

    return (
      <Flex
        direction={'column'} 
        align={'center'} 
        justify={'center'}
        bg={["linear-gradient(0deg, rgba(211,250,230,1) 0%, rgba(242,253,245,1) 100%)", ""]}
        h={["100%", 'calc(100vh)']}
      >
        <Flex
          direction={'column'} 
          align={'center'} 
          justify={'center'}
          h={["100%", 'calc(100vh)']}
          backgroundImage={[`url(${Frame})`, ""]}
          backgroundSize={"100% auto"}
          backgroundPosition={"0px 50px"}
          backgroundRepeat={"no-repeat"}
          w={["100%", "auto"]}
          justifyContent={["flex-end", "inherit"]}
          >
          <Box
            padding={'20px'}
            borderRadius='lg'
            overflow='hidden'
            border={["0", "1px solid #eee"]}
            position={"relative"}
            background={["", "#fff"]}
            width={["100%", "253px"]}
          >
            <Box
              padding={"0px 20px 20px 20px"}
              display={["none", "flex"]}
            >
              <Image
                src={Logo}
              />
            </Box>
            <Warning>
              <Title>Esqueceu sua senha?</Title>
              <Description>Tudo bem. Informe seu e-mail e login que logo lhe enviaremos uma nova senha.</Description>
            </Warning>
            <InputGroup marginBottom={['8px', '20px']}>
              <Input 
                width={["100%", "auto"]}
                padding={["26px 16px", "19px 16px"]}
                borderRadius={"50px"}
                backgroundColor={"#fff"}
                type='email' 
                placeholder='Digite seu e-mail' 
                value={fields.email}
                onChange={(val) => {
                  setFields({
                    ...fields,
                    email: val.target.value
                  })
                }} 
              />
            </InputGroup>
            <InputGroup marginBottom={'20px'}>
              <Input 
                width={["100%", "auto"]}
                padding={["26px 16px", "19px 16px"]}
                borderRadius={"50px"}
                backgroundColor={"#fff"}
                type='text' 
                placeholder='Digite seu login' 
                value={fields.login}
                onChange={(val) => {
                  setFields({
                    ...fields,
                    login: val.target.value
                  })
                }} 
              />
            </InputGroup>
            <ButtonContainer >
              <Button
                colorScheme='teal'
                size='md'
                onClick={() => execAuth()}
                width={["100%", "auto"]}
                padding={["26px 16px", "19px 16px"]}
                borderRadius={"50px"}
              >
                Recuperar senha
              </Button>
            </ButtonContainer>
            <Divider
              marginTop={5}
              marginBottom={5}
              color={["transparent", "#ccc"]}
            />
            <Box
              display={["flex", "initial"]}
              flexDirection={["row", "initial"]}
              justifyContent={["space-between", ""]}
            >
              <ButtonContainer>
                <Button
                  colorScheme='teal'
                  variant='link'
                  onClick={() => navigate("/auth")}
                >
                  Fazer login
                </Button>
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  colorScheme='teal'
                  variant='link'
                  onClick={() => navigate("/recover-login")}
                >
                  Esqueceu seu login?
                </Button>
              </ButtonContainer>
            </Box>
          </Box>
        </Flex>
      </Flex>
    )
}