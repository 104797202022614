import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import { MdRemoveRedEye } from "react-icons/md"

import ReactJsAlert from "reactjs-alert"

import { PaginationControl } from 'react-bootstrap-pagination-control';

import {
    Flex,
    Box,
    Text,
    Grid,
    GridItem,
    Button,
    Skeleton,
    Select,
} from '@chakra-ui/react'

import api from '../../../services/api'

import { RootState } from '../../../store'

import { setNotification } from '../../../store/reducers/notification'

import { NoDataMessageContainer } from './styles'

interface EndpointResponse {
    id: number
    typeNotification: string
    notificationDate: string
    read: Boolean
    notificationGroupId: number
}

export function NotificationsList() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const notification = useSelector((state: RootState) => state.notification.data);
    const currentPage = useSelector((state: RootState) => state.notification.data.currentPage);
    const totalPerPage:any = useSelector((state: RootState) => state.notification.data.totalPerPage);
    const qtdItens:any = useSelector((state: RootState) => state.notification.data.qtdItens);

    // const [qtdItens, setQtdItens] = useState('10')
    
    const [showAlertPermissionError, setShowAlertPermissionError] = useState(false)
    const [totalPage, setTotalPage] = useState(1)
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        init()
    }, [currentPage, qtdItens])

    async function init(){
        setIsLoading(true)

        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }

            const { data } = await api.get(`/PersonalNotification/GetAll?page=${currentPage}&items=${qtdItens}`, config);

            setTotalPage(data.totalPages)

            dispatch(setNotification({ ...notification, data: data.notifications }))

            setIsLoading(false)
        } catch(e) {

        }
    }

    function getDate(data: any){
        let date = ''
        let hora = ''
        
        data = data.split('T')
        let dateSplit = data[0].split('-')
        date = dateSplit[2] + '/' + dateSplit[1]+ '/' +dateSplit[0].substr(2)
        let horaSplit = data[1].split(':')
        hora = horaSplit[0]+':'+horaSplit[1]
        return `${date} - ${hora}`
    }

    function skeletonLoading() {
        const teste = [];

        for(let i = 0; i < Number(qtdItens); i++) {
            teste.push(i)
        }

        return teste.map(res => {
            return (
                <Skeleton
                    key={res}
                    height={"85px"}
                    marginBottom={"10px"}
                />
            )
        })
    }

    if(notification.data.length < 1) {
        return (
            <NoDataMessageContainer>
                <span style={{fontSize: 18, fontWeight: 'bold'}}>Não há notificações no momento!</span>
            </NoDataMessageContainer>
        )
    }
    
    return (
        <Box>
            <ReactJsAlert
                status={showAlertPermissionError}
                type={'error'}
                title={'Atenção!'}
                quotes={true}
                quote={'Por favor, verificar o perfil de acesso!'}
                autoCloseIn={20000}
                Close={() => setShowAlertPermissionError(false)}
                button={'OK'}
            /> 
            <Grid templateColumns={["0% 100% 0%", "35% 30% 35%"]}>
                <GridItem colSpan={1} />
                <GridItem colSpan={1}>
                    <Flex
                        marginTop={["10px", "40px"]}
                        marginBottom={"10px"}
                        flexDir={["column", "row"]}
                        justifyContent={["center", "space-between"]}
                        alignItems={["flex-end"]}
                    >
                        <Flex
                            justifyContent={["center", "space-between"]}
                            flexDir={["column", "row"]}
                            alignItems={["", "flex-end"]}
                            width={["100%", "100%"]}
                            marginBottom={["0px", "0px"]}
                        >
                            <Text
                                fontSize={[28, 20]}
                                fontWeight={600}
                                color={"#444"}
                                textAlign={["center", "start"]}
                            >
                                Notificações
                            </Text>
                            <Flex
                                alignItems={"center"}
                                justifyContent={["flex-end", ""]}
                            >
                                <Text marginRight={"10px"}>Itens por página</Text>
                                <Select
                                    w={"fit-content"}
                                    background={"#fff"}
                                    value={qtdItens}
                                    onChange={(e) => {
                                        // setQtdItens(e.target.value)
                                        dispatch(setNotification({
                                            ...notification,
                                            qtdItens: e.target.value,
                                            currentPage: 1
                                        }))
                                    }}
                                >
                                    <option value={"10"}>10</option>
                                    <option value={"25"}>25</option>
                                    <option value={"50"}>50</option>
                                    <option value={"100"}>100</option>
                                    <option value={"200"}>200</option>
                                </Select>
                            </Flex>
                        </Flex>
                    </Flex> 
                    <Flex
                        display={["flex"]}
                        flexDirection={"column"}
                    >
                        {isLoading
                            ? skeletonLoading()
                            : notification.data.map((v, i) => {
                                return (
                                    <Flex
                                        flexDirection={"row"}
                                        background={"#fff"}
                                        borderRadius={"8px"}
                                        marginBottom={"10px"}
                                        padding={"20px"}
                                        key={"item_"+i}
                                    >
                                        <Flex
                                            flexDir={"column"}
                                            marginLeft={"10px"}
                                            flexGrow={"1"}
                                            whiteSpace={"nowrap"}
                                            overflow={"hidden"}
                                            textOverflow={"ellipsis"}
                                            paddingRight={"18px"}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    color: v.read?"#a1a1a1":"#444",
                                                    fontWeight: 600,
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                {v.id + ' - ' + v.typeNotification} 
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "14px",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    color: v.read?"#a1a1a1":"#444",
                                                }}
                                            >
                                                {getDate(v.notificationDate)}
                                            </span>
                                        </Flex>
                                        <Flex
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                        >
                                            <Button
                                                variant={"link"}
                                                onClick={async () => {
                                                    console.log('v', v)
                                                    navigate('/notifications/details',  {
                                                        state: {
                                                            item: v,
                                                        }
                                                    })
                                                }}
                                            >
                                                <MdRemoveRedEye 
                                                    fontSize={25}
                                                    color={"#444"}
                                                />
                                            </Button>
                                        </Flex>
                                    </Flex>
                                )
                            })
                        }
                        <PaginationControl
                            page={currentPage}
                            total={totalPage}
                            limit={1}
                            changePage={(page) => {
                                if(!isLoading) {
                                    dispatch(setNotification({ ...notification, currentPage: page }));
                                }
                                
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            ellipsis={1}
                        />
                    </Flex>
                </GridItem>
                <GridItem colSpan={1} />
            </Grid>
        </Box>
    )
}
