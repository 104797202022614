import react, { useEffect, useRef, useState } from "react"

import { useDispatch } from "react-redux";

import { ref, onValue } from "@firebase/database";

import { toast } from "react-toastify";

import {
  Box,
  Heading,
  Flex,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent, 
  DrawerHeader,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Text,
  Badge,
  Img,
  FormLabel,
} from "@chakra-ui/react";

import { useSelector } from "react-redux";

import { HamburgerIcon, TriangleDownIcon, } from "@chakra-ui/icons"
//https://react-icons.github.io/react-icons/icons?name=md
import { MdAccountCircle, MdContactPhone, MdLogout, MdBookmarks, MdPolicy, MdHome } from "react-icons/md"

import { BsFillBellFill } from "react-icons/bs"

import { AiFillBell } from 'react-icons/ai'

import { HiOutlineDocumentText } from 'react-icons/hi'

import { BsFillGearFill } from 'react-icons/bs'

import ReactJsAlert from "reactjs-alert"

import { useLocation, useNavigate } from 'react-router-dom'

import jwt_decode from "jwt-decode"

import { FirebaseRealtimeDatabase } from '../../services/firebase'

import api from '../../services/api'

import { DefaultAvatar } from "../DefaultAvatar";

import { RootState } from '../../store';

import { IUserData } from '../../interfaces/IUserData'

import { hasPermission } from "../../utils/check-permission";

import { setNotification } from '../../store/reducers/notification';

import { setToggleModal } from '../../store/reducers/attendanceConfirmation';

import { ModalComponent } from "../Modal";

import chromeIcon from '../../assets/images/chrome.png'

import operaIcon from '../../assets/images/opera.png'

import {
    Logo,
    ContentLogo,
    ButtonClose,
    DrawerBodyList,
    ButtonMenuList,
    NavItems,
} from './styles';

export const Header = () => {
    const dispatch = useDispatch();
    const location = useLocation() as {
        pathname: any
    };

    const user = useSelector((state: RootState) => state.user.data);

    const attendanceConfirmationShowModal = useSelector((state: RootState) => state.attendanceConfirmation.data.showModal);
    const attendanceConfirmation = useSelector((state: RootState) => state.attendanceConfirmation.data);

    const menuParameterization = useSelector((state: RootState) => state.menuParameterization.data);
    const notification = useSelector((state: RootState) => state.notification.data);

    const btnRef = useRef<HTMLButtonElement>(null);
    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

    const handleToggle = () => (isOpen ? onClose() : onOpen());

    const [userName, setUserName] = useState('')
    const [showAlertPermissionError, setShowAlertPermissionError] = useState(false)
    const [qtdNotification, setQtdNotification] = useState(0)
    const [busynessImg, setBusynessImg] = useState<String>("")
    // const notification = useSelector((state: RootState) => state.notification.data)
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
        }
    }

    useEffect(() => {     
        if(!((location.pathname === '/notifications/details') || (location.pathname === '/notifications'))) {
            if(notification.keepCurrent){
                dispatch(setNotification({
                    ...notification,
                   
                }))
            }else{
                dispatch(setNotification({
                    ...notification,
                    currentPage: 1,
                    qtdItens: '10'
                }))

            }
        }
    }, [location.pathname])

    useEffect(() => {
        const userToken = localStorage.getItem('access_portal:access_token')

        if(userToken){
            const decoded = jwt_decode(userToken as string) as IUserData
            
            const selectedClient = decoded.client_id

            const clientId = selectedClient.split('#')[0]

            setUserName(decoded.user_name.toLocaleLowerCase())

            setBusynessImg(menuParameterization.imageData.data)

            onValue(ref(FirebaseRealtimeDatabase, '/commom-area-request-creation-or-change/' + clientId), (snapshot) => {
                getQtdNotification();
            });

            for(let i in decoded.unit_id) {
                onValue(ref(FirebaseRealtimeDatabase, '/pre-registration-creation-or-change/' + decoded.client_id + '/'+ decoded.unit_id[i]), (snapshot) => {
                    getQtdNotification();
                });
            }
        }
    }, []);

    useEffect(() => {
        if(localStorage.getItem('access_portal:compatibilityModal') === 'false') {
            onOpen2()
        }
    }, []);

    useEffect(() => {
        async function startListener() {

        }

        return () => {
            startListener();
        };
    }, []);

    async function getQtdNotification() {
        try {
            const { data } = await api.get('PersonalNotification/getallnotread', config);

            dispatch(setNotification({
                ...notification,
                count: data
            }))
        } catch(err) {

        }
    }

    return (
        <>
            <ModalComponent 
                isOpen={isOpen2}
                onClose={() => {
                    localStorage.setItem('access_portal:compatibilityModal', 'true');

                    onClose2();
                }}
                title={'Melhore sua experiência'}
                closeButtonLabel=""
                confirmButtonLabel="Eu entendi"
                onConfirmButton={() => {
                    localStorage.setItem('access_portal:compatibilityModal', 'true');

                    onClose2();
                }}

            >
                <Box>
                    <Text>
                        Nosso website foi desenvolvido para navegadores específicos (abaixo) e qualquer outro software, que seja utilizado para acessar o mesmo, pode não apresentar o comportamento esperado.
                    </Text>
                    <Flex
                        flexDir={'row'}
                        justifyContent={'center'}
                    >
                        <Img
                            src={chromeIcon}
                            width={'60px'}
                            padding={'0px 10px'}
                        />
                        <Img
                            src={operaIcon}
                            width={'60px'}
                            padding={'0px 10px'}
                        />
                    </Flex>
                </Box>
            </ModalComponent>

            {/* <ModalComponent
                isOpen={true}
                onClose={() => dispatch(setToggleModal())}
                title={'Confirmação de presença'}            
                closeButtonLabel="Não"
                confirmButtonLabel={informOutputForecastContainer ? "Salvar" : "Sim"}
                onConfirmButton={async () => {
                    if(informOutputForecastContainer) {
                        const config = {
                            headers: {
                                Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                            }
                        }

                        let time = informOutputForecastContainerValue.split(':');

                        const timeAdded = parseInt(time[0]) * parseInt(time[1]) * parseInt(time[2]);

                        await api.post(
                            `/guestEngineEvent/visitorStatusValidation`,
                            {
                                personalNotificationId: 1,
                                lateCheckOut: "FALSE",
                                timeAdded
                            },
                            config
                        );

                        toast.success('Atualização realizada com sucesso!');

                        dispatch(setToggleModal());
                    } else {
                        setInformOutputForecastContainer(true);
                    }
                }}
            >
                <Text>Por favor confirmar se o convidado xpto ainda se encontra presente em seu evento/visita</Text>
                {informOutputForecastContainer && (
                    <Box>
                        <FormLabel
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"3px"}
                        >
                            Informar previsão de saída
                        </FormLabel>
                        <InputTime
                            onChange={(e: any) => {
                                let time = e.target.value.split(':');

                                if (parseInt(time[0]) > 23) {
                                    time[0] = 23;
                                }

                                if (parseInt(time[1]) > 59) {
                                    time[1] = 59;
                                }
                        
                                if (parseInt(time[2]) > 59) {
                                    time[2] = 59;
                                }
                                
                                const value = time[0] + ':' + time[1] + ':' + time[2];

                                setInformOutputForecastContainerValue(value);
                            }}
                            value={informOutputForecastContainerValue}
                        />
                    </Box>
                )}
            </ModalComponent> */}

            <ReactJsAlert
                status={showAlertPermissionError}
                type={'error'}
                title={'Atenção!'}
                quotes={true}
                quote={'Por favor, verificar o perfil de acesso!'}
                autoCloseIn={20000}
                Close={() => setShowAlertPermissionError(false)}
                button={'OK'}
            />
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                <ButtonClose />
                <DrawerHeader>
                    <ContentLogo
                        marginRight={5}
                        display={{ base: "block" }}
                        onClick={() => { 
                            navigate("/home")
                            onClose()
                        }} 
                    >
                        <Logo src={busynessImg? busynessImg : require('../../assets/images/logo.png')} />
                    </ContentLogo>
                </DrawerHeader>
                <DrawerBodyList>
                    <ButtonMenuList
                        colorScheme='transparent'
                        onClick={async () => { 
                            if(await hasPermission(11, 'Visualizar')){
                                navigate('/home')
                                onClose()
                            }else{
                                setShowAlertPermissionError(true)
                            }
                        }}    
                    >
                        <span>
                            <MdHome
                                cursor="pointer"
                                fontSize={25}
                                color={"#acabab"}
                            />
                        </span>
                        <span style={{ marginLeft: 5, color: "#acabab" }}>Início</span>
                    </ButtonMenuList>
                    <ButtonMenuList
                        colorScheme='transparent'
                        onClick={async () => { 
                            if(await hasPermission(11, 'Visualizar')){
                                navigate('/my-profile')
                                onClose()
                            }else{
                                setShowAlertPermissionError(true)
                            }
                        }}    
                    >
                        <span>
                            <MdAccountCircle
                                cursor="pointer"
                                fontSize={25}
                                color={"#acabab"}
                            />
                        </span>
                        <span style={{ marginLeft: 5, color: "#acabab" }}>Meus Dados</span>
                    </ButtonMenuList>
                    <ButtonMenuList
                        colorScheme='transparent'
                        onClick={() => { 
                            navigate('/notifications')
                            onClose()
                        }}    
                    >
                        <span>
                            <AiFillBell color={"#acabab"} cursor="pointer" fontSize={25}/>
                        </span>
                        <span style={{ marginLeft: 5, color: "#acabab" }}>Notificações</span>
                    </ButtonMenuList>
                    <ButtonMenuList 
                        colorScheme='transparent'
                        onClick={async () => {
                            if(await hasPermission(4, 'Visualizar')){
                                navigate('/contact/list')
                                onClose()
                            }else{
                                setShowAlertPermissionError(true)
                            }
                        }}
                        
                    >
                        <span>
                            <MdContactPhone color={"#acabab"} cursor="pointer" fontSize={25} />
                        </span>
                        <span style={{ marginLeft: 5, color: "#acabab" }}>Contatos</span>
                    </ButtonMenuList>
                    <ButtonMenuList 
                        colorScheme='transparent'
                        onClick={async () => {
                            if(await hasPermission(1, 'Visualizar')){
                                navigate('/event/calendar-event')
                                onClose()
                            }else{
                                setShowAlertPermissionError(true)
                            }
                        }}
                        
                    >
                        <span>
                            <MdBookmarks color={"#acabab"} cursor="pointer" fontSize={25}/>
                        </span>
                        <span style={{ marginLeft: 5, color: "#acabab" }}>Reserva de Espaço</span>
                    </ButtonMenuList>
                    <ButtonMenuList 
                        colorScheme='transparent'
                        onClick={async () => {
                            if(await hasPermission(0, 'Visualizar')){
                                navigate('/visit/calendar-visit')
                                onClose()
                            }else{
                                setShowAlertPermissionError(true)
                            }
                        }}
                        
                    >
                        <span>
                            <MdBookmarks color={"#acabab"} cursor="pointer" fontSize={25}/>
                        </span>
                        <span style={{ marginLeft: 5, color: "#acabab" }}>Visitas</span>
                    </ButtonMenuList>
                    <ButtonMenuList 
                        colorScheme='transparent'
                        onClick={() => { 
                            navigate("/configuration")
                            onClose()
                        }}
                    >
                        <span>
                            <BsFillGearFill color={"#acabab"} cursor="pointer" fontSize={25}/>
                        </span>
                        <span style={{ marginLeft: 5, color: "#acabab" }}>Configurações</span>
                    </ButtonMenuList>
                    <ButtonMenuList 
                        colorScheme='transparent'
                        onClick={() => { 
                            navigate("/terms")
                            onClose()
                            // window.open('https://institucional.nicbrain.com.br/termos-de-uso/', '_blank')
                        }}
                    >
                        <span>
                            <HiOutlineDocumentText color={"#acabab"} cursor="pointer" fontSize={25}/>
                        </span>
                        <span style={{ marginLeft: 5, color: "#acabab" }}>Termos de Uso</span>
                    </ButtonMenuList>
                    <ButtonMenuList 
                        colorScheme='transparent'
                        onClick={() => { 
                            navigate("/policies")
                            onClose()
                            // window.open('https://institucional.nicbrain.com.br/politica-de-privacidade/', '_blank')
                        }}
                    >
                        <span>
                            <MdPolicy color={"#acabab"} cursor="pointer" fontSize={25}/>
                        </span>
                        <span style={{ marginLeft: 5, color: "#acabab" }}>Políticas de Privacidade</span>
                    </ButtonMenuList>
                    <ButtonMenuList 
                        colorScheme='transparent'
                        onClick={() => navigate("/auth")}
                    >
                        <span>
                            <MdLogout color={"#acabab"} cursor="pointer" fontSize={25}/>
                        </span>
                        <span style={{ marginLeft: 5, color: "#acabab" }}>Sair</span>
                    </ButtonMenuList>
                </DrawerBodyList>
                </DrawerContent>
            </Drawer>
            <Flex
                align="center"
                justify="space-between"
                wrap="wrap"
                padding={2}
                bg="white"
                color="white"
            >
                <Flex align="center" mr={5}>
                    <Box marginRight={5} display={{ base: "block" }} onClick={handleToggle}>
                        <Button
                            ref={btnRef}
                            colorScheme='white'
                            onClick={onOpen}
                        >
                            <HamburgerIcon cursor="pointer" fontSize={25} color="teal"/>
                        </Button>
                    </Box>
                    <Heading as="h1" size="lg" letterSpacing={"tighter"}>
                            <Button 
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    cursor: 'pointer',
                                }}
                                colorScheme='teal'
                                variant='link'
                                onClick={() => navigate("/home")} 
                            >
                                <div style={{ padding: 10, borderRadius: 10, }}>
                                    <Logo src={require('../../assets/images/logo.png')} />
                                </div>
                            </Button>
                        </Heading>
                </Flex>
                <NavItems display={"flex"}>
                    <Button
                        variant={"link"}
                        onClick={() => navigate('/notifications')}
                        position={"relative"}
                        marginRight={["10px", "0px"]}
                    >
                        <Badge
                            colorScheme={"teal"}
                            top={"-8px"}
                            right={"-8px"}
                            position={"absolute"}
                        >
                            {notification.count > 9
                                ? "9+"
                                : notification.count
                            }
                        </Badge>
                        <BsFillBellFill className="bell" color={"#efc671"} size={24} />
                    </Button>
                </NavItems>
                <Box display={{ base: "none", sm: 'block'}}>
                    <Menu >
                        <MenuButton 
                            px={4}
                            py={2}
                            transition='all 0.2s'
                            borderRadius='md'
                            borderWidth='none'
                            _expanded={{ bg: 'gray.200' }}
                            _focus={{ boxShadow: 'outline' }}
                            color="teal"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                alignSelf={"center"}
                            >
                                
                                {user && user.picture
                                    ?   <Image
                                            boxSize='40px'
                                            objectFit='cover'
                                            src={user.picture}
                                            alt={userName}
                                            display={"flex"}
                                            flexDirection={"row"}
                                            marginRight={"10px"}
                                            borderRadius={"full"}
                                            boxShadow={"0px 0px 5px 0px #0000001a"}
                                        />
                                    :   <DefaultAvatar boxSize={["40px"]} marginRight={"10px"} padding={"5px"} />
                                }
                                
                                <Text textTransform={"capitalize"} fontWeight={"600"}>{userName}</Text>
                                <TriangleDownIcon marginLeft={"10px"} cursor="pointer" fontSize={10}/>
                            </Box>
                        </MenuButton>
                        <MenuList>
                            <MenuItem style={{color: '#000'}} 
                                onClick={async () => { 
                                    if(await hasPermission(11, 'Visualizar')){
                                        navigate('/my-profile')
                                        onClose()
                                    }else{
                                        setShowAlertPermissionError(true)
                                    }
                                }}
                            >Meus Dados</MenuItem>
                            {/* <MenuItem style={{color: '#000'}} onClick={() => navigate("/change-password")} >Alterar Senha</MenuItem> */}
                            <MenuItem style={{color: '#000'}} onClick={() => navigate("/auth")}>Sair</MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
            </Flex>
        </>
    )
}
