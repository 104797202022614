import React, { useEffect, useState } from 'react'

import {
    MdModeEdit,
    MdRemoveRedEye,
    MdPerson,
    MdLocationPin,
} from "react-icons/md"

import {
    FaUser,
    FaUsers
} from "react-icons/fa"

import { toast } from 'react-toastify'

import { ref, onValue, off } from "@firebase/database"

import jwt_decode from "jwt-decode"

import { useNavigate, useLocation } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import {
    Box,
    Flex,
    Text,
    Button,
    CircularProgress,
    Grid,
    GridItem,
} from '@chakra-ui/react'

import api from '../../../services/api'

import { FakeLoadProgress } from '../../../components/FakeLoadProgress'

import { resetCurrentBookingRequest, setCurrentBookingRequest } from '../../../store/reducers/booking-request'

import { formatData } from '../../../utils/format-data'

import { Paginate } from '../../../components/Paginate'

import { resetEvent, setEvent } from '../../../store/reducers/event'

import { FirebaseRealtimeDatabase } from '../../../services/firebase'

import { IUserData } from '../../../interfaces/IUserData'

import { hasPermission } from '../../../utils/check-permission'

import { NewBookingRequestButton } from '../../../components/NewBookingRequestButton'

import { ContainerLoad, NoDataMessageContainer, ButtonsListContainer, ContentHeader } from './styles'

const userToken = localStorage.getItem('access_portal:access_token')

function getMoth(moth: number) {
    switch(moth) {
    case 1:            
        return 'Jan'
        break
    case 2:            
        return 'Fev'
        break
    case 3:
        return 'Mar'
        break
    case 4:
        return 'Abr'
        break
    case 5:
        return 'Mai'
        break
    case 6:
        return 'Jun'
        break
    case 7:
        return 'Jul'
        break
    case 8:
        return 'Ago'
        break
    case 9:
        return 'Set'
        break
    case 10:
        return 'Out'
        break
    case 11:
        return 'Nov'
        break
    case 12:
        return 'Dez'
        break
    }
  }

export function BookingRequestList () {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const [data, setData] = useState<any[]>([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        initContent()
    }, [])

    useEffect(() => {
        // initFirebase()
    }, [])

    async function initContent() {
        const date = location.state.date
        dispatch(resetCurrentBookingRequest())
        dispatch(resetEvent())

        if(!date) {
            navigate(-1)
        }

        if(!(await hasPermission(1, 'Visualizar'))){
            navigate('/home')
        }
                
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }

            const { data } = await api.get(`event/getbydate/${date}`, config)

            if(Array.isArray(data)){
                setData(data)
                
                // setTotalPages(data.totalPages)
            }

            setLoading(false)
        }catch(e) {

        }
    }
    
    async function initFirebase(){
        // const decoded = jwt_decode(userToken as string) as IUserData
        // let selectedClient = decoded.client_id
    
        // let clientId = selectedClient.split('#')[0]
        // let date = new Date()
        // let dia = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate()
        // let mes = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
        // let ano = date.getFullYear()
    
        // let dataFormatted = dia + '-' + mes + '-' + ano

        // for(let i in decoded.unit_id) {
        //     onValue(ref(
        //         FirebaseRealtimeDatabase, 
        //         '/event-creation-or-change/' + decoded.unit_id[i]
        //     ), (snapshot) => {
        //         setPage(1)
                
        //         setTimeout(() => {
        //             initContent()
        //         }, 200)
        //     })
        // }

        // onValue(ref(
        //     FirebaseRealtimeDatabase, 
        //     '/new-commom-area-request/' + clientId + '/' + dataFormatted
        // ), (snapshot) => {
        //     setPage(1)

        //     setTimeout(() => {
        //         initContent()
        //     }, 200)
        // })
    }

    if(loading) {
        return (
            <ContainerLoad>
                <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
            </ContainerLoad>
        )
    }

    if(data.length < 1) {
        return (
            <Box>
                <Flex
                    marginTop={["10px", "40px"]}
                    marginBottom={"10px"}
                    flexDir={["column", "row"]}
                    justifyContent={["center", "space-between"]}
                    alignItems={["flex-end", "flex-end"]}
                >
                    <Flex
                        justifyContent={["center", "flex-start"]}
                        alignItems={["", "flex-end"]}
                        width={["100%", "auto"]}
                        marginBottom={["40px", "0px"]}
                    >
                        <Text
                            fontSize={[28, 20]}
                            fontWeight={600}
                            color={"#444"}
                        >
                            Reservas de Espaço
                        </Text>
                    </Flex>
                    <NewBookingRequestButton />
                </Flex>
                <NoDataMessageContainer>
                    <span
                        style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            color: "#818181"
                        }}
                    >
                        Nenhum registro cadastrado ainda!
                    </span>
                </NoDataMessageContainer>
            </Box>
        )
    }

    return (
        <Grid templateColumns={["0% 100% 0%", "35% 30% 35%"]}>
            <GridItem colSpan={1} />
            <GridItem colSpan={1}>
                <Box>
                    <Flex
                        marginTop={["10px", "40px"]}
                        marginBottom={"10px"}
                        flexDir={["column", "row"]}
                        justifyContent={["center", "space-between"]}
                        alignItems={["flex-end", "flex-end"]}
                    >
                        <Flex
                            justifyContent={["center", "flex-start"]}
                            alignItems={["", "flex-end"]}
                            width={["100%", "auto"]}
                            marginBottom={["40px", "0px"]}
                        >
                            <Text
                                fontSize={[28, 20]}
                                fontWeight={600}
                                color={"#444"}
                            >
                                Reservas de Espaço
                            </Text>
                        </Flex>
                        <NewBookingRequestButton />
                    </Flex>
                    <Flex
                        // display={["flex", "none"]}
                        flexDirection={"column"}
                    >
                        {data.map((v, i) => {
                            return (
                                <Flex
                                    flexDirection={"row"}
                                    background={"#fff"}
                                    borderRadius={"8px"}
                                    marginBottom={"10px"}
                                    padding={"20px"}
                                    key={"item_" + i}
                                >
                                    <Flex
                                        flexDir={"column"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        <span style={{
                                            color: '#9E9E9E',
                                            width: 50,
                                            fontSize: 18,
                                            fontWeight: 'bold'
                                        }}>{v.startDate.substr(8, 2)}</span>
                                        <span style={{
                                            color: '#9E9E9E',
                                            width: 50,
                                            fontSize: 18,
                                            fontWeight: 'bold'
                                        }}>{getMoth(parseInt(v.startDate.substr(5, 2)))}</span>
                                    </Flex>
                                    <Flex
                                        flexDir={"column"}
                                        flexGrow={1}
                                        paddingLeft={"10px"}
                                        borderLeft={"2px solid #4EBC7A"}
                                    >
                                        <Flex>
                                            {v.qtyGuest > 0 && (
                                                <ContentHeader alignItems={"center"}>
                                                    <span style={{ marginRight: 7 }}>
                                                        {v.qtyGuest > 1
                                                            ?   <FaUsers
                                                                    cursor="pointer" 
                                                                    fontSize={18}
                                                                    color={"#fe7013"} 
                                                                />
                                                            :   <FaUser
                                                                    cursor="pointer" 
                                                                    fontSize={18}
                                                                    color={"#fe7013"} 
                                                                />
                                                        }
                                                    </span>
                                                    <span style={{ fontSize: 12 }}>
                                                        {v.qtyGuest > 0 && v.qtyGuest > 1 
                                                            ? `${v.qtyGuest} Convidados` 
                                                            : `${v.qtyGuest} Convidado` 
                                                        }
                                                    </span>
                                                </ContentHeader>     
                                            )}
                                            <ContentHeader alignItems={"center"}>
                                                <span><MdLocationPin size={18} color={"#fe7013"} /></span>
                                                <span style={{
                                                    marginLeft: 5,
                                                    fontSize: 12,
                                                    textTransform: "capitalize"
                                                }}>                                    
                                                    {v.local.name.toLowerCase()}
                                                </span>
                                            </ContentHeader>
                                        </Flex>
                                        <Flex
                                            justify={"space-between"}
                                        >
                                            <Flex alignItems={"center"} fontWeight={"700"}>
                                                {v.name}
                                                {v.title}
                                            </Flex>
                                            <Button
                                                variant='link'
                                                onClick={() => v.approval === 'APROVADO' && 
                                                    !v.changeToEvent &&
                                                    v.requestNeeded
                                                        ? navigate("/booking-request/register", { state: { id: v.id }})
                                                        : navigate("/booking-request/details", { state: { id: v.id, isAdmin: false }})
                                                }
                                            >
                                                {v.qtyGuest == 0
                                                    ?   (
                                                            <MdModeEdit 
                                                                cursor="pointer" 
                                                                fontSize={25}
                                                                color={"#444"} 
                                                            />
                                                        )
                                                    :   (
                                                            <MdRemoveRedEye 
                                                                cursor="pointer" 
                                                                fontSize={25}
                                                                color={"#444"}
                                                            />
                                                        )
                                                }
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            )
                        })}
                    </Flex>
                </Box>
            </GridItem>
        </Grid>
    )
}
