import styled from 'styled-components';

import InputMask from 'react-input-mask';

import { Box } from '@chakra-ui/react';

export const  ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const Logo = styled.img`
  width: 200px;
  margin-left: 20px;
`;

export const Warning = styled(Box)`
  margin-bottom: 20px;
  background: #e8e8e8;
  padding: 15px;
  border-radius: 8px;
`;

export const Title = styled(Box)`
  margin-bottom: 10px;
  font-weight: 500;
  color: #525252;
`;

export const Description = styled(Box)`
  color: #6d6d6d;
  font-size: 14px;
`;
