import styled from 'styled-components';

export const SubContainerCalendar = styled.div`
  width: 418px;
  
  @media (max-width: 1080px){
    width: 318px;
  }

  @media (max-width: 900px){
    width: 298px;
  }
`

export const ContainerHoursFilters = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: space-between;
  margin-top: 30px;
  border: 1px #49c5c3 solid;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
`;

export const ContainerCalendar = styled.div`
  width: 440px;
  overflow-x: scroll;
  border: 1px #49c5c3 solid;
  padding: 10px;
  border-radius: 5px;

  @media (max-width: 1080px){
    width: 340px;
  }

  @media (max-width: 900px){
    width: 260px;
  }

  @media (max-width: 700px){
    width: 165px;
  }

  @media (max-width: 480px){
    width: 320px;
  }
`;

export const SelectWrapper = styled.div`
  .select__value-container--is-multi .select__multi-value .select__multi-value__remove {
    display: none;
  }

  .select__value-container--is-multi .select__multi-value:nth-of-type(1) .select__multi-value__remove,
  .select__value-container--is-multi .select__multi-value:nth-last-of-type(1):not(.select__input-container) .select__multi-value__remove,
  .select__value-container--is-multi .select__multi-value:nth-last-of-type(2):not(.select__input-container) .select__multi-value__remove {
    display: block;
  }
`;