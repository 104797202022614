import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IData {
  showModal: boolean;
};

const initialState = { 
  data: { 
    showModal: true, 
  } as IData
};
  
export const attendanceConfirmation = createSlice({
  name: 'attendanceConfirmation',
  initialState,
  reducers: {
    resetAttendanceConfirmationRequest: (state) => {
      state.data = initialState.data
    },
    setAttendanceConfirmationRequest: (state, action: PayloadAction<IData>) => {
      state.data = action.payload
    },
    setToggleModal: (state) => {
      state.data.showModal = !state.data.showModal
    },
  },
});

export const {
  resetAttendanceConfirmationRequest,
  setAttendanceConfirmationRequest,
  setToggleModal,
} = attendanceConfirmation.actions;

export default attendanceConfirmation.reducer;