import React, { useEffect, useState } from 'react'

import { Container } from './styles'

export function NotFound () {

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }, [])

    if(isLoading) {
        return <></>
    }
    
    return (
        <Container>
            Página não encontrada :(
            <br/>
            <br/>
            Possíveis causas:
            <ul>
                <li>A página não existe;</li>
                <li>Está tentando acessar uma página da área logada estando desconectado;</li>
            </ul>
        </Container>
    )
}