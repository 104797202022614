import styled from 'styled-components'

import {
    Flex,
} from '@chakra-ui/react'

export const Container = styled(Flex)`
    padding: 10px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    > button {
        width: 100%;
    }

    > button + button {
        margin-top: 10px;
    }
`

export const ContainerAlert = styled.div`
    .alert__body{
        margin-top: 20px;        
    }

    a {
        text-decoration: underline;
    }
`
