import styled from 'styled-components';

import {
  Flex,
  Box,
} from '@chakra-ui/react'

export const Container = styled(Flex)`
  > div + div {
    margin-top: 40px;
  }
`;