import api from "../services/api"

export async function hasPermission(
    permissionType: number, 
    permission: string
) {
    //return false
    try{
        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }

        const response = await api.get(`Role/GetCurrentPermissionsByFunction/${permissionType}`, config)

        if(!(response.data.filter((res: any) => res.permission === permission)[0].value)) {
            return false
        }

        return true
        
    }catch(e: any) {
        return false
    }
}

/*

Meus Dados: 11
Permissões: Visualizar e Editar

Evento: 1
Permissões: Adicionar e Visualizar
               
Contatos: 4
Permissões: Adicionar, Visualizar e Editar
                        
*/