import React, { useEffect, useState } from 'react'

import { Box, Button, FormLabel, Select } from '@chakra-ui/react'
import api from '../../services/api'
import { MdUpdate } from 'react-icons/md'

interface IComboContacts {
    setCurrentContact: (val: number) => void
    value: number
    disabled: boolean
}

export function ComboContacts({setCurrentContact, value, disabled}: IComboContacts) {
    
    const [contacts, setContacts] = useState([])

    useEffect(() => {
        getContacts()
    }, [])

    async function getContacts() {
        const config = {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
            }
        }
            
        try {
        
            const { data } = await api.get('/contact/getContactsByUser', config);

            setContacts(data.filter((v: any) => !v.contactFrequent))
        
        }catch(e) {
    
        }
        
    }

    return (
        <Box width={"100%"} marginBottom={"10px"}>
            <FormLabel
                fontSize={"0.8em"}
                color={"#777"}
                marginBottom={"3px"}
            >
                Selecione um contato
            </FormLabel>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                <Select
                    width={"100%"}
                    background={"#fff"}
                    onChange={(e) => {
                        setCurrentContact(parseInt(e.target.value))
                    }}
                    value={value}
                    disabled={disabled}
                >
                    <option value='' selected>Selecione um contato</option>
                    {
                        contacts.map((val: any, key: number) => {
                            return (<option key={key} value={val.id}>{val.name}</option>)
                        })
                    }
                </Select>
                <Button 
                    style={{marginLeft: 5, backgroundColor: '#FFF', padding: 7, borderRadius: 5}}
                    onClick={async () => {
                        if (!disabled) {
                            setCurrentContact(0)
                            getContacts()
                        }
                    }}
                    disabled={disabled}
                >
                    <MdUpdate 
                        cursor={disabled ? "not-allowed" : "pointer"} 
                        fontSize={25}
                        color={disabled ? "#ccc" : "#444"}
                        
                        
                    />
                </Button>
                
            </div>
            <Button 
                colorScheme='teal' 
                onClick={() => {
                    if (!disabled) {
                        window.open('/contact/register', '_blank')
                    }
                }}
                marginTop={["20px"]}
                style={
                    {
                        width: '100%',
                        opacity: disabled ? 0.5 : 1,
                        cursor: disabled ? "not-allowed" : "pointer"
                    }
                }
            >
                Cadastrar um Contato Novo
            </Button>
        </Box>
    )
}