import React, { useRef, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { MdRemoveRedEye, MdModeEdit, MdDelete, MdStar } from "react-icons/md"

import { toast } from 'react-toastify'

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Alert,
    AlertIcon,
    AlertDescription,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Flex,
    Box,
    Text,
    CircularProgress
} from '@chakra-ui/react'

import api from '../../../../../services/api'

import { NoDataMessageContainer, ButtonsListContainer } from './styles'

import { resetCurrentPet, setCurrentPet } from '../../../../../store/reducers/pet'
import { hasPermission } from '../../../../../utils/check-permission'

import ReactJsAlert from "reactjs-alert"

interface EndpointResponse {
    id: number,
    name: string,
    specie: string,
    breed: string,
    gender: string,
    size: string,
    image: string,
    owner: boolean,
}

export function PetsList () {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef =  useRef<HTMLButtonElement>(null)

    const [data, setData] = useState<EndpointResponse[]>([])
    const [currentId, setCurrentId] = useState(0)
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('')
    const [showAlertPermissionError, setShowAlertPermissionError] = useState(false)

    const [isLoading, setIsLoading] = useState(true)
    const [disabledSaveButton, setDisabledSaveButton] = useState(false)

    useEffect(() => {
        init()
    }, [])

    async function init(){
        dispatch(resetCurrentPet())
        /*if(!(await hasPermission(4, 'Visualizar'))){
            navigate('/home')
        }*/
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }

            const { data } = await api.get('/UserAnimal/ListAnimal', config)

            setData(data)

            setIsLoading(false)
        }catch(e) {

        }
    }

    async function deleteItem() {
        setDisabledSaveButton(true)

        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }
            
            await api.delete(`UserAnimal/delete/${currentId}`, config)
            toast.success(`Pet excluído com sucesso.`)

            init()

            setTimeout(() => {
                onClose()

                setDisabledSaveButton(false)
            }, 200)
        }catch(e: any) {
            setDisabledSaveButton(false)
            if(
                e &&
                e.response &&
                e.response.data &&
                e.response.data.Message
            ){
                setDeleteErrorMessage(e.response.data.Message)
            }
        }
    }

    async function getDetailsItem(id: number, to: string) {
        

        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }
            
            const response = await api.get(`UserAnimal/Details/${id}`, config)

            dispatch(setCurrentPet({
                ...response.data[0],
                gender: response.data[0].sex,
                image: response.data[0].imageKey
            }))
            navigate('/pets/' + to)

        }catch(e: any) {
            setDisabledSaveButton(false)
            if(
                e &&
                e.response &&
                e.response.data &&
                e.response.data.Message
            ){
                setDeleteErrorMessage(e.response.data.Message)
            }
        }
    }

    if(isLoading) {
        return (
            <Flex
                position={"relative"}
                h={"calc(100vh - 175px)"}
                w={"100%"}
                top={"0px"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
            </Flex>
        )
    }

    if(data.length < 0) {
        return (
            <NoDataMessageContainer>
                <span
                    style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: "#818181"
                    }}
                >
                    Nenhum registro cadastrado ainda!
                </span>
            </NoDataMessageContainer>
        )
    }    

    return (
        <Box>
            <ReactJsAlert
                status={showAlertPermissionError}
                type={'error'}
                title={'Atenção!'}
                quotes={true}
                quote={'Por favor, verificar o perfil de acesso!'}
                autoCloseIn={20000}
                Close={() => setShowAlertPermissionError(false)}
                button={'OK'}
            />
            <Flex
                marginBottom={"10px"}
                flexDir={["column", "row"]}
                justifyContent={["center", "space-between"]}
                alignItems={["flex-end", "flex-end"]}
            >
                <Flex
                marginTop={["0px", "40px"]}
                justifyContent={["center", "flex-start"]}
                alignItems={["", "flex-end"]}
                width={["100%", "auto"]}
                marginBottom={["0px", "0px"]}
                opacity={["0", "1"]}
                >
                    <Text
                        fontSize={[28, 20]}
                        fontWeight={600}
                        color={"#444"}
                    >
                        Meus Pets
                    </Text>
                </Flex>
                <Button 
                    colorScheme='teal' 
                    size='md'
                    onClick={async () => {
                        if(await hasPermission(4, 'Adicionar')){
                            dispatch(resetCurrentPet())
                            navigate('/pets/register')
                        }else{
                            setShowAlertPermissionError(true)
                        }
                    }}
                >
                    + Novo Pet
                </Button>
            </Flex>
    
            <Flex
                display={["flex", "none"]}
                flexDirection={"column"}
            >
                {data.map((v, i) => {
                    return (
                        <Flex
                            flexDirection={"row"}
                            background={"#fff"}
                            borderRadius={"8px"}
                            marginBottom={"10px"}
                            padding={"20px"}
                        >
                            
                            <Flex
                                flexDir={"column"}
                                marginLeft={"10px"}
                                flexGrow={"1"}
                                whiteSpace={"nowrap"}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                                paddingRight={"18px"}
                            >
                                <span
                                    style={{
                                        fontSize: "16px",
                                        color: "#444",
                                        fontWeight: 600,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                    }}
                                >
                                    {v.name}
                                </span>
                                <span
                                    style={{
                                        fontSize: "14px",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                    }}
                                >
                                    {v.specie}
                                </span>
                                <span
                                    style={{
                                        fontSize: "14px",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                    }}
                                >
                                    {v.breed}
                                </span>
                            </Flex>
                            <Flex
                                justifyContent={"center"}
                                alignContent={"center"}
                            >
                                {v.owner && (
                                    <ButtonsListContainer>
                                        {/*<MdRemoveRedEye
                                            cursor="pointer" 
                                            fontSize={25}
                                            color={"#444"}
                                            onClick={() => {
                                                getDetailsItem(v.id, 'details')
                                            }}
                                        />*/}
                                        <MdModeEdit 
                                            cursor="pointer" 
                                            fontSize={25}
                                            color={"#444"}
                                            onClick={async () => {
                                                getDetailsItem(v.id, 'update')
                                            }}
                                        />
                                        <MdDelete 
                                            cursor="pointer" 
                                            fontSize={25}
                                            color={"#444"}
                                            onClick={() => {
                                                setDeleteErrorMessage('')
                                                setCurrentId(v.id)
                                                onOpen()
                                            }} 
                                        />
                                    </ButtonsListContainer>
                                )}
                            </Flex>
                        </Flex>
                    )
                })}
            </Flex>
            
            <Box
                background={["#fff", "#fff"]}
                padding={"20px 0px"}
                borderRadius={"10px"}
                display={["none", "inherit"]}
            >
                <TableContainer display={["none", "initial"]}>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Nome</Th>
                                <Th>Tipo</Th>
                                <Th>Raça</Th>
                                {/*<Th padding={0} maxW={"min-content"}></Th>*/}
                                <Th padding={0} maxW={"min-content"}></Th>
                                <Th padding={0} maxW={"min-content"}></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((v, i) => {
                                return (
                                    <Tr key={i} _hover={{ background: "#eee"}}>
                                        <Td fontSize={12}>
                                            <Flex alignItems={"center"}>
                                                {v.name}
                                            </Flex>
                                        </Td>
                                        <Td fontSize={12}>{v.specie}</Td>
                                        <Td fontSize={12}>{v.breed}</Td>
                                        {/*<Td padding={0} maxW={"min-content"}>
                                            <Flex justifyContent={"center"}>
                                                <Button
                                                    variant='link'
                                                > 
                                                    <MdRemoveRedEye
                                                        cursor="pointer" 
                                                        fontSize={25}
                                                        onClick={() => {
                                                            getDetailsItem(v.id, 'details')
                                                        }}
                                                    />
                                                </Button>
                                            </Flex>
                                        </Td>*/}
                                        <Td padding={0} maxW={"min-content"}>
                                            {v.owner && (
                                                <Flex justifyContent={"center"}>
                                                    <Button variant='link'>
                                                        <MdModeEdit 
                                                            cursor="pointer" 
                                                            fontSize={25}
                                                            onClick={async () => {
                                                                getDetailsItem(v.id, 'update')
                                                            }}
                                                        />
                                                    </Button>
                                                </Flex>
                                            )}
                                        </Td>
                                        <Td padding={0} maxW={"min-content"}>
                                            {v.owner && (
                                                <Flex justifyContent={"center"}>
                                                    <Button variant='link'>
                                                        <MdDelete 
                                                            cursor="pointer" 
                                                            fontSize={25} 
                                                            onClick={() => {
                                                                setDeleteErrorMessage('')
                                                                setCurrentId(v.id)
                                                                onOpen()
                                                            }} 
                                                        />
                                                    </Button>
                                                </Flex>
                                            )}
                                        </Td>
                                    </Tr>
                                )
                            })}
                            
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Atenção!
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Deseja realmente excluir este pet?
                            {
                                deleteErrorMessage && deleteErrorMessage.length > 0 &&
                                <Alert status='error' style={{marginTop: 20, marginBottom: 10}}>
                                    <AlertIcon />
                                    <AlertDescription>{ deleteErrorMessage }</AlertDescription>
                                </Alert>
                            }
                            
                        </AlertDialogBody>

                        <AlertDialogFooter>
                        <Button
                            background={"#d36262"}
                            color={"#fff"}
                            ref={cancelRef}
                            _hover={{
                                _disabled: {
                                    background: "#d36262"
                                }
                            }}
                            onClick={() => {
                                setDeleteErrorMessage('')
                                onClose()
                            }}
                            isDisabled={disabledSaveButton}
                        >
                            Cancelar
                        </Button>
                        <Button 
                            colorScheme={"teal"} 
                            onClick={() => {
                                deleteItem()
                            }} 
                            ml={3}
                            isDisabled={disabledSaveButton}
                        >
                            Confirmar
                        </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    )
}
