import React from 'react';

import stc from 'string-to-color';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";


interface IModalRules {
  isOpen: boolean
  onClose: () => void
  description: string
}

export function ModalRules({ isOpen, onClose, description }: IModalRules) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Regras</ModalHeader>
        <ModalBody>
          {description}
        </ModalBody>
        <ModalFooter
          display={"flex"}
          justifyContent={"space-between"}
          color={"#fff"}
        >
          <Button
            background={"#d36262"}
            mr={3}
            _hover={{
              _disabled: {
                background: "#d36262"
              }
            }}
            onClick={onClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}