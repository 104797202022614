import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom'

import { format, parseISO } from 'date-fns';


import { 
  Grid,
  GridItem,
  CircularProgress,
  Box,
  Button,
  Flex,
  useDisclosure,
} from '@chakra-ui/react'

import { Calendar, momentLocalizer } from 'react-big-calendar';

import { toast } from 'react-toastify'

import moment from 'moment';

import 'moment/locale/pt-br';

import ReactJsAlert from "reactjs-alert"

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-calendar/dist/Calendar.css';

import api from '../../../services/api'

import { ContainerLoad,  } from './styles';

import { NewBookingRequestButton } from '../../../components/NewBookingRequestButton'

import { hasPermission } from '../../../utils/check-permission'

export function CalendarOfVisits() {
  const localizer = momentLocalizer(moment);

  const defaultMessages = {
    date: 'Data',
    time: 'Hora',
    event: 'Evento',
    allDay: 'Dia todo',
    week: 'Semana',
    work_week: 'Semana de trabalho',
    day: 'Dia',
    month: 'Mês',
    previous: 'Voltar',
    next: 'Próximo',
    yesterday: 'Ontem',
    tomorrow: 'Amanhã',
    today: 'Hoje',
    agenda: 'Agenda',
    noEventsInRange: 'Não há visitas neste intervalo.',
    showMore: function showMore(total: number) {
      return `+${total} more`;
    },
  };

  const [currentData, setCurrentData] = useState(new Date())

  const [view, setView] = useState<any>("month")

  const navigate = useNavigate()

  const [data, setData] = useState<any[]>([])

  const [loading, setLoading] = useState(true)

  const [showModalPermission, setShowModalPermission] = useState(false)

  useEffect(() => {
    initContent()
  }, [])

  async function initContent() {
    try {
      setLoading(true)

      const config = {
        headers: {
          Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
        }
      }
  
      const { data } = await api.get(`visit/getschedule`, config)
  
      setData(data.map((res: any) => {
        return {
          id: 1,
          title: "",
          start: parseISO(res),
          end: parseISO(res),
          allDay: false,
          background: "#3eb7c0"
        }
      }));
      
      setLoading(false)
    }catch(e: any) {
      if(
        e &&
        e.response &&
        e.response.data &&
        e.response.data.Message
      ){
        toast.error(e.response.data.Message)
      }
    }
  }

  function onClick(change: Date) {
    navigate('/visit/list', {
      state: {
        date: format(change, "yyyy-MM-dd"),
      }
    })
  }

  function onNavigate(change: Date) {
    setCurrentData(change)
  }

  function CustomToolbar(event: any) {
    return (
      <Flex
        alignItems={"center"}
        marginBottom={"20px"}
      >
        <Flex>
          <Button
            marginRight={"5px"}
            onClick={() => event.onNavigate('PREV', null as any)}
          >
            Voltar
          </Button>
          <Button onClick={() => event.onNavigate('NEXT', null as any)}>
            Próximo
          </Button>
        </Flex>
        <Flex
          flexGrow={"1"}
          justifyContent={"center"}
          fontWeight={"600"}
        >
          {event.label}
        </Flex>
      </Flex>
    )
  }

  if(loading) {
    return (
      <ContainerLoad>
        <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
      </ContainerLoad>
    )
  }

  return (
    <Grid templateColumns={["0% 100% 0%", "30% 40% 30%"]}>
      <GridItem colSpan={1} />
      <GridItem
        colSpan={1}
        paddingTop={"20px"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <ReactJsAlert
                status={showModalPermission}
                type={'error'}
                title={'Atenção!'}
                quotes={true}
                quote={'Por favor, verificar o perfil de acesso!'}
                autoCloseIn={20000}
                Close={() => setShowModalPermission(false)}
                button={'OK'}
            />
        <Flex
          marginBottom={"20px"}
          justifyContent={"flex-end"}
          w={"100%"}
        >
          <Button 
              marginRight={['0px', '0px']}
              colorScheme='teal' 
              size='md'
              onClick={async () => {
                if(await hasPermission(0, 'Adicionar')){

                  navigate("/visit/register", {
                    // state: {
                      //   singlePersonEvent: fromTo.filter(res => res.num === data.singlePersonEvent)[0].value,
                      //   // guest_type: ,
                      //   editar: false
                      // }
                    })
                  }else{
                    setShowModalPermission(true)
                    // toast.error("Por favor, verificar o perfil de acesso!")
                  }
                }
                }
          >
              + Nova Visita
          </Button>
        </Flex>
        <Box
          padding={["10px", "20px"]}
          background={["#fff"]}
          w={"100%"}
          borderRadius={"4px"}
          h={["400px", "600px"]}
        >
          <Calendar
            defaultDate={moment().toDate()}
            date={currentData}
            defaultView='month'
            className='calendar-events'
            eventPropGetter={(event) => ({
              style: {
                color: event.color,
                background: event.background,
                fontSize: '12px',
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 0,
              },
            })}
            components={{ toolbar: CustomToolbar }}
            localizer={localizer}
            step={15}
            // messages={defaultMessages}
            events={data}
            views={['month']}
            onSelectEvent={(event) => onClick(event.start)}
            onNavigate={onNavigate}
          />
        </Box>
      </GridItem>
      <GridItem colSpan={1} />
    </Grid>
  );
}