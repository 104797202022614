import React, { useEffect, useState } from 'react';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import { useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { NotFound } from './pages/NotFound';

import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { MeusDados } from './pages/MeusDados';

import { PasswordRecovery } from './pages/PasswordRecovery';
import { RecoverLogin } from './pages/RecoverLogin';

import { Policies } from './pages/Policies';

import { Terms } from './pages/Terms';
//Contacts
import { ContactList } from './pages/Contacts/List';
import { ContactDetails } from './pages/Contacts/Details';
import { ContactForm } from './pages/Contacts/Form';

//Pets
import { PetsList } from './pages/MeusDados/components/Pets/List';
import { PetsForm } from './pages/MeusDados/components/Pets/Form';
import { PetsDetails } from './pages/MeusDados/components/Pets/Details';

//Guests
import { GuestList } from './pages/MeusDados/components/Guests/List';
import { GuestForm } from './pages/MeusDados/components/Guests/Form';

import { Termos } from './pages/Termos';

//Booking Request
import { BookingApproval } from './pages/BookingApproval';
import { BookingRequestList } from './pages/BookingRequest/List';
import { BookingRequestForm } from './pages/BookingRequest/Form';
import { BookingRequestDetails } from './pages/BookingRequest/Details';

//Visit
import { CalendarOfVisits } from './pages/Visit/CalendarOfVisits';
import { VisitList } from './pages/Visit/List';
import { VisitDetails } from './pages/Visit/Details';
import { VisitForm } from './pages/Visit/Form';
// Event
import { CalendarOfEvents } from './pages/Event/CalendarOfEvents';
import { EventList } from './pages/Event/List';
import { EventDetails } from './pages/Event/Details';
import { EventEdit } from './pages/Event/Edit';

//Pre cadastro
import { PreRegistration } from './pages/PreRegistration';

//Configuration
import { Configuration } from './pages/Configuration';

//Notifications
import { NotificationsList } from './pages/Notifications/List';
// import { NotifictaionsDetails } from './pages/Notifications/Details'
import { DependentsList } from './pages/MeusDados/components/Dependents/List';
import { DependentForm } from './pages/MeusDados/components/Dependents/Form';
// import { CreateDependent } from './pages/MeusDados/components/Dependents/Form'
import { IUserData, NotifictaionsDetails } from './pages/Notifications/Details';

import { VehiclesDetails } from './pages/MeusDados/components/Vehicles/details';

// no-show
// import { NoShow } from './pages/NoShow';

import { RootState } from './store';

import { Layout } from './components/Layout';

export default function RoutesComponent() {

  const loginStatus = useSelector((state: RootState) => state.loginStatus.data)
  
  const [subModuleId, setSubModuleId] = useState('')

  useEffect(() => {
    loadSubModuleId()
  }, [])

  function loadSubModuleId () {
    const userToken = localStorage.getItem('access_portal:access_token');

    if(userToken){
      const decoded = jwt_decode(userToken as string) as IUserData

      setSubModuleId(decoded.subModuleId);
    }else{
      setSubModuleId('0');
      setTimeout(() => {
        loadSubModuleId()
      }, 3000)
    }
  }

  if(!subModuleId || subModuleId === '') {
    return <></>
  }

  console.log('subModuleId', subModuleId)
  

  return (
    <Router>
      {loginStatus.isLogged
        ? (
          <Routes>
            <Route index element={<Layout><Home /></Layout>} />
            <Route path="/home" element={<Layout><Home /></Layout>} />
            <Route path="/my-profile" element={<Layout><MeusDados /></Layout>} />
            {/* Politica */}
            <Route path="/policies" element={<Layout><Policies /></Layout>} />
            {/* Termos */}
            <Route path="/terms" element={<Layout><Terms /></Layout>} />
            {/* Contacts */}
            <Route path="/contact/list" element={<Layout><ContactList /></Layout>} />
            <Route path="/contact/details" element={<Layout><ContactDetails /></Layout>} />
            <Route path="/contact/update" element={<Layout><ContactForm /></Layout>} />
            <Route path="/contact/register" element={<Layout><ContactForm /></Layout>} />
            {/* Dependents */}
            <Route path="/dependent/update" element={<Layout><DependentForm /></Layout>} />
            <Route path="/dependent/create" element={<Layout><DependentForm /></Layout>} />

            {/* Pets */}
            {subModuleId === '4' &&
              (
                <>
                  <Route path="/pets/list" element={<Layout><PetsList /></Layout>} />
                  <Route path="/pets/register" element={<Layout><PetsForm /></Layout>} />
                  <Route path="/pets/update" element={<Layout><PetsForm /></Layout>} />
                  <Route path="/pets/details" element={<Layout><PetsDetails /></Layout>} />
                </>
              )
            }

            {/* Guests */}
            {subModuleId === '4' &&
              (
                <>
                  <Route path="/guest/list" element={<Layout><GuestList /></Layout>} />
                  <Route path="/guest/register" element={<Layout><GuestForm /></Layout>} />
                </>
              )
            }

            {/* Visita */}
            <Route path="/visit/calendar-visit" element={<Layout><CalendarOfVisits /></Layout>} />
            <Route path="/visit/list" element={<Layout><VisitList /></Layout>} />
            <Route path="/visit/details" element={<Layout><VisitDetails /></Layout>} />
            <Route path="/visit/register" element={<Layout><VisitForm /></Layout>} />
            <Route path="/visit/update" element={<Layout><VisitForm /></Layout>} />
            {/** Event */}
            <Route path="/event/calendar-event" element={<Layout><CalendarOfEvents /></Layout>} />
            <Route path="/event/list" element={<Layout><EventList /></Layout>} />
            <Route path="/event/details" element={<Layout><EventDetails /></Layout>} />
            <Route path="/event/edit" element={<Layout><EventEdit /></Layout>} />
            {/* Booking Request */}
            <Route path="/booking-approval/list" element={<Layout><BookingApproval /></Layout>} />
            <Route path="/booking-request/list" element={<Layout><BookingRequestList /></Layout>} />
            <Route path="/booking-request/register" element={<Layout><BookingRequestForm /></Layout>} />
            <Route path="/booking-request/details" element={<Layout><BookingRequestDetails /></Layout>} />
            {/** Pre cadastro */}
            <Route path="/pre-registration/details" element={<Layout><PreRegistration /></Layout>} />
            {/* Configuration */}
            <Route path="/configuration" element={<Layout><Configuration /></Layout>} />
            {/* Notifications */}
            <Route path="/notifications" index element={<Layout><NotificationsList /></Layout>} />
            <Route path="/notifications/details" element={<Layout><NotifictaionsDetails /></Layout>} />
            {/** no-show */}            
            <Route path="/vehicles/edit" element={<Layout><VehiclesDetails /></Layout>} />
            <Route path="/vehicles/register" element={<Layout><VehiclesDetails /></Layout>} />
            {/** Logout */}
            <Route path="/auth" element={<Login />} />
            <Route path='*' element={<NotFound />}/>
            <Route path='/termos' element={<Termos />}/>
          </Routes>
        )
        : (
          <Routes>
            <Route index element={<Login />} />
            <Route path="/auth" element={<Login />} />
            <Route path="/password-recovery" element={<PasswordRecovery />} />
            <Route path="/recover-login" element={<RecoverLogin />} />
            <Route path='/termos' element={<Termos />}/>
            <Route path='*' element={<NotFound />}/>
          </Routes>
        )
      }
    </Router>
  )
}
