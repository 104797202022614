import React, { useEffect, useState, useRef } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { format, parseISO } from 'date-fns'

import { 
  Heading, 
  Box,
  Text,
  Button,
  Grid,
  GridItem,
  Flex,
  CircularProgress,
  Img,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react'

import { toast } from "react-toastify";

import api from '../../../services/api'

import { ModalGuest } from './components/ModalGuest'

import { ModalRules } from './components/ModalRules'

import { expiredEventCheck } from '../../../utils/expiredEventCheck';

import { Container } from './styles'

import jwt_decode from "jwt-decode"

import { IUserData } from '../../../interfaces/IUserData'
interface IGuest {
  contactMilestoneId: number
  unplannedMilestoneId: any
  contactFrequentWorkerId: any
  contactFrequentClosedPersonId: any
  attendance: string
  status: string
  informedOn: boolean
  guestReleaseType: {
    id: number
    name: string
  }
  contact: {
    id: number
    name: string
    isDeleted: boolean
  } | null
  internalGuest: {
    id: number
    name: string
    isDeleted: boolean
  } | null
  preRegistration: {
    id: number
    name: string
    rg: any
    attendance: string
    validated: string
    imageKeyValid: boolean
  }
  guest: any
  milestone: {
    id: number
    name: string
    autoAcceptPending: boolean
    preRegistrationRequired: boolean
  }
  is_deleted: boolean
}

interface IEvent {
  id: number
  name: string
  description: string
  startDate: string
  endDate: string
  guestCanEntry: false
  unit: {
    id: number
    name: string
  }
  apto: number
  floor: number
  privateLocalType: string
  visited: string
  intercom: number
  phoneNumber: string
  notifyUser: boolean
  client: {
    id: number
    name: string
  }
  local: {
    id: number
    name: string
    details: string
    request: boolean
  }
  milestone: {
    id: number
    name: string
    guest: boolean
    social: boolean
    preRegistrationRequired: boolean
    guestType: 'BOTH' | 'EXTERNAL' | 'INTERNAL'
  }
  deviceCalendarId: any
  subjectToCancellation: boolean
  guests: IGuest[]
  department: any
  departmentArea: any
  type: string
  data: string
  imageValid: boolean
  enableVirtualDoorman: boolean
}

type PropsAreaComuns = {
  localId: number,
  name: string,
  details: string,
}

export function EventDetails () {
  const navigate = useNavigate()
  const location = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();
  const { isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4 } = useDisclosure();

  const dialogRef: any = useRef();

  const dialogRef2: any = useRef();

  const [loading, setLoading] = useState(true)

  const [event, setEvent] = useState<IEvent>({
    id: 0,
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    guestCanEntry: false,
    unit: {
      id: 0,
      name: "",
    },
    apto: 0,
    floor: 0,
    privateLocalType: "",
    visited: "",
    intercom: 0,
    phoneNumber: "",
    notifyUser: false,
    client: {
      id: 0,
      name: "",
    },
    local: {
      id: 0,
      name: "",
      details: "",
      request: false,
    },
    milestone: {
      id: 0,
      name: "",
      guest: false,
      social: false,
      preRegistrationRequired: false,
      guestType: "EXTERNAL",
    },
    deviceCalendarId: null,
    subjectToCancellation: false,
    guests: [],
    department: null,
    departmentArea: null,
    type: "",
    data: "",
    imageValid: false,
    enableVirtualDoorman: false,
  })

  const [areasComuns, setAreasComuns] = useState<PropsAreaComuns[]>([])

  const [userId, setUserId] = useState(0)
  
  const [createdBy, setCreatedBy] = useState(0)

  useEffect(() => {
    const userToken = localStorage.getItem('access_portal:access_token')
    const decoded = jwt_decode(userToken as string) as IUserData

    setUserId(parseInt(decoded.user_id))
  },[])

  useEffect(() => {
    if(location && location.state && location.state.userId) {
      setCreatedBy(parseInt(location.state.userId))
    }
  }, [location && location.state && location.state.userId])

  useEffect(() => {
    if(location && location.state && location.state.id) {
      getDetails()
    }
  }, [location && location.state && location.state.id])

  async function getDetails() {
    const id = location && location.state && location.state.id;

    const userToken = localStorage.getItem('access_portal:access_token')

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }

    const { data } = await api.get(`event/getdetails/${id}`, config);

    setEvent(data)

    let local = data.local.id

    const { data: response } = await api.get(`local/getwithrules?localId=${local}`, config);

    setAreasComuns(response)

    setLoading(false)
  }

  function getLocalName() {
    const id = event?.local.id;

    let name = '';
    let rules = '';

    for(let i = 0; i < areasComuns.length; i++){
      if(id == areasComuns[i].localId){
        name = areasComuns[i].name;
        rules = areasComuns[i].details;

        break
      }
    }

    return { name,  rules };
  }

  if(loading) {
    return (
      <Flex
        position={"relative"}
        h={"calc(100vh - 175px)"}
        w={"100%"}
        top={"0px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
      </Flex>
    )
  }


  //console.log("event.guests>>>", event.guests)

  return (
    <Grid templateColumns={["0% 100% 0%", "30% 40% 30%"]}>
      <GridItem colSpan={1} />
      <GridItem
        colSpan={1}
        paddingTop={"20px"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <AlertDialog
          isOpen={isOpen3}
          leastDestructiveRef={dialogRef}
          onClose={onClose3}
        >
          <AlertDialogOverlay>
            <AlertDialogContent width={"90%"}>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Atenção!
              </AlertDialogHeader>
              <AlertDialogBody>
                Deseja realmente excluir esse evento?
              </AlertDialogBody>
              <AlertDialogFooter
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Button
                  background={"#d36262"}
                  color={"#fff"}
                  onClick={onClose3}
                  _hover={{
                    _disabled: {
                      background: "#d36262"
                    }
                  }}
                >
                  Não
                </Button>
                <Box>
                  <Button
                    onClick={async () => {
                      try {
                        const id = event.id;

                        await api.delete(`event/delete/${id}`);

                        toast.success("Evento excluído com sucesso!");

                        onClose3();

                        navigate(-1);
                      } catch(e: any) {
                        if(
                          e &&
                          e.response &&
                          e.response.data &&
                          e.response.data.Message
                        ){
                          toast.error(e.response.data.Message)
                        }
                      }
                    }}
                    color={"#65788a"}
                    boxShadow={"0px 1px 3px #ccc"}
                  >
                    Sim
                  </Button>                   
                </Box>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
          isOpen={isOpen4}
          leastDestructiveRef={dialogRef2}
          onClose={onClose4}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <AlertDialogOverlay>
            <AlertDialogContent width={"90%"}>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Atenção!
              </AlertDialogHeader>
              <AlertDialogBody>
                Deseja reagendar esse evento?
              </AlertDialogBody>
              <AlertDialogFooter
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Button
                  background={"#d36262"}
                  color={"#fff"}
                  onClick={onClose4}
                  _hover={{
                    _disabled: {
                      background: "#d36262"
                    }
                  }}
                >
                  Não
                </Button>
                <Box>
                  <Button
                    onClick={async () => {
                      const id = location.state.id

                      navigate("/event/edit", {
                        state: {
                          id,
                          title: "Reagendamento de evento"
                        }
                      });
                    }}
                    color={"#65788a"}
                    boxShadow={"0px 1px 3px #ccc"}
                  >
                    Sim
                  </Button>                   
                </Box>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <ModalGuest
          onClose={onClose}
          isOpen={isOpen}
          data={event.guests}
          guestType={event && event.milestone && event.milestone.guestType}
        />
        <ModalRules
          onClose={onClose2}
          isOpen={isOpen2}
          description={areasComuns[0].details}
        />
        <Box w={"100%"} >
          <Flex
            marginTop={["10px", "40px"]}
            flexDir={["column", "row"]}
            justifyContent={["center", "space-between"]}
            alignItems={["flex-end", "flex-end"]}
          >
            <Flex
              justifyContent={["center", "flex-start"]}
              alignItems={["", "flex-end"]}
              width={["100%", "auto"]}
              marginBottom={["40px", "0px"]}
            >
              <Text
                fontSize={[28, 20]}
                fontWeight={600}
                color={"#444"}
              >
                Detalhes do Evento
              </Text>
            </Flex>
          </Flex>
          <Container
            flexDir={"column"}
            padding={["0px", "20px"]}
            borderRadius={"6px"}
            background={["transparent", "#fff"]}
          >
            <Box>
              <Heading size='xs' textTransform='uppercase' color="#777">
                Nome do evento
              </Heading>
              <Text pt='2' fontSize='sm'>
                {event?.name}
              </Text>
            </Box>

            <Box>
              <Heading size='xs' textTransform='uppercase' color="#777">
                Tipo
              </Heading>
              <Text pt='2' fontSize='sm'>
                {event?.milestone.name}
              </Text>
            </Box>

            <Box>
              <Heading size='xs' textTransform='uppercase' color="#777">
                Espaço
              </Heading>
              <Text pt='2' fontSize='sm'>
                {areasComuns[0].name}
              </Text>
            </Box>

            <Box>
              <Heading size='xs' textTransform='uppercase' color="#777">
                Data
              </Heading>
              <Text pt='2' fontSize='sm'>
                {format(parseISO(event?.startDate), 'dd/MM/yyyy')}
              </Text>
            </Box>

            <Box>
              <Heading size='xs' textTransform='uppercase' color="#777">
                Horário
              </Heading>
              <Text pt='2' fontSize='sm'>
                {format(parseISO(event?.startDate), 'HH:mm')} às {format(parseISO(event?.endDate), 'HH:mm')}
              </Text>
            </Box>

            {event.guests.length > 0 && (
              <Box display={["flex", "initial"]} flexDir={["column"]}>
                <Heading size='xs' textTransform='uppercase' color="#777">
                  {event.guests.length} {event.guests.length > 1 ? "Convidados": "Convidado"}
                </Heading>
                <Button
                  marginTop={"8px"}
                  color={"#65788a"}
                  boxShadow={"0px 1px 3px #ccc"}
                  onClick={onOpen}
                >
                  Ver lista de convidados
                </Button>
              </Box>
            )}

            <Box>
              <Heading size='xs' textTransform='uppercase' color="#777">
                Imagem da reserva
              </Heading>
              <Text pt='2' fontSize='sm'>
                {event.data && (
                  <Img
                    src={"data:image/jpeg;base64," + event.data.replace("data:image/jpeg;base64,", '')}
                    w={"100%"}
                    borderRadius={"4px"}
                    _hover={{
                      boxShadow: "0px 5px 5px #ccc",
                      transition: "0.5s"
                    }}
                  />
                )}
                {!event.data && "Evento sem imagem!"}
              </Text>
            </Box>

            <Box display={["flex", "initial"]} flexDir={["column"]}>
              <Heading size='xs' textTransform='uppercase' color="#777">
                Regras
              </Heading>
              <Button
                marginTop={"8px"}
                color={"#65788a"}
                boxShadow={"0px 1px 3px #ccc"}
                onClick={onOpen2}
              >
                Ver
              </Button>
            </Box>

            <Flex flexDir={["column", "row"]} justifyContent={"space-between"}>
              <Button
                colorScheme={"teal"}
                isDisabled={expiredEventCheck(event.startDate) || createdBy != userId}
                onClick={() => {
                  if(expiredEventCheck(event.startDate)) {
                    return true
                  }

                  const id = location.state.id
                  
                  navigate("/event/edit", {
                    state: {
                      id,
                    }
                  })
                }}
              >
                Editar
              </Button>
              <Flex
                flexDir={'row'}
                marginTop={["20px", "initial"]}
                justifyContent={["space-between", "initial"]}
              >
                {(!expiredEventCheck(event.startDate) && !event.local.request) && (
                  <Button
                    colorScheme={'teal'}
                    marginRight={'20px'}
                    color={"#fff"}
                    onClick={onOpen4}
                  >
                    Reagendar
                  </Button>
                )}
                <Button
                  background={"#d36262"}
                  color={"#fff"}
                  isDisabled={expiredEventCheck(event.startDate) || createdBy != userId}
                  _hover={{
                    _disabled: {
                      background: "#d36262"
                    }
                  }}
                  onClick={async () => {
                    if(expiredEventCheck(event.startDate)) {
                      return true
                    }

                    const userToken = localStorage.getItem('access_portal:access_token')

                    const config = {
                      headers: {
                        Authorization: `Bearer ${userToken}`
                      }
                    }

                    const { data: datass } = await api.get(
                      `Role/GetCurrentPermissionsByFunction/1`, 
                      config
                    );

                    if(!(datass.filter((res: any) => res.permission === 'Deletar')[0].value)) {
                      return toast.error("Por favor, verificar o perfil de acesso!")
                    }

                    return onOpen3()
                  }}
                >
                  Excluir
                </Button>
              </Flex>
            </Flex>
            <Flex flexDir={["column", "row"]}>
              <Button 
                colorScheme='teal' 
                size='md'
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            </Flex>
          </Container>
        </Box>
      </GridItem>
      <GridItem colSpan={1} />
    </Grid>
  )
}
