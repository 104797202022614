import styled from 'styled-components'

export const Container = styled.div`
`

export const ContainerWeekDays = styled.div`
`

export const Title = styled.span`
`

export const SubContainerWeekDays = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;

    .row1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .row2 {
        margin-top: 10px;
    }

    .buttonCopyWrapper {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .buttonCopyWrapper button {
        background-color: #309794;
        border: 1px solid #309794;
        color: #ffffff;
    }
    
`

interface WeekDaySpanProps {
    selected: boolean;
}
export const WeekDayButton = styled.button<WeekDaySpanProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  ${props => (props.selected ? '#309794' : 'transparent')};
    border: 1px solid ${props => (props.selected ? '#F5F5F5' : '#309794')}; ;
    color: ${props => (props.selected ? '#FFF' : '#000')}; ;
    padding: 6px;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
`

export const TimeForWeekDays = styled.div`
`

export const WeekFooterLabel = styled.div`
    color: red;
    font-size: 10px;
`

export const TimeFieldLabel = styled.span`
    
`

export const ContainerFieldTime = styled.div`
    .time-picker-custom-class {
        width: 140px;
    }
`

