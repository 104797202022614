import React, { useEffect, useState, useRef } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { format, parseISO } from 'date-fns'

import { 
  Heading, 
  Box,
  Text,
  Button,
  Grid,
  GridItem,
  Flex,
  CircularProgress,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react'

import { toast } from "react-toastify";

import api from '../../../services/api'

import { ModalGuest } from './components/ModalGuest'

import { expiredEventCheck } from '../../../utils/expiredEventCheck';

import { Container } from './styles'

import jwt_decode from "jwt-decode"

import { IUserData } from '../../../interfaces/IUserData'
interface IGuest {
  companyName: string
  contactMilestoneId: number
  unplannedMilestoneId: any
  contactFrequentWorkerId: any
  contactFrequentClosedPersonId: any
  attendance: string
  status: string
  informedOn: boolean
  guestReleaseType: {
    id: number
    name: string
  }
  contact: {
    id: number
    name: string
    isDeleted: boolean
  } | null
  internalGuest: {
    id: number
    name: string
    isDeleted: boolean
  } | null
  preRegistration: {
    id: number
    name: string
    rg: any
    attendance: string
    validated: string
    imageKeyValid: boolean
  }
  guest: any
  milestone: {
    id: number
    name: string
    autoAcceptPending: boolean
    preRegistrationRequired: boolean
  }
}

interface IVisit {
  id: number
  name: string
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  milestone: {
    id: number
    name: string
    guest: boolean
    social: boolean
    preRegistrationRequired: boolean
    guestType: string
  }
  guests: IGuest[]

}

export function VisitDetails () {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();

  const dialogRef: any = useRef();

  const [loading, setLoading] = useState(true);

  const [canEdit, setCanEdit] = useState('');

  const [canSeeGuests, setCanSeeGuests] = useState('');

  const [visit, setVisit] = useState<IVisit>({
    id: 0,
    name:'',
    startDate:'',
    endDate:'',
    startTime:'',
    endTime:'',
    milestone:{
      id: 0,
      name:'',
      guest: false,
      social:false,
      preRegistrationRequired: false,
      guestType: '',
    },
    guests:[]
  });

  const [userId, setUserId] = useState(0);

  const [createdBy, setCreatedBy] = useState(0);

  useEffect(() => {
    const userToken = localStorage.getItem('access_portal:access_token');

    const decoded = jwt_decode(userToken as string) as IUserData

    setUserId(parseInt(decoded.user_id));
  },[])

  useEffect(() => {
    setCanEdit(location.state.canEdit);

    setCanSeeGuests(location.state.canSeeGuests);
  }, [location && location.state && location.state.canSeeGuests && location.state.canEdit])

  useEffect(() => {
    if(location && location.state && location.state.userId) {
      setCreatedBy(parseInt(location.state.userId));
    }
  }, [location && location.state && location.state.userId])

  useEffect(() => {
    if(location && location.state && location.state.id) {
      getDetails();
    }
  }, [location && location.state && location.state.id])

  async function getDetails() {
    const id = location && location.state && location.state.id;

    const userToken = localStorage.getItem('access_portal:access_token');

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    };

    const { data } = await api.get(`visit/getdetails/${id}`, config);

    setVisit(data);

    setLoading(false);
  }

  if(loading) {
    return (
      <Flex
        position={"relative"}
        h={"calc(100vh - 175px)"}
        w={"100%"}
        top={"0px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
      </Flex>
    )
  }

  return (
    <Grid templateColumns={["0% 100% 0%", "30% 40% 30%"]}>
      <GridItem colSpan={1} />
      <GridItem
        colSpan={1}
        paddingTop={"20px"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <AlertDialog
        isOpen={isOpen3}
        leastDestructiveRef={dialogRef}
        onClose={onClose3}
      >
        <AlertDialogOverlay>
          <AlertDialogContent width={"90%"}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Atenção!
            </AlertDialogHeader>
            <AlertDialogBody>
              Deseja realmente excluir essa visita?
            </AlertDialogBody>
            <AlertDialogFooter
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Button
                background={"#d36262"}
                color={"#fff"}
                onClick={onClose3}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
              >
                Não
              </Button>
              <Box>
                <Button
                  onClick={async () => {
                    try {
                      const id = visit.id;
                      const config = {
                        headers: {
                            Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                        }
                    }
                    await api.delete(`visit/delete/${id}`, config)
                      

                      toast.success("Visita excluída com sucesso!")

                      onClose3();

                      navigate(-1)
                      
                    } catch(e: any) {
                      if(
                        e &&
                        e.response &&
                        e.response.data &&
                        e.response.data.Message
                      ){
                        toast.error(e.response.data.Message)
                      }
                    }
                  }}
                  color={"#65788a"}
                  boxShadow={"0px 1px 3px #ccc"}
                >
                  Sim
                </Button>                   
              </Box>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
        <ModalGuest
          onClose={onClose}
          isOpen={isOpen}
          data={visit.guests}
        />
        <Box w={"100%"} >
          <Flex
            marginTop={["10px", "40px"]}
            flexDir={["column", "row"]}
            justifyContent={["center", "space-between"]}
            alignItems={["flex-end", "flex-end"]}
          >
            <Flex
              justifyContent={["center", "flex-start"]}
              alignItems={["", "flex-end"]}
              width={["100%", "auto"]}
              marginBottom={["40px", "0px"]}
            >
              <Text
                fontSize={[28, 20]}
                fontWeight={600}
                color={"#444"}
              >
                Detalhes da Visita
              </Text>
            </Flex>
          </Flex>
        <Container
          flexDir={"column"}
          padding={["0px", "20px"]}
          borderRadius={"6px"}
          background={["transparent", "#fff"]}
        >
          <Box>
            <Heading size='xs' textTransform='uppercase' color="#777">
              Nome da visita
            </Heading>
            <Text pt='2' fontSize='sm'>
              {visit?.name}
            </Text>
          </Box>

          <Box>
            <Heading size='xs' textTransform='uppercase' color="#777">
              Tipo
            </Heading>
            <Text pt='2' fontSize='sm'>
              {visit?.milestone.name}
            </Text>
          </Box>

          <Box>
            <Heading size='xs' textTransform='uppercase' color="#777">
              Data
            </Heading>
            <Text pt='2' fontSize='sm'>
            {format(parseISO(visit?.startDate), 'dd/MM/yyyy')} - {format(parseISO(visit?.endDate), 'dd/MM/yyyy')}
            </Text>
          </Box>

          <Box>
            <Heading size='xs' textTransform='uppercase' color="#777">
              Horário
            </Heading>
            <Text pt='2' fontSize='sm'>
            {format(parseISO(visit?.startDate), 'HH:mm')} às {format(parseISO(visit?.endDate), 'HH:mm')}
            </Text>
          </Box>

          {visit.guests.length > 0 && (
            <Box display={["flex", "initial"]} flexDir={["column"]}>
              <Heading size='xs' textTransform='uppercase' color="#777">
                {visit.guests.length} {visit.guests.length > 1 ? "Convidados": "Convidado"}
              </Heading>
              <Button
                marginTop={"8px"}
                color={"#65788a"}
                boxShadow={"0px 1px 3px #ccc"}
                onClick={onOpen}
              >
                Ver lista de convidados
              </Button>
            </Box>
          )}
          {canEdit &&
          <Flex flexDir={["row", "initial"]} justifyContent={"space-between"}>
            <Button
              colorScheme={"teal"}
              isDisabled={expiredEventCheck(visit.startDate) || userId != createdBy}
              onClick={() => {
                if(expiredEventCheck(visit.startDate)) {
                  return true
                }

                const id = location.state.id
                
                navigate("/visit/update", {
                  state: {
                    id,
                  }
                })
              }}
            >
              Editar
            </Button>
            <Button
              background={"#d36262"}
              color={"#fff"}
              isDisabled={expiredEventCheck(visit.startDate) || createdBy != userId}
              _hover={{
                _disabled: {
                  background: "#d36262"
                }
              }}
              onClick={async () => {
                if(expiredEventCheck(visit.startDate)) {
                  return true
                }

                const userToken = localStorage.getItem('access_portal:access_token')

                const config = {
                  headers: {
                    Authorization: `Bearer ${userToken}`
                  }
                }

                const { data: datass } = await api.get(
                  `Role/GetCurrentPermissionsByFunction/1`, 
                  config
                );

                if(!(datass.filter((res: any) => res.permission === 'Deletar')[0].value)) {
                  return toast.error("Por favor, verificar o perfil de acesso!")
                }

                return onOpen3()
              }}
            >
              Excluir
            </Button>
          </Flex>}
            <Flex flexDir={["column", "row"]}>
              <Button 
                colorScheme='teal' 
                size='md'
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            </Flex>
          </Container>
        </Box>
      </GridItem>
      <GridItem colSpan={1} />
    </Grid>
  )
}
