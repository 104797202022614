import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';

import { useLocation } from 'react-router-dom';

import { ref, set } from "@firebase/database";

import { toast } from 'react-toastify';

import { 
  Grid,
  GridItem,
  CircularProgress,
  Box,
  Button,
  Checkbox,
  Flex,
  Text,
  Table,
  Td,
  Tr,
  List,
  ListItem,
  Img,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Thead,
  Tbody,
} from '@chakra-ui/react';

import { FirebaseRealtimeDatabase } from '../../services/firebase';

import api from '../../services/api';

import { ContainerLoad } from './styles';

import nicbrainLogo from '../../assets/images/logo-nicbrain.png';

import KobelLogo from '../../assets/images/KobelLogo.png';

type PropsVehicle = {
  id: number,
  licensePlate: string,
  manufacturer: null,
  model: string,
  uhfRecognition: null
}

interface PropsUserGetOptInAccepted {
  id: number;
  name: string;
  cpf: string;
  rg: string;
  email: string;
  phoneNumber: string;
  privateLocal: string;
  matriz: string;
  vehicle: PropsVehicle[];
  userVehicleUhfByEmailId: number;
  client: {
    id: number;
    name: string;
    city: string;
    image: {
      type: string;
      data: string;
      thirdParty: boolean
    }
  }
}

const meses = new Array("Janeiro", "Fevereiro", "Marco", "Abril", "Maio", "Junho", "Julho", "Agosto", "Septembro", "Outubro", "Novembro", "Dezembro");

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function Termos() {
  let query = useQuery();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<PropsUserGetOptInAccepted>({
    id: 0,
    name: "",
    cpf: "",
    rg: "",
    email: "",
    phoneNumber: "",
    privateLocal: "11",
    matriz: "",
    vehicle: [],
    userVehicleUhfByEmailId: 0,
    client: {
      id: 0,
      name: "",
      city: "",
      image: {
        type: "",
        data: "",
        thirdParty: false
      }
    }
  });

  const [getOptInAccepted, setGetOptInAccepted] = useState(false);
  
  const [checksTerms, setChecksTerms] = useState(false);

  const [checksTermsAndPolitcs, setChecksTermsAndPolitcs] = useState(false);

  const [disabledCheckBox, setDisabledCheckBox] = useState(false);

  const [colors, setColors] = useState({
    secondary: '#104066',
    primary: '#fff'
  });

  useEffect(() => {
    async function init() {
      const getId = query.get('id') as string;
      const getEmailId = query.get('emailId') as string;

      const id = atob(getId);
      const emailId = atob(getEmailId);

      const { data: result } = await api.get(`user/getOptInAccepted/${id}/${emailId}`);
      
      setGetOptInAccepted(result);
      
      const { data } : { data: PropsUserGetOptInAccepted } = await api.get(`user/getDetailsForSemParar/${id}/${emailId}`);

      if(result) {
        try {
          await api.post(`vehicle/readConfirmantionUserVehicleUhfByEmail/${emailId}`);
        } catch(err: any) {
          toast.error(
            err &&
            err.response &&
            err.response.data &&
            err.response.data.Message
          );
        }
      }

      setData(data)

      setLoading(false);
    }

    init();
  }, []);

  function formataCPF(cpf: string){
    if(!cpf) {
      return '';
    }

    cpf = cpf.replace(/[^\d]/g, "");

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  function formataRg(rg: string){
    if(!rg) {
      return '';
    }

    rg = rg.replace(/[^\d]/g, "");
 
    return rg.replace(/(\d{2})(\d{3})(\d{3})(\d{0})/, "$1.$2.$3-$4");
  }

  async function handlerSave() {
    setDisabledCheckBox(true);

    const getId = query.get('id') as string;
    const getEmailId = query.get('emailId') as string;

    const id = atob(getId);
    const emailId = atob(getEmailId);

    await api.post(`nonstop/confirmation/${id}/${emailId}`);

    const newPack = { 
      date: new Date().toString(),
      userId: id,
      source: 'user'
    };

    set(
      ref( 
        FirebaseRealtimeDatabase, 
        "/middleware-creation-or-change/"+data.client.id+'/' + id
      ),
      newPack
    );

    onClose();
    
    setGetOptInAccepted(false);
  }

  if(loading) {
    return (
      <ContainerLoad bgColor={colors.primary}>
        <CircularProgress isIndeterminate color={colors.secondary} w={"20px"} h={"20px"} />
      </ContainerLoad>
    )
  }

  if(!getOptInAccepted) {
    return (
      <Flex 
        position={'absolute'}
        top={'0px'}
        left={'0px'}
        width={'100%'}
        h={"100%"}
        bgColor={colors.primary}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Text
          color={colors.secondary}
          fontWeight={600}
          fontSize={'24px'}
          textTransform={'uppercase'}
        >
          Você aceitou os termos do sem parar!
        </Text>
      </Flex>
    )
  }

  return (
    <Box 
      position={'absolute'}
      top={'0px'}
      left={'0px'}
      width={'100%'}
      h={"100%"}
      bgColor={colors.primary}
    >
      <Modal
        onClose={onClose}
        size={'xl'}
        isOpen={isOpen}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Fique por dentro</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexDir={'column'}
            >
              <Flex>
                <Text
                  color={'#104066'}
                  fontSize={'14px'}
                  textAlign={'center'}
                  marginTop={"20px"}
                  css={`
                    > a {
                      color: blue;
                    }
                  `}
                >
                  Ao continuar você compartilhará alguns dados do seu cadastro, armazenados em nossa platarforma, com a Sem Parar Instituição de Meio de Pagamento LTDA e assim concordará com nossos <a href="https://institucional.nicbrain.com.br/termos-de-uso/" target="_blank">Termos de Uso</a> e{' '}
                  <a href="https://institucional.nicbrain.com.br/politica-de-privacidade/" target="_blank">Política de Privacidade</a>.
                </Text>
              </Flex>
              <Flex justifyContent={'center'} marginTop={"40px"}>
                <Checkbox
                  size={'lg'}
                  colorScheme={"teal"}
                  isChecked={checksTermsAndPolitcs}
                  onChange={(e) => setChecksTermsAndPolitcs(!checksTermsAndPolitcs)}
                  color={'#104066'}
                  isDisabled={disabledCheckBox}
                >
                  Li e aceito
                </Checkbox>
              </Flex>
              <Flex
                width={'100%'}
                marginTop={'20px'}
                marginBottom={'20px'}
                justifyContent={'center'}
              >
                <Flex
                  height={'60px'}
                  alignItems={'center'}
                >
                  {checksTermsAndPolitcs && (
                    <Button
                      colorScheme={"teal"}
                      onClick={handlerSave}
                      isDisabled={disabledCheckBox}
                    >
                      Salvar
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Grid templateColumns={["0% 100% 0%", "25% 50% 25%"]} bgColor={colors.primary}>
        <GridItem colSpan={1} />
        <GridItem
          colSpan={1}
          paddingTop={"20px"}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Flex
            justifyContent={'space-around'}
          >
            {data.client.image && (
              <Flex
                w={'50%'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Img w={'76%'} src={data.client.image.data} alt="logo" />
              </Flex>
            )}
            <Flex
              marginTop={data.client.image ? 'auto' : '40px'}
              w={'50%'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Img w={'76%'} src={KobelLogo} alt="logo" />
            </Flex>
          </Flex>
          <Flex
            flexDir={'column'}
            marginTop={'20px'}
          >
            <Text
              margin={'40px'}
              color={colors.secondary}
              fontSize={'25px'}
              fontFamily={'Arial'}
              fontWeight={'700'}
              textAlign={'center'}
            >
              Termo de Recebimento e Condições de Uso
            </Text>
            <Text
              marginTop={'20px'}
              color={colors.secondary}
              fontSize={'20px'}
              fontFamily={'Arial'}
              fontWeight={'600'}
              textAlign={'center'}
            >
              Declaro que li e concordo com este Termo, estando ciente que:
            </Text>
          </Flex>
          <Flex>
            <List
              marginTop={'50px'}
              css={`
                & > li {
                  list-style-type: disc;
                }
              `}
            >
              <ListItem
                margin={'10px'}
                padding={'10px 10px'}
                color={colors.secondary}
                fontSize={'16px'}
                fontFamily={'Arial'}
              >
                A Sem Parar não é responsável pela segurança e o controle de acesso do(a) {data.client.name}, sendo esta de única e exclusiva responsabilidade da {data.matriz}.
              </ListItem>
              <ListItem
                margin={'10px'}
                padding={'10px 10px'}
                color={colors.secondary}
                fontSize={'16px'}
                fontFamily={'Arial'}
              >
                Para que meu veículo tenha acesso o(a) {data.client.name} receberei da {data.matriz} o(s) sticker(s) abaixo identificado(s), ciente de que, deverei utilizá-lo exclusivamente para acesso em meu {data.client.name} e caso queira contratar outros serviços do Sem Parar, deverei entrar em contato com a Sem Parar caso queira tornar-se cliente.
              </ListItem>
              <ListItem
                margin={'10px'}
                padding={'10px 10px'}
                color={colors.secondary}
                fontSize={'16px'}
                fontFamily={'Arial'}
              >
                A Sem Parar terá acesso aos meus dados constantes neste Termo: nome completo, telefone, email, CPF, RG, placa, marca, modelo, cor, ano do veículo e unidade do(a) {data.client.name} em que reside.
              </ListItem>
              <ListItem
                margin={'10px'}
                padding={'10px 10px'}
                color={colors.secondary}
                fontSize={'16px'}
                fontFamily={'Arial'}
              >
                Autorizo que estes dados sejam compartilhados com a {data.matriz} para a prestação dos serviços de controle de acesso em meu {data.client.name}.
              </ListItem>
              <ListItem
                margin={'10px'}
                padding={'10px 10px'}
                color={colors.secondary}
                fontSize={'16px'}
                fontFamily={'Arial'}
              >
                O Sem Parar, em conformidade com a LEI GERAL DE PROTEÇÃO DE DADOS PESSOAIS – LGPD LEI n" 13.709/2018, utilizará os dados acima com fim exclusivo de atuar em parceria da Parceira para os fins específicos de propiciar o acesso da Parceira à tecnologia RFID explorada pela Sem Parar, do mesmo modo que a Parceira atuará como parceria da Sem Parar para os fins específicos de operacionalizar a instalação da tecnologia RFID explorada pela Sem Parar no/na {data.client.name}, no qual a Parceira presta serviço.
              </ListItem>
              <ListItem
                margin={'10px'}
                padding={'10px 10px'}
                color={colors.secondary}
                fontSize={'16px'}
                fontFamily={'Arial'}
              >
                Declaro que aceito receber comunicações contendo informações de promoções, produtos e serviços do Sem Parar.
              </ListItem>
            </List>
          </Flex>
          <Flex
            width="100%"
            flexDirection={'column'}
          >
            <Table
              id="4"
              width="100%"
              cellSpacing="0"
              cellPadding="0"
              border={'1px solid black'}
              css={`
                td {
                  border: 1px solid ${colors.secondary};
                  border-collapse: collapse;
                  padding: 10px 10px;
                  color: ${colors.secondary};
                  font-size: 16px;
                  font-family: Arial;
                }
              `}
            >
              <Thead>
                <Tr>
                  <Td>Placa</Td>
                  <Td>Marca/Modelo</Td>
                  <Td>Nº Sticker</Td>
                </Tr>
              </Thead>
              <Tbody>
                {data.vehicle.map((res: PropsVehicle) => {
                  return (
                    <Tr key={res.id}>
                      <Td>{res.licensePlate}</Td>
                      <Td>{`${res.manufacturer || ""}/${res.model}`}</Td>
                      <Td>{res.uhfRecognition || ''}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
            <Text
              color={colors.secondary}
              fontSize={'16px'}
              fontFamily={'Arial'}
              marginTop={'10px'}
            >
              {data.client.city}, {format(new Date(), 'dd')} de {meses[Number(format(new Date(), 'MM'))-1]} de {format(new Date(), 'yyyy')}.
            </Text>
          </Flex>
          <Flex
            marginTop={'100px'}
            flexDir={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Text
              w={'100%'}
              color={colors.secondary}
              fontSize={'16px'}
              fontFamily={'Arial'}
              borderBottom={`1px solid ${colors.secondary}`}
            />
            <Text
              color={colors.secondary}
              fontSize={'16px'}
              fontFamily={'Arial'}
              marginTop={'10px'}
            >
              Assinatura do Condôminio – Residente no apartamento: {data.privateLocal}
            </Text>
            <Text
              color={colors.secondary}
              fontSize={'16px'}
              fontFamily={'Arial'}
            >
              Nome Completo: {data.name}
            </Text>
            <Text
              color={colors.secondary}
              fontSize={'16px'}
              fontFamily={'Arial'}
            >CPF: {formataCPF(data.cpf)}</Text>
            <Text
              color={colors.secondary}
              fontSize={'16px'}
              fontFamily={'Arial'}
            >RG: {formataRg(data.rg)}</Text>
            <Text
              color={colors.secondary}
              fontSize={'16px'}
              fontFamily={'Arial'}
            >Email: {data.email}</Text>
            <Text
              color={colors.secondary}
              fontSize={'16px'}
              fontFamily={'Arial'}
            >Telefone: {data.phoneNumber}</Text>
          </Flex>
          <Flex
            margin={'40px 0px'}
            justifyContent={'center'}
            w={'100%'}
          >
            <Button
              size={'lg'}
              colorScheme={"teal"}
              onClick={onOpen}
            >
              Concordar
            </Button>
          </Flex>
        </GridItem>
        <GridItem colSpan={1} />
      </Grid>
    </Box>
  );
}