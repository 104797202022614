import React, { useRef, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'

import { RootState } from '../../../../../store'

import Select, { SingleValue } from 'react-select'
import { useDispatch } from 'react-redux'
import { InputPhone } from '../../../../../components/InputPhone'
import {
    Stack,
    Box,
    Text,
    Button,
    Radio, 
    RadioGroup,
    Tag,
    TagLabel,
    TagLeftIcon,
    Input,
    Textarea,
    Grid,
    GridItem,
    Flex,
    FormLabel,
    CircularProgress,
    Checkbox,
    Select as SelectChakra
} from '@chakra-ui/react'
import { setCurrentDependent } from '../../../../../store/reducers/dependent'
import { toast } from 'react-toastify';
import api from '../../../../../services/api'
import jwt_decode from "jwt-decode"
import { Avatar } from '../Avatar';
import { IUserData } from '../../../../../interfaces/IUserData'


interface IOptions {
    readonly value: string;
    readonly label: string;
}
interface IValidPhoto {
    enable_facial: boolean | null
    image_valid: boolean | null
    image_key: string
    image_key_valid: boolean | null
    facial_by_app: boolean
    facial_by_app_opt_in_accepted: string
}


let parentescoM = [
    {label: "Pai", value: "Pai"},
    {label: "Irmão", value: "Irmão"},
    {label: "Filho", value: "Filho"},
    {label: "Marido", value: "Marido"},
    {label: "Cunhado", value: "Cunhado"},
    {label: "Tio", value: "Tio"},
    {label: "Afilhado", value: "Afilhado"},
    {label: "Sobrinho", value: "Sobrinho"},
    {label: "Avô", value: "Avô"},
    {label: "Amigo", value: "Amigo"},
    {label: "Primo", value: "Primo"},
  ]
let parentescoF =  [
    {label: "Mãe", value: "Mãe"},
    {label: "Irmã", value: "Irmã"},
    {label: "Filha", value: "Filha"},
    {label: "Esposa", value: "Esposa"},
    {label: "Cunhada", value: "Cunhada"},
    {label: "Tia", value: "Tia"},
    {label: "Afilhada", value: "Afilhada"},
    {label: "Sobrinha", value: "Sobrinha"},
    {label: "Avó", value: "Avó"},
    {label: "Amiga", value: "Amiga"},
    {label: "Prima", value: "Prima"},
  ]

  interface CreatingDependent {
        name: string,
        phoneNumber:string,
        email:string,
        gender:string,
        rg:string,
        rne:string,
        birthDate: string,
        relationship: string,
        driver: boolean | null,
        userName:string,
        imageData: string,
        imageType: string,
        foreigner: boolean | null
}
export function DependentForm(){
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const currentDependent = useSelector((state: RootState) => state.dependent.data)
    const [disableButtonSave, setDisableButtonSave] = useState(false);
    const [facialByApp, setFacialByApp] = useState(false);
    const [photoEnforced, setPhotoEnforced] = useState(false);
    const [canShowPhotoAlerts, setCanShowPhotoAlerts] = useState<string | null>(null);
    const [initiaImageData, setInitiaImageData] = useState('');
    const [unitName, setUnitName] = useState('');
    
    const [creatingDependent, setCreatingDependent] = useState<CreatingDependent>({
        name: '',
        phoneNumber:'',
        email:'',
        gender:'',
        rg:'',
        rne:'',
        birthDate: '',
        relationship: '',
        driver:false,
        userName:'',
        imageData: '',
        imageType: '',
        foreigner: false
    })

    const [validatePhotoObj, setValidatePhotoObj] = useState<IValidPhoto>({
        enable_facial: null,
        image_valid: null,
        image_key: "",
        image_key_valid: null,
        facial_by_app: false,
        facial_by_app_opt_in_accepted: ""
    });

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if(initiaImageData == ''){

            setInitiaImageData(currentDependent.imageData)
        }
    }, [currentDependent])


    const getBirthDate = (birthDate: string) => {

        let toReturn: string = ''
        var birth: string[] = birthDate.split("T")
        birth = birth[0].split("-")
        toReturn = birth[0] + "-" + birth[1] + "-" + birth[2]

        return toReturn
    }

    const getValueRelationship = (val: string) => {
        if(val){

            if(currentDependent.gender == 'Masculino'){
                for(let i in parentescoM){
                    if(val == parentescoM[i].label){
                        return({
                            value: parentescoM[i].value, 
                            label: parentescoM[i].label
                        })
                    }
                }
            }else{
                for(let i in parentescoF){
                    if(val == parentescoF[i].label){
                        return({
                            value: parentescoF[i].value, 
                            label: parentescoF[i].label
                        })
                    }
                }
            }
        }else{
            return({
                value: '', 
                label: ''
            })
        }
    }
    const validateEmail = (text: string) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
    
        if(!reg.test(text)){
          return false;
        } else {
          return true
        }
      }

    const validateData = (dependentToSave: any) => {
        let toReturn: boolean = true
        let erro: string = ''

        if(dependentToSave.name == null || dependentToSave.name.length === 0 ){
            erro = 'name'
            toast.error('O campo nome é obrigatório.')
            return false
        }

        if(dependentToSave.name.split(' ').length < 2){
            erro = 'name'
            toast.error('Por favor insira o nome completo.')
            return false
        }

        let nameToTest = dependentToSave.name.trim().split(' ')
        if(nameToTest[nameToTest.length-1].length < 2){
            erro = 'phoneNumber'
            toast.error('Por favor insira o sobrenome completo.')
            return false
        }

        if(dependentToSave.phoneNumber.replace(/[^0-9]/g,'').length !== 13) {
            erro = 'phoneNumber'
            toast.error('O celular não é válido.')
            return false
        }

        if(!validateEmail(dependentToSave.email)){
            erro = 'email'
            toast.error('O e-mail não é válido.')
            return false
        }

        if(dependentToSave.gender == null || dependentToSave.gender == ''){
            erro = 'gender'
            toast.error('Selecione o sexo.')
            return false
        }


        // if(dependentToSave.phoneNumber.length != 19 || dependentToSave.phoneNumber.substring(9, 10) != '9'){
        //     erro = 'phoneNumber'
        //     toast.error('O celular não é válido.')
        //     toReturn= false
        // }

        if(!dependentToSave.foreigner && !(dependentToSave.rg.length >= 7 && dependentToSave.rg.length <=10)){
            erro = 'rg'
            toast.error('O RG não é válido.')
            return false
        }

        if(dependentToSave.foreigner && (!dependentToSave.rne || dependentToSave.rne.length == 0)){
            erro = 'rne'
            toast.error('É necessário inserir o RNE.')
            return false
        }

        if(dependentToSave.birthDate.length<10){
            erro = 'birthDate'
            toast.error('A data de nascimento não é válida.')
            return false
        }

        if(dependentToSave.relationship == ''){
            erro = 'relationship'
            toast.error('Selecione o parentesco.')
            return false
        }

        //validações de foto
        if(facialByApp == false && canShowPhotoAlerts === 'true'){
            if(currentDependent.imageData == null  || currentDependent.imageData.length == 0){
                erro = 'image'
                toast.error('Por favor cadastre uma imagem.')
                return false
            }
        }
        return toReturn
        // return toReturn
    }

    const getNameSurname = (tag: string, name: string) => {
        let toReturn = ''
        if(tag == 'name'){
          toReturn = name.split(' ')[0]
        }else if(tag == 'surname'){
          let toSplit = name.split(' ')
          for (let i in toSplit){
            if(parseInt(i) > 0){
              toReturn = toReturn + ' ' + toSplit[i]
            }
          }
          toReturn = toReturn.trim()
        }
    
        return toReturn
      }


    async function handleUpdate(dependentToSave: any){

        setDisableButtonSave(true)
        if(validateData(dependentToSave)){
            //sendEndpoint1
            const dataToSend = {
                id: dependentToSave.id,
                email: dependentToSave.email,
                name: getNameSurname('name',dependentToSave.name),
                surname: getNameSurname('surname',dependentToSave.name),
                phoneNumber: dependentToSave.phoneNumber,
                login: dependentToSave.userName,
                gender: dependentToSave.gender,
                birthDate: dependentToSave.birthDate,
                relationship: dependentToSave.relationship,
                driver: dependentToSave.driver,
                foreigner: dependentToSave.foreigner,
                rne: '',
                rg: '',
                imageData: '',
                imageType: ''
            }
            if(!dataToSend.foreigner){
                let rgToSend: string = dependentToSave.rg
                rgToSend = rgToSend.replace(/[.]/g,"");
      
                rgToSend = rgToSend.replace(/[-]/g,"");
                dataToSend.rg = rgToSend
            }
            else{
                dataToSend.rne = dependentToSave.rne
            }

            if(facialByApp == false){
                if(initiaImageData != currentDependent.imageData){
                    dataToSend.imageData = currentDependent.imageData
                    dataToSend.imageType = 'base64'

                }
            }


            try {    
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
                    }
                }
    
                await api.post("user/updatedependent", dataToSend, config)
    
                
                toast.success("Dados atualizados com sucesso.")
                setTimeout(() =>{
                    setDisableButtonSave(false)
                    navigate('/my-profile', {
                        state: {
                          index: 1
                        }
                      })
                }, 2000)
            } catch (err: any) {
                setDisableButtonSave(false)
                if(
                    err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.Message
                    ){
                        toast.error(err.response.data.Message)
                    }
                }
            }else{
            setDisableButtonSave(false)

        }
    }

    async function handleSave(dependentToSave: any){
        setDisableButtonSave(true)
        if(validateData(dependentToSave)){
            const dataToSend = {
                id: dependentToSave.id,
                email: dependentToSave.email,
                name: getNameSurname('name',dependentToSave.name),
                surname: getNameSurname('surname',dependentToSave.name),
                phoneNumber: dependentToSave.phoneNumber,
                login: dependentToSave.userName,
                gender: dependentToSave.gender,
                birthDate: dependentToSave.birthDate,
                relationship: dependentToSave.relationship,
                driver: dependentToSave.driver,
                foreigner: dependentToSave.foreigner,
                rne: '',
                rg: '',
                imageData: '',
                imageType: '',
            }
            if(!dataToSend.foreigner){
                let rgToSend: string = dependentToSave.rg
                rgToSend = rgToSend.replace(/[.]/g,"");
      
                rgToSend = rgToSend.replace(/[-]/g,"");
                dataToSend.rg = rgToSend
            }
            else{
                dataToSend.rne = dependentToSave.rne
            }

            if(facialByApp == false){
               
                    dataToSend.imageData = creatingDependent.imageData
                    dataToSend.imageType = 'base64'

                
            }

            try {    
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
                    }
                }
    
                await api.post("user/createdependent", dataToSend, config)
    
                
                toast.success("Dependente cadastrado com sucesso.")
                setTimeout(() =>{
                    setDisableButtonSave(false)
                    navigate('/my-profile', {
                        state: {
                          index: 1
                        }
                      })
                }, 2000)
            } catch (err: any) {
                setDisableButtonSave(false)
                if(
                    err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.Message
                    ){
                        toast.error(err.response.data.Message)
                    }
                }
            }else{
                setDisableButtonSave(false)
                
        }
    }

    const getImage = (data: string) => {
        let toReturn: string = ''
        if(data?.length>0){

            if(data?.includes('data:image/jpeg;base64,')){
                toReturn = data
            } else{
                toReturn = 'data:image/jpeg;base64,'+data
            }
        }else{
            toReturn = ''
        }

        return toReturn
    }

    async function onExcludeImage() {
        const userToken = localStorage.getItem('access_portal:access_token');


        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }

        await api.get(
            `user/deleteImageByUser/${currentDependent.id}`,
            config
        );

        dispatch(setCurrentDependent({
            ...currentDependent,
            imageData: ''
        }))

    }

    async function getData() {
        const userToken = localStorage.getItem('access_portal:access_token')

        const decoded = jwt_decode(userToken as string) as IUserData

        const selectedClient = decoded.client_id

        const clientId = selectedClient.split('#')[0]

        const config = {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
            }
        }

        const { data } = await api.get(`/UserGeneralRule/UserGeneralRuleList/${clientId}`, config)

        setFacialByApp(data[0].facialByApp)
        setPhotoEnforced(data[0].photoEnforced)

        const getCanShowPhotoAlerts : string | null = localStorage.getItem('@userGeneralRuleList');
        
        setCanShowPhotoAlerts(getCanShowPhotoAlerts);

        const { data: datass } = await api.get(
            `unit/getNamesByUser`, 
            config
          );
          if(datass){
            setUnitName(datass[0].name)
          }

    }

    const getUnitNameForLogin = (data: string) => {
        let dataSplit = data.split(' ')
    
        let toReturn = ''
    
        if(dataSplit.length> 1){
          if(dataSplit[1].length >1){
            toReturn = dataSplit[1]
          }else{
            let toUse = ''

            for(let i in dataSplit){

            if(Number(i) === 0){
    
              }else{
                if(/^[a-zA-ZÀ-ü\s]*$/.test(dataSplit[i].charAt(0))){
                    toUse = dataSplit[i].charAt(0)
                  break
                }
              }
            }

            toReturn = dataSplit[0].charAt(0)+''+toUse
          }
        }else{
          toReturn = dataSplit[0]
        }
        
        return toReturn
      }

      function checkCangetLogin(){
        let canGet = true
        if(creatingDependent.name == null || creatingDependent.name.length === 0 ){
           canGet = false
        }

        if(creatingDependent.name.split(' ').length < 2){
           
            canGet = false
        }

        let nameToTest = creatingDependent.name.trim().split(' ')
        if(nameToTest[nameToTest.length-1].length < 2){
           
            canGet = false
        }
        if(canGet){
            
            getLogin()
        }
      }

      async function getLogin(){
        const userToken = localStorage.getItem('access_portal:access_token')

        const decoded = jwt_decode(userToken as string) as IUserData

        const unitId = decoded.unit_id
        const privateLocalName = decoded.private_local_name
        
        const envio = {
          "Name": creatingDependent.name.trim(),
          "UnitName": getUnitNameForLogin(unitName),
          "PrivateLocalName": privateLocalName
        }

        try {    
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
                }
            }

            const {data} = await api.post("login/generatelogin", envio, config)

            setCreatingDependent({...creatingDependent, userName: data})

        } catch (err: any) {
            setDisableButtonSave(false)
            if(
                err &&
                err.response &&
                err.response.data &&
                err.response.data.Message
            ){
                toast.error(err.response.data.Message)
            }
        }
        
      }

    if(currentDependent.id){

        return (
            <Grid templateColumns={["0% 100% 0%", "25% 50% 25%"]}>
                 <GridItem colSpan={1} />
                <GridItem
                    colSpan={1}
                    paddingTop={"20px"}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                >
                    <Text
                        fontSize={[28, 20]}
                        fontWeight={600}
                        color={"#444"}
                        marginBottom={"10px"}
                    >
                        Editar Dependente
                    </Text>
    
                    <Flex
                        flexDir={"column"}
                        background={["inherit", "#fff"]}
                        w={"100%"}
                        padding={["0px", "20px"]}
                        borderRadius={"10px"}
                        
                    >
                       {facialByApp == false &&
                        <Box marginBottom={"10px"} alignSelf={'center'} justifyContent={'center'} alignItems={'center'} >

                            <Avatar
                                source={getImage(currentDependent.imageData)}
                                setChangePhoto={() =>{}}
                                onUploadImage={(image: string) => {
                                    dispatch(setCurrentDependent({
                                        ...currentDependent,
                                        imageData: image.split(",")[1]
                                    }))
                                    
                                }}
                                onExcludeImage={onExcludeImage}
                                cancellLGPD={false}
                                validatePhotoObj={validatePhotoObj}
                                isLoading={false}
                                editPic={true}
                                exludePic={true}
                                />
                        </Box>}

                        <Box marginBottom={"10px"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Nome
                            </FormLabel>
                            <Input
                                placeholder={`Nome`}
                                background={"#fff"}
                                onChange={(e) =>{
                                    dispatch(setCurrentDependent({
                                        ...currentDependent,
                                        name: e.target.value
                                    }))
                                }}
                                disabled={true}
                                value={currentDependent.name}
                            />
                        </Box>
                        <Box marginBottom={"10px"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Celular
                            </FormLabel>
                            <InputPhone
                                style={{ background: "#fff", borderRadius: "4px", width: "100%" }}
                                placeholder={'+55 (xx) 9xxxx-xxxx'} 
                                onChange={(e: any) =>{
                                    dispatch(setCurrentDependent({
                                        ...currentDependent,
                                        phoneNumber: e.target.value
                                    }))
                                }}
                                value={currentDependent.phoneNumber}
                            />
                        </Box>
                        <Box marginBottom={"10px"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                E-mail
                            </FormLabel>
                            <Input
                                background={"#fff"}
                                placeholder={'exemplo@exemplo.com.br'} 
                                onChange={(e) =>{
                                    dispatch(setCurrentDependent({
                                        ...currentDependent,
                                        email: e.target.value
                                    }))
                                }}
                                value={currentDependent.email}
                            />
                        </Box>
                        <Box w={["100%", "50%"]}>
                                    <FormLabel
                                        fontSize={"0.8em"}
                                        color={"#777"}
                                        marginBottom={"3px"}
                                    >
                                        Sexo
                                    </FormLabel>
                                    <SelectChakra
                                        width={"100%"}
                                        background={"#fff"}
                                        onChange={(e) => {
                                            dispatch(setCurrentDependent({
                                                ...currentDependent,
                                                gender: e.target.value,
                                                relationship: ''
                                            }))
                                        }}
                                        value={currentDependent.gender}
                                        disabled={!currentDependent.gender}
                                    >
                                        <option value='' disabled selected>Selecione o sexo</option>
                                        <option value='Masculino'>Masculino</option>
                                        <option value='Feminino'>Feminino</option>
                                    </SelectChakra>
                        </Box>
    
                        {!currentDependent.foreigner ? 
                        <Box marginBottom={"10px"} marginTop={'10px'}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                RG
                            </FormLabel>
                            <Input
                                placeholder={'RG'}
                                background={"#fff"}
                                onChange={(e) =>{
                                    dispatch(setCurrentDependent({
                                        ...currentDependent,
                                        rg: e.target.value
                                    }))
                                }}
                                value={currentDependent.rg}
                            />
                        </Box>
                        :
                        <Box marginBottom={"10px"} marginTop={'10px'}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                RNE
                            </FormLabel>
                            <Input
                                placeholder={'RG'}
                                background={"#fff"}
                                onChange={(e) =>{
                                    dispatch(setCurrentDependent({
                                        ...currentDependent,
                                        rne: e.target.value
                                    }))
                                }}
                                value={currentDependent.rne}
                            />
                        </Box>}
                        <Box width={"100%"}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Data de Nascimento
                                </FormLabel>
                                <Input
                                    placeholder={'DD/MM/AAAA'}
                                    type={"date"}
                                    onChange={(date) => {
                                        dispatch(setCurrentDependent({
                                            ...currentDependent,
                                            birthDate: date.target.value
                                        }))
                                    }}
                                    value={getBirthDate(currentDependent.birthDate)}
                                    background={"#fff"}
                                />
                        </Box>
                        <Box w={["100%", "50%"]}  marginTop={'10px'}>
                                    <FormLabel
                                        fontSize={"0.8em"}
                                        color={"#777"}
                                        marginBottom={"3px"}
                                    >
                                        Parentesco
                                    </FormLabel>
                                    <Select<IOptions>
                                        placeholder={'Selecione...'}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        onChange={(val: SingleValue<IOptions>) => {
                                            if(val){
                                                dispatch(setCurrentDependent({
                                                    ...currentDependent,
                                                    relationship: val.label
                                                    
                                                }))

                                            }
                                        }}
                                        isDisabled={currentDependent.gender ? false : true}
                                        value={getValueRelationship(currentDependent.relationship)}
                                        isClearable={true}
                                        name="color"
                                        options={currentDependent.gender == 'Masculino' ?
                                        parentescoM :
                                        parentescoF}
                                    />
                        </Box>
                        <Box width={"100%"}  marginBottom={'10px'}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                                marginTop={"10px"}
                            >
                                Condutor
                            </FormLabel>
                            <RadioGroup
                                onChange={(val) => {
                                    dispatch(setCurrentDependent({
                                        ...currentDependent,
                                        driver: val === "1" ? true : false
                                    }))
                                    
                                }}
                                value={currentDependent.driver ? "1" : "2"}
                            >
                                <Stack direction='row'>
                                    <Radio value='1' borderColor={"#777"} disabled={currentDependent.driver === null}>Sim</Radio>
                                    <Radio value='2' borderColor={"#777"} disabled={currentDependent.driver === null}>Não</Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>
                        <Box marginBottom={"10px"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Login do dependente
                            </FormLabel>
                            <Input
                                disabled={true}
                                placeholder={'RG'}
                                background={"#fff"}
                                onChange={(e) =>{
                                    
                                }}
                                value={currentDependent.userName}
                            />
                        </Box>
                    </Flex>
                <Flex
                        width={"100%"}
                        flexDir={["row", "row"]}
                        justifyContent={["space-between", "space-between"]}
                        marginTop={["20px", "20px"]}
                        padding={["0px", "0px 20px"]}
                    >
                        <Button 
                            colorScheme='teal' 
                            // isDisabled={disabledButton}
                            onClick={() => navigate('/my-profile', {
                                state: {
                                  index: 1
                                }
                              })}
                        >
                            Voltar
                        </Button>
                        <Button 
                            isDisabled={disableButtonSave}
                            colorScheme='teal' 
                            onClick={() => handleUpdate(currentDependent)}
                            marginBottom={["10px", "0px"]}
                        >
                            Salvar
                        </Button>
                    </Flex>
                </GridItem>
                <GridItem colSpan={1} />
            </Grid>
        )
    }else{
        return(
            <Grid templateColumns={["0% 100% 0%", "25% 50% 25%"]}>
                 <GridItem colSpan={1} />
                <GridItem
                    colSpan={1}
                    paddingTop={"20px"}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                >
                    <Text
                        fontSize={[28, 20]}
                        fontWeight={600}
                        color={"#444"}
                        marginBottom={"10px"}
                    >
                        Cadastrar Dependente
                    </Text>
    
                    <Flex
                        flexDir={"column"}
                        background={["inherit", "#fff"]}
                        w={"100%"}
                        padding={["0px", "20px"]}
                        borderRadius={"10px"}
                    >
                        {facialByApp == false &&
                        <Box marginBottom={"10px"} alignSelf={'center'} justifyContent={'center'} alignItems={'center'} >

                            <Avatar
                                source={getImage(creatingDependent.imageData)}
                                setChangePhoto={() =>{}}
                                onUploadImage={(image: string) => {
                                    setCreatingDependent({...creatingDependent, imageData: image.split(',')[1]})
                                    // dispatch(setCurrentDependent({
                                    //     ...creatingDependent,
                                    //     imageData: image.split(",")[1]
                                    // }))
                                    
                                }}
                                onExcludeImage={() => { setCreatingDependent({...creatingDependent, imageData:''}) }}
                                cancellLGPD={false}
                                validatePhotoObj={validatePhotoObj}
                                isLoading={false}
                                editPic={true}
                                exludePic={true}
                                />
                        </Box>}

                        <Box marginBottom={"10px"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Nome
                            </FormLabel>
                            <Input
                                placeholder={`Qual o nome completo?`}
                                background={"#fff"}
                                onChange={(e) =>{
                                    setCreatingDependent({...creatingDependent, name: e.target.value})
                                    // dispatch(setCurrentContact({
                                    //     ...currentContact,
                                    //     name: e.target.value
                                    // }))
                                }}
                                onBlur={ () => checkCangetLogin()}
                                value={creatingDependent.name}
                            />
                        </Box>
                        <Box marginBottom={"10px"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Celular
                            </FormLabel>
                            <InputPhone
                                style={{ background: "#fff", borderRadius: "4px", width: "100%" }}
                                placeholder={'+55 (xx) 9xxxx-xxxx'} 
                                onChange={(e: any) =>{
                                    setCreatingDependent({...creatingDependent, phoneNumber: e.target.value})
                                    // dispatch(setCurrentContact({
                                    //     ...currentContact,
                                    //     cellPhone: e.target.value
                                    // }))
                                }}
                                value={creatingDependent.phoneNumber}
                            />
                        </Box>
                        <Box width={"100%"}  marginBottom={'10px'}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                                marginTop={"10px"}
                            >
                                É pessoa estrangeira?
                            </FormLabel>
                            <RadioGroup
                                onChange={(val) => {
                                    setCreatingDependent({...creatingDependent,  foreigner: val === "1" ? true : false})
                                    // dispatch(setCurrentDependent({
                                    //     ...currentDependent,
                                    //     foreigner: val === "1" ? true : false
                                    // }))
                                    
                                }}
                                value={creatingDependent.foreigner ? "1" : "2"}
                            >
                                <Stack direction='row'>
                                    <Radio value='1' borderColor={"#777"} disabled={creatingDependent.driver === null}>Sim</Radio>
                                    <Radio value='2' borderColor={"#777"} disabled={creatingDependent.driver === null}>Não</Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>
                        <Box marginBottom={"20px"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                E-mail
                            </FormLabel>
                            <Input
                                background={"#fff"}
                                placeholder={'exemplo@exemplo.com.br'} 
                                onChange={(e) =>{
                                    setCreatingDependent({...creatingDependent, email: e.target.value})
                                    // dispatch(setCurrentContact({
                                    //     ...currentContact,
                                    //     email: e.target.value
                                    // }))
                                }}
                                value={creatingDependent.email}
                            />
                        </Box>
                        <Box w={["100%", "50%"]}>
                                    <FormLabel
                                        fontSize={"0.8em"}
                                        color={"#777"}
                                        marginBottom={"3px"}
                                    >
                                        Sexo
                                    </FormLabel>
                                    <SelectChakra
                                        width={"100%"}
                                        background={"#fff"}
                                        onChange={(e) => {
                                            setCreatingDependent({...creatingDependent, gender: e.target.value, relationship: ''})
                                            // setUser({
                                            //     ...user,
                                            //     gender: e.target.value
                                            // })
                                        }}
                                        value={currentDependent.gender}
                                        // disabled={!currentDependent.gender}
                                    >
                                        <option value='' disabled selected>Selecione o sexo</option>
                                        <option value='Masculino'>Masculino</option>
                                        <option value='Feminino'>Feminino</option>
                                    </SelectChakra>
                        </Box>
                        
                        {!creatingDependent.foreigner ? 
                        <Box marginBottom={"10px"} marginTop={'10px'}>
                        <FormLabel
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"3px"}
                        >
                            RG
                        </FormLabel>
                        <Input
                            placeholder={'RG'}
                            background={"#fff"}
                            onChange={(e) =>{
                                setCreatingDependent({...creatingDependent, rg: e.target.value})
                                // dispatch(setCurrentContact({
                                //     ...currentContact,
                                //     name: e.target.value
                                // }))
                            }}
                            value={creatingDependent.rg}
                        />
                    </Box>
                        :
                        <Box marginBottom={"10px"} marginTop={'10px'}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                RNE
                            </FormLabel>
                            <Input
                                placeholder={'RNE'}
                                background={"#fff"}
                                onChange={(e) =>{
                                    setCreatingDependent({...creatingDependent, rne: e.target.value})
                                }}
                                value={currentDependent.rne}
                            />
                        </Box>}

                        
                        <Box width={"100%"}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Data de Nascimento
                                </FormLabel>
                                <Input
                                    placeholder={'DD/MM/AAAA'}
                                    type={"date"}
                                    onChange={(date) => {
                                        setCreatingDependent({...creatingDependent, birthDate: date.target.value})
                                        // setUser({
                                        //     ...user,
                                        //     birthDate: String(date.target.value)
                                        // })
                                    }}
                                    value={getBirthDate(creatingDependent.birthDate)}
                                    background={"#fff"}
                                />
                        </Box>
                        <Box w={["100%", "50%"]}  marginTop={'10px'}>
                                    <FormLabel
                                        fontSize={"0.8em"}
                                        color={"#777"}
                                        marginBottom={"3px"}
                                    >
                                        Parentesco
                                    </FormLabel>
                                    <Select<IOptions>
                                        
                                        placeholder={'Selecione...'}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        onChange={(val: SingleValue<IOptions>) => {
                                            if(val){
                                                setCreatingDependent({...creatingDependent, relationship: val.label})

                                            }
                                            
                                        }}
                                        isDisabled={creatingDependent.gender ? false : true}
                                        value={getValueRelationship(creatingDependent.relationship)}
                                        isClearable={true}
                                        name="color"
                                        options={creatingDependent.gender == 'Masculino' ?
                                        parentescoM :
                                        parentescoF}
                                    />
                        </Box>
                        <Box width={"100%"} marginBottom={'10px'}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                                marginTop={"10px"}
                            >
                                Condutor
                            </FormLabel>
                            <RadioGroup
                                onChange={(val) => {
                                    setCreatingDependent({...creatingDependent, driver: val === "1" ? true : false})
                                    // dispatch(setCurrentDependent({
                                    //     ...currentDependent,
                                    //     driver: val === "1" ? true : false
                                    // }))
                                    // setUser({
                                    //     ...user,
                                    //     driver: val === "1" ? true : false
                                    // })
                                }}
                                value={creatingDependent.driver ? "1" : "2"}
                            >
                                <Stack direction='row'>
                                    <Radio value='1' borderColor={"#777"} disabled={creatingDependent.driver === null}>Sim</Radio>
                                    <Radio value='2' borderColor={"#777"} disabled={creatingDependent.driver === null}>Não</Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>
                        <Box marginBottom={"10px"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Login do dependente
                            </FormLabel>
                            <Input
                                disabled={true}
                                placeholder={'Login'}
                                background={"#fff"}
                                onChange={(e) =>{
                                    // dispatch(setCurrentContact({
                                    //     ...currentContact,
                                    //     name: e.target.value
                                    // }))
                                }}
                                value={creatingDependent.userName}
                            />
                        </Box>
                    </Flex>
                    <Flex
                        width={"100%"}
                        flexDir={["row", "row"]}
                        justifyContent={["space-between", "space-between"]}
                        marginTop={["20px", "20px"]}
                        padding={["0px", "0px 20px"]}
                    >
                        <Button 
                            colorScheme='teal' 
                            // isDisabled={disabledButton}
                            onClick={() => navigate('/my-profile', {
                                state: {
                                  index: 1
                                }
                              })}
                        >
                            Voltar
                        </Button>
                        <Button 
                            isDisabled={disableButtonSave}
                            colorScheme='teal' 
                            onClick={() => handleSave(creatingDependent)}
                            marginBottom={["10px", "0px"]}
                        >
                            Cadastrar
                        </Button>
                    </Flex>

                {/* <Flex
                        flexDir={["column-reverse", "row"]}
                        justifyContent={["row", "space-between"]}
                        marginTop={["20px", "0px"]}
                        padding={["0px", "0px 20px"]}
                    >
                        <Button 
                            colorScheme='teal' 
                            // isDisabled={disabledButton}
                            onClick={() => navigate(-1)}
                        >
                            Voltar
                        </Button>
                        <Button 
                            isDisabled={disableButtonSave}
                            colorScheme='teal' 
                            onClick={() => handleSave(creatingDependent)}
                            marginBottom={["10px", "0px"]}
                        >
                            Cadastrar
                        </Button>
                    </Flex> */}
                </GridItem>
                <GridItem colSpan={1} />
            </Grid>
        )
    }
}