import moment from "moment";

export function validateEmail(value: string) {
    const validateEmail =
        /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return validateEmail.test(value);
}

export function validateCellphone(value: string) {
    const clearValue = value
        .split('(').join('')
        .split(')').join('')
        .split('_').join('')
        .split('-').join('')
        .split(' ').join('')
        .split('+55').join('')

    if(clearValue.charAt(2) !== '9' || clearValue.length !== 11){
        return false
    }

    return true
}

//validateDate('02/05/2023')
export function validateDate(value: string) {
    let valueSplit = value ? value.split('/') : ''
    let valueFormatted = value
    if(valueSplit.length === 3){
        valueFormatted = valueSplit[2] + '-' + valueSplit[1] + '-' + valueSplit[0]
    }

    return moment(valueFormatted, 'YYYY-MM-DD', true).isValid()
}