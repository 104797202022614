import React, { useEffect, useState, useRef } from 'react'

import { useSelector } from 'react-redux'

import {
    Box,
    Flex,
    Grid,
    GridItem,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    CloseButton,
} from '@chakra-ui/react'

import { FaTimes } from "react-icons/fa"

import { toast } from 'react-toastify'

import { useNavigate } from 'react-router-dom'

import jwt_decode from "jwt-decode"

import { confirmAlert } from 'react-confirm-alert'

import 'react-confirm-alert/src/react-confirm-alert.css'

import { RootState } from '../../store'

import api from '../../services/api'

import { IUserData } from '../../interfaces/IUserData'

import { hasPermission } from '../../utils/check-permission'

import { Container, ContainerAlert } from './styles'

export function Home () {
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dialogRef: any = useRef();

    const menuParameterization = useSelector((state: RootState) => state.menuParameterization.data)

    const [name, setName] = useState("")

    const [notification, setNotification] = useState(false)
    const [subModuleId, setSubModuleId] = useState('')

    useEffect(() => {
        const userToken = localStorage.getItem('access_portal:access_token')

        const decoded = jwt_decode(userToken as string) as IUserData        

        setName(decoded.user_name)
        
        setSubModuleId(decoded.subModuleId)

        init()
    }, [])

    function getLink (how: string, content: string) {
        if(how === 'termos'){
            return <a href='https://institucional.nicbrain.com.br/termos-de-uso/' target='_blank'>{ content }</a>
        }
        if(how === 'politicas'){
            return <a href='https://institucional.nicbrain.com.br/politica-de-privacidade/' target='_blank'>{ content }</a>
        }
    }

    async function init() {
        try{
            
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }

            const { data } = await api.get('/user/GetOptInAccepted', config)

            if(!data.optInAccepted) {
                
                confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <ContainerAlert>
                            <div id="react-confirm-alert">
                                <div className="react-confirm-alert-overlay undefined">
                                    <div className="react-confirm-alert">
                                        <button className='closeTimes'>
                                            <FaTimes
                                                size={"20px"}
                                                onClick={() => {
                                                    navigate("/auth")
                                                    onClose()
                                                }}
                                            />
                                        </button>
                                        <div className="react-confirm-alert-body">
                                            <h1>Atenção!</h1>
                                            <p className="alert__body">
                                                Os {getLink('termos', 'Termos de Uso')} e as { getLink('politicas', 'Políticas de Privacidade') } para utilização deste aplicativo foram atualizados. Ao continuar utilizando você concorda com estas condições.
                                            </p>
                                            <div className="react-confirm-alert-button-group">
                                                <button 
                                                    onClick={async () => {
                                                        try {
                                                            const userToken = localStorage.getItem('access_portal:access_token')

                                                            const decoded = jwt_decode(userToken as string) as IUserData
                                                            
                                                            await api.post('/user/optInAccepted/' + decoded.user_id, {}, config);
                                                            
                                                            setTimeout(() => {
                                                                if(!menuParameterization.appMenuConfiguration) {
                                                                    onOpen()
                                                                }
                                                            }, 500)
                                                            
                                                            confirmLGPD(onClose);
                                                        } catch(e) {
                                                        }
                                                    }}
                                                >Continuar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ContainerAlert>
                        
                      );
                    },
                    
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                })
            } else {
                setTimeout(() => {
                    if(!menuParameterization.appMenuConfiguration) {
                        onOpen()
                    }
                }, 500)
            }
        }catch(e) {

        }
        
    }

    async function confirmLGPD(onClose: any) {
        const userToken = localStorage.getItem('access_portal:access_token')

        const decoded = jwt_decode(userToken as string) as IUserData

        try{
            
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }

            await api.post('/user/optInAccepted/' + decoded.user_id,  { }, config)

            onClose()
        }catch(e) {

        }
    }

    function getSaudacao() {
        let saudacao = '';

        let data = new Date();

        let hora = data.getHours();
        
        if(hora >= 0 && hora <= 12){
            saudacao = 'Bom Dia,';
        } else if (hora > 12 && hora <= 18){
            saudacao = 'Boa Tarde,';
        } else if (hora > 18 && hora <= 24){
            saudacao = 'Boa Noite,';
        }

        return saudacao;
    }

    function formatName(name: string) {
        let nome = name.split(' ');
        let nomeFormatado = '';

        for (let i = 0; i < nome.length; i++){
            if(i==0){
                nomeFormatado = nome[i].substr(0,1).toUpperCase() + nome[i].substr(1).toLowerCase()
            }else{
                nomeFormatado = nomeFormatado + 
                ' ' + nome[i].substr(0,1).toUpperCase() +
                nome[i].substr(1).toLowerCase()
            }
        }

        return nomeFormatado;
    }
    
    return (
        <Flex flexDir={"column"} justifyContent={"start"}>
            <Grid templateColumns={["0% 100% 0%", "30% 40% 30%"]}>
                <GridItem colSpan={1} />
                <GridItem
                    colSpan={1}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                >
                    <AlertDialog
                        isOpen={isOpen}
                        leastDestructiveRef={dialogRef}
                        onClose={onClose}
                        closeOnOverlayClick={false}
                    >
                        <AlertDialogOverlay>
                            <AlertDialogContent width={"90%"}>
                                <CloseButton
                                    onClick={() => onClose()}
                                />
                                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                    Atenção!
                                </AlertDialogHeader>
                                <AlertDialogBody>
                                    É necessário que você defina a forma como gostaria de receber seus alertas.
                                </AlertDialogBody>
                                <AlertDialogFooter
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                >
                                    <Box>
                                        <Button
                                            onClick={() => {
                                                navigate("/configuration")
                                                onClose()
                                            }}
                                            color={"#65788a"}
                                            boxShadow={"0px 1px 3px #ccc"}
                                        >
                                            Continuar
                                        </Button>                   
                                    </Box>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>
                    <Box
                        fontWeight={600}
                        fontStyle={"italic"}
                        fontSize={["20px", "30px"]}
                        marginBottom={["20px"]}
                        color={"#898989"}
                        w={"100%"}
                    >
                        {getSaudacao()} {name && formatName(name.trim().split(' ')[0])}!
                    </Box>
                    <Container flexDir={"column"} w={"100%"}>
                        <Button
                            background={"#fff"}
                            size={["lg"]}
                            onClick={async () => {
                                if(await hasPermission(11, 'Visualizar')){
                                    navigate('/my-profile')
                                }else{
                                    toast.warn("Por favor, verificar o perfil de acesso!")
                                }
                            }}
                        >
                            Meus Dados
                        </Button>
                        <Button
                            background={"#fff"}
                            size={["lg"]}
                            onClick={async () => {
                                if(await hasPermission(4, 'Visualizar')){
                                    navigate('/contact/list')
                                }else{
                                    toast.warn("Por favor, verificar o perfil de acesso!")
                                }
                            }}
                        >
                            Contatos
                        </Button>
                        <Button
                            background={"#fff"}
                            size={["lg"]}
                            onClick={async () => {
                                if(await hasPermission(1, 'Visualizar')){
                                    navigate('/event/calendar-event')
                                }else{
                                    toast.warn("Por favor, verificar o perfil de acesso!")
                                }
                            }}
                        >
                            Reserva de Espaço
                        </Button>

                        <Button
                            background={"#fff"}
                            size={["lg"]}
                            onClick={async () => {
                                if(await hasPermission(1, 'Visualizar')){
                                    navigate('/visit/calendar-visit')
                                }else{
                                    toast.warn("Por favor, verificar o perfil de acesso!")
                                }
                            }}
                        >
                            Visitas
                        </Button>
                        
                        <Button
                            background={"#fff"}
                            size={["lg"]}
                            onClick={() => navigate('/configuration')}
                        >
                            Configurações
                        </Button>
                    </Container>
                </GridItem>
                <GridItem colSpan={1} />
            </Grid>
        </Flex>
    )
}