import React from 'react'

import { useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'

import { MdStar } from "react-icons/md"

import { 
    Heading, 
    Box,
    Text,
    Button,
    FormControl,
    FormLabel,
    Switch,
    Flex,
    Grid,
    GridItem
} from '@chakra-ui/react'

import { RootState } from '../../../../../store'

import { Container } from './styles';
import { ImageUpload } from '../../../../../components/ImageUpload'

export function PetsDetails () {

    const navigate = useNavigate()
    
    const currentPet = useSelector((state: RootState) => state.pet.data)

    return (
        <Grid templateColumns={["0% 100% 0%", "30% 40% 30%"]}>
            <GridItem colSpan={1} />
            <GridItem
                colSpan={1}
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <Box w={"100%"}>
                    <Flex
                        marginTop={["10px", "40px"]}
                        flexDir={["column", "row"]}
                        justifyContent={["center", "space-between"]}
                        alignItems={["flex-end", "flex-end"]}
                    >
                        <Flex
                            justifyContent={["center", "flex-start"]}
                            alignItems={["", "flex-end"]}
                            width={["100%", "auto"]}
                            marginBottom={["40px", "0px"]}
                        >
                            <Text
                                fontSize={[28, 20]}
                                fontWeight={600}
                                color={"#444"}
                            >
                                Detalhes do Pet
                            </Text>
                        </Flex>
                    </Flex>
                    <Container
                        flexDir={"column"}
                        padding={["0px", "20px"]}
                        borderRadius={"6px"}
                        background={["transparent", "#fff"]}
                    >
                       
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Nome
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {currentPet.name}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Sexo
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {currentPet.gender}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Tipo
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {currentPet.specie}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Raça
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {currentPet.breed}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Tamanho
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {currentPet.size}
                            </Text>
                        </Box>
                        <Box>
                        <ImageUpload
                            initialDefaultImage={'data:image/png;base64,' + currentPet.image}
                            disabled={true}
                        />
                        </Box>
                        
                        
                        <Flex flexDir={["column", "row"]}>
                            <Button 
                                colorScheme='teal' 
                                size='md'
                                onClick={() => navigate('/my-profile', {
                                    state: {
                                      index: 3
                                    }
                                  })}
                            >
                                Voltar
                            </Button>
                        </Flex>
                    </Container>
                </Box>
            </GridItem>
            <GridItem colSpan={1} />
        </Grid>
    )
}