import React, { useState, useRef, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { MdAccessTimeFilled } from "react-icons/md";

import TimePicker from 'react-time-picker';

import chroma from 'chroma-js';

import { toast } from 'react-toastify';

import Select, { SingleValue, StylesConfig } from 'react-select';

import {
    Stack,
    Box,
    Button,
    Tag,
    TagLabel,
    TagLeftIcon,
    Input,
    Flex,
    FormLabel,
    Checkbox,
} from '@chakra-ui/react';

import { RootState } from '../../../../../store';

import { setCurrentBookingRequest } from '../../../../../store/reducers/booking-request';

import api from '../../../../../services/api';

import { CalendarComponent }  from '../../../../../components/Calendar';

import { BoxWithBorder } from '../../../../../components/BoxWithBorder';

import { ContainerCalendar, SubContainerCalendar, ContainerHoursFilters, SelectWrapper } from './styles';


import './style.css';

interface IAvailableBookingDates {
    activeDays: string [],
    commonAreaRuleId: number,
    startDate: string,
    endDate: string,
    exceptionDate: string[],
    indisponibleDay: boolean
}

type PropsLocals = {
    allDay: boolean
    allDayAllowed: boolean
    availableTimes: any;
    enableVirtualDoorman: boolean;
    value: string;
    label: string;
}

interface IOptions {
    readonly value: string;
    readonly label: string;
}

interface IDateOrHoursEvent {
    isCheckedFilterByDateAndHour: boolean;
    reloadLocalEHorarioDaReserva: boolean;
    setReloadLocalEHorarioDaReserva: (value: any) => void;
    onChangeStartHourFilter: (value: any) => void;
    valueStartHourFilter: any;
    disabledButton: boolean;
    valueEndHourFilter: any;
    onChangeEndHourFilter: (value: any) => void;
    setCommonAreaRuleList: (value: any) => void;
    setMessageNoLocals: (value: string) => void;
    locals: PropsLocals[]
    setLocals: (value: PropsLocals[]) => void;
}

export function DateOrHoursEvent({
    isCheckedFilterByDateAndHour,
    reloadLocalEHorarioDaReserva,
    setReloadLocalEHorarioDaReserva,
    onChangeStartHourFilter,
    valueStartHourFilter,
    disabledButton,
    valueEndHourFilter,
    onChangeEndHourFilter,
    setCommonAreaRuleList,
    setMessageNoLocals,
    locals,
    setLocals,
}: IDateOrHoursEvent) {
    const dispatch = useDispatch();

    const currentBookingRequest = useSelector((state: RootState) => state.bookingRequest.data)

    const currentTimeSelectRef = useRef<any>(null);

    const [allDay, setAllDay] = useState(false);
    const [hideSelectAll, setHideSelectAll] = useState(true)

    const [availableBookingDates, setAvailableBookingDates] = useState<IAvailableBookingDates>({
        activeDays: [],
        commonAreaRuleId: 0,
        startDate: '',
        endDate: '',
        exceptionDate: [],
        indisponibleDay: false
    })

    useEffect(() => {
        if(currentBookingRequest.commonAreaRuleId !== '0'){
            loadAvailableBookingDates()
        }
    }, [currentBookingRequest.commonAreaRuleId])

    useEffect(() => {
        if (currentBookingRequest.availableBookingTimes.length > 0) {
            let arrayLabelsAvailable = currentBookingRequest.availableBookingTimes.map(res => {
                let labelBreak = res.label.split(' - ');
                return labelBreak;
            });
            let shouldHideSelectAll = false;

            for (let i = 0; i < arrayLabelsAvailable.length - 1; i++) {
                if (arrayLabelsAvailable[i][1] !== arrayLabelsAvailable[i+1][0]) {
                    shouldHideSelectAll = true;
                    break;
                }
            }

            setHideSelectAll(shouldHideSelectAll);
        }

    }, [currentBookingRequest.availableBookingTimes]);

    async function loadAvailableBookingDates() {
        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        
        try{
            const { data } = await api.get(`CommonAreaRequest/GetAvailableBookingDates/${currentBookingRequest.commonAreaRuleId}`, config)

            setAvailableBookingDates(data)
            
        }catch(e: any) {
            
        }
    }


    function getDateAfter4Months() {
        let date = new Date();
        date.setMonth(date.getMonth() + 4);
    
        return date;
    }

    function formatStringDateUSToBr(val: string) : string{
        let formatDate = val.split('-')
        return formatDate[2] + '/' + formatDate[1] + '/' + formatDate[0]
    }

    async function searchLocals() {
        //validations
        if(!currentBookingRequest.reservationDate) {
            toast.error('É obrigatório selecionar a data antes de buscar os locais.')
            return
        }
        if(!valueStartHourFilter || !valueEndHourFilter) {
            toast.error('É obrigatório definir todos os campos de horário antes de buscar os locais.')
            return
        }
        const startHourTime = 
            (document.querySelectorAll('.react-time-picker__inputGroup__hour')[0] as HTMLInputElement).value + ':' +
            (document.querySelectorAll('.react-time-picker__inputGroup__minute')[0] as HTMLInputElement).value
        const endHourTime = 
            (document.querySelectorAll('.react-time-picker__inputGroup__hour')[1] as HTMLInputElement).value + ':' +
            (document.querySelectorAll('.react-time-picker__inputGroup__minute')[1] as HTMLInputElement).value
        if(
            !validateHoutFormat(startHourTime)
            || !validateHoutFormat(endHourTime)
        ){
            return toast.error('Todos os campos de horário precisam conter um horário válido.');
        }
        let objDateStart = new Date();

        objDateStart.setHours(valueStartHourFilter.split(':')[0])
        objDateStart.setMinutes(valueStartHourFilter.split(':')[1])

        let objDateEnd = new Date();

        objDateEnd.setHours(valueEndHourFilter.split(':')[0])
        objDateEnd.setMinutes(valueEndHourFilter.split(':')[1])

        if(objDateStart >= objDateEnd) {
            return toast.error('O horário de início não pode ser maior ou igual que o horário final.')
        }
           
        try{
            const token = localStorage.getItem('access_portal:access_token')
    
            const headerParams = {
                headers: {
                    Authorization: `Bearer ${ token }`,
                    'Content-Type': 'application/json; charset=utf-8'
                } 
            }

            const response = await api.post(
                '/milestone/BookingSearch', 
                {
                    MilestoneId: currentBookingRequest.milestoneId,
                    date: currentBookingRequest.reservationDate,
                    startTime: valueStartHourFilter,
                    endTime: valueEndHourFilter
                }, 
                headerParams
            );
            

            try{
                const data2 = await api.get(`event/getEventSpaceByUnitGuest?unitId=${currentBookingRequest.unitId}&guest=${currentBookingRequest.bookingType === 'Coletivo'}`, headerParams)
    
                setCommonAreaRuleList(data2.data.map((v: any) => {
                    return {
                        ...v.commonAreaRule,
                        localId: v.local.id,
                        localName: v.local.name,
                        milestoneId: v.milestone.id
                    }
                }))
                
                setLocals(response.data.map((v: any) => {
                    return {
                        label: v.localName,
                        value: v.localId,
                        enableVirtualDoorman: v.commonAreaRuleEnableVirtualDoorman,
                        allDayAllowed: v.commonAreaReservationRuleAllDayAllowed,
                        allDay: v.commonAreaReservationRuleAllDay,
                        availableTimes: v.availableTimes.map((v2: any) => {
                            return {
                                label: v2.commonAreaTimesheetStartTime.substring(0, 5) + ' - ' + v2.commonAreaTimesheetEndTime.substring(0, 5),
                                value: v2.commonAreaTimesheetId,
                                startTime: v2.commonAreaTimesheetStartTime,
                                endTime: v2.commonAreaTimesheetEndTime
            
                            }
                        })
                    }
                }))

                dispatch(setCurrentBookingRequest({
                    ...currentBookingRequest, 
                    localId: '0'
                }))

                
                
            }catch(e: any) {
                setMessageNoLocals('Não existem espaços cadastrados para este tipo de reserva.');

                setLocals([]);
                
                dispatch(setCurrentBookingRequest({
                    ...currentBookingRequest, 
                    localId: '0'
                }));
            }
            
        }catch(e: any) {
            if(e?.response?.data?.Message){
            setTimeout(() => {
                toast.error(e?.response?.data?.Message)
                return
            }, 1000) 
            }else{
                setTimeout(() => {
                    toast.error('Algo deu errado enquanto tentavamos nos conectar aos nossos servidores, por favor entre em contato com o suporte.')
                    return
                }, 1000) 
            }
        }

    }

    function validateHoutFormat(value: string) {
        if(!(value.indexOf(':') > -1)) {
            return false
        }
        const valueSplit = value.split(':')
        if(valueSplit.length !== 2){
            return false
        }
        if(isNaN(parseInt(valueSplit[0])) || isNaN(parseInt(valueSplit[1]))){
            return false
        }
        if(
            parseInt(valueSplit[0]) > 23
            || parseInt(valueSplit[1]) > 59
        ){
            return false
        }   

        return true
    }

    function getLabelByValue(val: any, options: any): string {
        const result = options.filter((v: any) => v.value.toString() === val.toString())

        if(result.length > 0){
            return result[0].label
        }

        return ''
    }

  return (
    <>
        <BoxWithBorder
            title={'Data e Horário'}
            padding={["0px", "20px"]}
            margin={["0px", "20px"]}
            borderWidth={["0px", "1px"]}
            display={"flex"}
            marginTop={["50px", "0px"]}
            flexDir={["column"]}
        >
            <>
                {currentBookingRequest.id === 0
                    ?   (
                            <Flex
                                paddingRight={["0px", "2.5px"]}
                                flexDir={"column"}
                                marginTop={["10px", "0px"]}
                                w={["100%"]}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Data da Reserva 
                                </FormLabel>
                                <ContainerCalendar>
                                    <SubContainerCalendar>
                                        <CalendarComponent
                                            minDate={new Date()}
                                            maxDate={isCheckedFilterByDateAndHour ? getDateAfter4Months() : new Date(availableBookingDates.endDate.split('T')[0] + ' 23:59:00')}
                                            value={currentBookingRequest.reservationDate ? new Date(currentBookingRequest.reservationDate + ' 00:00:00') : new Date()}
                                            onChange={(e: any, i: any) => {
                                                //console.log("availableBookingDatesendDate", availableBookingDates.activeDays);
                                                let day = e.getDate()

                                                let month = e.getMonth() + 1

                                                month = month.toString().length === 1 ? '0' + month : month

                                                let year = e.getFullYear()

                                                let dateFormatted = `${year}-${month}-${day}`

                                                
                                                let toUpdate = {
                                                    ...currentBookingRequest, 
                                                    reservationDate: dateFormatted,
                                                    availableBookingTimes: [],
                                                    timesheets: []
                                                }

                                                if(isCheckedFilterByDateAndHour){
                                                    //toUpdate.localId = '0'
                                                    setLocals([])
                                                }

                                                dispatch(setCurrentBookingRequest(toUpdate))

                                                setReloadLocalEHorarioDaReserva(true);

                                                setTimeout(() => setReloadLocalEHorarioDaReserva(false), 500)
                                            }}
                                            tileDisabled={({ date, view }) => {
                                                if(isCheckedFilterByDateAndHour){
                                                    return false
                                                }
                                                
                                                // date.getDay('Sunday')
                                                const weekDays = [
                                                    {
                                                        weekDay: "SÁBADO",
                                                        index: 0
                                                    },{
                                                        weekDay: "SEGUNDA-FEIRA",
                                                        index: 1
                                                    },{
                                                        weekDay: "TERÇA-FEIRA",
                                                        index: 2
                                                    }, {
                                                        weekDay: "QUARTA-FEIRA",
                                                        index: 3
                                                    }, {
                                                        weekDay: "QUINTA-FEIRA",
                                                        index: 4
                                                    }, {
                                                        weekDay: "SEXTA-FEIRA",
                                                        index: 5
                                                    },{
                                                        weekDay: "DOMINGO",
                                                        index: 6
                                                    }
                                                ];

                                                const newWeekDays = []

                                                for(let i = 0; i < availableBookingDates.activeDays.length; i++) {
                                                    newWeekDays.push(weekDays.filter(res => res.weekDay === availableBookingDates.activeDays[i])[0].index)
                                                }

                                                const exceptionDates = availableBookingDates.exceptionDate?.some((disabledDate) => {
                                                    let month = (date.getMonth() + 1).toString()
                                                    
                                                    month = month.length === 1 ? '0' + month : month
                                                    
                                                    if(disabledDate?.split('-')[2].substring(0, 2) === '25'){
                                                        /*console.log(
                                                            'exceptionDates-loop', 
                                                            date.getFullYear().toString() + ' === ' + disabledDate?.split('-')[0].toString()  + '&& '+ month.toString() + ' === ' + disabledDate?.split('-')[1].toString() + ' && '+ date.getDate().toString() + ' === ' + disabledDate?.split('-')[2].substring(0, 2).toString()
                                                        )*/
                                                    }

                                                    return (
                                                        date.getFullYear().toString() === disabledDate?.split('-')[0].toString()  && 
                                                        month.toString() === disabledDate?.split('-')[1].toString()  &&
                                                        date.getDate().toString() === disabledDate?.split('-')[2].substring(0, 2).toString()
                                                    )
                                                })

                                                return !(newWeekDays.filter(res => res === date.getDay()).length > 0) || exceptionDates
                                                
                                            }}
                                        />
                                    </SubContainerCalendar>
                                </ContainerCalendar>
                            </Flex>
                        )
                    :   (
                            <Box marginTop={"10px"}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Data da Reserva
                                </FormLabel>
                                <Input
                                    placeholder=''
                                    background={"#fff"}
                                    value={formatStringDateUSToBr(currentBookingRequest.reservationDate)}
                                    isDisabled={currentBookingRequest.id !== 0}
                                />
                            </Box>
                        )
                }
                
            
                {currentBookingRequest.id === 0
                    ?   !isCheckedFilterByDateAndHour && !reloadLocalEHorarioDaReserva && (
                            <Flex justifyContent={["flex", "column"]} flexDirection={["column", "row"]}>
                                <>
                                {locals.filter((v) => {
                                    return (
                                        v.value.toString() === currentBookingRequest.localId.toString()
                                        && v.allDayAllowed && v.allDay
                                    )
                                }).length > 0 && (
                                    ((currentBookingRequest.reservationDate && currentBookingRequest.availableBookingTimes.length > 0) &&
                                        <Flex
                                            alignItems={"center"}
                                            marginTop={["20px"]}
                                            w={"150px"}
                                            padding={["0px 2.5px", "0px 2.5px"]}
                                        >
                                            <Stack spacing={5} direction='row'>
                                                <Checkbox
                                                    borderColor={["#777777", "#E2E8F0"]}
                                                    // isChecked={allDay || currentBookingRequest.availableBookingTimes.length === currentBookingRequest.timesheets.length}
                                                    isChecked={currentBookingRequest.availableBookingTimes.length === currentBookingRequest.timesheets.length}
                                                    onChange={() => {
                                                        const val = currentBookingRequest
                                                        .availableBookingTimes
                                                        .map((v, i) => {
                                                            let color = '#000'
                                                            let label = v.label
                                                            if(v.ongoing){
                                                                color = '#FF5630'
                                                                let now = new Date()
                                                                let hour = now.getHours().toString().length === 1 ? '0' + now.getHours().toString() : now.getHours().toString()
                                                                let minute = now.getMinutes().toString().length === 1 ? '0' + now.getMinutes().toString() : now.getMinutes().toString()
                                                                label = hour + ':' + minute + ' ' + label.substring(5, 13)
                                                            }
                                                            return {
                                                                ...v,
                                                                color: color,
                                                                label: label
                                                            }
                                                        })
                                                        const valInOrder = []
                                                        for(let i in currentBookingRequest.availableBookingTimesBackup){
                                                            if(val.filter((v: any) => v.value === currentBookingRequest.availableBookingTimesBackup[i].value).length > 0){
                                                                valInOrder.push({
                                                                    ...currentBookingRequest.availableBookingTimesBackup[i]
                                                                })
                                                            }
                                                        }

                                                        dispatch(setCurrentBookingRequest({
                                                            ...currentBookingRequest, 
                                                            timesheets: valInOrder,
                                                            availableBookingTimes: val
                                                        }))

                                                        setAllDay(!allDay)
                                                    }}
                                                >
                                                    Dia todo
                                                </Checkbox>
                                            </Stack>
                                        </Flex>
                                    )
                                )}

                                {/*(locals.filter((v) => {
                                    return (
                                        v.value.toString() === currentBookingRequest.localId.toString()
                                        && v.allDayAllowed && v.allDay
                                    )
                                }).length === 0 && false) && (
                                    ((currentBookingRequest.reservationDate && currentBookingRequest.availableBookingTimes.length > 0) &&
                                        <Flex
                                            alignItems={"center"}
                                            marginTop={["20px"]}
                                            w={"150px"}
                                            padding={["0px 2.5px", "0px 2.5px"]}
                                        >
                                            <Stack spacing={5} direction='row'>
                                                <Checkbox
                                                    borderColor={["#777777", "#E2E8F0"]}
                                                    isChecked={currentBookingRequest.availableBookingTimes.length === currentBookingRequest.timesheets.length}
                                                    onChange={(e) => {
                                                        //console.log("currentBookingRequest", e.target.checked)//e.target.checked);
                                                        
                                                        dispatch(setCurrentBookingRequest({
                                                            ...currentBookingRequest, 
                                                            timesheets: currentBookingRequest.availableBookingTimes
                                                        }))

                                                    }}
                                                >
                                                    Selecionar todos
                                                </Checkbox>
                                            </Stack>
                                        </Flex>
                                    )
                                )*/}

                                {(
                                    (currentBookingRequest.reservationDate && currentBookingRequest.availableBookingTimes.length > 0) &&
                                    <SelectWrapper>
                                        <Box
                                            marginTop={["20px"]}
                                            w={["100%"]}
                                            flexFlow={["1"]}
                                            padding={["0px 2.5px", "0px 2.5px"]}
                                        >
                                            <FormLabel
                                                fontSize={"0.8em"}
                                                color={"#777"}
                                                marginBottom={"3px"}
                                            >
                                                Horário da Reserva
                                            </FormLabel>
                                            <Select
                                                // isDisabled={allDay}
                                                ref={currentTimeSelectRef}
                                                placeholder={'Selecione...'}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                menuPlacement={'top'}
                                                onChange={(val: SingleValue<any>) => {
                                                    const allTimesReserved = (currentBookingRequest.availableBookingTimes.length-1) === currentBookingRequest.timesheets.length

                                                    if(val && val.length > 0){
                                                        if (val.filter((valor: any) => {
                                                            return valor.value === "Selecionar todos"
                                                        }).length > 0) {
                                                            dispatch(setCurrentBookingRequest({
                                                                ...currentBookingRequest,
                                                                timesheets: currentBookingRequest.availableBookingTimes
                                                            }));
                                                        } else {
                                                            let valInOrder = []

                                                            for (let i in currentBookingRequest.availableBookingTimesBackup) {
                                                                if (val.filter((v: any) => v.value === currentBookingRequest.availableBookingTimesBackup[i].value).length > 0) {
                                                                    valInOrder.push({
                                                                        ...currentBookingRequest.availableBookingTimesBackup[i],
                                                                    })
                                                                }
                                                            }
                                                
                                                            //pegando os indices
                                                            let startTime = 0
                                                            let endTime = 0
                                                            let startIndex = 0
                                                            let endIndex = 0
                                                
                                                            for (let i in currentBookingRequest.availableBookingTimesBackup) {
                                                                if (
                                                                    valInOrder[0].value ===
                                                                    currentBookingRequest.availableBookingTimesBackup[i].value
                                                                ) {
                                                                    startIndex = parseInt(i)
                                                                    startTime = currentBookingRequest.availableBookingTimesBackup[i].startTime
                                                                }
                                                                if (
                                                                    valInOrder[valInOrder.length - 1].value ===
                                                                    currentBookingRequest.availableBookingTimesBackup[i].value
                                                                ) {
                                                                    endIndex = parseInt(i)
                                                                    endTime = currentBookingRequest.availableBookingTimesBackup[i].endTime
                                                                }
                                                            }
                                                
                                                            //desabilitando e habilitando
                                                            let newAvailableBookingTimes = [];

                                                            for (let i in currentBookingRequest.availableBookingTimesBackup) {
                                                                let isDisabled = false
                                                                if (
                                                                    //se for mais de um para tras
                                                                    parseInt(i) < startIndex - 1 ||
                                                                    (parseInt(i) === startIndex - 1 && startTime != currentBookingRequest.availableBookingTimesBackup[i].endTime) ||
                                                                    //se for mais de um para frente
                                                                    (parseInt(i) === endIndex + 1 && endTime != currentBookingRequest.availableBookingTimesBackup[i].startTime) ||
                                                                    parseInt(i) > endIndex + 1
                                                
                                                
                                                                ) {
                                                                    isDisabled = true
                                                                }
                                                
                                                                newAvailableBookingTimes.push({
                                                                    ...currentBookingRequest.availableBookingTimesBackup[i],
                                                                    isDisabled: isDisabled,
                                                                })
                                                            }

                                                            dispatch(setCurrentBookingRequest({
                                                                ...currentBookingRequest, 
                                                                timesheets: valInOrder,
                                                                availableBookingTimes: newAvailableBookingTimes,
                                                                allTimesReserved
                                                            }))
                                                        }
                                                    }else{
                                                        if(val && val.value) {
                                                            let valToAdd = []
                                                            valToAdd.push(val)
                                                            dispatch(setCurrentBookingRequest({
                                                                ...currentBookingRequest, 
                                                                timesheets: valToAdd,
                                                                allTimesReserved
                                                            }))
                                                        }else{
                                                            dispatch(setCurrentBookingRequest({
                                                                ...currentBookingRequest, 
                                                                timesheets: [],
                                                                availableBookingTimes: currentBookingRequest.availableBookingTimes.map((v) => {
                                                                    return {
                                                                        ...v,
                                                                        isDisabled: false
                                                                    }
                                                                }),
                                                                allTimesReserved
                                                            }))
                                                        }
                                                    }
                                                }}
                                                isClearable={true}
                                                name="color"
                                                options={
                                                    (
                                                        (currentBookingRequest.availableBookingTimes.length === currentBookingRequest.timesheets.length ||
                                                        locals.filter((v) => {
                                                            return (
                                                                v.value.toString() === currentBookingRequest.localId.toString()
                                                                && v.allDayAllowed
                                                            )
                                                        }).length === 0) ||
                                                        hideSelectAll
                                                    ) ?
                                                    currentBookingRequest
                                                        .availableBookingTimes
                                                        .map((v, i) => {
                                                            let color = '#000'
                                                            let label = v.label
                                                            if(v.ongoing){
                                                                color = '#FF5630'
                                                                let now = new Date()
                                                                let hour = now.getHours().toString().length === 1 ? '0' + now.getHours().toString() : now.getHours().toString()
                                                                let minute = now.getMinutes().toString().length === 1 ? '0' + now.getMinutes().toString() : now.getMinutes().toString()
                                                                label = hour + ':' + minute + ' ' + label.substring(5, 13)
                                                            }
                                                            return {
                                                                ...v,
                                                                color: color,
                                                                label: label
                                                            }
                                                        })
                                                    :
                                                    [{
                                                        color: "#000",
                                                        endTime: "",
                                                        isDisabled: false,
                                                        label: "Selecionar todos",
                                                        ongoing: false,
                                                        startTime: "",
                                                        value: "Selecionar todos",
                                                    }].concat(
                                                    currentBookingRequest
                                                        .availableBookingTimes
                                                        .map((v, i) => {
                                                            let color = '#000'
                                                            let label = v.label
                                                            if(v.ongoing){
                                                                color = '#FF5630'
                                                                let now = new Date()
                                                                let hour = now.getHours().toString().length === 1 ? '0' + now.getHours().toString() : now.getHours().toString()
                                                                let minute = now.getMinutes().toString().length === 1 ? '0' + now.getMinutes().toString() : now.getMinutes().toString()
                                                                label = hour + ':' + minute + ' ' + label.substring(5, 13)
                                                            }
                                                            return {
                                                                ...v,
                                                                color: color,
                                                                label: label
                                                            }
                                                        })
                                                    )
                                                }
                                                isMulti={locals.filter((v) => {
                                                    return (
                                                        v.value.toString() === currentBookingRequest.localId.toString()
                                                        && v.allDayAllowed
                                                    )
                                                }).length > 0}
                                                value={locals.filter((v) => {
                                                    return (
                                                        v.value.toString() === currentBookingRequest.localId.toString()
                                                        && v.allDayAllowed
                                                    )
                                                }).length > 0 ? currentBookingRequest.timesheets : currentBookingRequest.timesheets[0]}
                                                styles={colourStyles}
                                            />
                                        </Box>
                                    </SelectWrapper>
                                )}

                                </>
                                
                            </Flex>
                        )
                    :   ( /* Se for edição apenas mostra os horários e não deixa editar. */
                        <Box w={"100%"} marginTop={"10px"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                                marginLeft={"10px"}
                            >
                                Horário da Reserva
                            </FormLabel>
                                {
                                    [...currentBookingRequest.timesheets].map((v, i) => {
                                        return (
                                            <Tag
                                                key={i}
                                                variant='subtle'
                                                colorScheme='cyan'
                                                padding={"12px"}
                                                marginLeft={"10px"}
                                                // style={{height: 50, padding: 10, margin: 10}}>
                                            >
                                                <TagLeftIcon boxSize='12px' as={MdAccessTimeFilled} />
                                                <TagLabel>{v.startTime.substring(0, 5)} - {v.endTime.substring(0, 5)}</TagLabel>
                                            </Tag>
                                        )
                                    })  
                                }       
                            </Box>
                        )
                }
                {isCheckedFilterByDateAndHour && (
                    <>
                        <span style={{textAlign: 'center', marginTop: '30px'}}>Intervalo de início</span>
                        <ContainerHoursFilters>
                            <div className='container-field'>
                                <span>De: </span>
                                <TimePicker 
                                    clearIcon={null}
                                    onChange={(e) => {
                                        onChangeStartHourFilter(e)
                                    }}
                                    value={valueStartHourFilter} 
                                    disableClock={true}
                                    maxTime={'23:59'}
                                    minTime={'00:00'}
                                />
                            </div>
                            <div className='container-field'>
                                <span>Até: </span>
                                <TimePicker 
                                    clearIcon={null}
                                    onChange={(e) => {
                                        onChangeEndHourFilter(e)
                                    }}
                                    value={valueEndHourFilter} 
                                    disableClock={true}
                                    maxTime={'23:59'}
                                    minTime={'00:00'}
                                />
                            </div>

                            <Button 
                                colorScheme='teal' 
                                isDisabled={disabledButton}
                                onClick={() => searchLocals()}
                            >
                                Buscar locais
                            </Button>

                        </ContainerHoursFilters>
                    </>
                )}
                
            </>
        </BoxWithBorder>

        {isCheckedFilterByDateAndHour && locals && Array.isArray(locals) && locals.length > 0 && (
            <BoxWithBorder
                title={'Local e Horário da Reserva'}
                padding={["0px", "20px"]}
                margin={["0px", "20px"]}
                borderWidth={["0px", "1px"]}
            >
                <>
                    {!reloadLocalEHorarioDaReserva && (
                        <Box
                            w={["100%", "50%"]}
                            marginTop={["10px", "0px"]}
                        >
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Espaço para Reserva
                            </FormLabel>
                            <Select<IOptions>
                                placeholder={'Selecione...'}
                                className="basic-single"
                                classNamePrefix="select"
                                onChange={(val: SingleValue<IOptions>) => {
                                    if(val){
                                        if(val.value.toString() !== currentBookingRequest.localId) {
                                            const availableBookingTimes = locals.filter((v) => { 
                                                return v.value.toString() === val.value.toString()
                                            })[0].availableTimes
                                            
                                            dispatch(setCurrentBookingRequest({
                                                ...currentBookingRequest, 
                                                localId: val.value.toString(),
                                                availableBookingTimes: availableBookingTimes,
                                                availableBookingTimesBackup: availableBookingTimes,
                                            }))
                                        }
                                    } else {
                                        dispatch(setCurrentBookingRequest({
                                            ...currentBookingRequest, 
                                            localId: '0',
                                            reservationDate: "",
                                        }))
                                    }
                                }}
                                isClearable={true}
                                name="color"
                                value={{
                                    value: currentBookingRequest.localId,
                                    label: getLabelByValue(currentBookingRequest.localId, locals)
                                }}
                                options={locals}
                                isDisabled={currentBookingRequest.id !== 0}
                            />
                        </Box>
                    )}
                    {currentBookingRequest.localId !== '0' && !reloadLocalEHorarioDaReserva && (
                        <SelectWrapper>
                            <Box w={"100%"} marginTop={["20px"]}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Horário da Reserva
                                </FormLabel>
                                <Select
                                    ref={currentTimeSelectRef}
                                    placeholder={'Selecione...'}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    menuPlacement={'top'}
                                    onChange={(val: SingleValue<any>) => {
                                        const allTimesReserved = (currentBookingRequest.availableBookingTimes.length-1) === currentBookingRequest.timesheets.length

                                        if(val && Array.isArray(val) && val.length > 0){
                                            let valInOrder = [];

                                            for(let i in currentBookingRequest.availableBookingTimesBackup){
                                                if(val.filter((v: any) => v.value === currentBookingRequest.availableBookingTimesBackup[i].value).length > 0){
                                                    valInOrder.push({
                                                        ...currentBookingRequest.availableBookingTimesBackup[i]
                                                    });
                                                }
                                            }

                                            //pegando os indices
                                            let startIndex = 0;
                                            let endIndex = 0;

                                            for(let i in currentBookingRequest.availableBookingTimesBackup){
                                                if(valInOrder[0].value === currentBookingRequest.availableBookingTimesBackup[i].value){
                                                    startIndex = parseInt(i)
                                                }
                                                if(valInOrder[valInOrder.length - 1].value === currentBookingRequest.availableBookingTimesBackup[i].value){
                                                    endIndex = parseInt(i)
                                                }
                                            }

                                            //desabilitando e habilitando
                                            let newAvailableBookingTimes = [];
                                            
                                            for(let i in currentBookingRequest.availableBookingTimesBackup){
                                                let isDisabled = false;

                                                if(
                                                    //se for mais de um para tras
                                                    parseInt(i) < (startIndex - 1)
                                                    ||
                                                    //se for mais de um para frente
                                                    parseInt(i) > (endIndex + 1)
                                                ){
                                                    isDisabled = true;
                                                }

                                                newAvailableBookingTimes.push({
                                                    ...currentBookingRequest.availableBookingTimesBackup[i],
                                                    isDisabled: isDisabled
                                                });
                                            }

                                            dispatch(setCurrentBookingRequest({
                                                ...currentBookingRequest, 
                                                timesheets: valInOrder,
                                                availableBookingTimes: newAvailableBookingTimes,
                                                allTimesReserved
                                            }));
                                        }else{
                                            if(val && val.value) {
                                                let valToAdd = [];

                                                valToAdd.push(val);

                                                dispatch(setCurrentBookingRequest({
                                                    ...currentBookingRequest, 
                                                    timesheets: valToAdd,
                                                    allTimesReserved
                                                }));
                                            }else{
                                                dispatch(setCurrentBookingRequest({
                                                    ...currentBookingRequest, 
                                                    timesheets: [],
                                                    availableBookingTimes: currentBookingRequest.availableBookingTimes.map((v) => {
                                                        return {
                                                            ...v,
                                                            isDisabled: false
                                                        }
                                                    }),
                                                    allTimesReserved
                                                }));
                                            }
                                        }
                                    }}
                                    isClearable={true}
                                    name="color"
                                    options={
                                        currentBookingRequest
                                            .availableBookingTimes
                                            .map((v, i) => {
                                                let color = '#000'
                                                if(v.ongoing){
                                                    color = '#FF5630'
                                                }
                                                return {
                                                    ...v,
                                                    color: color
                                                }
                                            })
                                    }
                                    isMulti={locals.filter((v) => {
                                        return (
                                            v.value.toString() === currentBookingRequest.localId.toString()
                                            && v.allDayAllowed
                                        )
                                    }).length > 0}
                                    value={locals.filter((v) => {
                                        return (
                                            v.value.toString() === currentBookingRequest.localId.toString()
                                            && v.allDayAllowed
                                        )
                                    }).length > 0 ? currentBookingRequest.timesheets : currentBookingRequest.timesheets[0]}
                                    styles={colourStyles}
                                />
                            </Box>
                            
                        </SelectWrapper>
                    )}
                </>
            </BoxWithBorder>
        )}
    </>
  );
}

const colourStyles: StylesConfig<any, boolean> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);

        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor:
                    !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.color ?? 'black');

        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
};