import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ITimeSheetsObj {
    id: number
    date: Date
    startTime: string
    endTime: string
    approval: boolean
    label: string
    value: string
}

interface IHistory {
    id: number
    status: string
    userId: number
    description: string
    createOn: Date
}

interface IData {
    unitId: string, 
    department: any,
    localId: string,
    guestType: 'EXTERNAL' | 'INTERNAL' | 'BOTH';
    commonAreaRuleId: string,
    commonAreaReservationRule: {
        id: number,
        noShowEnable: boolean,
        noShowTimeLimit: number
    },
    reservationDate: string,
    availableBookingTimes: any[],
    availableBookingTimesBackup: any[],
    timesheets: ITimeSheetsObj[],
    milestoneId: string,
    answerOccurrence: boolean,
    approval: string,
    bookingType: "0" | "Coletivo" | "Individual",
    changeToEvent: boolean,
    createdDate: string, 
    eventDate: string,
    subjectToCancellation: boolean
    id: number,
    local: string,
    milestone: string
    paid: boolean,
    history: IHistory[],
    privateLocalName: string, 
    privateLocalType: string,
    requestBy?: {
        id: number
        name?: string
        privateLocal: {
            id: number, 
            name: string, 
            floor: string, 
            type: string, 
            requestById: number, 
            requestByName: string
        }
        singlePersonEvent: number
    },
    requestDate: string,
    requestNeeded: boolean,
    status: string,
    allTimesReserved: boolean
}

const initialState = { 
    data: { 
        unitId: '0', 
        department: {
            id: '0'
        },
        localId: '0',
        guestType: 'EXTERNAL',
        commonAreaRuleId: '0',
        commonAreaReservationRule: {
            id: 68,
            noShowEnable: false,
            noShowTimeLimit: 0
        },
        reservationDate: '',
        availableBookingTimes: [],
        availableBookingTimesBackup: [],
        timesheets: [],
        history: [],
        milestoneId: '0',
        answerOccurrence: false,
        approval: '',
        bookingType: '0',
        subjectToCancellation: false,
        changeToEvent: false,
        createdDate: '', 
        eventDate: '',
        id: 0,
        local: '',
        milestone: '',
        paid: false,
        privateLocalName: '', 
        privateLocalType: '',
        requestBy: {
            id: 0,
            name: "",
            privateLocal: {
                id: 0, 
                name: "", 
                floor: "", 
                type: "", 
                requestById: 0, 
                requestByName: ""
            },
            singlePersonEvent: 0
        },
        requestDate: '',
        requestNeeded: false,
        status: '',
        allTimesReserved: false
  } as IData
}
  
export const bookingRequestSlice = createSlice({
name: 'bookingRequest',
initialState,
reducers: {
    resetCurrentBookingRequest: (state) => {
        state.data = initialState.data
    },
    setCurrentBookingRequest: (state, action: PayloadAction<IData>) => {
        state.data = action.payload
    },
},
})

export const { resetCurrentBookingRequest, setCurrentBookingRequest } = bookingRequestSlice.actions

export default bookingRequestSlice.reducer