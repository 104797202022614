import  React, { useEffect, useState } from 'react'
import { Container, ContainerWeekDays, SubContainerWeekDays, Title, WeekDayButton, TimeFieldLabel, WeekFooterLabel, ContainerFieldTime } from './styles'
import {
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Heading,
    Stack,
    StackDivider,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
} from '@chakra-ui/react'
import TimePicker from 'react-time-picker'
import api from '../../../../../services/api'
import { ModalComponent } from '../../../../../components/Modal';
import { FormCopyWeekTime } from '../FormCopyWeekTime';
import { toast } from "react-toastify";

/*interface IWeekDays {
    diasDaSemana: any
}*/

const allowedPropsDaySpan = {selected: false}

export function WeekDaysCollaborator ({setDiasDaSemana, diasDaSemana }: any){
    const nomesDosDiasDaSemana = [
        "DOMINGO",
        "SEGUNDA-FEIRA",
        "TERÇA-FEIRA",
        "QUARTA-FEIRA",
        "QUINTA-FEIRA",
        "SEXTA-FEIRA",
        "SÁBADO"
    ];
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [days, setDays] = useState<any>([
        {id: 0, name: "DOMINGO", openTime: '', closeTime: '', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {id: 0, name: "SEGUNDA-FEIRA", openTime: '', closeTime: '', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {id: 0, name: "TERÇA-FEIRA", openTime: '', closeTime: '', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {id: 0, name: "QUARTA-FEIRA", openTime: '', closeTime: '', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {id: 0, name: "QUINTA-FEIRA", openTime: '', closeTime: '', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {id: 0, name: "SEXTA-FEIRA", openTime: '', closeTime: '', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {id: 0, name: "SÁBADO", openTime: '', closeTime: '', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'}
    ])
    const [daysSelected, setDaysSelected] = useState<any>([])
    const [hourLimits, setHourLimits] = useState<any>([
        {index: 0, name: "DOMINGO", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 1, name: "SEGUNDA-FEIRA", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 2, name: "TERÇA-FEIRA", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 3, name: "QUARTA-FEIRA", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 4, name: "QUINTA-FEIRA", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 5, name: "SEXTA-FEIRA", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 6, name: "SÁBADO", openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'}
    ])

    function countTimes(str: string, ch: string) {
        return str.split(ch).length - 1;
    }

    function copyDays(day: any, diasSelecionados: any) {
        let diasReplace = daysSelected;
        for (let i = 0; i < diasReplace.length; i++) {
            for (let j = 0; j < diasSelecionados.length; j++) {
                if (diasSelecionados[j]) {
                    let label1 = diasReplace[i]?.name ? diasReplace[i]?.name : diasReplace[i]?.days[0];
                    let label2 = diasSelecionados[j]?.name ? diasSelecionados[j]?.name : diasSelecionados[j]?.days[0];

                    if (diasSelecionados[j]?.selectedToCopy && label1 === label2) {
                        diasReplace[i].openTime = day?.openTime;
                        diasReplace[i].closeTime = day?.closeTime;
                        if (!diasReplace[i].times) {
                            diasReplace[i].times = {}
                        }
                        diasReplace[i].times.workStartTime = countTimes(day?.openTime, ':') > 1 ? day?.openTime : day?.openTime + ':00';
                        diasReplace[i].times.workEndTime = countTimes(day?.closeTime, ':') > 1 ? day?.closeTime : day?.closeTime + ':00';
                    }
                }
            }
        }

        setDaysSelected(diasReplace);
        return;
    }

    async function getWeekDays(){
        // /milestone/GetName?frequent=true
        const userToken = localStorage.getItem('access_portal:access_token')
        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }

        try{
            const response = await api.get(`milestone/GetName?frequent=true`, config)
            //console.log("RESPONSE>>>", response);
            let openTimes = response.data.visitServiceProviders.openTimes;
            let activeDays = response.data.visitServiceProviders.activeDay;

            if (openTimes.length === 1 && activeDays.length === 1) {
                let newHourLimits = hourLimits;
                if (openTimes.length === 1) {
                    let openTimeSplit = openTimes[0].openTime.split(':');
                    let closeTimeSplit = openTimes[0].closeTime.split(':');

                    for (let i = 0; i < newHourLimits.length; i++) {
                        newHourLimits[i].openTime = openTimeSplit[0] + ':' + openTimeSplit[1];
                        newHourLimits[i].closeTime = closeTimeSplit[0] + ':' + closeTimeSplit[1];
                        newHourLimits[i].openTimeFormatted = openTimeSplit[0] + 'h' + openTimeSplit[1] + 'min';
                        newHourLimits[i].closeTimeFormatted = closeTimeSplit[0] + 'h' + closeTimeSplit[1] + 'min';
                    }
                    setHourLimits(newHourLimits);
                }

                let daysNew = days.filter((value: any) => {
                    return response.data.visitServiceProviders.activeDay[0].activeDays.includes(value.name)
                });

                //console.log("DAYSNEW", daysNew);
                setDays(daysNew);
            } else {
                let objComDiaEHora = []
                for (let i = 0; i < activeDays.length; i++) {
                    for (let j = 0; j < activeDays[i].activeDays.length; j++) {
                        let objetoNovo = {
                            day: activeDays[i].activeDays[j],
                            openTime: openTimes[i].openTime,
                            closeTime: openTimes[i].closeTime,
                        }
                        objComDiaEHora.push(objetoNovo);
                    }
                }
                
                setHourLimits(objComDiaEHora.map((e, i) => {
                    let openTimeSplit = e.openTime.split(':');
                    let closeTimeSplit = e.closeTime.split(':');
                    let objectToReturn = {
                        index: i,
                        openTime: openTimeSplit[0] + ':' + openTimeSplit[1],
                        closeTime: closeTimeSplit[0] + ':' + closeTimeSplit[1],
                        openTimeFormatted: openTimeSplit[0] + 'h' + openTimeSplit[1] + 'min',
                        closeTimeFormatted: closeTimeSplit[0] + 'h' + closeTimeSplit[1] + 'min',
                        name: e.day
                    };

                    return objectToReturn;
                }));

                setDays(objComDiaEHora.map((e, i) => {
                    let openTimeSplit = e.openTime.split(':');
                    let closeTimeSplit = e.closeTime.split(':');
                    let objectToReturn = {
                        id: 0,
                        name: e.day,
                        openTime: diasDaSemana.length > 1 ? openTimeSplit[0] + ':' + openTimeSplit[1] : '',
                        closeTime: diasDaSemana.length > 1 ? closeTimeSplit[0] + ':' + closeTimeSplit[1] : '',
                        openTimeFormatted: openTimeSplit[0] + 'h' + openTimeSplit[1] + 'min',
                        closeTimeFormatted: closeTimeSplit[0] + 'h' + closeTimeSplit[1] + 'min',
                    };
                    return objectToReturn;
                }));


                /*let newHourLimits = hourLimits;
                if (openTimes.length === 1) {
                    let openTimeSplit = openTimes[0].openTime.split(':');
                    let closeTimeSplit = openTimes[0].closeTime.split(':');

                    for (let i = 0; i < newHourLimits.length; i++) {
                        newHourLimits[i].openTime = openTimeSplit[0] + ':' + openTimeSplit[1];
                        newHourLimits[i].closeTime = closeTimeSplit[0] + ':' + closeTimeSplit[1];
                        newHourLimits[i].openTimeFormatted = openTimeSplit[0] + 'h' + openTimeSplit[1] + 'min';
                        newHourLimits[i].closeTimeFormatted = closeTimeSplit[0] + 'h' + closeTimeSplit[1] + 'min';
                    }
                    setHourLimits(newHourLimits);
                }*/

                /*let daysNew = days.filter((value: any) => {
                    return response.data.visitServiceProviders.activeDay[0].activeDays.includes(value.name)
                });

                setDays(daysNew);*/

            }
        }catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        //pega os labels de um endpoint
        getWeekDays();
    }, []);

    useEffect(() => {
        //setTimeout(function() {
        let diasDaSemana_aux = [...diasDaSemana];
        let days_aux = [...days];
        if(
            diasDaSemana_aux
            && Array.isArray(diasDaSemana_aux)
            && diasDaSemana_aux.length > 0
        ) {
            let daysfromFor: any[] = []

            if (diasDaSemana_aux) {

                setDaysSelected(diasDaSemana.map((val: any)=> {
                    let startTime;
                    let endTime;

                    if (val.times) {
                        startTime = val.times.workStartTime.replace(":00", "");
                        endTime = val.times.workEndTime.replace(":00", "");
                    } else {
                        startTime = val.openTime;
                        endTime = val.closeTime;
                    }
                    return {
                        ...val,
                        id: val.id,
                        name: (val.workDays && Array.isArray(val.workDays)) && val.workDays.length > 0 ? val.workDays[0] : '',
                        openTime: startTime,
                        closeTime: endTime,
                    }
                }));
            }
        }
    }, [days]) 

    return (
        <Container>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader paddingRight={"40px"}>
                <ModalCloseButton />
                Copiar horários
              </ModalHeader>                    
              <ModalBody>
                <FormCopyWeekTime
                    diasDaSemana={daysSelected}
                    copyDays={copyDays}
                    onClose={onClose}
                />
              </ModalBody>
              <ModalFooter
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDir={"row-reverse"}
              >
              </ModalFooter>
            </ModalContent>
          </Modal>
            {
                days.length > 0 &&
                <ContainerWeekDays>
                    <Title>Dias da semana</Title>
                    <SubContainerWeekDays>
                        <div className={'row1'}>
                        {
                            days.map((val: any, key: number) => {
                                return (
                                    <WeekDayButton
                                        selected={daysSelected?.filter((v: any) => {
                                            if (v.days) {
                                                return v.days[0]?.toUpperCase() === val.name?.toUpperCase()
                                            } else {
                                                return v.name?.toUpperCase() === val.name?.toUpperCase()
                                            }
                                        }).length > 0}
                                        key={key}
                                        onClick={() => {
                                            let newArr = []
                                            if(daysSelected?.filter((v: any) => {
                                                    if (v.days) {
                                                        return v.days[0] === val.name
                                                    } else {
                                                       return v.name === val.name 
                                                    }
                                                }).length === 0){
                                                newArr = daysSelected

                                                newArr.push({ ...val, key: key })
                                            }else{
                                                newArr = daysSelected?.filter((v: any) => {
                                                    //v.days[0] !== val.name
                                                    if (v.days) {
                                                        return v.days[0] !== val.name
                                                    } else {
                                                       return v.name !== val.name 
                                                    }
                                                })
                                            }
                                            
                                            setDaysSelected([...newArr])
                                            setDiasDaSemana([...newArr])

                                        }}
                                    >
                                        {val.name?.substring(0, 3).toLowerCase()}
                                    </WeekDayButton>
                                )
                            })
                        }
                        </div>
                        {daysSelected.length > 1 &&
                            <div className="buttonCopyWrapper">
                                <Button onClick={onOpen}>Copiar horários entre dias</Button>
                            </div>
                        }
                        <div className={'row2'}>
                        {
                            daysSelected
                                .sort( ( a: any, b: any ) => {
                                    if ( a.key < b.key ){
                                      return -1;
                                    }
                                    if ( a.key > b.key ){
                                      return 1;
                                    }
                                    return 0;
                                  } )
                                .map((val: any, index: any)=> {
                                    let minHour = '00:00';
                                    let maxHour = '23:59';
                                    let minHourFormatted = '00h00min';
                                    let maxHourFormatted = '23h59min';
                                    let nomeDoDia = val.name ? val.name : val.days[0];
                                    let currentHourLimit = hourLimits.filter((hourLim: any) => {
                                        return hourLim.name === nomeDoDia
                                    })[0];
                                    if (currentHourLimit) {
                                        minHour = currentHourLimit.openTime;
                                        maxHour = currentHourLimit.closeTime;
                                        minHourFormatted = currentHourLimit.openTimeFormatted;
                                        maxHourFormatted = currentHourLimit.closeTimeFormatted;
                                    }
                                    return (
                                        <Card style={{marginTop: '10px'}}>
                                            <CardHeader>
                                                <Heading size='md'>{val.days ? val.days[0] : val.name}</Heading>
                                            </CardHeader>
                                            <CardBody>
                                                <Box
                                                        w={["100%", "100%"]}
                                                        style={{
                                                            marginTop: -25,
                                                            backgroundColor: '#FFF', 
                                                            padding: 0, 
                                                            borderRadius: 10, 
                                                            display: 'flex', 
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between'
                                                        }}
                                                >
                                                    <ContainerFieldTime>
                                                        <TimeFieldLabel>Horário de entrada</TimeFieldLabel>
                                                        <TimePicker 
                                                            className={'time-picker-custom-class'}
                                                            clearIcon={null}
                                                            onInvalidChange={(e: any) => {

                                                            }}
                                                            onChange={(e) => {
                                                                let daysSelectedNovo = daysSelected;

                                                                for (let i = 0; i < daysSelectedNovo.length; i++) {
                                                                    if (daysSelectedNovo[i].key === val.key) {
                                                                        daysSelectedNovo[i].openTime = e;
                                                                        if (daysSelectedNovo[i].times) {
                                                                            daysSelectedNovo[i].times.workStartTime = e;
                                                                        }
                                                                        if (e) {
                                                                            let horaDividida = e.toString().split(":");
                                                                            daysSelectedNovo[i].openTimeFormatted = horaDividida[0] + "h" + horaDividida[1] + "min";
                                                                        }
                                                                    }
                                                                }

                                                                setDiasDaSemana([daysSelectedNovo])
                                                            }}
                                                            value={daysSelected?.filter((v: any) => {
                                                                    if (v.days && v.days.length === 1) {
                                                                        if (val.days) {
                                                                            return v.days[0]?.toUpperCase() === val.days[0]?.toUpperCase();
                                                                        } else {
                                                                            return v.days[0]?.toUpperCase() === val.name?.toUpperCase();
                                                                        }
                                                                    } else {
                                                                        //return v.name?.toUpperCase() === val.days[0]?.toUpperCase();
                                                                        if (val.days) {
                                                                            return v.name?.toUpperCase() === val.days[0]?.toUpperCase();
                                                                        } else {
                                                                            return v.name?.toUpperCase() === val.name?.toUpperCase();
                                                                        }
                                                                    }
                                                                    
                                                                }).length > 0 ? daysSelected?.filter((v: any) => {
                                                                    if (v.days && v.days.length === 1) {
                                                                        //return v.days[0]?.toUpperCase() === val.days[0]?.toUpperCase();
                                                                        if (val.days) {
                                                                            return v.days[0]?.toUpperCase() === val.days[0]?.toUpperCase();
                                                                        } else {
                                                                            return v.days[0]?.toUpperCase() === val.name?.toUpperCase();
                                                                        }
                                                                    } else {
                                                                        //return v.name?.toUpperCase() === val.days[0]?.toUpperCase();
                                                                        if (val.days) {
                                                                            return v.name?.toUpperCase() === val.days[0]?.toUpperCase();
                                                                        } else {
                                                                            return v.name?.toUpperCase() === val.name?.toUpperCase();
                                                                        }
                                                                    }
                                                                }).map((v: any) => {
                                                                //if (v.days[0]?.toUpperCase() === val.name?.toUpperCase()) {
                                                                    let hour;
                                                                    if (val.times && val.times.workStartTime) {
                                                                        hour = val.times.workStartTime?.replace(':00', '');
                                                                    } else {
                                                                        hour = val.openTime;
                                                                    }
                                                                    
                                                                    if (hour) {
                                                                        if (hour.includes(":")) {
                                                                            return hour;
                                                                        } else {
                                                                            return hour + ":00";
                                                                        } 
                                                                    }
                                                            })[0] : ''}
                                                            disableClock={true} 
                                                            maxTime={maxHour}
                                                            minTime={minHour}
                                                        />
                                                    </ContainerFieldTime>
                                                    <ContainerFieldTime>
                                                        <TimeFieldLabel>Horário de saída</TimeFieldLabel>
                                                        <TimePicker 
                                                            className={'time-picker-custom-class'}
                                                            clearIcon={null}
                                                            onChange={(e) => {
                                                                let daysSelectedNovo = daysSelected;

                                                                for (let i = 0; i < daysSelectedNovo.length; i++) {
                                                                    if (daysSelectedNovo[i].key === val.key) {
                                                                        daysSelectedNovo[i].closeTime = e;
                                                                        if (daysSelectedNovo[i].times) {
                                                                            daysSelectedNovo[i].times.workEndTime = e;
                                                                        }
                                                                        if (e) {
                                                                            let horaDividida = e.toString().split(":");
                                                                            daysSelectedNovo[i].closeTimeFormatted = horaDividida[0] + "h" + horaDividida[1] + "min";
                                                                        }

                                                                    }
                                                                }

                                                                setDiasDaSemana([daysSelectedNovo])
                                                            }}
                                                            value={daysSelected?.filter((v: any) => {
                                                                    if (v.days && v.days.length === 1) {
                                                                        if (val.days) {
                                                                            return v.days[0]?.toUpperCase() === val.days[0]?.toUpperCase();
                                                                        } else {
                                                                            return v.days[0]?.toUpperCase() === val.name?.toUpperCase();
                                                                        }
                                                                    } else {
                                                                        if (val.days) {
                                                                            return v.name?.toUpperCase() === val.days[0]?.toUpperCase();
                                                                        } else {
                                                                            return v.name?.toUpperCase() === val.name?.toUpperCase();
                                                                        }
                                                                    }
                                                                }).length > 0 ? daysSelected?.filter((v: any) => {
                                                                    if (v.days && v.days.length === 1) {
                                                                        if (val.days) {
                                                                            return v.days[0]?.toUpperCase() === val.days[0]?.toUpperCase();
                                                                        } else {
                                                                            return v.days[0]?.toUpperCase() === val.name?.toUpperCase();
                                                                        }
                                                                    } else {
                                                                        if (val.days) {
                                                                            return v.name?.toUpperCase() === val.days[0]?.toUpperCase();
                                                                        } else {
                                                                            return v.name?.toUpperCase() === val.name?.toUpperCase();
                                                                        }
                                                                    }
                                                                }).map((v: any) => {
                                                                    let hour;
                                                                    
                                                                    if (val.times && val.times.workEndTime) {
                                                                        hour = val.times.workEndTime?.replace(':00', '');
                                                                    } else {
                                                                        hour = val.closeTime;
                                                                    }
                                                                    
                                                                    if (hour) {
                                                                        if (hour.includes(":")) {
                                                                            return hour;
                                                                        } else {
                                                                            return hour + ":00";
                                                                        } 
                                                                    }

                                                                })[0] : ''}
                                                            disableClock={true}
                                                            maxTime={maxHour}
                                                            minTime={minHour}                                                            
                                                        />
                                                    </ContainerFieldTime>
                                                </Box> 
                                                <CardFooter
                                                    style={{
                                                        padding: 0,
                                                        marginTop: 20
                                                    }}
                                                >
                                                    <WeekFooterLabel>
                                                        Horário possível de seleção: das&nbsp;{minHourFormatted} às&nbsp;{maxHourFormatted}.
                                                    </WeekFooterLabel>
                                                </CardFooter>
                                            </CardBody>
                                        </Card>
                                    )
                                })
                        }
                        </div>
                    </SubContainerWeekDays>
                </ContainerWeekDays>
                
            }
        </Container>
    )
}