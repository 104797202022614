import styled from 'styled-components'

export const NoDataMessageContainer = styled.div`
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ButtonsListContainer = styled.div`
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`