import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { useLocation, useNavigate } from 'react-router-dom'

import jwt_decode from "jwt-decode"

import { validateDate } from '../../../../../utils/validates'

import { toast } from 'react-toastify'

import Select, { SingleValue } from 'react-select'

import { 
    Box,
    Text,
    Button,
    FormControl,
    FormLabel,
    Switch,
    Input,
    Grid,
    GridItem,
    Flex,
    Select as SelectChakra,
    Checkbox,
    useDisclosure,
} from '@chakra-ui/react'

import { RootState } from '../../../../../store'


import { validateEmail } from '../../../../../utils/validates'

import { InputPhone } from '../../../../../components/InputPhone'

import api from '../../../../../services/api'

import { ref, set } from "@firebase/database"

import { FirebaseRealtimeDatabase } from '../../../../../services/firebase'

import { IUserData } from '../../../../../interfaces/IUserData'
import { BoxWithBorder } from '../../../../../components/BoxWithBorder'
import { ImageUpload } from '../../../../../components/ImageUpload'
import { current } from '@reduxjs/toolkit'
import { setCurrentGuest, resetCurrentGuest } from '../../../../../store/reducers/guest'
import { MdUpdate } from 'react-icons/md'
import { ComboContacts } from '../../../../../components/ComboContacts'
import { InputRg } from '../../../../../components/InputRg'
import { DateComponent } from '../../../../../components/Fields/DateComponent'
import { formatData } from '../../../../../utils/format-data'
import { WeekDays } from '../WeekDays'
import { WeekDaysCollaborator } from '../WeekDaysCollaborator'
import { WeekDaysFamiliar } from '../WeekDaysFamiliar'
import { Container, ContainerWeekDays, SubContainerWeekDays, Title, WeekDayButton, TimeFieldLabel, WeekFooterLabel, ContainerFieldTime } from '../WeekDaysFamiliar/styles'
import TimePicker from 'react-time-picker';
import { Avatar } from '../../MyDate/components/Avatar';

interface IDiasDaSemana {
    days: any;
    times: any;
    openTime?: any;
    closeTime?: any;
    name?: any;
}

interface IOptions {
    readonly value: string;
    readonly label: string;
}

let parentescoM = [
    {label: "Pai", value: "Pai"},
    {label: "Irmão", value: "Irmão"},
    {label: "Filho", value: "Filho"},
    {label: "Marido", value: "Marido"},
    {label: "Cunhado", value: "Cunhado"},
    {label: "Tio", value: "Tio"},
    {label: "Afilhado", value: "Afilhado"},
    {label: "Sobrinho", value: "Sobrinho"},
    {label: "Avô", value: "Avô"},
    {label: "Amigo", value: "Amigo"},
    {label: "Primo", value: "Primo"},
  ]
let parentescoF =  [
    {label: "Mãe", value: "Mãe"},
    {label: "Irmã", value: "Irmã"},
    {label: "Filha", value: "Filha"},
    {label: "Esposa", value: "Esposa"},
    {label: "Cunhada", value: "Cunhada"},
    {label: "Tia", value: "Tia"},
    {label: "Afilhada", value: "Afilhada"},
    {label: "Sobrinha", value: "Sobrinha"},
    {label: "Avó", value: "Avó"},
    {label: "Amiga", value: "Amiga"},
    {label: "Prima", value: "Prima"},
  ]

export function GuestForm () {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {state} = useLocation();

    const { guestType, guestTypeEntrance } = state

    const currentGuest = useSelector((state: RootState) => state.guest.data)

    const [disabledSaveButton, setDisabledSaveButton] = useState(false)
    const [companys, setCompanys] = useState([])
    const [maxVisitTimeDateObject, setMaxVisitTimeDateObject] = useState(new Date())
    const [showModalWithoutMaxVisitTime, setShowModalWithoutMaxVisitTime] = useState(false)
    const [maxVisitTime, setMaxVisitTime] = useState(false)
    const [facialByWebcam, setFacialByWebcam] = useState(false)
    const [enableFacial, setEnableFacial] = useState(false)

    const [startDateError, setStartDateError] = useState('')
    const [endDateError, setEndDateError] = useState('')
    const [diasDaSemana, setDiasDaSemana] = useState<IDiasDaSemana[]>([])
    const [diasDaSemanaSelecteds, setDiasDaSemanaSelecteds] = useState<string[]>([])
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactCellphone, setContactCellphone] = useState('');
    const [changePhoto, setChangePhoto] = useState(false);

    const [editing, setEditing] = useState(false)
    const [visitanteHoraEntrada, setVisitanteHoraEntrada] = useState('');
    const [visitanteHoraSaida, setVisitanteHoraSaida] = useState('');
    const [hourLimits, setHourLimits] = useState([
        {index: 0, name: 'DOMINGO', openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 1, name: 'SEGUNDA-FEIRA', openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 2, name: 'TERÇA-FEIRA', openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 3, name: 'QUARTA-FEIRA', openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 4, name: 'QUINTA-FEIRA', openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 5, name: 'SEXTA-FEIRA', openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'},
        {index: 6, name: 'SÁBADO', openTime: '00:00', closeTime: '23:59', openTimeFormatted: '00h00min', closeTimeFormatted: '23h59min'}
    ]);

    const { isOpen, onOpen, onClose } = useDisclosure();

    //const [virtualDoorman, setVirtualDoorman] = useState(false);

    const [editPic, setEditPic] = useState(false);

    const [exludePic, setExludePic] = useState(false);
 
    const [isLoading, setIsLoading] = useState(true);

    const [cancellLGPD, setCancellLGPD] = useState(false);

    const [loadingMiddlewarePhotoValidation, setLoadingMiddlewarePhotoValidation] = useState(false)
    const [image, setImage] = useState('');

    const [oldData, setOldData] = useState<any>(null);

    const [contactsList, setContactsList] = useState<any>([]);

    const [periodHasChanged,  setPeriodHasChanged] = useState(false)

    const [shouldShow, setShouldShow] = useState(true);

    async function getWeekDays(){
        // /milestone/GetName?frequent=true
        const userToken = localStorage.getItem('access_portal:access_token')
        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }

        try{
            const response = await api.get(`milestone/GetName?frequent=true`, config)
            console.log("RESPONSE===>", response.data);
            if (!response.data) {
                toast.error("Não há definição para os dias e horários de entrada e saída de colaboradores.");
                navigate('/my-profile', {
                    state: {
                        index: 2
                    }
                })

                return false;
            }

            setTimeout(() => {
                setShouldShow(true);
            }, 200);
            let openTimes = response.data.visitServiceProviders.openTimes;
            let activeDays = response.data.visitServiceProviders.activeDay;
            let newHourLimits = hourLimits;
            if (openTimes.length === 1 && activeDays.length === 1) {
                let openTimeSplit = openTimes[0].openTime.split(':');
                let closeTimeSplit = openTimes[0].closeTime.split(':');

                for (let i = 0; i < newHourLimits.length; i++) {
                    newHourLimits[i].openTime = openTimeSplit[0] + ':' + openTimeSplit[1];
                    newHourLimits[i].closeTime = closeTimeSplit[0] + ':' + closeTimeSplit[1];
                    newHourLimits[i].openTimeFormatted = openTimeSplit[0] + 'h' + openTimeSplit[1] + 'min';
                    newHourLimits[i].closeTimeFormatted = closeTimeSplit[0] + 'h' + closeTimeSplit[1] + 'min';
                }
                setHourLimits(newHourLimits);
            } else {
                let objComDiaEHora = []
                for (let i = 0; i < activeDays.length; i++) {
                    for (let j = 0; j < activeDays[i].activeDays.length; j++) {
                        let objetoNovo = {
                            day: activeDays[i].activeDays[j],
                            openTime: openTimes[i].openTime,
                            closeTime: openTimes[i].closeTime,
                        }
                        objComDiaEHora.push(objetoNovo);
                    }
                }
                setHourLimits(objComDiaEHora.map((e, i) => {
                    let openTimeSplit = e.openTime.split(':');
                    let closeTimeSplit = e.closeTime.split(':');
                    let objectToReturn = {
                        index: i,
                        openTime: openTimeSplit[0] + ':' + openTimeSplit[1],
                        closeTime: closeTimeSplit[0] + ':' + closeTimeSplit[1],
                        openTimeFormatted: openTimeSplit[0] + 'h' + openTimeSplit[1] + 'min',
                        closeTimeFormatted: closeTimeSplit[0] + 'h' + closeTimeSplit[1] + 'min',
                        name: e.day
                    };

                    return objectToReturn;
                }));
            }
        }catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getCompanys()
        getGuestGeneralRule()
        //getWeekDays()
        getFacialByWebcam()

        if (state.id) {
            setEditing(true);
            let currentGuestNovo = state.id;
            currentGuestNovo.contactId = 0;
            currentGuestNovo.id = state.id.id;

            if (guestType !== 'COLABORADOR') {
                if (state.id.file && state.id.file.data) {
                    setImage(state.id.file.data);
                }
            } else {
                if (state.id.image && state.id.image.data) {
                    setImage(state.id.image.data);
                }
            }
            currentGuestNovo.contactId = state.id.contact.id;
            currentGuestNovo.functionName = state.id.work;
            setContactName(state.id.contact.name)
            setContactEmail(state.id.contact.email)
            if (state.id.contact.cellPhone) {
                setContactCellphone(state.id.contact.cellPhone)
            } else {
                setContactCellphone(state.id.contact.cellNumber)
            }
            let startDate = state.id.starDate?.split('T')[0];
            let endDate = state.id.endDate?.split('T')[0];

            let startDateSplit = startDate?.split('-');
            let endDateSplit = endDate?.split('-');

            if (startDateSplit) {
                if (startDateSplit.includes('/')) {
                    currentGuestNovo.startDate = startDateSplit;
                } else {
                    currentGuestNovo.startDate = startDateSplit[2] + '/' + startDateSplit[1] + '/' + startDateSplit[0];
                }
            }

            if (endDateSplit) {
                if (endDateSplit.includes('/')) {
                    currentGuestNovo.endDate = endDateSplit;
                } else {
                    currentGuestNovo.endDate = endDateSplit[2] + '/' + endDateSplit[1] + '/' + endDateSplit[0];
                }
            }

            if (guestType !== 'COLABORADOR') {

                setDiasDaSemanaSelecteds(currentGuestNovo.visitDays);
                setVisitanteHoraEntrada(state.id.visitStarTime);
                setVisitanteHoraSaida(state.id.visitEndTime);
            }

            if (guestType === 'COLABORADOR') {
                setDiasDaSemana(removeRepeatedDays(state.id.contactFrequentWorkerDaysTimes));
            }

            if (currentGuestNovo.startDate.includes("T")) {
                let startDateSplit1 = currentGuestNovo.startDate.split("T");
                let startDateSplit2 = startDateSplit1[0].split("-");
                currentGuestNovo.startDate = startDateSplit2[2] + '/' + startDateSplit2[1] + '/' + startDateSplit2[0];
            }

            dispatch(setCurrentGuest({
                ...currentGuest,
                contactId: currentGuestNovo.contactId,
                endDate: currentGuestNovo.endDate,
                functionName: currentGuestNovo.functionName,
                id: currentGuestNovo.id,
                rg: currentGuestNovo.rg,
                startDate: currentGuestNovo.startDate,
                gender: currentGuestNovo.gender,
                relationship: currentGuestNovo.relationship,
                maxVisitTimeActive: !!currentGuestNovo.maxVisitTimeActive,
                outsourced: state.id.guestCompanyId ? true : false,
                guestTypeEntrance: !!currentGuestNovo.guestTypeEntrance,
                companyId: state.id.guestCompanyId,
            }));

            if (guestType !== 'COLABORADOR') {
                setOldData({
                    startDate: currentGuest.startDate.replaceAll('undefined/', ''),
                    endDate: currentGuest.endDate.replaceAll('undefined/', ''),
                    visitStartTime: state.id.visitStarTime,
                    visitEndTime: state.id.visitEndTime,
                    weekDays: currentGuestNovo.visitDays,
                    companyId: state.id.guestCompanyId
                })
            } else {
                setOldData({
                    startDate: currentGuest.startDate.replaceAll('undefined/', ''),
                    endDate: currentGuest.endDate.replaceAll('undefined/', ''),
                    weekDays: state.id.contactFrequentWorkerDaysTimes,
                });
            }
        }

        if (guestType === 'COLABORADOR') {
            setShouldShow(false);
            getWeekDays()
        }
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try{
                const config = {
                    headers: {
                        Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                    }
                }
                const { data } = await api.get('/contact/getContactsByUser', config)
                setContactsList(data)
            }catch(e) {

            }
        }
        fetchData();
    }, [])

    const getValueRelationship = (val: string) => {
        if(val){

            if(currentGuest.gender == 'Masculino'){
                for(let i in parentescoM){
                    if(val == parentescoM[i].label){
                        return({
                            value: parentescoM[i].value, 
                            label: parentescoM[i].label
                        })
                    }
                }
            }else{
                for(let i in parentescoF){
                    if(val == parentescoF[i].label){
                        return({
                            value: parentescoF[i].value, 
                            label: parentescoF[i].label
                        })
                    }
                }
            }
        }else{
            return({
                value: '', 
                label: '' 
            })
        }
    }

    function removeRepeatedDays(diasDaSemana: any) {
        //cria um array de 7 dias da semana, mas com horario vazio
        //para cada dia da semana, vasculhe por todo o array de diasDaSemana
        //quando achar o primeiro item com o dia da semana, substitua-o no array de 7 dias da semana
        //apos os loops, remova todos os dias da semana com horario vazio
        //retorne o array de dias da semana

        let diasEmpty = [
            {id: 0, days: ['DOMINGO'], times: {workEndTime: "", workStartTime: ""}, key: 0},
            {id: 0, days: ['SEGUNDA-FEIRA'], times: {workEndTime: "", workStartTime: ""}, key: 1},
            {id: 0, days: ['TERÇA-FEIRA'], times: {workEndTime: "", workStartTime: ""}, key: 2},
            {id: 0, days: ['QUARTA-FEIRA'], times: {workEndTime: "", workStartTime: ""}, key: 3},
            {id: 0, days: ['QUINTA-FEIRA'], times: {workEndTime: "", workStartTime: ""}, key: 4},
            {id: 0, days: ['SEXTA-FEIRA'], times: {workEndTime: "", workStartTime: ""}, key: 5},
            {id: 0, days: ['SÁBADO'], times: {workEndTime: "", workStartTime: "", key: 6}}
        ];

        for (let i = 0; i < diasEmpty.length; i++) {
            for (let j = 0; j < diasDaSemana.length; j++) {
                if (diasDaSemana[j].workDays && diasDaSemana[j].workDays[0]) {
                    if (diasEmpty[i].days[0].toUpperCase() === diasDaSemana[j].workDays[0].toUpperCase()) {

                        diasEmpty[i].id = diasDaSemana[j].id;
                        diasEmpty[i].times.workStartTime = diasDaSemana[j].workStartTime;
                        diasEmpty[i].times.workEndTime = diasDaSemana[j].workEndTime;
                        break;
                    }
                }                
            }
        }

        for (let i = diasEmpty.length - 1; i >= 0; i--) {
            if (diasEmpty[i].times.workStartTime === "" && diasEmpty[i].times.workEndTime === "") {
                diasEmpty.splice(i, 1);
            }
        }

        return diasEmpty;
    }

    async function getFacialByWebcam() {
        const config = {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`,
                'Content-Type': 'application/json; charset=utf-8'
            }
        }

        const response = await api.get(`GuestGeneralRule/get`, config);
        //console.log("RESPONSE>>>>>1", response);

        setFacialByWebcam(response.data.facialByWebcam);
        setEnableFacial(response.data.enableFacial);
    }

     function getImage() {
        if(image){
            const imageSplit = image.split('base64,')
            return 'data:image/png;base64,' + imageSplit[imageSplit.length - 1]
        }

        return ''
    }

    async function getCompanys() {
        const userToken = localStorage.getItem('access_portal:access_token')
        const decoded = jwt_decode(userToken as string) as IUserData
        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        try {
        
            const { data } = await api.get('/guest/getCompanyByClient/' + decoded.client_id, config);

            setCompanys(data.map((v: any) => {
                return {
                    value: v.id,
                    label: v.companyFancyName
                }
            }))
        
        }catch(e) {
        }
    }

    async function getGuestGeneralRule() {
        const userToken = localStorage.getItem('access_portal:access_token')
        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        try {
        
            const { data } = await api.get('/GuestGeneralRule/get', config);

            
            //MaxVisitTimeDate
            let newMaxVisitTimeDateObject = maxVisitTimeDateObject
            newMaxVisitTimeDateObject.setMonth(newMaxVisitTimeDateObject.getMonth() + data.maxVisitTime)
            setMaxVisitTimeDateObject(newMaxVisitTimeDateObject)

            if(data.maxVisitTime === 0){
                setShowModalWithoutMaxVisitTime(true)
            }

            setMaxVisitTime(data.maxVisitTime === 0? null: data.maxVisitTime)
            //console.log("RESPONSE>>>>>2", data);
            setFacialByWebcam(data.facialByWebcam)
            setEnableFacial(data.enableFacial)
             
        }catch(e) {
        }
    }

    

    function getCreateOn () {
        let data = new Date();
        let mes = data.getMonth()+1>9 ? data.getMonth()+1 : '0' + (data.getMonth()+1)
        return data.getFullYear()+'-'+mes+'-'+data.getDate()
    }

    function format(date :string) {
        let dateSplit = date.replaceAll('undefined/', '').split('/');

        return (dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0])
    }

    function getDeletedDays(weekDays: any) {
        //percorre por toda a oldData.weekDays. para cada item, vê se ele existe em weekDays. se ele não existir, adicione ele em arraysToDelete
        for (let i = 0; i < oldData.weekDays.length; i++) {

            let persiste = false;

            for (let j = 0; j < weekDays.length; j++) {
                if (weekDays[j].name.toLowerCase() === oldData.weekDays[i].workDays[0]) {
                    persiste = true;

                    break;
                }
            }

            if (!persiste) {
                if (oldData.weekDays[i].workDays) {
                    weekDays.push({
                        id: oldData.weekDays[i].id,
                        workDays: [oldData.weekDays[i].workDays[0]],
                        isDeleted: true
                    });
                } else {
                    weekDays.push({
                        id: oldData.weekDays[i].id,
                        workDays: [oldData.weekDays[i].name],
                        isDeleted: true
                    });
                }
            }

        }
    }

    async function save() {
        //validar nome, celular e e-mail
        //----nome é obrigatório
        //----celular e e-mail são opcionais, mas ao menos um desses dois campos precisa estar preenchido
        //----nenhum desses três campos pode ter um valor que já esteja sendo utilizado por algum contato cadastrado pelo usuário logado
        if (editing) {
            if (!contactName) {
                return toast.error('O nome de contato é obrigatório');
            }

            if (!contactEmail && !contactCellphone) {
                return toast.error('É necessário informar o e-mail ou o celular');
            }
        }

        for (let i = 0; i < contactsList.length; i++) {
            if (editing) {
                if (contactsList[i].name?.toUpperCase() === contactName.toUpperCase() && (contactsList[i].id !== currentGuest.contactId)) {
                    return toast.error("Já existe um contato com esse nome.");
                }
            } else {
                if (contactsList[i].name?.toUpperCase() === contactName.toUpperCase()) {
                    return toast.error("Já existe um contato com esse nome.");
                }
            }
        }

        //console.log("entrei1");

        //validar se valores dos campos estão repetidos

        /*if (!facialByWebcam && (enableFacial && !image)) {
            return toast.error('A foto do visitante é obrigatória');
        }*/

        let agora = new Date();

        if (new Date(format(currentGuest.startDate)).toString() === "Invalid Date") {
            return toast.error('A data de início é inválida');
        }

        if (new Date(format(currentGuest.endDate)).toString() === "Invalid Date") {
            return toast.error('A data de fim é inválida');
        }

        const agoraFormatted = agora.getDate().toString().padStart(2, '0') + '/' + (agora.getMonth() + 1).toString().padStart(2, '0') + '/' + agora.getFullYear();

        let entradaASerUsada = visitanteHoraEntrada;

        if (guestType === 'COLABORADOR') {
            entradaASerUsada = agora.getHours().toString().padStart(2, '0') + ':' + agora.getMinutes().toString().padStart(2, '0') + ':' + agora.getSeconds().toString().padStart(2, '0');
        }

        let saidaASerUsada = visitanteHoraSaida;

        if (guestType === 'COLABORADOR') {
            saidaASerUsada = agora.getHours().toString().padStart(2, '0') + ':' + agora.getMinutes().toString().padStart(2, '0') + ':' + agora.getSeconds().toString().padStart(2, '0');
        }

        // console.log("AGORA>>>>", agoraFormatted, currentGuest.startDate, entradaASerUsada, new Date(format(currentGuest.startDate) + ' ' + entradaASerUsada));

        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate:any = new Date(format(currentGuest.startDate));
        const secondDate:any = new Date(format(currentGuest.endDate));

        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

        if (agoraFormatted === currentGuest.startDate) {
            if (diffDays < 6 && agora > new Date(format(currentGuest.startDate) + ' ' + entradaASerUsada) ) {
                return toast.error('O horário informado para a data de início já passou. Por favor, informe um horário maior que o atual ou altere a data de início.');
            }
        }

        if (agoraFormatted !== currentGuest.startDate) {
            if (agora > new Date(format(currentGuest.startDate) + ' ' + entradaASerUsada) ) {
                return toast.error('A data de início não pode ser anterior à data atual');
            }
        }

        if (agoraFormatted !== currentGuest.endDate) {
            if (agora > new Date(format(currentGuest.endDate) + ' ' + saidaASerUsada) ) {
                return toast.error('A data de fim não pode ser anterior à data atual');
            }
        }

        if (guestType !== 'COLABORADOR') {
            if ( new Date(format(currentGuest.startDate)) > new Date(format(currentGuest.endDate)) ) {
                return toast.error('A data de fim não pode ser anterior à data de início');
            }
        }

        if (format(currentGuest.startDate) === format(currentGuest.endDate) ) {
            for (let j = 0; j < diasDaSemana.length; j++) {
                let hora1 = diasDaSemana[j].times.openTime.split(':'); //23:00
                let hora2 = diasDaSemana[j].times.closeTime.split(':'); //23:25
                if ((hora2[0] < hora1[0] ) || (hora2[0] === hora1[0] && hora2[1] < hora1[1])) {
                    return toast.error('O(s) horário(s) de saída não pode(m) ser anteriores ao(s) horário(s) de entrada');
                }
            }
        }

        if (guestType !== 'COLABORADOR') {
            if (!diasDaSemanaSelecteds || diasDaSemanaSelecteds?.length < 1) {
                return toast.error('É necessário escolher ao menos um dia da semana');
            }

            if (!validateHours(visitanteHoraEntrada, visitanteHoraSaida)) {
                return toast.error('É necessário escolher horários válidos');
            }
        }

        //verifica se a data de fim excede o prazo máximo de liberação
        if (new Date(format(currentGuest.endDate)) > maxVisitTimeDateObject) {
            return toast.error('A data de fim não pode exceder o prazo máximo de liberação');
        }



        //se o visitante eh um colaborador, verifica se os horarios estao dentro dos limites vindos do endpoint
        if (guestType === 'COLABORADOR') {
            let diasDaSemanaFormatado1 = diasDaSemana;
            if (Array.isArray(diasDaSemanaFormatado1[0])) {
                diasDaSemanaFormatado1 = diasDaSemanaFormatado1[0]
            }
            for (let i = 0; i < diasDaSemanaFormatado1.length; i++) {
                let hora1, hora2;
                if (diasDaSemanaFormatado1[i].times) {
                    hora1 = diasDaSemanaFormatado1[i].times.openTime ? diasDaSemanaFormatado1[i].times.openTime.split(':') : diasDaSemanaFormatado1[i].times.workStartTime.split(':'); //23:00
                    hora2 = diasDaSemanaFormatado1[i].times.closeTime ? diasDaSemanaFormatado1[i].times.closeTime.split(':') : diasDaSemanaFormatado1[i].times.workEndTime.split(':'); //23:25
                } else {
                    if (!diasDaSemanaFormatado1[i]?.openTime || !diasDaSemanaFormatado1[i]?.closeTime) {
                        return toast.error("Por favor, verifique os horários de entrada e saída");
                    }
                    hora1 = diasDaSemanaFormatado1[i]?.openTime.split(':');//23:00
                    hora2 = diasDaSemanaFormatado1[i]?.closeTime.split(':');//23:25
                }
                let dayTimeLimits = hourLimits.filter(a => {
                    if (diasDaSemanaFormatado1[i].days) {
                        return a.name === diasDaSemanaFormatado1[i]?.days[0];
                    } else {
                        return a.name === diasDaSemanaFormatado1[i].name;
                    }
                })[0];
                let minLimitTime = new Date('2024-01-05 ' + dayTimeLimits.openTime);
                let maxLimitTime = new Date('2024-01-05 ' + dayTimeLimits.closeTime);
                let userInputStart = new Date('2024-01-05 ' + hora1[0] + ':' + hora1[1]);
                let userInputEnd = new Date('2024-01-05 ' + hora2[0] + ':' + hora2[1]);
                if (
                    (userInputStart.getTime() < minLimitTime.getTime() || userInputStart.getTime() > maxLimitTime.getTime()) ||
                    (userInputEnd.getTime() < minLimitTime.getTime() || userInputEnd.getTime() > maxLimitTime.getTime())
                ) {
                    return toast.error("Por favor, verifique os horários de entrada e saída");
                }
            }
        }
        //return false;


        //console.log("entrei2");

        try{
            //const periodHasChanged = checkIfThePeriodHasChanged();
            //return;
            setDisabledSaveButton(true)

            const userToken = localStorage.getItem('access_portal:access_token')

            const decoded = jwt_decode(userToken as string) as IUserData
  
            const config = {
                headers: {
                    Authorization: `Bearer ${ userToken }`
                }
            }

            //recupera o valor do virtualDoorman a partir de um endpoint;
            let virtualDoorman = false;

            const config2 = {
                headers: {
                    Authorization: `Bearer ${ userToken }`,
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }

            const { data: informPredictedTypes } = await api.get('guestGeneralRule/informPredictedTypes', config2)

            //console.log("entrei3", informPredictedTypes);

            let informPredictedTypesFilter = []
            if(guestType !== 'COLABORADOR') {
                informPredictedTypesFilter = informPredictedTypes.filter((v: any) => v.guestType === 'FREQUENTE')
            }
            if(guestType === 'COLABORADOR') {
                informPredictedTypesFilter = informPredictedTypes.filter((v: any) => v.guestType === 'COLABORADOR')
            }
            if(informPredictedTypesFilter.length > 0) {
                virtualDoorman = informPredictedTypesFilter[0].virtualDoorman
            }

            //console.log("entrei3.1");

            let toSend: any = {
              clientId: decoded.client_id,
              startDate: format(currentGuest.startDate),
              endDate: format(currentGuest.endDate),
              rg: currentGuest.rg.replace(/[^0-9]/g, ''),
              maxVisitTimeActive: currentGuest.maxVisitTimeActive,
              guestType: guestType,
              permanent: true,
              contactId: currentGuest.contactId,
              virtualDoorman: virtualDoorman,
              VirtualDoormanEnable: virtualDoorman,
            }

            //console.log("entrei3.2", contactCellphone);

            let contactCellphoneFormatted = contactCellphone;

            if (contactCellphone && !contactCellphone.match(/^\+[1-9]{2}\s\([1-9]{2}\)\s(?:[2-8]|9[0-9])[0-9]{3}\-[0-9]{4}$/)) {
                //formata o celular conforme o necessario
                let contactCellphoneUnformatted = contactCellphone?.replace(/\D/g,'');
                //55 11 9 8183 3369
                contactCellphoneFormatted = '+' + contactCellphoneUnformatted.substring(0, 2) + ' (' + contactCellphoneUnformatted.substring(2, 4) + ') ';
                if (contactCellphoneUnformatted.charAt(4) === '9' && contactCellphoneUnformatted.length === 13) {
                    contactCellphoneFormatted += contactCellphoneUnformatted.substring(4, 9) + '-' + contactCellphoneUnformatted.substring(9, 13);
                } else {
                    contactCellphoneFormatted += contactCellphoneUnformatted.substring(4, 8) + '-' + contactCellphoneUnformatted.substring(8, 12);
                }
            }


            //console.log("contactCellphone", contactCellphone, contactCellphoneFormatted);
            //return false;

            //console.log("entrei4");

            if (editing) {
                toSend.contact = {
                    clientId: decoded.client_id,
                    id: currentGuest.contactId,
                    UserId: decoded.user_id,
                    name: contactName,
                    cellPhone: contactCellphoneFormatted,//contactCellphone?.replace(/\D/g,''),
                    email: contactEmail
                }
            }

            if (guestType !== 'COLABORADOR') {
                toSend.gender = currentGuest.gender;
                toSend.relationship = currentGuest.relationship;
                toSend.visitStartTime = visitanteHoraEntrada;
                toSend.visitEndTime = visitanteHoraSaida;
                toSend.worker = false;
                toSend.visitDays = diasDaSemanaSelecteds.map((v: any) => {
                    if (v.name) {
                        return v.name?.toLowerCase();
                    } else {
                        return v?.toLowerCase();
                    }
                });
            } else {
                toSend.virtualDoormanEnable = virtualDoorman;
                toSend.work = currentGuest.functionName;
                toSend.driver = currentGuest.driver;
                toSend.outsourced = currentGuest.outsourced;

                if (currentGuest.outsourced) {
                    toSend.guestCompanyId = currentGuest.companyId;
                }

                let diasDaSemanaFormatado = removeRepeatedDays(diasDaSemana);

                let regexHora = '^[0-9]{2}:[0-9]{2}:[0-9]{2}$';

                toSend.contactFrequentWorkerDaysTimes = diasDaSemana.map((v: any) => {
                    if (!Array.isArray(v)) {
                        let nome;
                        let workStartTime;
                        let workEndTime;

                        if (v.name && v.closeTime && v.openTime) {
                            nome = v.name;
                            workStartTime = v.openTime;
                            workEndTime = v.closeTime;
                        } else {
                            if (v.workDays) {
                                if (Array.isArray(v.workDays)) {

                                    nome = v.workDays[0];
                                } else {

                                    nome = v.workDays;
                                }

                                workStartTime = v?.workStartTime;
                                workEndTime = v?.workEndTime;
                            } else {
                                if (Array.isArray(v.days)) {

                                    nome = v.days[0];
                                } else {

                                    nome = v.days;
                                }

                                workStartTime = v?.times?.workStartTime;
                                workEndTime = v?.times?.workEndTime;
                            }
                        }

                        let objeto;

                        if (v?.id) {
                            objeto = {
                              id: v.id,
                              name: nome?.toLowerCase(),
                              workDays: [nome?.toLowerCase()],
                              workStartTime: workStartTime,
                              workEndTime: workEndTime
                            }
                        } else {
                            objeto = {
                              name: nome?.toLowerCase(),
                              workDays: [nome?.toLowerCase()],
                              workStartTime: workStartTime,
                              workEndTime: workEndTime
                            }
                        }

                        return objeto;
                    }

                    let days:any = [];

                    for (let i in v) {
                        if (v[i].name) {
                            let openTimeFormatted = v[i].openTime;

                            if (!openTimeFormatted.match(regexHora)) {
                                openTimeFormatted += ':00';
                            }

                            let closeTimeFormatted = v[i].closeTime;

                            if (!closeTimeFormatted.match(regexHora)) {
                                closeTimeFormatted += ':00';
                            }

                            let objectToPush;

                            if (v[i].id) {
                                objectToPush = {
                                  "id": v[i].id,
                                  "name": v[i].name.toLowerCase(),
                                  "workDays": [v[i].name.toLowerCase()],
                                  "workStartTime": openTimeFormatted,
                                  "workEndTime": closeTimeFormatted
                                }
                            } else {
                                objectToPush = {
                                  "name": v[i].name.toLowerCase(),
                                  "workDays": [v[i].name.toLowerCase()],
                                  "workStartTime": openTimeFormatted,
                                  "workEndTime": closeTimeFormatted
                                }
                            }
                            days.push(objectToPush);
                        } else {
                            let openTimeFormatted = v[i].times.workStartTime;

                            if (!openTimeFormatted.match(regexHora)) {
                                openTimeFormatted += ':00';
                            }
                            let closeTimeFormatted = v[i].times.workEndTime;
                            if (!closeTimeFormatted.match(regexHora)) {
                                closeTimeFormatted += ':00';
                            }

                            let objectToPush;

                            if (v[i].id) {
                                objectToPush = {
                                  "id": v[i].id,
                                  "name": v[i].days[0].toLowerCase(),
                                  "workDays": [v[i].days[0].toLowerCase()],
                                  "workStartTime": openTimeFormatted,
                                  "workEndTime": closeTimeFormatted
                                }
                            } else {
                                objectToPush = {
                                  "name": v[i].days[0].toLowerCase(),
                                  "workDays": [v[i].days[0].toLowerCase()],
                                  "workStartTime": openTimeFormatted,
                                  "workEndTime": closeTimeFormatted
                                }
                            }

                            days.push(objectToPush);
                        }
                    }

                    return days;
                })

                if (Array.isArray(toSend.contactFrequentWorkerDaysTimes[0])) {
                    toSend.contactFrequentWorkerDaysTimes = toSend.contactFrequentWorkerDaysTimes[0];
                }

            }

            let url = '/contactFrequentWorker'

            if (guestType !== 'COLABORADOR') {
                url = url.replace('Worker', 'ClosedPerson')
            }

            if(facialByWebcam){
                toSend.ImageKey = image;
                toSend.ImageType = 'base64';
            }

            if (editing) {
                if (!currentGuest.id) {
                    currentGuest.id = state.id.id;
                }

                toSend.id = state.id.id;

                if (guestType !== 'COLABORADOR') {
                    url += '/updateAsync/true'
                }else{
                    url += '/update'
                }                
            } else {

                if (guestType !== 'COLABORADOR') {
                    url += '/createAsync'//true'
                }else{
                    url += '/create'
                }                
            }

            if (editing && guestType === 'COLABORADOR') {
                getDeletedDays(toSend.contactFrequentWorkerDaysTimes);
            }


            //console.log("entrei5");

            await api.post(url, toSend, config)

            setDisabledSaveButton(false)

            toast.success(`${guestType === 'COLABORADOR' ? 'Colaborador' : 'Visitante'} ${currentGuest.id ? 'atualizado' : 'cadastrado'} com sucesso.`)
            
            /*navigate('/guest/list')*/
            navigate('/my-profile', {
                state: {
                    index: 2
                }
            })
        }catch(e: any) {
            setDisabledSaveButton(false)

            if(
                e &&
                e.response &&
                e.response.data &&
                e.response.data.Message
            ){
                toast.error(e.response.data.Message)
            }
        }
    }

    function validateDateField(which: string){

        switch (which) {
            case 'startDate': 
                
                if(!validateDate(currentGuest.startDate)) {
                    setStartDateError('Data inválida')
                }else{
                    setStartDateError('')
                }
                break
            case 'endDate': 
                if(!validateDate(currentGuest.endDate)) {
                    setEndDateError('Data inválida')
                }else{
                    setEndDateError('')
                }
                break
        }
       
    }

    function validateHours(startTime: string, endTime: string){
        const start = new Date();
        const end = new Date();

        if (
            (!startTime || !endTime) ||
            (startTime === "00:00:00" || endTime === "00:00:00") ||
            (startTime === "00:00" || endTime === "00:00")) {

            return false;
        } else {
            const startTimeSplit = startTime.split(":");
            const endTimeSplit = endTime.split(":");

            start.setHours(parseInt(startTimeSplit[0]), parseInt(startTimeSplit[1]));
            end.setHours(parseInt(endTimeSplit[0]), parseInt(endTimeSplit[1]));


            if (start >= end) {
                toast.error('O horário de entrada não pode ser maior que o horário de saída.')
                return false;
            }

            return true;
        }
       
    }

    //apply changes when the user is editing
    if (shouldShow) {
        return (
            <Grid templateColumns={["0% 100% 0%", "25% 50% 25%"]}>
                <GridItem colSpan={1} />
                <GridItem
                    colSpan={1}
                    paddingTop={"20px"}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                >
                    <Text
                        fontSize={[28, 20]}
                        fontWeight={600}
                        color={"#444"}
                        marginBottom={"10px"}
                    >
                        {currentGuest.id ? 'Editar' : 'Cadastrar'} {guestType === 'COLABORADOR' ? 'Colaborador' : 'Familiar/Amigo (a)'}
                    </Text>

                    <Flex
                        flexDir={"column"}
                        background={["inherit", "#fff"]}
                        w={"100%"}
                        padding={["0px", "20px"]}
                        borderRadius={"10px"}
                    >
                        {!facialByWebcam &&
                            <BoxWithBorder
                                title={'Foto'}
                                padding={["0px", "20px"]}
                                margin={["0px", "20px"]}
                                borderWidth={["0px", "1px"]}
                                display={"flex"}
                                marginTop={["50px", "0px"]}
                                flexDir={["column"]}
                            >
                                <ImageUpload
                                    initialDefaultImage={getImage()}
                                    responseFunction={(val) => {
                                        //
                                        if(val){
                                            const imageSplit = val.split('base64,')

                                            setImage(imageSplit[imageSplit.length - 1]);
                                        }else{
                                            setImage('');
                                        }
                                    }}
                                />
                            </BoxWithBorder>
                        }

                        {!editing &&
                            <ComboContacts 
                                setCurrentContact={(val) => {
                                    dispatch(setCurrentGuest({
                                        ...currentGuest,
                                        contactId: val
                                    }))
                                }}
                                value={currentGuest.contactId}
                                disabled={editing && guestType !== 'COLABORADOR'}
                            />
                         }

                         {editing &&
                            <>
                            <Box marginBottom={"10px"}>
                                <Text>Contato</Text>
                            </Box>
                            <Box marginBottom={"10px"}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"} 
                                    marginBottom={"3px"}
                                >
                                    Nome 
                                </FormLabel>
                                <Input
                                    type='text' 
                                    placeholder='Digite o nome do visitante' 
                                    value={contactName}
                                    disabled={false}
                                    onChange={(e: any) => {
                                        setContactName(e.target.value);
                                    }}
                                    style={{
                                        background: "#fff",
                                        borderRadius: "4px",
                                        width: "100%",
                                    }}
                                    
                                />
                            </Box>
                            <Box marginBottom={"10px"}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"} 
                                    marginBottom={"3px"}
                                >
                                    E-Mail 
                                </FormLabel>
                                <Input
                                    type='text' 
                                    placeholder={'exemplo@exemplo.com.br'} 
                                    value={contactEmail}
                                    disabled={false}
                                    onChange={(e: any) => {
                                        setContactEmail(e.target.value);
                                    }}
                                    style={{
                                        background: "#fff",
                                        borderRadius: "4px",
                                        width: "100%",
                                    }}
                                    
                                />
                            </Box>
                            <Box marginBottom={"10px"}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"} 
                                    marginBottom={"3px"}
                                >
                                    Celular 
                                </FormLabel>
                                <InputPhone
                                    type='text' 
                                    placeholder={'+55 (xx) 9xxxx-xxxx'}
                                    value={contactCellphone}
                                    disabled={false}
                                    onChange={(e: any) => {
                                        setContactCellphone(e.target.value);
                                    }}
                                    style={{
                                        background: "#fff",
                                        borderRadius: "4px",
                                        width: "100%",
                                    }}
                                    
                                />
                            </Box>
                            <Box>
                                Parentesco: {currentGuest.relationship}
                            </Box>
                            </>
                         }
                        
                        <Box marginBottom={"10px"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"} 
                                marginBottom={"3px"}
                            >
                                RG
                            </FormLabel>
                            <InputRg
                                type='text' 
                                placeholder='Digite o RG do visitante' 
                                value={currentGuest.rg}
                                onChange={(e: any) => {
                                    dispatch(setCurrentGuest({
                                        ...currentGuest,
                                        rg: e.target.value
                                    }))
                                }}
                                disabled={editing && guestType !== 'COLABORADOR'}
                                style={{
                                    background: "#fff",
                                    borderRadius: "4px",
                                    width: "100%",
                                    opacity: editing && guestType ? 0.5 : 1,
                                    cursor: editing && guestType ? 'not-allowed' : 'default'
                                }}
                                
                            />
                        </Box>
                        {!editing && guestType !== 'COLABORADOR' &&
                        <>
                            <Box w={"100%"}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Sexo
                                </FormLabel>
                                <SelectChakra
                                    width={"100%"}
                                    background={"#fff"}
                                    onChange={(e) => {
                                        dispatch(setCurrentGuest({
                                            ...currentGuest,
                                            gender: e.target.value,
                                            relationship: ''
                                        }))
                                    }}
                                    value={currentGuest.gender}
                                    disabled={editing}
                                >
                                    <option value='' disabled>Selecione o sexo</option>
                                    <option value='Masculino'>Masculino</option>
                                    <option value='Feminino'>Feminino</option>
                                </SelectChakra>
                            </Box>

                            <Box w={"100%"}  marginTop={'10px'}>
                                        <FormLabel
                                            fontSize={"0.8em"}
                                            color={"#777"}
                                            marginBottom={"3px"}
                                        >
                                            Parentesco
                                        </FormLabel>
                                        <Select<IOptions>
                                            placeholder={'Selecione...'}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            onChange={(val: SingleValue<IOptions>) => {
                                                if(val){
                                                    dispatch(setCurrentGuest({
                                                        ...currentGuest,
                                                        relationship: val.label
                                                        
                                                    }))

                                                }
                                            }}
                                            isDisabled={currentGuest.gender ? false : true}
                                            value={getValueRelationship(currentGuest.relationship)}
                                            isClearable={true}
                                            name="color"
                                            options={currentGuest.gender == 'Masculino' ?
                                            parentescoM :
                                            parentescoF}
                                            // isDisabled={currentBookingRequest.id !== 0}
                                        />
                            </Box>
                        </>
                        }
                        {guestType === 'COLABORADOR' &&
                        <>
                            <Box
                                w={["100%", "100%"]}
                                padding={["0px 2.5px", "0px 2.5px"]}
                                marginTop={'5'}
                                marginBottom={'5'}
                                style={{backgroundColor: '#FFF', padding: 10, borderRadius: 10}}
                            >
                                <Checkbox 
                                    isChecked={currentGuest.outsourced}
                                    onChange={(e) => {
                                        if (e.target.checked === false) {
                                            dispatch(setCurrentGuest({
                                                ...currentGuest,
                                                companyId: 0,
                                                outsourced: false
                                            }))
                                        } else {
                                            dispatch(setCurrentGuest({
                                                ...currentGuest,
                                                outsourced: true
                                            }));
                                        }
                                    }}
                                >Terceirizado</Checkbox>
                            </Box>
                        
                        
                        {
                            (currentGuest.outsourced || (editing && !!currentGuest.companyId)) &&
                            <Box width={"100%"} marginBottom={"10px"}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Empresa
                                </FormLabel>
                                <SelectChakra
                                    width={"100%"}
                                    background={"#fff"}
                                    onChange={(e) => {
                                        dispatch(setCurrentGuest({
                                            ...currentGuest,
                                            companyId: parseInt(e.target.value)
                                        }))
                                    }}
                                    value={currentGuest.companyId}
                                >
                                    <option value=''>Selecione a empresa</option>
                                    {
                                        companys.map((v: any) => {
                                            return (
                                                <option value={v.value}>{v.label}</option>
                                            )
                                        })
                                    }
                                </SelectChakra>
                            </Box>
                        }
                        <Box width={"100%"} marginBottom={"10px"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Função
                            </FormLabel>
                            <SelectChakra
                                width={"100%"}
                                background={"#fff"}
                                onChange={(e) => {
                                    dispatch(setCurrentGuest({
                                        ...currentGuest,
                                        functionName: e.target.value,
                                        driver: e.target.value === 'Motorista' ? true : currentGuest.driver
                                    }))
                                }}
                                value={currentGuest.functionName}
                            >
                                <option value=''>Selecione a função</option>
                                <option value='Babá' label='Babá' />
                                <option value='Cuidador (a)' label='Cuidador (a)' />
                                <option value='Cozinheiro (a)' label='Cozinheiro (a)' />
                                <option value='Diarista' label='Diarista' />
                                <option value='Faxineiro (a)' label='Faxineiro (a)' />
                                <option value='Jardineiro (a)' label='Jardineiro (a)' />
                                <option value='Personal Trainer' label='Personal Trainer' />
                                <option value='Piscineiro (a)' label='Piscineiro (a)' />
                                <option value='Professor (a)' label='Professor (a)' />
                                <option value='Motorista' label='Motorista' />
                                <option value='Temporário' label='Temporário' />
                            </SelectChakra>
                        </Box>
                        <Box
                            w={["100%", "100%"]}
                            padding={["0px 2.5px", "0px 2.5px"]}
                            marginTop={'5'}
                            marginBottom={'5'}
                            style={{backgroundColor: '#FFF', padding: 10, borderRadius: 10}}
                        >
                            <Checkbox 
                                disabled={ currentGuest.functionName === 'Motorista'}
                                isChecked={currentGuest.driver}
                                onChange={(e) => {
                                    dispatch(setCurrentGuest({
                                        ...currentGuest,
                                        driver: e.target.checked
                                    }))

                                }}
                            >Condutor</Checkbox>
                        </Box>
                        {
                            guestTypeEntrance &&
                            <Box
                                w={["100%", "100%"]}
                                padding={["0px 2.5px", "0px 2.5px"]}
                                marginTop={'5'}
                                marginBottom={'5'}
                                style={{backgroundColor: '#FFF', padding: 10, borderRadius: 10}}
                            >
                                <Checkbox 
                                    isChecked={currentGuest.guestTypeEntrance}
                                    onChange={(e) => {
                                        dispatch(setCurrentGuest({
                                            ...currentGuest,
                                            guestTypeEntrance: e.target.checked
                                        }))

                                    }}
                                >Acesso somente pelos portões sociais</Checkbox>
                            </Box>
                        }

                        </>}

                        {
                            maxVisitTime && 
                            <Box
                                w={["100%", "100%"]}
                                padding={["0px 2.5px", "0px 2.5px"]}
                                marginTop={'5'}
                                marginBottom={'5'}
                                style={{backgroundColor: '#FFF', padding: 10, borderRadius: 10}}
                            >
                                <Checkbox 
                                    isChecked={currentGuest.maxVisitTimeActive}
                                    onChange={(e) => {
                                        const day = String(maxVisitTimeDateObject.getDate()).padStart(2, '0');
                                        const month = String(maxVisitTimeDateObject.getMonth() + 1).padStart(2, '0');//String(parseInt(maxVisitTimeDateObject.getMonth()) + 1).padStart(2, '0');
                                        const year = maxVisitTimeDateObject.getFullYear();

                                        let now = new Date();

                                        const dayStart = String(now.getDate()).padStart(2, '0');
                                        const monthStart = String(now.getMonth() + 1).padStart(2, '0');//String(parseInt(now.getMonth()) + 1).padStart(2, '0');
                                        const yearStart = now.getFullYear();
                                        
                                        dispatch(setCurrentGuest({
                                            ...currentGuest,
                                            maxVisitTimeActive: e.target.checked,
                                            startDate: dayStart + '/' + monthStart + '/' + yearStart,
                                            endDate: day + '/' + month + '/' + year
                                        }))

                                        //alterar data final para a data maxima de liberacao
                                    }}
                                >Usar prazo máximo de liberação</Checkbox>
                            </Box>
                        }

                        {/*guestType === 'COLABORADOR' &&
                            <Box
                                w={["100%", "100%"]}
                                padding={["0px 2.5px", "0px 2.5px"]}
                                marginTop={'5'}
                                marginBottom={'5'}
                                style={{backgroundColor: '#FFF', padding: 10, borderRadius: 10}}
                            >
                                <Checkbox 
                                    isChecked={virtualDoorman}
                                    onChange={(e) => {
                                        setVirtualDoorman(!virtualDoorman);
                                    }}
                                >Portaria automática</Checkbox>
                            </Box>
                        */}

                        <Box
                            w={["100%", "100%"]}
                            padding={["0px 2.5px", "0px 2.5px"]}
                            marginTop={'5'}
                            marginBottom={'5'}
                            style={{
                                backgroundColor: '#FFF', 
                                padding: 10, 
                                borderRadius: 10, 
                                display: 'flex', 
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div>
                                <DateComponent 
                                    width={120}
                                    label={'Data de início'}
                                    value={currentGuest.startDate}
                                    onChange={(e: any) => {
                                        dispatch(setCurrentGuest({
                                            ...currentGuest,
                                            startDate: e.target.value
                                        }))
                                    }}
                                    disabled={false}
                                    onBlur={() => {
                                        validateDateField('startDate')
                                    }}
                                />
                                {
                                    startDateError &&
                                    startDateError.length > 0 &&
                                    <span style={{color: 'red'}}>{startDateError}</span>
                                }
                            </div>
                            <div>
                                <DateComponent 
                                    width={120}
                                    label={'Data de fim'}
                                    value={currentGuest.endDate}
                                    onChange={(e: any) => {
                                        dispatch(setCurrentGuest({
                                            ...currentGuest,
                                            endDate: e.target.value
                                        }))
                                    }}
                                    onBlur={() => {
                                        validateDateField('endDate')
                                    }}
                                    disabled={currentGuest.maxVisitTimeActive}
                                />
                                {
                                    endDateError &&
                                    endDateError.length > 0 &&
                                    <span style={{color: 'red'}}>{endDateError}</span>
                                }
                            </div>


                        </Box>

                        {guestType !== 'COLABORADOR' &&
                            <Box style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                padding: 10,
                                backgroundColor: "#ffffff",
                                borderRadius: 10
                            }}>
                                <ContainerFieldTime>
                                    <TimeFieldLabel>Horário de entrada</TimeFieldLabel>
                                    <TimePicker 
                                        className={'time-picker-custom-class'}
                                        clearIcon={null}
                                        onChange={(e) => {
                                            setVisitanteHoraEntrada(e?.toString());

                                            setTimeout(function() {
                                                validateHours(visitanteHoraEntrada, visitanteHoraSaida);
                                            }, 200);
                                        }}
                                        value={visitanteHoraEntrada} 
                                        disableClock={true} 
                                        maxTime={'23:59'}
                                        minTime={'00:00'}
                                        width={110}
                                    />
                                </ContainerFieldTime>
                                <ContainerFieldTime>
                                    <TimeFieldLabel>Horário de saída</TimeFieldLabel>
                                    <TimePicker 
                                        className={'time-picker-custom-class'}
                                        clearIcon={null}
                                        onChange={(e) => {
                                            setVisitanteHoraSaida(e?.toString());
                                            setTimeout(function() {
                                                validateHours(visitanteHoraEntrada, visitanteHoraSaida);
                                            }, 200);
                                        }}
                                        value={visitanteHoraSaida} 
                                        disableClock={true}
                                        maxTime={'23:59'}
                                        minTime={'00:00'}
                                        
                                    />
                                </ContainerFieldTime>
                            </Box>
                        }
                        
                        <Box>
                            <>
                                {
                                    //diasDaSemana?.length > 0 &&
                                    guestType === 'COLABORADOR' &&
                                    <WeekDaysCollaborator 
                                        setDiasDaSemana={setDiasDaSemana}
                                        diasDaSemana={diasDaSemana}
                                    />
                                }
                                {
                                    !editing &&
                                    guestType !== 'COLABORADOR' &&
                                    <WeekDaysFamiliar
                                        setDiasDaSemanaSelecteds={setDiasDaSemanaSelecteds}
                                    />
                                }
                                {
                                    editing &&
                                    diasDaSemanaSelecteds &&
                                    guestType !== 'COLABORADOR' &&
                                    <WeekDaysFamiliar
                                        setDiasDaSemanaSelecteds={setDiasDaSemanaSelecteds}
                                        diasSelectedEdit={diasDaSemanaSelecteds}
                                    />
                                }
                            </>
                        </Box>
      

                        <Flex
                            flexDir={["column-reverse", "row"]}
                            justifyContent={["initial", "space-between"]}
                            style={{marginTop: 20}}
                        >
                            <Button 
                                colorScheme='teal' 
                                onClick={() => navigate('/my-profile', {
                                    state: {
                                      index: 2
                                    }
                                  })}
                                isDisabled={disabledSaveButton}
                            >
                                Voltar
                            </Button>
                            <Button 
                                colorScheme='teal' 
                                onClick={save}
                                marginBottom={["10px", "0px"]}
                                isDisabled={disabledSaveButton}
                            >
                                Salvar
                            </Button>
                        </Flex>
                    </Flex>
                </GridItem>
                <GridItem colSpan={1} />
            </Grid>
        )
    } else {
        return <></>
    }
}
