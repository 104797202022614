import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IUserData {
  userId: number | null
  name: string
  function: string
  email: string
  phone: string
  gender: string
  cpf: string
  rg: string
  birthDate: string
  driver: boolean | null
  picture: string
  subModuleId?: string
  type: string
}

const initialState = { 
  data: { 
    userId: null,
    name: "",
    function: "",
    email: "",
    phone: "",
    gender: "",
    cpf: "",
    rg: "",
    birthDate: "",
    driver: null,
    picture: "",
    subModuleId: "",
    type: ''
  } as IUserData
}
  
export const user = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetCurrentUser: (state) => {
      state.data = initialState.data
    },
    setCurrentUser: (state, action: PayloadAction<IUserData>) => {
      state.data = action.payload
    },
  },
})

export const { resetCurrentUser, setCurrentUser } = user.actions

export default user.reducer