import styled from 'styled-components';

import {
  ModalFooter
} from '@chakra-ui/react'

export const FooterEditAvatar = styled(ModalFooter)`
  background-color: #fcfcfc;
  flex-direction: column;

  .sizeImageContent {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 20px;

    .div_slide {
      width: 100%;
      padding: 0px 12px;
      border-radius: 20px;

      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 7px;
        background: #111111;
        outline: none;
        opacity: 0.7;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        margin: 15px 0px;
        border-radius: 20px;
      }

      .slider:hover {
        opacity: 1;
      }
      
      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background: #319795;
        cursor: pointer;
        border-radius: 50%;
      }

      .slider::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background: #319795;
        cursor: pointer;
        border-radius: 50%;
      }
      .slider::-webkit-slider-thumb:hover {
        width: 25px;
        height: 25px;
        transition: 0.4s;
      }
      .slider::-moz-range-thumb:hover {
        width: 25px;
        height: 25px;
        transition: 0.4s;
      }
    }
  }
`;
