import react, { useState, useEffect, useRef, useCallback } from 'react';

import { MdImage, MdDelete } from "react-icons/md"

import {
  FaUndo,
  FaRedo,
  FaRegImage
} from 'react-icons/fa';

import {
  Box,
  Button,
  Img,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react'

import Webcam from "react-webcam";

import AvatarEditor from 'react-avatar-editor';

import { FooterEditAvatar } from './styles';

interface IImageUpload {
  responseFunction?: (data: string) => void
  initialDefaultImage?: string
  disabled?: boolean
}

export function ImageUpload({ responseFunction, initialDefaultImage, disabled }: IImageUpload) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();

  const cancelRef =  useRef<HTMLButtonElement>(null);

  const webcamRef: any = useRef(null);

  let editorRef: any = useRef();

  const [showEditor, setShowEditor] = useState(false);

  const [imageEvent, setImageEvent] = useState("");

  const [scale, setScale] = useState(1.2);

  const [rotate, setRotate] = useState(0);

  const [height, setHeight] = useState(0);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight - 100);

    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if(initialDefaultImage) {
      setImageEvent(initialDefaultImage);
    }
  }, [initialDefaultImage]);

  const capture = useCallback(() => {
    if(responseFunction) {
      const imageSrc = webcamRef.current.getScreenshot();

      responseFunction(imageSrc);

      setImageEvent(imageSrc);

      setShowEditor(false);

      onClose();
    
      onClose2();
    }
    
  }, [webcamRef]);

  function checkDevice() { 
    if( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ){
      return true; // está utilizando celular
    } else {
      return false; // não é celular
    }
  }

  function handleFileEvent(event: any) {
    const img = new Image();

    img.crossOrigin = 'Anonymous';
    img.src = URL.createObjectURL(event.target.files[0]);

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);

    reader.onloadend = async () => {
      if(responseFunction) {
        const base64data: any = reader.result;

        responseFunction(base64data);

        setImageEvent(base64data);
        
        setShowEditor(false);
    
        onClose();
      
        onClose2();
      }
    };
        
    event.target.value = null;
  }

  function handleFileEventMobile(event: any) {
    const img = new Image();

    img.crossOrigin = 'Anonymous';
    img.src = URL.createObjectURL(event.target.files[0]);

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);

    reader.onloadend = async () => {
      const base64data: any = reader.result;

      setImageEvent(base64data);
      
      setShowEditor(false);
  
      onClose();
    
      onClose2();
    };
        
    event.target.value = null;
  }

  if(showEditor) {
    return (
      <Box
        position={"absolute"}
        top={0}
        left={0}
        w={"100%"}
        h={"100%"}
      >
        <Modal 
          isOpen={isOpen2}
          onClose={onClose2}
          closeOnOverlayClick={false}
          size={"2xl"}
        >
          <ModalOverlay />
          <ModalContent id={"modal"}>
            <ModalHeader></ModalHeader>
            <ModalBody
              className='bodyCanvasPic'
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {isOpen && (
                <Webcam
                  mirrored={true}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                />
              )}
            </ModalBody>
            <ModalFooter
              display={"flex"}
              justifyContent={"space-between"}
              flexDir={"row-reverse"}
            >
              <Button
                onClick={capture}
                id='capture'
                background={"#d36262"}
                color={"#fff"}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
              >
                Salvar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    )
  }

  return (
    <>
      <Modal 
        isOpen={isOpen3}
        onClose={onClose2}
        closeOnOverlayClick={false}
        size={"full"}
      >
        <ModalOverlay />
        <ModalContent id={"modal"}>
          <ModalHeader>sadsa</ModalHeader>
          <ModalBody
            className='bodyCanvasPic'
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {imageEvent && (
              <AvatarEditor
                style={{
                  width: width-40,
                  height: height-60,
                }}
                image={imageEvent}
                ref={(editor: any) => (editorRef = editor)}
                width={width-40}
                height={height-60}
                border={10}
                color={[255, 255, 255, 0.6]}
                scale={scale}
                rotate={rotate}
                borderRadius={0}
                crossOrigin="Anonymous"
                disableHiDPIScaling
              />
            )}
          </ModalBody>
          <FooterEditAvatar
            display={"flex"}
            
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              padding={"10px 0px"}
            >
              <Button
                type="button"
                onClick={() => setRotate(rotate - 90)}
                colorScheme='teal'
                marginRight={"10px"}
              >
                <FaUndo />
              </Button>
              <Button
                type="button"
                onClick={() => setRotate(rotate + 90)}
                colorScheme='teal'
                marginLeft={"10px"}
              >
                <FaRedo />
              </Button>
            </Box>
            <div className="sizeImageContent">
              <FaRegImage
                size="20"
                color={scale === 1 ? '#c3c2c7' : '#319795'}
              />
              <div className="div_slide">
                <input
                  type="range"
                  min="1"
                  max="10"
                  onChange={(e: any )=> setScale(e.target.value)}
                  step="0.01"
                  value={scale}
                  className="slider"
                />
              </div>

              <FaRegImage
                size="30"
                color={scale === 10 ? '#c3c2c7' : '#319795'}
              />
            </div>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"0px 10px 10px 10px"}
            >
              <Button
                onClick={() => {
                  setImageEvent("")
                  onClose3()
                }}
                background={"#d36262"}
                color={"#fff"}
                marginTop={"20px"}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  if(responseFunction) {
                    const canvasScaled = editorRef.getImageScaledToCanvas();

                    const base64 = canvasScaled.toDataURL("image/jpeg");

                    setImageEvent(base64)

                    responseFunction(base64)

                    onClose3()
                  }
                }}
                colorScheme="teal"
                marginTop={"20px"}
              >
                Salvar
              </Button>
            </Box>
          </FooterEditAvatar>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Atenção!
            </AlertDialogHeader>
            <AlertDialogBody>
              De onde gostaria de obter a imagem?
            </AlertDialogBody>
            <AlertDialogFooter
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={["row", "initial"]}
            >
              <Button
                background={"#d36262"}
                color={"#fff"}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </Button>
              <Box
                display={["flex", "initial"]}
                paddingLeft={["10px", "0px"]}
              >
                <Button
                  colorScheme='teal'
                  onClick={() => {
                    const inputFile: any = document.getElementById('folder');

                    inputFile.click();

                    onClose();
                  }}
                >
                  Galeria
                </Button>
                <Button 
                  colorScheme='teal'
                  onClick={() => {
                    if(checkDevice()) {
                      const inputFile: any = document.getElementById('cam');

                      inputFile.click();

                      onOpen3()

                      setTimeout(() => {
                        onOpen2();
                      }, 500)

                      return
                    }

                    setShowEditor(true)

                    setTimeout(() => {
                      onOpen2();
                    }, 500)
                  }} 
                  ml={3}
                >
                  Câmera
                </Button>
              </Box>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Box marginTop={"10px"}>
        {!imageEvent
          ? (
              <Button
                isDisabled={disabled}
                variant={"ghost"}
                _hover={{
                  cursor: "pointer"
                }}
                width={"100%"}
                height={"200px"}
                onClick={() => {
                  onOpen()
                }}
              >
                <MdImage color={"#ccc"} size={"100px"} />
              </Button>
            )
          :  (
              <Box position={"relative"}>
                {
                  !disabled &&
                  <Button
                    w={"60px"}
                    h={"60px"}
                    position={"absolute"}
                    background={"#d36262"}
                    color={"#fff"}
                    top={"10px"}
                    right={"10px"}
                    _hover={{
                      background: "#854242",
                      _disabled: {
                        background: "#d36262"
                      }
                    }}
                    boxShadow={"0px 2px 4px #292828"}
                    onClick={() => {
                      if(responseFunction) {
                        setImageEvent("")
                        responseFunction("")
                      }
                    }}
                  >
                    <MdDelete size={"40px"} />
                  </Button>
                }
                
                <Img
                  src={imageEvent}
                  borderRadius={"4px"}
                  w={"100%"}
                  _hover={{
                    boxShadow: "0px 5px 5px #ccc",
                    transition: "0.5s"
                  }}
                />
              </Box>
            )
        }
        <input
          id="cam"
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={handleFileEventMobile}
          capture
        />
        <input
          id="folder"
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={handleFileEvent}
        />
      </Box>
    </>
  );
}