import React from 'react';

import { ToastContainer } from 'react-toastify';

import { ThemeProvider } from 'styled-components'
import { ChakraProvider } from '@chakra-ui/react'

import theme from './global/styles/theme'
import { Provider } from 'react-redux'
import { store } from './store'

import Routes from './Routes'
import 'react-toastify/dist/ReactToastify.css';

import GlobalStyles from './global/styles/global';

export default function App() {
  return (
    <ChakraProvider>
      <Provider store={store}>
          <ThemeProvider theme={theme}>
              <GlobalStyles />
              <Routes /> 
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
          </ThemeProvider>
      </Provider>
    </ChakraProvider>
  )
}