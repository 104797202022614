import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import jwt_decode from "jwt-decode"

import { 
  Flex, 
  Box, 
  Grid,
  GridItem,
  Text,
  Heading,
  Button,
  Image,
  CircularProgress,
} from '@chakra-ui/react';

import { ref, set } from "@firebase/database"

import api from '../../services/api'

import { DefaultAvatar } from '../../components/DefaultAvatar';

import { FirebaseRealtimeDatabase } from '../../services/firebase'

import { IUserData } from '../../interfaces/IUserData'

import { Container, ContainerLoad } from './styles';

interface IPreRegistration {
  id: number
  name: string
  contactMilestoneId: number
  email: string
  rg: number
  carModel: string
  carColor: string
  carPlate: string
  attendance: string | "TRUE" | "PEDDING" | "FALSE"
  validated: string
  companyFancyName: string
  preRegistrationRequired: boolean
  cellPhone: string
  file: {
    type: string
    data: string
  }
  imageKeyValid: boolean
  facialByWebcam: boolean[]
  guestType: string
  visitVirtualDoorman: boolean
  enableVirtualDoorman: boolean
  eventName: string
}

export function PreRegistration() {
  const location = useLocation();
  const navigate = useNavigate();
  const company = location && location.state && location.state.company;

  const [preRegister, setPreRegister] = useState<IPreRegistration>({
    id: 0,
    name: "",
    contactMilestoneId: 0,
    email: "",
    rg: 0,
    carModel: "",
    carColor: "",
    carPlate: "",
    attendance: "",
    validated: "",
    companyFancyName: "",
    preRegistrationRequired: false,
    cellPhone: "",
    file: {
      type: "",
      data: ""
    },
    imageKeyValid: false,
    facialByWebcam: [],
    guestType: "",
    visitVirtualDoorman: false,
    enableVirtualDoorman: false,
    eventName: "",
  });

  const [disabledButtonSendNotification, setDisabledButtonSendNotification] = useState(false)

  const [buttonDisabledOrApprovePressed, setButtonDisabledOrApprovePressed] = useState(false)

  const [loading, setLoading] = useState(true)

  const [pastEvent, setPastEvent] = useState(false)

  const [isToday, setIsToday] = useState(false)

  useEffect(() => {
    getDetails()
  }, []);

  async function getDetails() {
    const id = location && location.state && location.state.id;
    const unplanned = location && location.state && location.state.unplanned;
    const internal = location && location.state && location.state.internal;

    const userToken = localStorage.getItem('access_portal:access_token');

    const config = {
      headers: {
        Authorization: `Bearer ${ userToken }`
      }
    }

    try {
      let endpoint = `preRegistration/getDetails/${id}`;
      if (unplanned) {
        if (internal) {
          endpoint = `User/${id}`;
        } else {
          endpoint = `Guest/${id}`;
        }
      }
      const { data } = await api.get(endpoint, config);
      //console.log("data>>>", data);

      let today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)

      let startDate = new Date(data.startDate)
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)

      if(startDate <= today){
        setPastEvent(true )
      }
      //Thu May 11 2023 00:00:00 GMT-0300 
      //Thu May 11 2023 00:00:00 GMT-0300 
      if(
          today.getDate() === startDate.getDate()
          && today.getMonth() === startDate.getMonth()
          && today.getFullYear() === startDate.getFullYear()
      ){
          setIsToday(true )
      }

      if(data.guestType === "EXTERNAL" && 
        (data.visitVirtualDoorman || data.enableVirtualDoorman) &&
        data.facialByWebcam[0] &&
        !data.imageKeyValid &&
        startDate > today
      ) {
        toast.warn("Por favor notifique seu visitante para trocar a foto.") 
      }

      setPreRegister({
        ...data,
        file: {
          ...data.file,
          data: data.file?.data ? "data:image/jpeg;base64," + data.file?.data.replace("data:image/jpeg;base64,", '') : ""
        }        
      })

      setLoading(false)
    } catch(err: any) {
      toast.error(
        err &&
        err.response &&
        err.response.data &&
        err.response.data.Message
      )
      setTimeout(() => {
        navigate(-1)
      }, 1000)
    }
  }

  function getStatus() {
    if(preRegister.attendance === 'TRUE'){
      if(preRegister.validated === 'FALSE'){
        return {
          color: 'red',
          title: 'PRÉ-CADASTRO REJEITADO'
        }
      }else{
        return {
          color: 'green',
          title: 'PRESENÇA CONFIRMADA'
        }
      }
    }else if(preRegister.attendance === 'PENDING'){
      return {
        color: 'black',
        title: 'AGUARDANDO CONFIRMAÇÃO'
      }
    }else if(preRegister.attendance ==='FALSE'){
      return {
        color: 'red',
        title: 'AUSÊNCIA CONFIRMADA'
      }
    }
  }

  async function handleNotification() {
    const { id } = preRegister

    const userToken = localStorage.getItem('access_portal:access_token');

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }

    setDisabledButtonSendNotification(true)

    try {
      await api.post("personalNotification/notify?preRegistrationId=" + id, {}, config)

      toast.success("Contato notificado com sucesso.")
    } catch(err) {
      toast.error("Ocorreu um erro ao tentar notificar o contato.")

      setDisabledButtonSendNotification(false)
    }
  }

  async function handleApprove() {
    setButtonDisabledOrApprovePressed(true);

    if(
      preRegister.guestType !== "INTERNAL" && 
      (preRegister.visitVirtualDoorman || preRegister.enableVirtualDoorman) &&
      preRegister.facialByWebcam[0] &&
      preRegister.imageKeyValid
    ) {
      const { id } = preRegister

      const userToken = localStorage.getItem('access_portal:access_token');
  
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }

      const virtualDoorman = preRegister.visitVirtualDoorman || preRegister.enableVirtualDoorman;

      try {
        const { data } = await api.post(
          `preRegistration/UpdateValidated?virtualDoorman=${virtualDoorman}`,
          {
            id,
            Validated: "TRUE"
          },
          config
        )

        const decoded = jwt_decode(userToken as string) as IUserData

        const newPack = {
          date: '' + new Date().toISOString(),
          guestId: data.guestId,
        };

        getDetails();

        set(
          ref( 
            FirebaseRealtimeDatabase, 
            `/automatic-entry-creation-or-change/${decoded.client_id}/${data.guestId}`
          ),
          newPack
        )

        sendUpdate('true')
      } catch (err) {
      }
    } else {
      sendUpdate('true')
    }
  }

  async function sendUpdate(approval: string) {
    const { id } = preRegister

    const userToken = localStorage.getItem('access_portal:access_token');

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }

    try {
      const { data } = await api.post(`preRegistration/updateValidated`, {
        id,
        validated: approval
      }, config)

      sendFirebase(approval, data)
    } catch(err) {
      toast.error('Ocorreu um erro, por favor tente mais tarde.')
    }
  }

  async function sendFirebase(approval: string, data: string) {
    const id = location && location.state && location.state.id;

    const userToken = localStorage.getItem('access_portal:access_token');

    const decoded = jwt_decode(userToken as string) as IUserData
    
    const toSend = { 
      status: approval,
      date: new Date().toString()
    }


    if(typeof decoded.unit_id === 'object') {
      let showNotificationSuccess = null

      for(let i in decoded.unit_id) {
        set(
          ref( 
            FirebaseRealtimeDatabase, 
            '/pre-registration-creation-or-change/' + decoded.client_id + '/'+ decoded.unit_id[i] + '/' + id
          ),
          toSend
        ).then(async () => {
          showNotificationSuccess = data
        })  
      }
  
      if(showNotificationSuccess) {
        toast.success(showNotificationSuccess)
      }
    } else {      
      set(
        ref( 
          FirebaseRealtimeDatabase, 
          '/pre-registration-creation-or-change/' + decoded.client_id + '/'+ decoded.unit_id + '/' + id
        ),
        toSend
      ).then(async () => {
        toast.success(data)

        // if(this.state.typeDetails === 'evento'){                    
        //   store.dispatch(fetchEventGetDetailsStart({
        //     key: this.state.idVisita
        //   }))  
        // } else if(this.state.typeDetails === 'visita') {
        //   store.dispatch(fetchVisitGetDetailsStart({
        //     key: this.state.idVisita
        //   }))
        // }
      })
    }
  }

  if(loading) {
    return (
      <ContainerLoad>
        <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
      </ContainerLoad>
    )
  }

  return (
    <Grid templateColumns={["0% 100% 0%", "30% 40% 30%"]}>
      <GridItem colSpan={1} />
      <GridItem
        colSpan={1}
        paddingTop={"20px"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Box w={"100%"}>
          <Flex
            marginTop={["10px", "40px"]}
            flexDir={["column", "row"]}
            justifyContent={["center", "space-between"]}
            alignItems={["flex-end", "flex-end"]}
          >
            <Flex
              justifyContent={["center", "flex-start"]}
              alignItems={["", "flex-end"]}
              width={["100%", "auto"]}
              marginBottom={["40px", "0px"]}
            >
              <Text
                fontSize={[28, 20]}
                fontWeight={600}
                color={"#444"}
              >
                Detalhes da Reserva
              </Text>
            </Flex>
          </Flex>
          <Container
            flexDir={"column"}
            padding={["0px", "20px"]}
            borderRadius={"6px"}
            background={["transparent", "#fff"]}
          >
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Flex
                display={"flex"}
                justifyContent={"center"}
                position={"relative"}
              >
                
                {preRegister.file.data
                  ? <Image
                      objectFit={"cover"}
                      src={preRegister.file.data}
                      boxSize={["130px", "150px"]}
                      // objectFit='cover'
                      alt={"Perfil"}
                      display={"flex"}
                      flexDirection={"row"}
                      borderRadius={"full"}
                      background={"#fff"}
                      padding={"3px"}
                      boxShadow={"1px 1px 6px #d0d0d0"}
                    />
                  : <DefaultAvatar
                      boxSize={["130px", "150px"]}
                      marginRight={"0px"}
                      padding={"3px"}
                      background={"#ccc"}
                    />
                }
              </Flex>
            </Flex>
            <Box>
              <Heading size='xs' textTransform='uppercase' color="#777">
                Nome
              </Heading>
              <Text pt='2' fontSize='sm'>
                {preRegister.name}
              </Text>
            </Box>
            {preRegister.cellPhone && (
              <Box>
                <Heading size='xs' textTransform='uppercase' color="#777">
                  Telefone
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {preRegister.cellPhone}
                </Text>
              </Box>
            )}
            <Box>
              <Heading size='xs' textTransform='uppercase' color="#777">
                E-mail
              </Heading>
              <Text pt='2' fontSize='sm'>
                {preRegister.email}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase' color="#777">
                RG
              </Heading>
              <Text pt='2' fontSize='sm'>
                {preRegister.rg}
              </Text>
            </Box>
            {preRegister.carModel && preRegister.carColor && preRegister.carPlate && (
              <Box>
                <Container
                  padding={"15px 10px 10px 10px"}
                  background={["#cdcdcd", "#e9e9e9"]}
                  borderRadius={"4px"}
                >
                  <Heading size={"sm"} textTransform='uppercase' color="#777">
                    Veículo
                  </Heading>
                  <Box marginTop={"20px"}>
                    <Heading fontSize={"12px"} textTransform='uppercase' color="#777">
                      Modelo
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {preRegister.carModel}
                    </Text>
                  </Box>
                  <Box marginTop={"20px !important"}>
                    <Heading fontSize={"12px"} textTransform='uppercase' color="#777">
                      Color
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {preRegister.carColor}
                    </Text>
                  </Box>
                  <Box marginTop={"20px !important"}>
                    <Heading fontSize={"12px"} textTransform='uppercase' color="#777">
                      Placa
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {preRegister.carPlate}
                    </Text>
                  </Box>
                </Container>
              </Box>
            )}
            <Container>
              {preRegister.eventName && (
                <Box>
                  <Heading size='xs' textTransform='uppercase' color="#777">
                    Nome do Evento
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {preRegister.eventName || "(Nome ainda não definido!)"}
                  </Text>
                </Box>
              )}
              {getStatus() &&
                <Box>
                  <Heading size='xs' textTransform='uppercase' color="#777">
                    Situação
                  </Heading>
                  <Text pt='2' fontSize='sm' color={getStatus()?.color}>
                    {getStatus()?.title}
                  </Text>
                </Box>
              }
              {company &&
                <Box>
                  <Heading size='xs' textTransform='uppercase' color="#777">
                    Empresa
                  </Heading>
                  <Text pt='2' fontSize='sm' color={getStatus()?.color}>
                    {company?.companyName}
                  </Text>
                </Box>
              }
            </Container>
            <Flex
              justifyContent={"space-between"}
            >
            {preRegister.guestType === "EXTERNAL" && 
              (preRegister.visitVirtualDoorman || preRegister.enableVirtualDoorman) &&
              preRegister.facialByWebcam[0] &&
              !preRegister.imageKeyValid &&
              !pastEvent

                ? (
                    <Button
                      onClick={handleNotification}
                      isDisabled={disabledButtonSendNotification}
                      color={"#65788a"}
                      boxShadow={"0px 1px 3px #ccc"}
                    >
                      Notificar visitante com foto inválida
                    </Button>
                  )
                : preRegister && preRegister.preRegistrationRequired &&
                  preRegister.validated === 'PENDING' &&
                  preRegister.attendance === 'TRUE' && 
                  (isToday || !pastEvent) &&
                  (
                    <>
                      <Button
                        isDisabled={buttonDisabledOrApprovePressed}
                        onClick={() => sendUpdate('false')}
                        background={"#d36262"}
                        color={"#fff"}
                        _hover={{
                          _disabled: {
                            background: "#d36262"
                          }
                        }}
                      >
                        Rejeitar
                      </Button>
                      <Button
                        isDisabled={buttonDisabledOrApprovePressed}
                        onClick={handleApprove}
                        colorScheme={"teal"}
                      >
                        Aprovar
                      </Button>
                    </>
                  )
              }
              {/* <Button
                isDisabled={false}
                onClick={handleApprove}
                colorScheme={"teal"}
              >
                Aprovar
              </Button> */}
            </Flex>
            <Flex flexDir={["column", "row"]}>
              <Button 
                colorScheme='teal' 
                size='md'
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            </Flex>
          </Container>
        </Box>
      </GridItem>
      <GridItem colSpan={1} />
    </Grid>
  )
}