import axios, { InternalAxiosRequestConfig } from 'axios'
import { store } from '../../src/store/'
import { setEndpointError } from '../store/reducers/endpointError'
import { isDev, isHml } from '../helpers/environment'

let apiTarefa = axios.create({
  baseURL: 'https://api.nicbrain.com.br/api',
})
  
if (
  isDev()
) {
  apiTarefa = axios.create({ 
    baseURL: 'https://apidev.nicbrain.com.br/api',
  })
}

if (
  isHml()
) {
  apiTarefa = axios.create({ 
    baseURL: 'https://apihml.nicbrain.com.br/api',
  })
}


apiTarefa.interceptors.request.use(config => saveRequest(config), error => Promise.reject(error))
apiTarefa.interceptors.response.use(response => response, async error => await errorHandler(error))

function saveRequest(config: InternalAxiosRequestConfig<any>) {
    store.dispatch(
        setEndpointError({
            show: false,
            title: 'Base: ' + config.baseURL + ' - Endpoint: ' + config.url,
            description: JSON.stringify(config.method === 'post' ? config.data : {}),
            name: "Sistema",
            subModuleId: '0',
            email: "kobel@kobel.com.br",
        })
    )
  
    return config
}

async function errorHandler(error: any) {
    //const config = error.response.config;
    if(
      error.response.status >= 400
      &&
      error.response.status != 401
      &&
      error.response.status < 599
    ){
      if(
        error.response.status === 400 
        && error.response
        && error.response.data
        && error.response.data.Details 
        && error.response.data.Details.InnerException === null 
        && error.response.data.Details.Message 
        && error.response.data.Details.Message.length > 0
        && (
            error.response.data.Details.Message === 'O limite máximo de classificados ativos por privativo foi atingido!'
            || error.response.data.Details.Message === 'Recurso não configurado.'
            || error.response.data.Details.Message === 'Não existe nenhum evento para este morador.'
            || error.response.data.Details.Message === 'Contato associado como visitante frequente!, por favor exclua o visitante frequente e tente novamente.'
            || error.response.data.Details.Message === 'Não existe horários de reserva disponíveis'
            || error.response.data.Details.Message === 'Solicitação de reserva não encontrada.'
            || error.response.data.Details.Message === 'Não existem espaços cadastrados para esse marco.'
            || error.response.data.Details.Message === 'Usuário bloqueado'
            || error.response.data.Details.Message === 'Biometria inválida.'
            || error.response.data.Details.Message === 'Senha inválida.'
            || error.response.data.Details.Message === 'Sua senha atual está incorreta.'
            || error.response.data.Details.Message === 'Contato associado como visitante frequente, por favor exclua o visitante frequente e tente novamente.'
            || error.response.data.Details.Message === 'Usuário não encontrado para envio de Push-Notification.'
            || error.response.data.Details.Message === 'Convidado não encontrado!'
            || error.response.data.Details.Message === 'Usuário não encontrado'
            || error.response.data.Details.Message === 'Não foi possível cadastrar nova senha. Certifique-se de que os dados estão corretos.'
            || error.response.data.Details.Message === 'Contato não encontrado.'
            || error.response.data.Details.Message === 'Animal não encontrado'
            || error.response.data.Details.Message === 'Comunicado não encontrado!'
            || error.response.data.Details.Message === 'O espaço ainda não está disponível para efetuar reservas.'
            || error.response.data.Details.Message === 'Limite semanal para uso do espaço excedido.'
            || error.response.data.Details.Message === 'Parâmetros incompletos para geração do login, por favor, entre em contato com a equipe de suporte.'
            || error.response.data.Details.Message.indexOf(' já está sendo utilizado.') > -1
            || error.response.data.Details.Message === 'Contato associado como visitante colaborador frequente, por favor exclua o colaborador frequente e tente novamente'
        )
        && !error.response.data.Details.ActualValue
      ){
        return Promise.reject(error)
      }
  
      if(
        error.response.status === 400 
        && error.response
        && error.response.data
        && error.response.data.Message
        && error.response.data.Message.length > 0
        && (
            error.response.data.Message === 'O limite máximo de classificados ativos por privativo foi atingido!'
            || error.response.data.Message === 'Recurso não configurado.'
            || error.response.data.Message === 'Não existe nenhum evento para este morador.'
            || error.response.data.Message === 'Contato associado como visitante frequente!, por favor exclua o visitante frequente e tente novamente.'
            || error.response.data.Message === 'Não existe horários de reserva disponíveis'
            || error.response.data.Message === 'Solicitação de reserva não encontrada.'
            || error.response.data.Message === 'Não existem espaços cadastrados para esse marco.'
            || error.response.data.Message === 'Usuário bloqueado'
            || error.response.data.Message === 'Biometria inválida.'
            || error.response.data.Message === 'Senha inválida.'
            || error.response.data.Message === 'Sua senha atual está incorreta.'
            || error.response.data.Message === 'Contato associado como visitante frequente, por favor exclua o visitante frequente e tente novamente.'
            || error.response.data.Message === 'Usuário não encontrado para envio de Push-Notification.'
            || error.response.data.Message === 'Convidado não encontrado!'
            || error.response.data.Message === 'Usuário não encontrado'
            || error.response.data.Message === 'Não foi possível cadastrar nova senha. Certifique-se de que os dados estão corretos.'
            || error.response.data.Message === 'Contato não encontrado.'
            || error.response.data.Message === 'Animal não encontrado'
            || error.response.data.Message === 'Comunicado não encontrado!'
            || error.response.data.Message === 'O espaço ainda não está disponível para efetuar reservas.'
            || error.response.data.Message === 'Limite semanal para uso do espaço excedido.'
            || error.response.data.Message === 'Parâmetros incompletos para geração do login, por favor, entre em contato com a equipe de suporte.'
            || error.response.data.Message.indexOf(' já está sendo utilizado.') > -1
            || error.response.data.Message === 'Contato associado como visitante colaborador frequente, por favor exclua o colaborador frequente e tente novamente'
        )
      ){
        return Promise.reject(error)
      }
  
      setTimeout(async () => {      
        let errorToSend = null;
  
        if(error){
          errorToSend = error.response 
          delete errorToSend.config['headers']
          delete errorToSend.request['_performanceLogger']
          delete errorToSend['headers']
          delete errorToSend['config']
          errorToSend = JSON.stringify(errorToSend)
        }
  
        const toSend = {
          ...store.getState().endpointError.data,
          error: errorToSend,
          files: [],
        } 
  
        try {
          await apiTarefa.post('User/ReportProblems', toSend);
  
          store.dispatch(setEndpointError({
            ...store.getState().endpointError.data,
            show: true,
          }));
        } catch(err) {

        }
      }, 500)
    }
  
    return Promise.reject(error)
  }

export default apiTarefa
