import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IData {
    name: string,
    description: string,
    milestone: any,
    local: any,
    startDate: string,
    endDate: string,
    unit: any
}

const initialState = { 
    data: { 
        name: '',
        description: '',
        milestone: {
            name: ''
        },
        local: {
            name: ''
        },
        startDate: '',
        endDate: '',
        unit: {
            name: ''
        },
        guests: []
  } as IData
}
  
export const eventSlice = createSlice({
name: 'event',
initialState,
reducers: {
    resetEvent: (state) => {
        state.data = initialState.data
    },
    setEvent: (state, action: PayloadAction<IData>) => {
        state.data = action.payload
    },
},
})

export const { resetEvent, setEvent } = eventSlice.actions

export default eventSlice.reducer