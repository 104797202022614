import React from "react"

import { 
    BoxProps
} from '@chakra-ui/react'

import { Container, Title, SubContainer } from './styles'

interface IProps {
    title: string,
    children: JSX.Element
}

type Props = BoxProps & IProps

export function BoxWithBorder({ title, children, ...props }: Props) {

    return (
        <Container {...props}>
            <Title display={["none", "inherit"]}>{ title }</Title>
            {children}
        </Container>
    )
}