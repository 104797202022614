import styled from 'styled-components';

import { Box, DrawerBody, DrawerCloseButton, Button } from '@chakra-ui/react';

export const Container = styled.div`
    background-color: #cccccc;
    height: 50px;
`;

export const Logo = styled.img`
    width: 130px;
    margin-left: 0px;
`;

export const ContentLogo = styled(Box)`
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 0px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    cursor: pointer;

    > div {
        width: 250px;
        padding: 10px;
        border-radius: 10px;
    }
`;

export const ButtonClose = styled(DrawerCloseButton)`
    display: flex !important;
    justify-content: center !important;
    position: absolute !important;
    right: -40px !important;
    top: 0 !important;
    z-index: 9999999 !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 0px !important;
    background: #319795 !important;
    color: #fff !important;
`;

export const DrawerBodyList = styled(DrawerBody)`
    display: flex;
    flex-direction: column;
    
    > button + button {
        margin-top: 10px;
    }
`;

export const ButtonMenuList = styled(Button)`
    width: 100% !important;
    display: flex !important;
    justify-content: start !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    height: auto !important;
    transition: 0.4s;

    &:hover {
        box-sizing: border-box;
        padding-top: 10px;
        padding-bottom: 10px;
        height: auto;
        box-shadow: 0px 0px 12px #cccccc57;
        transition: 0.4s;

        > span:nth-child(1) {
            background: #319795;
            transition: 0.4s;

            > svg {
                color: #fff !important;
                transition: 0.4s;
            }
        }

        > span:nth-child(2) {
            color: #333 !important;
            transition: 0.4s;
        }
    }

    > span:nth-child(1) {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        box-shadow: 0px 0px 12px #cccccc57;
        font-size: 14;
        transition: 0.4s;
    }

    > span:nth-child(2) {
        margin-left: 20px !important;
        transition: 0.4s;
    }
`;

export const NavItems = styled(Box)`
    .bell{
        color: #9e9e9e;
        -webkit-animation: ring 4s .7s ease-in-out infinite;
        -webkit-transform-origin: 50% 4px;
        -moz-animation: ring 4s .7s ease-in-out infinite;
        -moz-transform-origin: 50% 4px;
        animation: ring 4s .7s ease-in-out infinite;
        transform-origin: 50% 4px;
    }

    @-webkit-keyframes ring {
    0% { -webkit-transform: rotateZ(0); }
    1% { -webkit-transform: rotateZ(30deg); }
    3% { -webkit-transform: rotateZ(-28deg); }
    5% { -webkit-transform: rotateZ(34deg); }
    7% { -webkit-transform: rotateZ(-32deg); }
    9% { -webkit-transform: rotateZ(30deg); }
    11% { -webkit-transform: rotateZ(-28deg); }
    13% { -webkit-transform: rotateZ(26deg); }
    15% { -webkit-transform: rotateZ(-24deg); }
    17% { -webkit-transform: rotateZ(22deg); }
    19% { -webkit-transform: rotateZ(-20deg); }
    21% { -webkit-transform: rotateZ(18deg); }
    23% { -webkit-transform: rotateZ(-16deg); }
    25% { -webkit-transform: rotateZ(14deg); }
    27% { -webkit-transform: rotateZ(-12deg); }
    29% { -webkit-transform: rotateZ(10deg); }
    31% { -webkit-transform: rotateZ(-8deg); }
    33% { -webkit-transform: rotateZ(6deg); }
    35% { -webkit-transform: rotateZ(-4deg); }
    37% { -webkit-transform: rotateZ(2deg); }
    39% { -webkit-transform: rotateZ(-1deg); }
    41% { -webkit-transform: rotateZ(1deg); }

    43% { -webkit-transform: rotateZ(0); }
    100% { -webkit-transform: rotateZ(0); }
    }

    @-moz-keyframes ring {
    0% { -moz-transform: rotate(0); }
    1% { -moz-transform: rotate(30deg); }
    3% { -moz-transform: rotate(-28deg); }
    5% { -moz-transform: rotate(34deg); }
    7% { -moz-transform: rotate(-32deg); }
    9% { -moz-transform: rotate(30deg); }
    11% { -moz-transform: rotate(-28deg); }
    13% { -moz-transform: rotate(26deg); }
    15% { -moz-transform: rotate(-24deg); }
    17% { -moz-transform: rotate(22deg); }
    19% { -moz-transform: rotate(-20deg); }
    21% { -moz-transform: rotate(18deg); }
    23% { -moz-transform: rotate(-16deg); }
    25% { -moz-transform: rotate(14deg); }
    27% { -moz-transform: rotate(-12deg); }
    29% { -moz-transform: rotate(10deg); }
    31% { -moz-transform: rotate(-8deg); }
    33% { -moz-transform: rotate(6deg); }
    35% { -moz-transform: rotate(-4deg); }
    37% { -moz-transform: rotate(2deg); }
    39% { -moz-transform: rotate(-1deg); }
    41% { -moz-transform: rotate(1deg); }

    43% { -moz-transform: rotate(0); }
    100% { -moz-transform: rotate(0); }
    }

    @keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
    }
`;

export const Span = styled.span`
    position: absolute;
    top: -8px;
    right: -7px;
    background: green;
    color: #fff;
    z-index: 1;
    font-size: 10px;
    padding: 4px;
    border-radius: 50%;
`;
