import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify'

import jwt_decode from "jwt-decode"

import { format, parseISO } from 'date-fns'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  CircularProgress
} from '@chakra-ui/react'

import { resetCurrentBookingRequest } from '../../store/reducers/booking-request';

import api from '../../services/api'

import { hasPermission } from '../../utils/check-permission'

import { IUserData } from '../../interfaces/IUserData'

import { setCurrentVisitCreate, resetCurrentVisitCreate } from '../../store/reducers/visitCreate'

import { Content } from './styles';
interface IProps {
  isVisit?: boolean,
  

}
export function NewBookingRequestButton({isVisit}: IProps) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [options, setOptions] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const [newEvent, setNewEvent] = useState(false)

  const [visit, setVisit] = useState(false)

  useEffect(() => {
    if(isVisit){

      setVisit(true)
    }
  }, [])

  useEffect(() => {
    async function getNewEvent() {
      setIsLoading(true);

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
        }
      };

      const { data: response } = await api.get("user/verifydata", config);

      setNewEvent(response.newEvent);

      setIsLoading(false);
    }

    getNewEvent();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Selecione a reserva</ModalHeader>
          <ModalBody>
            <Content flexDir={"column"}>
              {options.map((res: any) => {
                return (
                  <Button
                    flexDir={"column"}
                    alignItems={"start"}
                    padding={"10px !important"}
                    height={"auto"}
                    onClick={() => {
                      navigate("/booking-request/details", { state: { id: res.id, isAdmin: false }})
                    }}
                  >
                    <span>
                      {format(parseISO(res.date), "dd/MM/yyyy")}
                    </span>
                    <span>
                      {res.localName}
                    </span>
                  </Button>
                )
              })}
            </Content>
          </ModalBody>
          <ModalFooter
            display={"flex"}
            justifyContent={"space-between"}
            flexDir={"row-reverse"}
          >
            <Button
              onClick={onClose}
              background={"#d36262"}
              color={"#fff"}
              _hover={{
                _disabled: {
                  background: "#d36262"
                }
              }}
            >
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Button 
        colorScheme='teal' 
        size='md'
        onClick={async () => {
          if(visit){
            if(await hasPermission(0, 'Adicionar')){
            dispatch(resetCurrentVisitCreate())
            navigate("/visit/register", { })
            }else{
              setIsLoading(false)
              toast.error("Por favor, verificar o perfil de acesso!")
            }
          }

          dispatch(resetCurrentBookingRequest())
          
          setIsLoading(true)

          if(await hasPermission(1, 'Adicionar')){
            const fromTo = [
              { 
                num: 'FALSE', 
                value: 'false' 
              },
              { 
                num: 'TRUE', 
                value: 'true' 
              },
              { 
                num: 'BOTH', 
                value: 'both' 
              }
            ];

            const decoded = jwt_decode(localStorage.getItem('access_portal:access_token') as string) as IUserData

            const config = {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
              }
            }

            try {
              const { data } = await api.get(`/commonAreaRequest/checkAvailability`, config);

              if(data.checkAvailability) {
                if(!data.newBooking){
                  toast.info(decoded.subModuleId == '5'
                    ? 'A quantidade máxima de reservas que podem ser feitas por seu departamento foi atingida.'
                    : 'A quantidade máxima de reservas que podem ser feitas por sua unidade privativa foi atingida.'
                  )
                } else {
                  navigate("/booking-request/register", {
                    state: {
                      singlePersonEvent: fromTo.filter(res => res.num === data.singlePersonEvent)[0].value,
                      // guest_type: ,
                      editar: false
                    }
                  })
                }
              } else {
                if(data && data.requests && data.requests.length > 1){
                  onOpen()

                  setOptions(data.requests)
                } else {
                  navigate("/booking-request/details", {
                    state: {
                      id: data && data.requests && data.requests[0].id,
                      isAdmin: false
                    }
                  })
                }
              }
            } catch(err) {

            }

            setIsLoading(false)
          }else{
            setIsLoading(false)
            toast.error("Por favor, verificar o perfil de acesso!")
          }
        }}
        isDisabled={isLoading}
        _disabled={{
          background: "teal",
          width: "131px"
        }}
      >
        {isLoading
          ? (
              <CircularProgress
                color={"#fff"}
                isIndeterminate
                w={"20px"}
                h={"20px"}
                size={"20px"}
              />
            )
          : visit ? 'Nova Visita' : newEvent ? "+ Nova Reserva" : "+ Nova Solicitação"
        }
      </Button>
    </>
  );
}