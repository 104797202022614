import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IData {
    isLogged: boolean
}

const initialState = { 
    data: { 
        isLogged: false
  } as IData
}
  
export const loginStatusSlice = createSlice({
name: 'loginStatus',
initialState,
reducers: {
    setLoginStatus: (state, action: PayloadAction<IData>) => {
        state.data = action.payload
    },
},
})

export const { setLoginStatus } = loginStatusSlice.actions

export default loginStatusSlice.reducer