import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  FormLabel,
  Select as SelectInput,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from '@chakra-ui/react';

import jwt_decode from "jwt-decode";

import api from '../../services/api';

import { External } from './components/External';

import { Internal } from './components/Interno';

import { PropsContatos } from '../../interfaces/IContacts';

import { IUserData } from '../../interfaces/IUserData';

import { ContactFormSeparated } from '../../pages/Contacts/FormSeparated';

interface ISelectContactsProps {
  guestType: 'EXTERNAL' | 'INTERNAL' | 'BOTH';
  contactsSelecteds: PropsContatos[];
  showButtonChangeReleaseType: boolean;
  saveFunction: (data: any) => void;
  isDisabled?: boolean;
}

export function SelectContacts({
  guestType,
  contactsSelecteds,
  showButtonChangeReleaseType,
  saveFunction,
  isDisabled,
}: ISelectContactsProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [internalData, setInternalData] = useState<PropsContatos[]>([]);
  const [externalData, setExternalData] = useState<PropsContatos[]>([]);
  const [preSelectData, setPreSelectData] = useState<PropsContatos[]>([]);
  const [subModuleId, setSubModuleId] = useState('');
  const [tabOrder, setTabOrder] = useState(['Contatos']);
  const [showContactCreation, setShowContactCreation] = useState(false);
  const [showContactCreationButton, setShowContactCreationButton] = useState(true);

  useEffect(() => {
    const userToken = localStorage.getItem('access_portal:access_token');

    const decoded = jwt_decode(userToken as string) as IUserData;

    if(userToken){
      setSubModuleId(decoded.subModuleId)
    }else{
      setSubModuleId('0')
    }

    if(decoded.subModuleId === '4') {
      if(guestType === 'BOTH') {
        setTabOrder(['Contatos', 'Moradores'])
      } else if(guestType === 'EXTERNAL') {
        setTabOrder(['Contatos'])
      } else {
        setTabOrder(['Moradores'])
      }
    } else {
      if(guestType === 'BOTH') {
        setTabOrder(['Colaboradores', 'Contatos'])
      } else if(guestType === 'EXTERNAL') {
        setTabOrder(['Contatos'])
      } else {
        setTabOrder(['Colaboradores'])
      }
    }
  }, []);

  useEffect(() => {
    reloadContacts();
  }, [isOpen === true]);

  useEffect(() => {
    setPreSelectData([ ...externalData, ...internalData ].filter(res => res.selected === true));
  }, [externalData, internalData]);

  function reloadContacts() {
    if(isOpen === true && guestType !== 'INTERNAL') {
      loadContactsExternal();
    }

    if(contactsSelecteds.filter(res => res.guestType === 'INTERNAL').length > 0) {
      setInternalData(contactsSelecteds.filter(res => res.guestType === 'INTERNAL').sort((a, b) => {
        if (a.favorite) {
          return -1;
        } else {
          return 0;
        }
      }));
    }
  }

  async function loadContactsExternal() {
    const userToken = localStorage.getItem('access_portal:access_token');

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    };

    try{
      const { data } = await api.get(`/contact/getContactsByUser`, config);
      
      let contacts = data.map((res: PropsContatos) => {
        return {
          id: res.id,
          name: res.name,
          guestReleaseType: 0,
          editable: false,
          guestType: 'EXTERNAL',
          favorite: res.favorite,
          email: res?.email,
          cellPhone: res?.cellPhone
        } as PropsContatos
      });

      const newContats2 =  JSON.parse(JSON.stringify(contactsSelecteds.filter(res => res.guestType === 'EXTERNAL')));

      contacts.map((v: any) => {
        if(!(contactsSelecteds.filter(res => res.guestType === 'EXTERNAL').filter(res => res.id === v.id).length > 0)) {
          newContats2.push({...v, selected: false })
        }
      });

      const newContats = Object.assign(newContats2, contactsSelecteds.filter(res => res.guestType === 'EXTERNAL'));

      setExternalData(newContats);
    } catch(e: any) {
        
    }
  }

  function handleChangeTypeLiberaion(e: any, i: number, typeGuest: 'EXTERNAL' | 'INTERNAL') {
    let newData = JSON.parse(JSON.stringify(typeGuest === 'EXTERNAL' ? externalData : internalData ));
    
    newData[i].guestReleaseType = parseInt(e.target.value);
    newData[i].editable = !newData[i].editable;
    
    if(typeGuest === 'EXTERNAL') {
      setExternalData(newData);
    } else {
      setInternalData(newData);
    }
  }

  return (
    <>
    <Flex flexDir={["column", "row"]}>
      <Button
        colorScheme={"teal"}
        onClick={onOpen}
        isDisabled={isDisabled}
      >
        Ver lista de convidados
      </Button>
    </Flex>
    <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader padding={0}></ModalHeader>
        <ModalBody padding={0}>
          {!showContactCreation && 
            <>
              <Tabs>
                <TabList>
                  {tabOrder.map((res, i) => {
                    return <Tab onClick={(e: any) => {
                      setShowContactCreationButton(e.target.innerText === "Contatos")
                    }} key={'tab_'+i}>{res}</Tab>
                  })}
                </TabList>
                <TabPanels padding={'12px'}>
                  {tabOrder.map((res, i) => {
                    if(res === 'Contatos') {
                      return (
                        <TabPanel padding={0} key={'tab_'+i}>
                          <External
                            contactsSelecteds={preSelectData.filter(res => res.guestType === 'EXTERNAL')}
                            data={externalData}
                            handleChangeTypeLiberaion={handleChangeTypeLiberaion}
                            setData={setExternalData}
                            showButtonChangeReleaseType={showButtonChangeReleaseType}
                          />
                        </TabPanel>
                      )
                    } else {
                      return (
                        <TabPanel padding={0}>
                          <Internal
                            contactsSelecteds={preSelectData.filter(res => res.guestType === 'INTERNAL')}
                            data={internalData}
                            handleChangeTypeLiberaion={handleChangeTypeLiberaion}
                            setData={setInternalData}
                            showButtonChangeReleaseType={showButtonChangeReleaseType}
                          />
                        </TabPanel>
                      )
                    }
                  })}
                </TabPanels>
              </Tabs>
              {showContactCreationButton && 
                <Box padding={'0px 20px'}>
                  <Button onClick={() => setShowContactCreation(true)} >
                    Novo contato
                  </Button>
                </Box>
              }
            </>
          }
          {showContactCreation &&
            <ContactFormSeparated
              onCloseForm={() => setShowContactCreation(false)}
              onCreateNewContact={reloadContacts}
            />
          }
        </ModalBody>
        <ModalFooter display={"flex"} justifyContent={"space-between"}>
            <Button
              background={"#d36262"}
              color={"#fff"}
              _hover={{
                _disabled: {
                  background: "#d36262"
                }
              }}
              onClick={() => {
                setShowContactCreation(false)
                onClose()
              }}
            >
              Fechar
            </Button>
            {!showContactCreation && 
            <Button
              colorScheme={"teal"}
              onClick={() => {
                saveFunction(preSelectData)
                setShowContactCreation(false)
                onClose()
              }}
            >
              Salvar
            </Button>
            }
          </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  );
}