import styled from 'styled-components';

import {
  Box,
  BoxProps
} from '@chakra-ui/react'

export const Container = styled(Box)<BoxProps>`
  > svg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: #b5b5b5;
  }
`;
