import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { 
    Flex, 
    Box, 
    InputGroup, 
    Input, 
    Button, 
    Divider,
    Image,
    FormControl,
    FormLabel,
    Switch
} from '@chakra-ui/react';

import jwt_decode from "jwt-decode";

import { toast } from 'react-toastify';

import api from '../../services/api';

import { setCurrentUser } from '../../store/reducers/user';

import { setLoginStatus } from '../../store/reducers/loginStatus';

import { ButtonContainer } from './styles';

import Frame from '../../assets/images/Frame1.png';

import Logo from '../../assets/images/logo.png';

import { PasswordInput } from '../../components/InputPassword';

import { setMenuParameterization } from '../../store/reducers/menuParameterization';

import { IUserData } from '../../interfaces/IUserData';

export function Login () {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [fields, setFields] = useState<any>({
        login: localStorage.getItem('access_portal:remember_login') ? localStorage.getItem('access_portal:remember_login') : '',
        password: ''
    });

    const [rememberLogin, setRememberLogin] = useState(false);

    useEffect(() => {
        localStorage.removeItem('access_portal:access_token');

        if(/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
            localStorage.setItem('access_portal:compatibilityModal', 'true');
        } else {
            localStorage.setItem('access_portal:compatibilityModal', 'false');
        }
        
        dispatch(setLoginStatus({ isLogged: false }));
    }, [])

    const execAuth = async () => {
        if(!fields.login){
            return toast.error("O campo Login é obrigatório.");
        }
      
        if(!fields.password){
            return toast.error("O campo Senha é obrigatório.");
        };
          
        const post = {
            login: fields.login.trim(),
            password: fields.password
        };

        try{
            const { data } = await api.post('login/login', post);

            localStorage.setItem('access_portal:access_token', data.login.token);

            api.defaults.headers.Authorization = `Bearer ${data.login.token}`;

            const decoded = jwt_decode(data.login.token as string) as IUserData;

            if(decoded.appAccess === 'False') {
                return toast.error("Acesso negado! Esse login não possui permissão para acessar este aplicativo.");
            }

            dispatch(setLoginStatus({ isLogged: true }));

            const { data: menuParameterization } = await api.get("login/menuParameterizationByUser");

            dispatch(setMenuParameterization(menuParameterization));

            if(rememberLogin) {
                localStorage.setItem('access_portal:remember_login', fields.login.trim());
            }else{
                localStorage.removeItem('access_portal:remember_login');
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${data.login.token}`
                }
            };

            const { data: userData } = await api.get('user/' + decoded.user_id, config);

            dispatch(setCurrentUser({
                userId: Number(decoded.user_id),
                name: userData.name.toLocaleLowerCase(),
                function: userData.userType,
                email: userData.email,
                phone: userData.phoneNumber,
                gender: userData.gender,
                type: userData.userType,
                cpf: userData?.cpf,
                rg: userData.rg,
                birthDate: userData.birthDate
                    ? userData.birthDate.split("T")[0]
                    : "",
                driver: userData.driver,
                picture: userData.fileData
                    ? "data:image/jpeg;base64," + userData.fileData.replace("data:image/jpeg;base64,", '')
                    : ""
            }));
            
            navigate("/home");
        } catch (error: any) {
            if(error && 
                error.response &&
                error.response.data &&
                error.response.data.Message === 'Usuário bloqueado'
            ) {
                toast.error("Morador/Dependente está desativado! por favor entrar em contato com a administração.");
            } else {
                if (error && error.response && error.response.data) {
                    toast.error(error.response.data);
                } else {
                    toast.error("Dados de acesso incorretos.");
                }
            }
        }
    }

    return (
        <Flex
            direction={'column'} 
            align={'center'} 
            justify={'center'}
            bg={["linear-gradient(0deg, rgba(211,250,230,1) 0%, rgba(242,253,245,1) 100%)", ""]}
            h={["100%", 'calc(100vh)']}
        >
            <Flex
                direction={'column'} 
                align={'center'} 
                justify={'center'}
                h={["100%", 'calc(100vh)']}
                backgroundImage={[`url(${Frame})`, ""]}
                backgroundSize={"100% auto"}
                backgroundPosition={"0px 50px"}
                backgroundRepeat={"no-repeat"}
                w={["100%", "auto"]}
                justifyContent={["flex-end", "inherit"]}
            >
                <Box
                    padding={'20px'}
                    borderRadius='lg'
                    overflow='hidden'
                    border={["0", "1px solid #eee"]}
                    position={"relative"}
                    background={["", "#fff"]}
                    width={["100%", "251px"]}
                >
                    <Box
                        padding={"0px 20px 20px 20px"}
                        display={["none", "flex"]}
                    >
                        <Image src={Logo} />
                    </Box>
                    <InputGroup marginBottom={['8px', '10px']}>
                        <Input 
                            backgroundColor={"#fff"}
                            padding={["26px 16px", "19px 16px"]}
                            width={["100%", "auto"]}
                            borderRadius={"50px"}
                            type='text' 
                            placeholder={"Digite seu login"} 
                            value={fields.login}
                            onChange={(val) => setFields({ ...fields, login: val.target.value })} 
                        />
                    </InputGroup>
                    <InputGroup marginBottom={'10px'}>
                        <PasswordInput 
                            onKeyDown={(e: any) => {
                                var key = e.which || e.keyCode;

                                if (key === 13) {
                                    execAuth();
                                }
                            }}
                            placeholder={"Digite sua senha"}
                            value={fields.password}
                            onChange={(val: any) => setFields({ ...fields, password: val.target.value })} 
                        />
                    </InputGroup>
                    <FormControl display='flex' alignItems='center'>
                        <FormLabel htmlFor='email-alerts' mb='0'>
                            Lembrar meu login?
                        </FormLabel> 
                        <Switch 
                            onChange={(e) => setRememberLogin(e.target.checked)} 
                            isChecked={rememberLogin}
                        />
                    </FormControl>
                    <ButtonContainer>
                        <Button
                            width={["100%", "auto"]}
                            padding={["26px 16px", "19px 16px"]}
                            borderRadius={"50px"}
                            colorScheme='teal'
                            size='md'
                            onClick={execAuth}
                        >
                            Entrar
                        </Button>
                    </ButtonContainer>
                    <Divider
                        marginTop={5}
                        marginBottom={5}
                        color={["transparent", "#ccc"]}
                    />
                    <Box
                        display={["flex", "initial"]}
                        flexDirection={["row", "initial"]}
                        justifyContent={["space-between", ""]}
                    >
                        <ButtonContainer>
                            <Button
                                colorScheme='teal'
                                variant='link'
                                onClick={() => navigate("/password-recovery")}
                            >
                                Esqueceu sua senha?
                            </Button>
                        </ButtonContainer>
                        <ButtonContainer>
                            <Button
                                colorScheme='teal'
                                variant='link'
                                borderRadius={"50px"}
                                onClick={() => navigate("/recover-login")}
                            >
                                Esqueceu seu login?
                            </Button>
                        </ButtonContainer>
                    </Box>
                </Box>
            </Flex>
        </Flex>
    )
}