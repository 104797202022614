import React, { useEffect, useState } from 'react'

import Calendar /*, { CalendarTileProperties, OnChangeDateCallback }*/ from 'react-calendar'

import { Container } from './styles'


interface IProps {
    minDate?: Date,
    maxDate?: Date,
    value: Date,
    onChange: /*OnChangeDateCallback*/ any,
    tileDisabled?: (props: /*CalendarTileProperties*/ any) => boolean

}

export function CalendarComponent({minDate, maxDate, value, onChange, tileDisabled}: IProps) {

    const [minDateInside, setMinDateInside] = useState(new Date())
    const [maxDateInside, setMaxDateInside] = useState(new Date())

    useEffect(() => {
        if(!minDate){
            let currentDate = new Date()
            currentDate.setFullYear(new Date().getFullYear() - 120)
            setMinDateInside(currentDate)
        }

        if(!maxDate){
            let currentDate = new Date()
            currentDate.setFullYear(new Date().getFullYear() + 20)
            setMaxDateInside(currentDate)
        }

    }, []) 

    return (
        <Container>
            <Calendar
                view={'month'}
                calendarType={'US'}
                className='calendar'
                minDate={minDate ? minDate : minDateInside}
                maxDate={maxDate ? maxDate : maxDateInside}
                tileDisabled={tileDisabled}
                value={value}
                onChange={onChange}
            />
        </Container>
    )
}