import styled from 'styled-components'

export const Container = styled.div`
    overflow-x: auto;
    width: 100%;
    box-shadow: 0px 2px 5px #ccc;
    border-radius: 8px;
    overflow: hidden;
    /* padding: 15px; */
    /* border: 1px #CCC solid; */
    /* box-shadow: 5px 5px 10px rgb(0 0 0 / 30%); */

    /* @media only screen and (max-width: 500px) {
        width: 300px;
    }

    @media only screen and (max-width: 440px) {
        width: 250px;
    }

    @media only screen and (max-width: 390px) {
        width: 200px;
    } */
    .react-calendar{
        min-width: 100%;
    }
`
