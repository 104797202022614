import React from 'react'

import { 
    Button, 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'

import { Container } from './styles'

interface ModalComponentsProps {
    title?: string
    isOpen: boolean
    onClose: () => void
    closeButtonLabel?: string
    confirmButtonLabel?: string
    onConfirmButton?: () => void
    hideButtons?: boolean
    closeX?: () => void | null
    children?: any,
    flexDir?: 'row' | 'row-reverse',
    closeButtonColor?: string,
    confirmColorScheme?: string,
}

export function ModalComponent({
    title, 
    isOpen, 
    onClose,
    closeButtonLabel,
    confirmButtonLabel,
    onConfirmButton,
    hideButtons,
    closeX,
    children,
    flexDir = "row-reverse",
    closeButtonColor = "#d36262",
    confirmColorScheme = "teal",
    ...rest
}: ModalComponentsProps) {
    
    return (
        <Container>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} {...rest}>
                <ModalOverlay />
                {!!closeX && (<ModalCloseButton />)}
                <ModalContent>
                    <ModalHeader paddingRight={"40px"}>{title}</ModalHeader>                    
                    <ModalBody>{children}</ModalBody>

                    <ModalFooter
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDir={flexDir}
                    >
                        {!hideButtons && (
                            <>
                                {confirmButtonLabel !== "" && (
                                    <Button
                                        colorScheme={confirmColorScheme}
                                        onClick={onConfirmButton}
                                    >
                                        {confirmButtonLabel}
                                    </Button>
                                )}
                                {closeButtonLabel !== "" && (
                                    <Button
                                        onClick={onClose}
                                        background={closeButtonColor}
                                        color={"#fff"}
                                        _hover={{
                                            _disabled: {
                                                background: closeButtonColor
                                            }
                                        }}
                                    >
                                        {closeButtonLabel}
                                    </Button>
                                )}
                            </>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    )
}