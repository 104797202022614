import React from 'react';

import {
  BoxProps
} from '@chakra-ui/react'

import {
  FaUser
} from 'react-icons/fa'

import { Container } from './styles';

export function DefaultAvatar({ boxSize, ...props }: BoxProps) {
  return (
    <Container
      {...props}
      boxSize={boxSize}
      borderRadius={"full"}
      background={"#fff"}
      boxShadow={"1px 1px 6px #d0d0d0"}
    >
      <FaUser />
    </Container>
  );
}