import styled from 'styled-components'
import ReactPaginate from 'react-paginate'

export const Container = styled.div`
    max-width: 100%;
    display: flex;
    padding: 30px;
    justify-content: center;
    align-items: center;
`

export const MyPaginate = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  /* padding: 0 5rem; */

  li {
    margin: 2px;
  }

  li.previous {
    margin-right: 10px;
  }

  li.next {
    margin-left: 10px;
  }

  li a {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 0.1rem 1rem;
    border: #555 1px solid;
    cursor: pointer;
    color: #555;
  }

  li.previous a,
  li.next a {
    border-color: transparent;
    height: auto;
    width: auto;
    padding: 0px;
    margin: 0px;
  }

  li.break a {
    border-color: transparent;
    margin: 0px 10px;
    height: auto;
    width: auto;
    padding: 0px;
  }

  li.active a {
    background-color: #309794;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }

  li.disabled a {
    color: grey;
  }
  
  li.disable,
  li.disabled a {
    cursor: default;
  }
`