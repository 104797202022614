import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IData {
    id: number,
    name: string,
    specie: string,
    breed: string,
    gender: string,
    size: string,
    image: string
}

const initialState = { 
    data: { 
        id: 0,
        name: '',
        specie: '',
        breed: '',
        gender: '',
        size: '',
        image: ''
  } as IData
}
  
export const contactSlice = createSlice({
    name: 'pet',
    initialState,
    reducers: {
        resetCurrentPet: (state) => {
            state.data = initialState.data
        },
        setCurrentPet: (state, action: PayloadAction<IData>) => {
            state.data = action.payload
        },
    },
})

export const { resetCurrentPet, setCurrentPet } = contactSlice.actions

export default contactSlice.reducer