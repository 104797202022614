import React from 'react'

import { Container } from './styles'


import { Header } from '../Header'
import { Footer } from '../Footer'

type Props = {
    children: JSX.Element,
}

export function Layout ({children}: Props) {

    return (
        <>
            <Header />
                <Container>
                    { children }
                </Container>
            <Footer />
        </>
        
    )
}