import React, { useState } from 'react'

import { 
    InputGroup,
    InputRightElement,
    Button,
    Input,
} from '@chakra-ui/react'

import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'

export function PasswordInput({value, onChange, placeholder = 'Senha', ...props }: any) {

    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)
  
    return (
      <InputGroup size='md'>
        <Input
          {...props}
          type={show ? 'text' : 'password'}
          onChange={onChange}
          value={value}
          size='md'
          placeholder={placeholder}
          background={"#fff"}
          padding={["26px 16px", "19px 16px"]}
          width={["100%", "auto"]}
          borderRadius={"50px"}
        />
        <InputRightElement width='4.5rem'>
          <Button
            colorScheme={"teal"}
            h='1.75rem'
            size='sm'
            marginRight={["-10px", "-18px"]}
            marginTop={["12px", "0px"]}
            borderRadius={"full"}
            onClick={handleClick}
          >
            {show ? <AiFillEyeInvisible /> : <AiFillEye />}
          </Button>
        </InputRightElement>
      </InputGroup>
    )
  }