import React from 'react'

import { useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'

import stc from 'string-to-color';

import { MdStar } from "react-icons/md"

import { 
    Heading, 
    Box,
    Text,
    Button,
    FormControl,
    FormLabel,
    Switch,
    Flex,
    Grid,
    GridItem
} from '@chakra-ui/react'

import { RootState } from '../../../store'

import { Container } from './styles';

export function ContactDetails () {

    const navigate = useNavigate()
    
    const currentContact = useSelector((state: RootState) => state.contact.data)

    return (
        <Grid templateColumns={["0% 100% 0%", "30% 40% 30%"]}>
            <GridItem colSpan={1} />
            <GridItem
                colSpan={1}
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <Box w={"100%"}>
                    <Flex
                        marginTop={["10px", "40px"]}
                        flexDir={["column", "row"]}
                        justifyContent={["center", "space-between"]}
                        alignItems={["flex-end", "flex-end"]}
                    >
                        <Flex
                            justifyContent={["center", "flex-start"]}
                            alignItems={["", "flex-end"]}
                            width={["100%", "auto"]}
                            marginBottom={["40px", "0px"]}
                        >
                            <Text
                                fontSize={[28, 20]}
                                fontWeight={600}
                                color={"#444"}
                            >
                                Detalhes do Contato
                            </Text>
                        </Flex>
                    </Flex>
                    <Container
                        flexDir={"column"}
                        padding={["0px", "20px"]}
                        borderRadius={"6px"}
                        background={["transparent", "#fff"]}
                    >
                        <Flex
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <Flex
                                width={"100px"}
                                height={"100px"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                background={stc(currentContact.name)}
                                borderRadius={"full"}
                                fontSize={40}
                                color={"#fff"}
                                position={"relative"}
                            >
                                {currentContact.name.substr(0, 1).toUpperCase()}
                                {currentContact.favorite && (
                                    <MdStar
                                        color={"#ffe124"}
                                        style={{
                                            position: "absolute",
                                            right: "-4px",
                                            bottom: "-4px",
                                        }}
                                    />
                                )}
                            </Flex>
                        </Flex>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Nome
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {currentContact.name}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Celular
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {currentContact.cellPhone === '' || currentContact.cellPhone === '+55'
                                    ? 'Não cadastrado'
                                    : currentContact.cellPhone
                                }
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                E-mail
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                            {currentContact.email === ''
                                ? 'Não cadastrado'
                                : currentContact.email
                            }
                            </Text>
                        </Box>
                        <Box>
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor='email-alerts' mb='0' color="#777">
                                    Favorito?
                                </FormLabel>
                                <Switch id='favorite' isChecked={currentContact.favorite} disabled={true} />
                            </FormControl>
                        </Box>
                        <Flex flexDir={["column", "row"]}>
                            <Button 
                                colorScheme='teal' 
                                size='md'
                                onClick={() => navigate('/contact/list')}
                            >
                                Voltar
                            </Button>
                        </Flex>
                    </Container>
                </Box>
            </GridItem>
            <GridItem colSpan={1} />
        </Grid>
    )
}