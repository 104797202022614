import React, { useRef, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { MdRemoveRedEye, MdModeEdit, MdDelete, MdStar } from "react-icons/md"

import stc from 'string-to-color';

import { toast } from 'react-toastify'

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Alert,
    AlertIcon,
    AlertDescription,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Flex,
    Box,
    Text,
    CircularProgress,
    FormLabel,
    Input
} from '@chakra-ui/react'
import { InputPhoneNoDDI } from '../../../components/InputPhoneNoDDI'
import api from '../../../services/api'

import { NoDataMessageContainer, ButtonsListContainer } from './styles'

import { resetCurrentContact, setCurrentContact } from '../../../store/reducers/contact'
import { hasPermission } from '../../../utils/check-permission'

import ReactJsAlert from "reactjs-alert"

interface EndpointResponse {
    id: number,
    name: string,
    cellPhone: string,
    email: string,
    favorite: boolean,
    userId: number,
    clientId: number
}

export function ContactList () {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    // const {isOpen2, onOpen2, onClose2} = useDisclosure()
    const cancelRef =  useRef<HTMLButtonElement>(null)
    
    const [data, setData] = useState<EndpointResponse[]>([])
    const [currentId, setCurrentId] = useState(0)
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('')
    const [showAlertPermissionError, setShowAlertPermissionError] = useState(false)
    
    const [isLoading, setIsLoading] = useState(true)
    const [disabledSaveButton, setDisabledSaveButton] = useState(false)
    
    const [ isOpenSearch, setIsOpenSearch] = useState(false)
    const [phoneToSearch, setPhoneToSearch] = useState('')
    const [nameToSearch, setNameToSearch] = useState('')

    useEffect(() => {
        init()
    }, [])

    async function init(){
        dispatch(resetCurrentContact())
        if(!(await hasPermission(4, 'Visualizar'))){
            navigate('/home')
        }
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }

            const { data } = await api.get('/contact/getContactsByUser', config)

            setData(data)

            setIsLoading(false)
        }catch(e) {

        }
    }

    async function deleteItem() {
        setDisabledSaveButton(true)

        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }
            
            await api.post('/Contact/DeleteContacts', {
                id: currentId,
                isDeleted: true
            }, config)

            init()

            setTimeout(() => {
                onClose()

                setDisabledSaveButton(false)
            }, 200)
        }catch(e: any) {
            setDisabledSaveButton(false)
            if(
                e &&
                e.response &&
                e.response.data &&
                e.response.data.Message
            ){
                setDeleteErrorMessage(e.response.data.Message)
            }
        }
    }

    async function searchContacts(){
        
        if((nameToSearch && nameToSearch.trim() == '' || nameToSearch ==null || !nameToSearch) &&
        (phoneToSearch && phoneToSearch.trim() == '' || phoneToSearch == null || !phoneToSearch)){
            //erro
            
        return toast.error("É necessário selecionar um filtro antes de pesquisar.")
        return
        }
        if(phoneToSearch && phoneToSearch.replaceAll('_', '').length<2){
            
            return toast.error("O celular informado deve ter ao menos 2 caracteres.")
            return

        }

        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }

            let url = `contact/search`
            let phone = phoneToSearch.replaceAll('+', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '').replaceAll('_', '')
            if(nameToSearch){
                url+= '?name='+nameToSearch
                if(phone){
                    url+= '&cellPhone='+phone
                }
            }else{
                if(phone){
                    url+= '?cellPhone='+phone
                }
            }

            const { data } = await api.get(url, config)

            
            let dataOrganizada = data.sort((itemA: any, itemB: any) => itemA.name > itemB.name ? 1 : -1)
            
            
            setData(dataOrganizada)
            setIsOpenSearch(false)
            // setIsLoading(false)
        }catch(e) {
            

        }

    }

    if(isLoading) {
        return (
            <Flex
                position={"relative"}
                h={"calc(100vh - 175px)"}
                w={"100%"}
                top={"0px"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
            </Flex>
        )
    }

    if(data.length < 0) {
        return (
            <NoDataMessageContainer>
                <span
                    style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: "#818181"
                    }}
                >
                    Nenhum registro cadastrado ainda!
                </span>
            </NoDataMessageContainer>
        )
    }
    
    return (
        <Box>
            <ReactJsAlert
                status={showAlertPermissionError}
                type={'error'}
                title={'Atenção!'}
                quotes={true}
                quote={'Por favor, verificar o perfil de acesso!'}
                autoCloseIn={20000}
                Close={() => setShowAlertPermissionError(false)}
                button={'OK'}
            />
            <Flex
                marginTop={["10px", "40px"]}
                marginBottom={"10px"}
                flexDir={["column", "row"]}
                justifyContent={["center", "space-between"]}
                alignItems={["flex-end", "flex-end"]}
            >
                <Flex
                    justifyContent={["center", "flex-start"]}
                    alignItems={["", "flex-end"]}
                    width={["100%", "auto"]}
                    marginBottom={["40px", "0px"]}
                >
                    <Text
                        fontSize={[28, 20]}
                        fontWeight={600}
                        color={"#444"}
                    >
                        Meus Contatos
                    </Text>
                </Flex>

                <Flex
                    width={["100%", "auto"]}
                    justifyContent={["space-between", "space-between"]}
                >

                <Button 
                    marginRight={['0px', '15px']}
                    colorScheme='teal' 
                    size='md'
                    onClick={async () => {
                        setIsOpenSearch(true)
                    }}
                >
                    Pesquisar Contato
                </Button>
                <Button 
                    colorScheme='teal' 
                    size='md'
                    onClick={async () => {
                        if(await hasPermission(4, 'Adicionar')){
                            dispatch(resetCurrentContact())
                            navigate('/contact/register')
                        }else{
                            setShowAlertPermissionError(true)
                        }
                    }}
                >
                    + Novo Contato
                </Button>
                </Flex>
            </Flex>
    
            <Flex
                display={["flex", "none"]}
                flexDirection={"column"}
            >
                {data.sort((a, b) => {
                    if (a.favorite) {
                        return -1;
                    } else {
                        return 0;
                    }
                }).map((v, i) => {
                    return (
                        <Flex
                            flexDirection={"row"}
                            background={"#fff"}
                            borderRadius={"8px"}
                            marginBottom={"10px"}
                            padding={"20px"}
                        >
                            <Flex>
                                <Flex
                                    borderRadius={"full"}
                                    w={"50px"}
                                    h={"50px"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    background={stc(v.name)}
                                    color={"#fff"}
                                    position={"relative"}
                                >
                                    {
                                        v.favorite && (
                                            <MdStar
                                                color={"#ffe124"}
                                                size={"30px"}
                                                style={{
                                                    position: "absolute",
                                                    right: "-6px",
                                                    bottom: "-10px",
                                                }}
                                            />
                                        )
                                    }
                                    {v.name.substr(0, 1).toUpperCase()}
                                </Flex>
                            </Flex>
                            <Flex
                                flexDir={"column"}
                                marginLeft={"10px"}
                                flexGrow={"1"}
                                whiteSpace={"nowrap"}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                                paddingRight={"18px"}
                            >
                                <span
                                    style={{
                                        fontSize: "16px",
                                        color: "#444",
                                        fontWeight: 600,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                    }}
                                >
                                    {v.name}
                                </span>
                                <span
                                    style={{
                                        fontSize: "14px",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                    }}
                                >
                                    {v.email === '' ? 'Não cadastrado' : v.email}
                                </span>
                            </Flex>
                            <Flex
                                justifyContent={"center"}
                                alignContent={"center"}
                            >
                                <ButtonsListContainer>
                                    <MdRemoveRedEye
                                        cursor="pointer" 
                                        fontSize={25}
                                        color={"#444"}
                                        onClick={() => {
                                            dispatch(setCurrentContact(v))
                                            navigate("/contact/details")
                                        }}
                                    />
                                    <MdModeEdit 
                                        cursor="pointer" 
                                        fontSize={25}
                                        color={"#444"}
                                        onClick={async () => {
                                            if(await hasPermission(4, 'Editar')){
                                                dispatch(setCurrentContact(v))
                                                navigate('/contact/update')
                                            }else{
                                                setShowAlertPermissionError(true)
                                            }
                                        }}
                                    />
                                    <MdDelete 
                                        cursor="pointer" 
                                        fontSize={25}
                                        color={"#444"}
                                        onClick={() => {
                                            setDeleteErrorMessage('')
                                            setCurrentId(v.id)
                                            onOpen()
                                        }} 
                                    />
                                </ButtonsListContainer>
                            </Flex>
                        </Flex>
                    )
                })}
            </Flex>
            
           
            <Box
                background={["#fff", "#fff"]}
                padding={"20px 0px"}
                borderRadius={"10px"}
                display={["none", "inherit"]}
            >
                <TableContainer display={["none", "initial"]}>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Nome</Th>
                                <Th>Celular</Th>
                                <Th>E-mail</Th>
                                <Th padding={0} maxW={"min-content"}></Th>
                                <Th padding={0} maxW={"min-content"}></Th>
                                <Th padding={0} maxW={"min-content"}></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.sort((a, b) => {
                                if (a.favorite) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }).map((v, i) => {
                                return (
                                    <Tr key={i} _hover={{ background: "#eee"}}>
                                        <Td fontSize={12}>
                                            <Flex alignItems={"center"}>
                                                <Flex>
                                                    <Flex
                                                        borderRadius={"full"}
                                                        w={"30px"}
                                                        h={"30px"}
                                                        display={"flex"}
                                                        alignItems={"center"}
                                                        justifyContent={"center"}
                                                        background={stc(v.name)}
                                                        color={"#fff"}
                                                        marginRight={"10px"}
                                                        position={"relative"}
                                                    >
                                                        {v.favorite && (
                                                            <MdStar
                                                                color={"#ffe124"}
                                                                size={"20px"}
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "-7px",
                                                                    bottom: "-7px",
                                                                }}
                                                            />
                                                        )}
                                                        {v.name.substr(0, 1).toUpperCase()}
                                                    </Flex>
                                                </Flex>
                                                {v.name}
                                            </Flex>
                                        </Td>
                                        <Td fontSize={12}>{!v.cellPhone || v.cellPhone === '' || v.cellPhone === '+55' ? 'Não cadastrado' : v.cellPhone}</Td>
                                        <Td fontSize={12}>{!v.email || v.email === '' ? 'Não cadastrado' : v.email}</Td>
                                        <Td padding={0} maxW={"min-content"}>
                                            <Flex justifyContent={"center"}>
                                                <Button
                                                    variant='link'
                                                >
                                                    <MdRemoveRedEye
                                                        cursor="pointer" 
                                                        fontSize={25}
                                                        onClick={() => {
                                                            dispatch(setCurrentContact(v))
                                                            navigate("/contact/details")
                                                        }}
                                                    />
                                                </Button>
                                            </Flex>
                                        </Td>
                                        <Td padding={0} maxW={"min-content"}>
                                            <Flex justifyContent={"center"}>
                                                <Button variant='link'>
                                                    <MdModeEdit 
                                                        cursor="pointer" 
                                                        fontSize={25}
                                                        onClick={async () => {
                                                            if(await hasPermission(4, 'Editar')){
                                                                dispatch(setCurrentContact(v))
                                                                navigate('/contact/update')
                                                            }else{
                                                                setShowAlertPermissionError(true)
                                                            }
                                                        }}
                                                    />
                                                </Button>
                                            </Flex>
                                        </Td>
                                        <Td padding={0} maxW={"min-content"}>
                                            <Flex justifyContent={"center"}>
                                                <Button variant='link'>
                                                    <MdDelete 
                                                        cursor="pointer" 
                                                        fontSize={25} 
                                                        onClick={() => {
                                                            setDeleteErrorMessage('')
                                                            setCurrentId(v.id)
                                                            onOpen()
                                                        }} 
                                                    />
                                                </Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                            
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>

            <AlertDialog
                isOpen={isOpenSearch}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>

                        <AlertDialogHeader fontSize='lg' fontWeight='bold' justifyContent={'space-between'}
                        >
                            Filtrar contatos
                            <Button
                                fontSize='lg'
                                left={['190px', '260px']}
                                top={['5px']}
                                // style={{
                                //     right: '0px',
                                //     left: ['185px', '250px'],
                                //     top: '10px'
                                // }}
                                size={'sm'}
                                _hover={{
                                    _disabled: {
                                        background: "transparent"
                                    }
                                }}
                                marginRight={'-200px'}
                                marginTop={'-45px'}
                                background={"transparent"}
                                color={"#f00"}
                                ref={cancelRef}
                                onClick={() => {
                                    setIsOpenSearch(false)
                                }}
                                // isDisabled={disabledSaveButton}
                            >
                                X
                            </Button>
                        </AlertDialogHeader>
                        
                        <AlertDialogBody>
                            <Box marginBottom={"10px"}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Nome
                                </FormLabel>
                                <Input
                                    placeholder={"Nome do contato"}
                                    background={"#fff"}
                                    onChange={(e) =>{
                                        setNameToSearch(e.target.value)
                                        
                                    }}
                                    value={nameToSearch}
                                />
                            </Box>
                            <Box marginBottom={"10px"}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Celular
                                </FormLabel>
                                <InputPhoneNoDDI
                                    style={{ background: "#fff", borderRadius: "4px", width: "100%" }}
                                    placeholder={'+55 (xx) 9xxxx-xxxx'} 
                                    onChange={(e: any) =>{
                                        setPhoneToSearch(e.target.value)
                                        
                                    }}
                                    value={phoneToSearch}
                                />
                            </Box>

                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                background={"#d36262"}
                                color={"#fff"}
                                ref={cancelRef}
                                _hover={{
                                    _disabled: {
                                        background: "#d36262"
                                    }
                                }}
                                onClick={() => {
                                    setNameToSearch('')
                                    setPhoneToSearch('')
                                    init()
                                }}
                                // isDisabled={disabledSaveButton}
                            >
                                Limpar filtros
                            </Button>
                            <Button 
                                colorScheme={"teal"} 
                                onClick={() => {
                                    searchContacts()
                                }} 
                                ml={3}
                                isDisabled={disabledSaveButton}
                            >
                                Confirmar
                            </Button>
                        </AlertDialogFooter>

                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Atenção!
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Deseja realmente excluir este contato?
                            {
                                deleteErrorMessage && deleteErrorMessage.length > 0 &&
                                <Alert status='error' style={{marginTop: 20, marginBottom: 10}}>
                                    <AlertIcon />
                                    <AlertDescription>{ deleteErrorMessage }</AlertDescription>
                                </Alert>
                            }
                            
                        </AlertDialogBody>

                        <AlertDialogFooter>
                        <Button
                            background={"#d36262"}
                            color={"#fff"}
                            ref={cancelRef}
                            _hover={{
                                _disabled: {
                                    background: "#d36262"
                                }
                            }}
                            onClick={() => {
                                setDeleteErrorMessage('')
                                onClose()
                            }}
                            isDisabled={disabledSaveButton}
                        >
                            Cancelar
                        </Button>
                        {!(deleteErrorMessage && deleteErrorMessage.length > 0) &&
                        <Button 
                            colorScheme={"teal"} 
                            onClick={() => {
                                deleteItem()
                            }} 
                            ml={3}
                            isDisabled={disabledSaveButton}
                        >
                            Confirmar
                        </Button>}
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    )
}
