import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import jwt_decode from 'jwt-decode';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Box,
  InputGroup,
  Input,
  useDisclosure,
} from "@chakra-ui/react";

import api from '../../../../../../services/api';

interface IUserDataToken {
  user_id: number
}

export function UserPasswordReset() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [minPassWordLength, setMinPassWordLength] = useState(6);

  const [maxPassWordLength, msetMxPassWordLength] = useState(8);

  const [currentPassword, setCurrentPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");

  const [validationNewPassword, setValidationNewPassword] = useState("");

  const [disbaledSaveButton, setDisabledSaveButton] = useState(false)

  useEffect(() => {
    async function getLengthPassword() {
      const config = {
        headers: {
          Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
        }
      }

      const { data } = await api.get(
        `user/GetMaxMinPass/3`,
        config
      );

      if(data.length > 0 && data[0].length > 0){
        setMinPassWordLength(data[0][0].minPassWordLength);

        msetMxPassWordLength(data[0][0].maxPassWordLength);
      }
    }

    getLengthPassword();
  }, [])

  async function handlerChangePassword() {
    if(
      currentPassword === '' ||
      newPassword === '' ||
      validationNewPassword === ''
    ) {
      return toast.error('Todos os campos são obrigatórios.')
    }

    if(newPassword.length > maxPassWordLength) {
      return toast.error(`A nova senha deve conter no máximo ${maxPassWordLength} caracteres.`);
    }

    if(newPassword.length < minPassWordLength) {
      return toast.error(`A nova senha deve conter no mínimo ${minPassWordLength} caracteres.`);
    }

    if(validationNewPassword !== newPassword) {
      return toast.error(`Senha e confirmação de senha devem ser iguais.`);
    }

    const config = {
      headers: {
        Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
      }
    }

    const userToken = localStorage.getItem('access_portal:access_token');

    const decoded = jwt_decode(userToken as string) as IUserDataToken

    setDisabledSaveButton(true)

    try {
      const post = {
        UserId: decoded.user_id,
        oldPassword: currentPassword,
        newPassword: newPassword,
        module_id: 3
      }
  
      await api.post(
        `user/changepassword`,
        post,
        config
      );

      toast.success("Senha alterada com sucesso.");

      setDisabledSaveButton(false)

      setCurrentPassword("")

      setNewPassword("")

      setValidationNewPassword("")

      onClose()
    } catch(err) {
      toast.error("Sua senha atual está incorreta.");
      setDisabledSaveButton(false)
    }
  }

  return (
    <div style={{ padding: "0px 20px" }}>
      <Button
        onClick={onOpen}
        colorScheme='teal'
        borderRadius={"20px"}
        marginTop={"20px"}
      >
        Alterar senha 
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Alterar senha</ModalHeader>
          <ModalBody>
            <Box>
              <InputGroup>
                <Input
                  placeholder={"Digite sua senha atual"}
                  value={currentPassword}
                  onChange={e => setCurrentPassword(e.target.value)}
                  type={"password"}
                />
              </InputGroup>
              <InputGroup marginTop={"10px"}>
                <Input
                  placeholder={"Digite sua nova senha"}
                  maxLength={maxPassWordLength}
                  minLength={minPassWordLength}
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  type={"password"}
                />
              </InputGroup>
              <InputGroup marginTop={"10px"}>
                <Input
                  placeholder={"Repita a nova senha"}
                  value={validationNewPassword}
                  onChange={e => setValidationNewPassword(e.target.value)}
                  type={"password"}
                />
              </InputGroup>
            </Box>
          </ModalBody>
          <ModalFooter
            display={"flex"}
            justifyContent={"space-between"}
            color={"#fff"}
          >
            <Button
              background={"#d36262"}
              mr={3}
              _hover={{
                _disabled: {
                  background: "#d36262"
                }
              }}
              isDisabled={disbaledSaveButton}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button colorScheme='teal' onClick={handlerChangePassword} isDisabled={disbaledSaveButton}>Salvar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}