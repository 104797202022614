import React from "react"

import {
    MdChevronLeft,
    MdChevronRight,
} from "react-icons/md"

import { Container, MyPaginate } from './styles'

interface IProps { 
    breakLabel?: string,
    nextLabel?: any,
    onPageChange(selectedItem: { selected: number }): void,
    pageRangeDisplayed?: number,
    pageCount: number,
    previousLabel?: any
}

export function Paginate({
    breakLabel='...', 
    nextLabel=<MdChevronRight size={35} />,
    onPageChange, 
    pageRangeDisplayed=3,
    pageCount,
    previousLabel=<MdChevronLeft size={35} />
}: IProps) {
    return (
        <Container>
            <MyPaginate 
                breakLabel={breakLabel}
                nextLabel={nextLabel}
                onPageChange={onPageChange}
                pageRangeDisplayed={pageRangeDisplayed }
                pageCount={pageCount}
                previousLabel={previousLabel}
            />
        </Container>
        
    )
}