import axios, { InternalAxiosRequestConfig } from 'axios'
import { store } from '../../src/store/'
import { setEndpointError } from '../store/reducers/endpointError'

import { isDev, isHml } from '../helpers/environment'

// import apiTarefa from '../services/apiTarefa'


let api = axios.create({
  baseURL: 'https://acessoapi.nicbrain.com.br/api/',
})

  
if (
  isDev()
) {
    api = axios.create({ 
      baseURL: 'https://devacessoapi.nicbrain.com.br/api/',
    })
}
 
if (
  isHml()
) {
    api = axios.create({ 
      baseURL: 'https://hmlacessoapi.nicbrain.com.br/api/',
    })
}

api.interceptors.request.use(config => saveRequest(config), error => Promise.reject(error))
api.interceptors.response.use(response => response, async error => await errorHandler(error))

let apiTarefa = axios.create({
  baseURL: 'https://api.nicbrain.com.br/api',
})



if (
  isDev()
) {
  apiTarefa = axios.create({ 
    baseURL: 'https://apidev.nicbrain.com.br/api',
  })
}

if (
  isHml()
) {
  apiTarefa = axios.create({ 
    baseURL: 'https://hmldev.nicbrain.com.br/api',
  })
}

apiTarefa.interceptors.request.use(config => saveRequest(config), error => Promise.reject(error))
apiTarefa.interceptors.response.use(response => response, async error => await errorHandler(error))


function saveRequest(config: InternalAxiosRequestConfig<any>) {
    store.dispatch(
        setEndpointError({
            show: false,
            title: 'Base: ' + config.baseURL + ' - Endpoint: ' + config.url,
            description: JSON.stringify(config.method === 'post' ? config.data : {}),
            name: "Sistema",
            subModuleId: '0',
            email: "kobel@kobel.com.br",
        })
    )
  
    return config
}

async function errorHandler(error: any) {
    //const config = error.response.config;
    if(
      error.response.status >= 400
      &&
      error.response.status != 401
      &&
      error.response.status < 599
    ){
      if(
        error.response.status === 400 
        && error.response
        && error.response.data
        && error.response.data.Details 
        && error.response.data.Details.InnerException === null 
        && error.response.data.Details.Message 
        && error.response.data.Details.Message.length > 0
        && (
            error.response.data.Details.Message === 'O limite máximo de classificados ativos por privativo foi atingido!'
            || error.response.data.Details.Message === 'Recurso não configurado.'
            || error.response.data.Details.Message === 'Não existe nenhum evento para este morador.'
            || error.response.data.Details.Message === 'Contato associado como visitante frequente!, por favor exclua o visitante frequente e tente novamente.'
            || error.response.data.Details.Message === 'Não existe horários de reserva disponíveis'
            || error.response.data.Details.Message === 'Solicitação de reserva não encontrada.'
            || error.response.data.Details.Message === 'Não existem espaços cadastrados para esse marco.'
            || error.response.data.Details.Message === 'Usuário bloqueado'
            || error.response.data.Details.Message === 'Biometria inválida.'
            || error.response.data.Details.Message === 'Senha inválida.'
            || error.response.data.Details.Message === 'Sua senha atual está incorreta.'
            || error.response.data.Details.Message === 'Contato associado como visitante frequente, por favor exclua o visitante frequente e tente novamente.'
            || error.response.data.Details.Message === 'Usuário não encontrado para envio de Push-Notification.'
            || error.response.data.Details.Message === 'Convidado não encontrado!'
            || error.response.data.Details.Message === 'Usuário não encontrado'
            || error.response.data.Details.Message === 'Não foi possível cadastrar nova senha. Certifique-se de que os dados estão corretos.'
            || error.response.data.Details.Message === 'Contato não encontrado.'
            || error.response.data.Details.Message === 'Animal não encontrado'
            || error.response.data.Details.Message === 'Comunicado não encontrado!'
            || error.response.data.Details.Message === 'O espaço ainda não está disponível para efetuar reservas.'
            || error.response.data.Details.Message === 'Limite semanal para uso do espaço excedido.'
            || error.response.data.Details.Message === 'Parâmetros incompletos para geração do login, por favor, entre em contato com a equipe de suporte.'
            || error.response.data.Details.Message === 'Contato associado como visitante colaborador frequente, por favor exclua o colaborador frequente e tente novamente'
            || error.response.data.Details.Message === 'O sistema identificou a chegada recente de visitante(s) e, portanto, a reserva NÃO SERÁ cancelada.'
            || error.response.data.Details.Message === 'Devido a ausência de resposta no prazo esperado a reserva em questão FOI cancelada.'
            || error.response.data.Details.Message === 'Não foi encontrado perfil dependente, por favor entre em contato com o administrador!'
            || error.response.data.Details.Message === 'Parâmetros incompletos para geração do login, por favor, entre em contato com a equipe de suporte.'
            || error.response.data.Details.Message === 'Necessário informar um e-mail e/ou um celular válidos para o contato.'
            || error.response.data.Details.Message === 'A placa informada já se encontra cadastrada em outro veículo'
            || error.response.data.Details.Message === "Login '' é inválido, pode conter apenas letras ou dígitos."
            || error.response.data.Details.Message === "Nenhum evento foi encontrado com o id informado."
            || error.response.data.Details.Message === "Esse evento foi cancelado pelo seu criador."
            || error.response.data.Details.Message === "Regra de área comum não encontrada!"
            || error.response.data.Details.Message.indexOf(' já está sendo utilizado.') > -1
            || error.response.data.Details.Message.indexOf(' já está na lista de favoritos!') > -1
            || error.response.data.Details.Message.indexOf('O e-mail informado já pertence ao contato') > -1
            || error.response.data.Details.Message.indexOf('O celular informado já pertence ao contato') > -1
            || error.response.data.Details.Message.indexOf('O e-mail e celular informados já pertencem ao contato') > -1
            || error.response.data.Details.Message.indexOf('Durante a sua jornada para a criação deste evento, de forma mais ágil, um outro usuário selecionou esse(s) mesmo(s) horário(s)') > -1
            || error.response.data.Details.Message === 'Favor, informar parâmetro valido para a consulta.'
            || error.response.data.Details.Message === 'Favor, informar apenas um parâmtros para a consulta.'
            || error.response.data.Details.Message === "Parâmetro 'placa' informado não é válido."
            || error.response.data.Details.Message === "Parâmetro 'numeroIndentificador' informado não é válido."
            || error.response.data.Details.Message === "Parâmetro 'numeroIndentificadorTid' informado não é válido."
            || error.response.data.Details.Message === 'Placa não encontrada.'
            || error.response.data.Details.Message === 'Placa está ativo para mais de um cliente.'
            || error.response.data.Details.Message === 'Identificador não encontrado.'
        )
        && !error.response.data.Details.ActualValue
      ){
        return Promise.reject(error)
      }
  
      if(
        error.response.status === 400 
        && error.response
        && error.response.data
        && error.response.data.Message
        && error.response.data.Message.length > 0
        && (
            error.response.data.Message === 'O limite máximo de classificados ativos por privativo foi atingido!'
            || error.response.data.Message === 'Recurso não configurado.'
            || error.response.data.Message === 'Não existe nenhum evento para este morador.'
            || error.response.data.Message === 'Contato associado como visitante frequente!, por favor exclua o visitante frequente e tente novamente.'
            || error.response.data.Message === 'Não existe horários de reserva disponíveis'
            || error.response.data.Message === 'Solicitação de reserva não encontrada.'
            || error.response.data.Message === 'Não existem espaços cadastrados para esse marco.'
            || error.response.data.Message === 'Usuário bloqueado'
            || error.response.data.Message === 'Biometria inválida.'
            || error.response.data.Message === 'Senha inválida.'
            || error.response.data.Message === 'Sua senha atual está incorreta.'
            || error.response.data.Message === 'Contato associado como visitante frequente, por favor exclua o visitante frequente e tente novamente.'
            || error.response.data.Message === 'Usuário não encontrado para envio de Push-Notification.'
            || error.response.data.Message === 'Convidado não encontrado!'
            || error.response.data.Message === 'Usuário não encontrado'
            || error.response.data.Message === 'Não foi possível cadastrar nova senha. Certifique-se de que os dados estão corretos.'
            || error.response.data.Message === 'Contato não encontrado.'
            || error.response.data.Message === 'Animal não encontrado'
            || error.response.data.Message === 'Comunicado não encontrado!'
            || error.response.data.Message === 'O espaço ainda não está disponível para efetuar reservas.'
            || error.response.data.Message === 'Limite semanal para uso do espaço excedido.'
            || error.response.data.Message === 'Parâmetros incompletos para geração do login, por favor, entre em contato com a equipe de suporte.'
            || error.response.data.Message === 'Contato associado como visitante colaborador frequente, por favor exclua o colaborador frequente e tente novamente'
            || error.response.data.Message === 'O sistema identificou a chegada recente de visitante(s) e, portanto, a reserva NÃO SERÁ cancelada.'
            || error.response.data.Message === 'Devido a ausência de resposta no prazo esperado a reserva em questão FOI cancelada.'
            || error.response.data.Message === 'Não foi encontrado perfil dependente, por favor entre em contato com o administrador!'
            || error.response.data.Message === 'Parâmetros incompletos para geração do login, por favor, entre em contato com a equipe de suporte.'
            || error.response.data.Message === 'Necessário informar um e-mail e/ou um celular válidos para o contato.'
            || error.response.data.Message === 'A placa informada já se encontra cadastrada em outro veículo'
            || error.response.data.Message === "Login '' é inválido, pode conter apenas letras ou dígitos."
            || error.response.data.Message === "Nenhum evento foi encontrado com o id informado."
            || error.response.data.Message === "Esse evento foi cancelado pelo seu criador."
            || error.response.data.Message === "Regra de área comum não encontrada!"
            || error.response.data.Message.indexOf(' já está sendo utilizado.') > -1
            || error.response.data.Message.indexOf(' já está na lista de favoritos!') > -1
            || error.response.data.Message.indexOf('O e-mail informado já pertence ao contato') > -1
            || error.response.data.Message.indexOf('O celular informado já pertence ao contato') > -1
            || error.response.data.Message.indexOf('O e-mail e celular informados já pertencem ao contato') > -1
            || error.response.data.Message.indexOf('Durante a sua jornada para a criação deste evento, de forma mais ágil, um outro usuário selecionou esse(s) mesmo(s) horário(s)') > -1
            || error.response.data.Message === 'Favor, informar parâmetro valido para a consulta.'
            || error.response.data.Message === 'Favor, informar apenas um parâmtros para a consulta.'
            || error.response.data.Message === "Parâmetro 'placa' informado não é válido."
            || error.response.data.Message === "Parâmetro 'numeroIndentificador' informado não é válido."
            || error.response.data.Message === "Parâmetro 'numeroIndentificadorTid' informado não é válido."
            || error.response.data.Message === 'Placa não encontrada.'
            || error.response.data.Message === 'Placa está ativo para mais de um cliente.'
            || error.response.data.Message === 'Identificador não encontrado.'
      )){
        return Promise.reject(error)
      }
  
      setTimeout(async () => {      
        let errorToSend = null;
  
        if(error){
          errorToSend = error.response 
          delete errorToSend.config['headers']
          delete errorToSend.request['_performanceLogger']
          delete errorToSend['headers']
          delete errorToSend['config']
          errorToSend = JSON.stringify(errorToSend)
        }
  
        const toSend = {
          ...store.getState().endpointError.data,
          error: errorToSend,
          files: [],
        } 
  
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`,
              'Content-Type': 'application/json; charset=utf-8'
            }
          }
          
          await apiTarefa.post('User/ReportProblems', toSend, config);
  
          store.dispatch(setEndpointError({
            ...store.getState().endpointError.data,
            show: true,
          }));
        } catch(err) {

        }
      }, 500)
    }
  
    return Promise.reject(error)
}

export default api
