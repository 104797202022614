import styled from 'styled-components';

interface IContentEditerAvatar {
  height: number
  mobileWidth?: number | null
}

export const ModalEditerAvatar = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
`;

export const ContentEditAvatar = styled.div<IContentEditerAvatar>`
  display: flex;
  flex-direction: column;
  width: 400px;
  /* height: ${props => (props.height ? `${props.height}px` : `500px`)}; */
  background: #262525;
  display: flex;
  border-radius: 4px;
  overflow: hidden;

  canvas {
    margin: 0px;
  }

  .editor {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
    flex-grow: 1;

    > canvas {
      background: rgb(221, 221, 221);
    }
  }

  @media screen and (max-width: 1024px) {
    /* height: ${props => (props.height ? `${props.height}px` : `500px`)}; */
    width: ${props => (props.mobileWidth ? `${props.mobileWidth-40}px` : `500px`)};
  }
`;

export const HeaderEditAvatar = styled.div`
  height: 40px;
  background: #ccc;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 3px 3px 0 0;
  color: #1d2129;
  font-weight: bold;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h3 {
    color: #1d2129;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &:active {
      box-shadow: none !important;
      transform: translateY(1px) !important;
      transition: all 0.15s ease;
    }
  }
`;

export const BodyEditAvatar = styled.div`
  flex-grow: 1;
  background: #fcfcfc;
  height: 400px;
`;

export const FooterEditAvatar = styled.div`
  /* height: 40px; */
  display: flex !important;
  background-color: #fcfcfc;
  flex-direction: column;

  .sizeImageContent {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 20px;

    .div_slide {
      width: 100%;
      padding: 0px 12px;
      border-radius: 20px;

      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 7px;
        background: #111111;
        outline: none;
        opacity: 0.7;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        margin: 15px 0px;
        border-radius: 20px;
      }

      .slider:hover {
        opacity: 1;
      }
      
      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background: #319795;
        cursor: pointer;
        border-radius: 50%;
      }

      .slider::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background: #319795;
        cursor: pointer;
        border-radius: 50%;
      }
      .slider::-webkit-slider-thumb:hover {
        width: 25px;
        height: 25px;
        transition: 0.4s;
      }
      .slider::-moz-range-thumb:hover {
        width: 25px;
        height: 25px;
        transition: 0.4s;
      }
    }
  }
`;