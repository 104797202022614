import styled from 'styled-components'

export const Container = styled.div`
`

export const ContainerWeekDays = styled.div`
`

export const Title = styled.span`
`

export const SubContainerWeekDays = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;

    .row1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .row2 {
        margin-top: 20px;
    }
    
`

interface WeekDaySpanProps {
    selected: boolean;
}
export const WeekDayButton = styled.button<WeekDaySpanProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  ${props => (props.selected ? '#309794' : 'transparent')};
    border: 1px solid ${props => (props.selected ? '#F5F5F5' : '#309794')}; ;
    color: ${props => (props.selected ? '#FFF' : '#000')}; ;
    padding: 6px;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
`

export const TimeForWeekDays = styled.div`
`

export const WeekFooterLabel = styled.div`
    color: red;
    font-size: 10px;
`

export const TimeFieldLabel = styled.span`
    font-weight: 500;
`

export const ContainerFieldTime = styled.div`
    display: flex;
    flex-direction: column;
    width: 110px;

    
    .time-picker-custom-class {
        color: rgb(26, 32, 44);
        border-radius: 6px;
        border: 1px solid rgb(226, 232, 240);
        padding: 0px 16px;
        width: 110px;
        height: 40px;

        .react-time-picker__wrapper {
            border: none;
        }
    }
`