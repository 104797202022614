import React, { useState, useEffect, useRef } from 'react';

import stc from 'string-to-color';

import { FaCog, FaTimes, FaCheck, FaStar, FaTrashAlt } from "react-icons/fa";

import { PaginationControl } from 'react-bootstrap-pagination-control';

import jwt_decode from "jwt-decode";

import api from '../../../../services/api';

import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Select as SelectInput,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Switch,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import Select from 'react-select';

import { toast } from 'react-toastify';

import { IUserData } from '../../../../interfaces/IUserData';

import { PropsContatos } from '../../../../interfaces/IContacts';

import { ModalComponent } from '../../../Modal';

import { InputPhone } from '../../../InputPhone';

interface IInternalProps {
  data: PropsContatos[];
  contactsSelecteds: PropsContatos[];
  setData: (data: PropsContatos[]) => void;
  handleChangeTypeLiberaion: (e: any, i: number, typeGuest: 'EXTERNAL' | 'INTERNAL') => void;
  showButtonChangeReleaseType: boolean;
}

type ISelectProps = {
  value: string;
  label: string;
}

type IDepartamentOptionsProps = {
  value: string;
  label: string;
  departament: ISelectProps[]
}

const optionsTypeLiberation = [
  {
    label: 'SOLICITAR LIBERAÇÃO POR TELEFONE',
    value: 0,
  },
  {
    label: 'LIBERAR ENTRADA AUTOMATICAMENTE',
    value: 1,
  },
  {
    label: 'AGUARDAR NA PORTARIA E INFORMAR MORADOR VIA APP',
    value: 2,
  },
];

export function Internal({
  data,
  contactsSelecteds,
  setData,
  handleChangeTypeLiberaion,
  showButtonChangeReleaseType,
}: IInternalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

  let dialogRef: any = useRef();

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPage, setTotalPage] = useState(1);

  const [qtd, setQtd] = useState(10);

  const [typeSearch, setTypeSearch] = useState<null | number>(null);

  const [searchUnitOptions, setSearchUnitOptions] = useState<ISelectProps[]>([]);

  const [departamentOptions, setDepartamentOptions] = useState<IDepartamentOptionsProps[]>([]);

  const [privateLocalOptions, setPrivateLocalOptions] = useState<any[]>([]);

  const [description, setDescription] = useState({
    name: '',
    cell: '',
    unitId: { label: "", value: "" },
    department: { label: "", value: "" },
    area: '',
    privateLocalId: { label: "", value: "" },
  });

  const [subModuleId, setSubModuleId] = useState('');

  const [filterContats, setFilterContats] = useState(false);

  const [index, setIndex] = useState<null | number>(null);

  useEffect(() => {
    const userToken = localStorage.getItem('access_portal:access_token')

    if(userToken){
      const decoded = jwt_decode(userToken as string) as IUserData
  
      setSubModuleId(decoded.subModuleId)
    }else{
      setSubModuleId('0')
    }
  }, [])

  useEffect(() => {
    if(typeSearch !== null) {
      handlerSearch();
    }
  }, [qtd, currentPage])

  async function handlerSearch() {
    const userToken = localStorage.getItem('access_portal:access_token');

    const config = {
      headers: {
        Authorization: `Bearer ${ userToken }`
      }
    };

    let url = '';

    if(typeSearch === 1) {
      url = `user/UserDirectoryList?typeSearch=1&description=${description.name}&page=${currentPage}&items=${qtd}`;
    } else if(typeSearch === 2) {
      url = `user/UserDirectoryList?typeSearch=2&description=${description.cell}&page=${currentPage}&items=${qtd}`;
    } else if(typeSearch === 3) {
      url = `user/UserDirectoryList?typeSearch=3&description=${description.unitId?.value}&page=${currentPage}&items=${qtd}`;
    } else if(typeSearch === 4) {
      url = `user/UserDirectoryList?typeSearch=4&description=${description.department?.value}&page=${currentPage}&items=${qtd}`;
    } else if(typeSearch === 5) {
      url = `user/UserDirectoryList?typeSearch=5&description=${description.privateLocalId?.value}&page=${currentPage}&items=${qtd}`;
    }

    try {
      const { data } = await api.get(url, config);

      if(data === 'Nenhum resultado encontrado para pesquisa!') {
        toast.warn(data)
      }

      setTotalPage(data.totalPages);

      let contacts = data.list.map((res: PropsContatos) => {
        return {
          id: res.id,
          name: res.name,
          guestReleaseType: 0,
          editable: false,
          guestType: 'INTERNAL',
          favorite: res.favorite
        }
      }).sort((a: any, b: any) => {
        if (a.favorite) {
          return -1;
        } else {
          return 0;
        }
      });

      const newContats2 =  JSON.parse(JSON.stringify(contactsSelecteds));

      contacts.map((v: any) => {
        if(!(contactsSelecteds.filter(res => res.id === v.id).length > 0)) {
          newContats2.push({...v, selected: false })
        }
      });

      const newContats = Object.assign(newContats2, contactsSelecteds);

      setData(newContats);

      onClose()
    } catch(e: any) {
    }
  }

  async function favoriteContact(v: PropsContatos) {
    try {
      const userToken = localStorage.getItem('access_portal:access_token');

      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      };
  
      await api.post(`user/favoriteinternalvisitor?userId=${v.id}&favorite=${!v.favorite}`, {}, config);

      handlerSearch()
    } catch(e: any) {
      toast.error(e?.response?.data?.Message);
      handlerSearch()
    }
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen2}
        leastDestructiveRef={dialogRef}
        onClose={onClose2}
      >
        <AlertDialogOverlay>
          <AlertDialogContent width={"90%"}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Atenção!
            </AlertDialogHeader>
            <AlertDialogBody>
              Deseja remover este convidado?
            </AlertDialogBody>
            <AlertDialogFooter
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Button
                background={"#d36262"}
                color={"#fff"}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
                onClick={onClose2}
              >
                Cancelar
              </Button>
              <Button
                onClick={async () => {
                  try {
                    let newData = JSON.parse(JSON.stringify(data));

                    const config = {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
                      }
                    }

                    await api.delete(`contactMilestone/delete/${newData[Number(index)].contactMilestoneId}`, config)

                    newData[Number(index)].selected = !newData[Number(index)].selected;
                    newData[Number(index)].edit = false;
                    newData[Number(index)].excludeOption = false;

                    if(showButtonChangeReleaseType) {
                      newData[Number(index)].guestReleaseType = 1;
                    }

                    toast.success('Convidado removido com sucesso!');
                    
                    setData(newData);

                    onClose2();
                  } catch(err: any) {
                    toast.error('Não foi possível remover o convidado!');
                  }
                }}
              >
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader paddingRight={"40px"}>
            <ModalCloseButton />
            {subModuleId === '4' ? 'Pesquisar moradores' : 'Pesquisar colaboradores'}
          </ModalHeader>                    
          <ModalBody>
            <Flex
              flexDir={"column"}
              w={'100%'}
              css={`> * + * {
                margin-top: 10px;
              }`}
            >
              <Box w={["100%"]}>
                <FormLabel
                  fontSize={"0.8em"}
                  color={"#777"}
                  marginBottom={"3px"}
                >
                  Nome
                </FormLabel>
                <Input
                  value={description.name}
                  placeholder='Nome'
                  isDisabled={typeSearch !== null && typeSearch !== 1}
                  onChange={(e) => {
                    setTypeSearch(e.target.value.length < 1 ? null: 1);

                    setDescription({
                      ...description,
                      name: e.target.value
                    })
                  }}
                />
              </Box>         
              <Box w={["100%"]}>
                <FormLabel
                  fontSize={"0.8em"}
                  color={"#777"}
                  marginBottom={"3px"}
                >
                  Celular
                </FormLabel>
                <InputPhone
                  style={{ background: "#fff", borderRadius: "4px", width: "100%" }}
                  disabled={typeSearch !== null && typeSearch !== 2}
                  placeholder={'+55 (xx) 9xxxx-xxxx'}
                  onChange={(e: any) =>{
                    setTypeSearch(e.target.value.length < 1 ? null: 2);

                    setDescription({
                      ...description,
                      cell: e.target.value
                    })
                  }}
                  value={description.cell}
                />
              </Box>
              <Box w={["100%"]}>
                <FormLabel
                  fontSize={"0.8em"}
                  color={"#777"}
                  marginBottom={"3px"}
                >
                  Unidade
                </FormLabel>
                <Select
                  isDisabled={typeSearch !== null && typeSearch !== 3}
                  isClearable
                  placeholder={"Selecione..."}
                  options={searchUnitOptions}
                  onChange={(val) => {
                    setTypeSearch(val === null ? null: 3);

                    setDescription({
                      ...description,
                      unitId: val ? val : { label: '', value: '' }
                    });
                  }}
                  value={description.unitId.label ? description.unitId : null}
                />
              </Box>
              {subModuleId !== '4' && (
                <Box w={["100%"]}>
                  <FormLabel
                    fontSize={"0.8em"}
                    color={"#777"}
                    marginBottom={"3px"}
                  >
                    Depart. Área
                  </FormLabel>
                  <Select
                    isDisabled={!!(typeSearch !== null && typeSearch !== 4)}
                    isClearable
                    placeholder={"Selecione..."}
                    options={departamentOptions}
                    onChange={(val) => {
                      setTypeSearch(val === null ? null: 4);
      
                      setDescription({
                        ...description,
                        department: val ? val : { label: '', value: '' }
                      });
                    }}
                    value={description.department.label ? description.department : null}
                  />
                </Box>
              )}
              {subModuleId === '4' && (
                <Box w={["100%"]}>
                  <FormLabel
                    fontSize={"0.8em"}
                    color={"#777"}
                    marginBottom={"3px"}
                  >
                    Privativo
                  </FormLabel>
                  <Select
                    isDisabled={!!(typeSearch !== null && typeSearch !== 5)}
                    isClearable
                    placeholder={"Selecione..."}
                    options={privateLocalOptions}
                    onChange={(val) => {
                      setTypeSearch(val === null ? null: 5);

                      setDescription({
                        ...description,
                        privateLocalId: val ? val : { label: '', value: '' }
                      });
                    }}
                    value={description.privateLocalId.label ? description.privateLocalId : null}
                  />
                </Box>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter
              display={"flex"}
              justifyContent={"space-between"}
              flexDir={"row-reverse"}
          >
            <Button
              colorScheme='teal'
              onClick={handlerSearch}
            >
              Pesquisar
            </Button>
            <Button
              onClick={() => {
                setDescription({
                  name: '',
                  cell: '',
                  unitId: { label: "", value: "" },
                  department: { label: "", value: "" },
                  area: '',
                  privateLocalId: { label: "", value: "" },
                });

                setTypeSearch(null);

              }}
              background={"#d36262"}
              color={"#fff"}
              _hover={{
                _disabled: {
                  background: "#d36262"
                }
              }}
            >
              Limpar filtros
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex flexDir={"row"} flexGrow={"1"}>
        <span
          style={{
            fontSize: "1rem",
            color: "#444",
            fontWeight: 600,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          Para iniciar, faça uma pesquisa no catálogo:
        </span>
      </Flex>
      <Flex
        padding={0}
        margin={"12px 0px"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Flex flexDir={"row"} flexGrow={"1"}>
          <SelectInput
            value={qtd}
            onChange={(e: any) => setQtd(e.target.value)}
            w={"100px"}
          >
            <option key={1} value={10}>10</option>
            <option key={2} value={25}>25</option>
            <option key={3} value={50}>50</option>
            <option key={4} value={100}>100</option>
          </SelectInput>
          <Flex
            marginLeft={"10px"}
            fontSize={"0.8em"}
            color={"#777"}
            flexGrow={"1"}
            alignItems={"center"}
          >itens por página</Flex>
        </Flex>
        <Button
          onClick={async () => {
            try {
              const userToken = localStorage.getItem('access_portal:access_token');

              const config = {
                headers: {
                  Authorization: `Bearer ${userToken}`
                }
              };

              const decoded = jwt_decode(userToken as string) as IUserData;
      
              const selectedClient = decoded.client_id;

              const clientId = selectedClient.split('#')[0];

              const { data } = await api.get(`unit/GetNamesByClient/${clientId}`, config);

              setSearchUnitOptions(data.map((res: { name: string, id: string }) => {
                return {
                  label: res.name,
                  value: res.id,
                }
              }));

              const { data: responseDepartmentArea } = await api.get(`departmentArea/GetByClient/${clientId}`, config);

              setDepartamentOptions(responseDepartmentArea.map((res: { name: string, id: string, department: { name: number, id: string } }) => {
                return {
                  label: `${res.department.name} - ${res.name}`,
                  value: `${res.id};${res.department.id}`,
                }
              }));

              const { data: responsePrivateLocal } = await api.get(`privateLocal/GetByClient/${clientId}`, config);

              setPrivateLocalOptions(responsePrivateLocal.map((res: { unitName: string, id: number, floor: string, name: string }) => {
                return {
                  ...res,
                  label: `${res.unitName} - Andar ${res.floor} - Apto ${res.name}`,
                  value: res.id,
                }
              }));
            } catch(err: any) {
              toast.error(
                err &&
                err.response &&
                err.response.data &&
                err.response.data.Message
              )
            }

            onOpen();
          }}
          color={"#65788a"}
          boxShadow={"0px 1px 3px #ccc"}
        >
          Pesquisar
        </Button>
      </Flex>
      <Flex paddingBottom={"12px"}>
        <FormControl display='flex' alignItems='center'>
          <FormLabel htmlFor='email-alerts' mb='0'>
            Contatos selecionados
          </FormLabel>
          <Switch
            value={String(filterContats)}
            onChange={() => {
              setFilterContats(!filterContats)
            }}
          />
        </FormControl>
      </Flex>
      <Flex flexDir={"column"} minH={"200px"}>
        {data.length > 0 
          ? data.filter(res => filterContats ? res.selected === true: res.selected === true || res.selected === false).map((v, i) => {
            return (
              <Flex
                key={"item_"+i}
                w={"100%"}
                flexDirection={"row"}
                background={v.selected ? "#eeeeee" : "#fff"}
                paddingTop={"10px"}
                paddingBottom={"10px"}
                marginBottom={"10px"}
                borderBottom={contactsSelecteds.length > 1 && (i < contactsSelecteds.length-1) ? "1px solid #eaeaea": "0px"}
                padding={"10px"}
                borderRadius={"4px"}
                position={'relative'}
              >
                <Button
                  variant={'link'}
                  style={{
                    position: 'absolute',
                    width: '25px',
                    height: '25px',
                    top: '0px',
                    left: '0px',
                  }}
                  transition={'.4s'}
                  opacity={0.8}
                  _hover={{
                    opacity: 1,
                    transition: '.4s'
                  }}
                  onClick={() => favoriteContact(v)}
                >
                  <FaStar size={25} color={v.favorite ? '#fff204' : '#888'} />
                </Button>
                <Flex>
                  <Flex
                    borderRadius={"full"}
                    w={"50px"}
                    h={"50px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    background={v.selected ? "black" : stc(v.name)}
                    color={"#fff"}
                    _hover={{
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      if(!v.edit) {
                        let newData = JSON.parse(JSON.stringify(data));
    
                        newData[i].selected = !newData[i].selected;

                        if(showButtonChangeReleaseType) {
                          newData[i].guestReleaseType = 1;
                        }
                        
                        setData(newData);
                      }
                    }}
                  >
                    {v.selected
                      ? <FaCheck />
                      : v.name.substr(0, 1).toUpperCase()}
                  </Flex>
                </Flex>
                <Flex
                  flexDir={"column"}
                  marginLeft={"10px"}
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  paddingRight={"18px"}
                  flexGrow={1}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#444",
                      fontWeight: 600,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {v.name}
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      //whiteSpace: 'normal',
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {v.editable
                      ? (
                          <SelectInput
                            placeholder={'Selecione...'}
                            className="basic-single"
                            onChange={(e) => {
                              handleChangeTypeLiberaion(e, i, 'INTERNAL')
                            }}
                            value={optionsTypeLiberation.filter(res => res.value === v.guestReleaseType)[0].value}
                            background={"#fff"}
                          >
                            {optionsTypeLiberation.map(res => {
                              return <option value={res.value}>{res.label}</option>
                            })}
                          </SelectInput>
                        )
                      : optionsTypeLiberation.filter(res => res.value === v.guestReleaseType).length > 0 && optionsTypeLiberation.filter(res => res.value === v.guestReleaseType)[0].label
                    }
                  </span>
                </Flex>
                <Flex>
                  {(v.selected && !showButtonChangeReleaseType) && (
                    <Button
                      onClick={() => {
                        let newData = JSON.parse(JSON.stringify(data))

                        newData[i].editable = !newData[i].editable
                        
                        setData(newData)
                      }}
                      colorScheme={"black"}
                      background={"black"}
                    >
                      {v.editable
                        ? <FaTimes />
                        : <FaCog />
                      }
                    </Button>
                  )}
                </Flex>
                {v.selected && v.excludeOption && (
                  <Button
                    variant={'link'}
                    position={'static'}
                    right={0}
                    top={'10px'}
                    onClick={() => {
                      onOpen2();
                      setIndex(i);
                    }}
                  >
                    <FaTrashAlt />
                  </Button>
                )}
              </Flex>
            )
          })
          : (
              <div
                style={{
                  width: '100%',
                  height: '200px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#c7c7c7',
                  fontWeight: 600,
                  fontSize: '20px',
                }}
              >
                <span>Lista vazia</span>
              </div>
            )
        }
      </Flex>
      {data.length > 1 && (
        <PaginationControl
          page={currentPage}
          total={totalPage}
          limit={1}
          changePage={(page) => {
            setCurrentPage(page)

            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          ellipsis={1}
        />
      )}
    </>
  );
}