import React from 'react';

import {
  Flex,
  Button
} from '@chakra-ui/react';

import stc from 'string-to-color';

import { useNavigate } from 'react-router-dom';

interface IGuestCard {
  contactMilestoneId: number
  unplannedMilestoneId: any
  contactFrequentWorkerId: any
  contactFrequentClosedPersonId: any
  attendance: string
  status: string
  informedOn: boolean
  guestReleaseType: {
    id: number
    name: string
  }
  contact: {
    id: number
    name: string
    isDeleted: boolean
  } | null
  internalGuest: {
    id: number
    name: string
    isDeleted: boolean
  } | null
  preRegistration: {
    id: number
    name: string
    rg: any
    attendance: string
    validated: string
    imageKeyValid: boolean
  }
  guest: any
  milestone: {
    id: number
    name: string
    autoAcceptPending: boolean
    preRegistrationRequired: boolean
  }
}

export function GuestCard({
  data,
  index,
  borderBottom,
  type
}: { 
  data: IGuestCard,
  index: number,
  borderBottom: boolean,
  type: 'Morador' | 'Colaborador' | 'Contato' | 'Outros'
}) {
  const navigate = useNavigate()

  function getStatusPreRegister(val: IGuestCard) {
    //console.log("CARD????", val);
    if(val.preRegistration?.attendance.toUpperCase() === 'TRUE') {
      if(
        val.milestone.preRegistrationRequired &&
        val.preRegistration?.validated == 'FALSE'
      ) {
        return (
          <span
            style={{
              fontSize: "10px",
              background: "#da6969",
              borderRadius: "20px",
              padding: "2px 10px",
              color: "#fff",
              fontWeight: 600,
            }}
          >
            Pré-cadastro Rejeitado
          </span>
        )
      } else if(
        val.milestone.preRegistrationRequired &&
        val.preRegistration?.validated != 'TRUE'
      ) {
        return (
          <span
            style={{
              fontSize: "10px",
              background: "#da6969",
              borderRadius: "20px",
              padding: "2px 10px",
              color: "#fff",
              fontWeight: 600,
            }}
          >
            Aguardando Validação
          </span>
        )
      } else {
        return (
          <span
            style={{
              fontSize: "10px",
              background: "#76bc76",
              borderRadius: "20px",
              padding: "2px 10px",
              color: "#fff",
              fontWeight: 600,
            }}
          >
            Presença Confirmada
          </span>
        )
      }
    }
    
    if(val.preRegistration?.attendance.toUpperCase() === 'PENDING') {
      return (
        <span
          style={{
            fontSize: "10px",
            background: "#737373",
            borderRadius: "20px",
            padding: "2px 10px",
            color: "#fff",
            fontWeight: 600,
          }}
        >
          Aguardando Validação
        </span>
      )
    }

    if(val.preRegistration?.attendance.toUpperCase() == 'FALSE') {
      return (
        <span
          style={{
            fontSize: "10px",
            background: "#da6969",
            borderRadius: "20px",
            padding: "2px 10px",
            color: "#fff",
            fontWeight: 600,
          }}
        >
          Ausência Confirmada
        </span>
      )
    }
  }

  let letra = '';

  if (data?.guest?.company) {
    letra = data.guest.company.companyName.substr(0, 1).toUpperCase();
    //letra = data.guest.name.substr(0, 1).toUpperCase();
  } else {
    if (data?.guest) {
      letra = data.guest.name.substr(0, 1).toUpperCase();
    }
    if (data?.contact) {
      letra = data.contact.name.substr(0, 1).toUpperCase();
    } else {
      if (data.internalGuest) {
        letra = data.internalGuest.name.substr(0, 1).toUpperCase();
      }
    }
  }

  //console.log('LETRA>>>>', data);
  let isDeleted = false;

  if (data.contact) {
    isDeleted = data.contact.isDeleted;
  }

  if (data.internalGuest) {
    isDeleted = data.internalGuest.isDeleted;
  }

  return (
    <Flex
      key={"item_"+index}
      flexDirection={"row"}
      background={isDeleted ? "#ffe0e0" : "#fff"}
      paddingTop={"10px"}
      paddingBottom={"10px"}
      marginBottom={"10px"}
      borderBottom={borderBottom ? "1px solid #eaeaea": "0px"}
    >
      <Flex>
        <Flex
          borderRadius={"full"}
          w={"50px"}
          h={"50px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          marginLeft={isDeleted ? "10px" : "0px"}
          background={stc(data?.contact?.name || data?.internalGuest?.name || data?.guest?.name)}
          color={"#fff"}
        >
          {letra}
          {/*data?.guest?.company
            ? data?.guest?.company
            : data?.contact
              ? data?.contact.name.substr(0, 1).toUpperCase()
              : data?.internalGuest?.name.substr(0, 1).toUpperCase()*/}
        </Flex>
      </Flex>
      <Flex
        flexDir={"column"}
        marginLeft={"10px"}
        flexGrow={"1"}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        paddingRight={"18px"}
        paddingBottom={"2px"}
      >
        <Flex marginBottom={"5px"}>
          {getStatusPreRegister(data)}
        </Flex>
        <span
          style={{
            fontSize: "16px",
            color: "#444",
            fontWeight: 600,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {data?.contact?.name || data?.internalGuest?.name || data?.guest?.name}
        </span>
        <span
          style={{
            fontSize: "14px",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {data.guestReleaseType?.name}
        </span>
        {isDeleted && (
          <span style={{ color: 'red' }}>{type} excluído</span>
        )}
        {((data.contactMilestoneId || true) && (data.preRegistration?.attendance.toUpperCase() !== 'PENDING') && (data.milestone.preRegistrationRequired && data.preRegistration?.validated !== 'TRUE')) && (
          <Flex>
            <Button
              color={"#65788a"}
              boxShadow={"0px 1px 3px #ccc"}
              onClick={() => {
                //console.log("DATA???!!", data);
                let id = data.contactMilestoneId;
                if (!data.contactMilestoneId) {
                  if (data.guest) {
                    id = data.guest.id;
                  }
                  if (data.internalGuest) {
                    id = data.internalGuest.id;
                  }
                }
                navigate('/pre-registration/details', {
                  state: {
                    id: id,
                    unplanned: !!data.unplannedMilestoneId,
                    company: data?.guest?.company,
                    internal: !!data.internalGuest
                  }
                })
              }}
            >Ver detalhes do convidado</Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}