import React, { useRef, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { ref, set } from "@firebase/database";

import { MdRemoveRedEye, MdModeEdit } from "react-icons/md";

import { resetCurrentDependent, setCurrentDependent } from '../../../../../store/reducers/dependent';

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Flex,
    Box,
    Text,
    CircularProgress,
    Switch
} from '@chakra-ui/react';

import { FirebaseRealtimeDatabase } from '../../../../../services/firebase';

import api from '../../../../../services/api';

import { ButtonsListContainer } from './styles';

import { hasPermission } from '../../../../../utils/check-permission';

import ReactJsAlert from "reactjs-alert";

interface EndpointResponse {
    id: number,
    name: string,
    phoneNumber: string,
    email: string,
    favorite: boolean,
    userId: number,
    clientId: number,
    client: {
        id: number,
        name: string,
    },
    rg: string,
    credential: boolean;
    rne: string,
    birthDate: string,
    userName: string,
    gender: string,
    relationship: string,
    imageData: string,
    driver: boolean | null,
    foreigner: boolean | null,
    active: boolean
}
export function DependentsList(){
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef =  useRef<HTMLButtonElement>(null)
    const [showAlertPermissionError, setShowAlertPermissionError] = useState(false)
    const [data, setData] = useState<EndpointResponse[]>([])
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('')
    const [disabledSaveButton, setDisabledSaveButton] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [idToUse, setIdToUse] = useState(0)
    const [isActive, setIsActive] = useState(true)

    useEffect(() => {
        init()
    }, [])

    async function init(){
        
        if(!(await hasPermission(5, 'Visualizar'))){
            navigate('/home')
        }
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }

            const { data } = await api.get('/user/getdependents', config)

            setData(data)

            setIsLoading(false)
        }catch(e) {

        }
    }

    async function handleActiveDeactiveDependent(){
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('access_portal:access_token') }`
                }
            }

            await api.post(
                `User/EnableOrDisableDependents?userId=${idToUse}&active=${!isActive}`, 
                {},
                config
            );


            const depedente = data.filter((res: EndpointResponse) => res.id === idToUse)[0];


            if(depedente.credential) {
                set(
                    ref( 
                        FirebaseRealtimeDatabase, 
                        "/middleware-creation-or-change/"+depedente.client.id+'/' + idToUse
                    ),
                    new Date().toString()
                );
            }
            
            onClose();

            setTimeout(() => {
                init()
            }, 600)           
        }catch(e) {
            console.log(e)
        }
    }

    if(isLoading) {
        return (
            <Flex
                position={"relative"}
                h={"calc(100vh - 175px)"}
                w={"100%"}
                top={"0px"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
            </Flex>
        )
    }

    return (
        <Box>
            <ReactJsAlert
                status={showAlertPermissionError}
                type={'error'}
                title={'Atenção!'}
                quotes={true}
                quote={'Por favor, verificar o perfil de acesso!'}
                autoCloseIn={20000}
                Close={() => setShowAlertPermissionError(false)}
                button={'OK'}
            />
                
            <Flex
                marginBottom={"10px"}
                flexDir={["column", "row"]}
                justifyContent={["center", "space-between"]}
                alignItems={["flex-end", "flex-end"]}
            >
                <Flex
                marginTop={["0px", "40px"]}
                justifyContent={["center", "flex-start"]}
                alignItems={["", "flex-end"]}
                width={["100%", "auto"]}
                marginBottom={["0px", "0px"]}
                opacity={["0", "1"]}
                >
                <Text
                    fontSize={[28, 20]}
                    fontWeight={600}
                    color={"#444"}
                >
                    Meus Dependentes
                </Text>
                </Flex>
                <Button 
                    colorScheme='teal' 
                    size='md'
                    onClick={async () => {
                        if(await hasPermission(5, 'Editar')){
                            dispatch(resetCurrentDependent())
                            navigate('/dependent/create')
                        }else{
                            setShowAlertPermissionError(true)
                        }
                    }
                }
                >
                    + Novo Dependente
                </Button>
            </Flex>
    {/* mobile */}
            <Flex
                display={["flex", "none"]}
                flexDirection={"column"}
            >
                {data.map((v, i) => {
                    return (
                        <Flex
                            flexDirection={"row"}
                            background={"#fff"}
                            borderRadius={"8px"}
                            marginBottom={"10px"}
                            padding={"20px"}
                        >
                           <Flex
                                flexDir={"column"}
                                marginLeft={"10px"}
                                flexGrow={"1"}
                                whiteSpace={"nowrap"}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                                paddingRight={"18px"}
                            >
                                <span
                                    style={{
                                        fontSize: "16px",
                                        color: "#444",
                                        fontWeight: 600,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                    }}
                                >
                                    {v.name}
                                </span>
                                <span
                                    style={{
                                        fontSize: "14px",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                    }}
                                >
                                    {v.email === '' ? 'Não cadastrado' : v.email}
                                </span>
                            </Flex>
                            <Flex
                                justifyContent={"center"}
                                alignContent={"center"}
                            >
                                <ButtonsListContainer>
                                    <MdRemoveRedEye
                                        cursor="none" 
                                        fontSize={25}
                                        display={'none'}
                                        color={"#ffffff00"}
                                        onClick={() => {
                                            
                                        }}
                                    />
                                    {v.active &&
                                        <MdModeEdit 
                                            cursor="pointer" 
                                            fontSize={25}
                                            color={"#444"}
                                            onClick={async () => {
                                                if(await hasPermission(5, 'Editar')){
                                                    dispatch(setCurrentDependent(v))
                                                    navigate('/dependent/update')
                                                }else{
                                                    setShowAlertPermissionError(true)
                                                }
                                            }}
                                        />
                                    }
                                    <Switch 
                                        id='active' 
                                        isChecked={v.active}
                                        marginBottom={"10px"}
                                        onChange={(e) => {
                                            setIsActive(v.active)
                                            setIdToUse(v.id)
                                            onOpen()
                                        }} 
                                    />
                                    {/* <MdDelete 
                                        cursor="pointer" 
                                        fontSize={25}
                                        color={"#444"}
                                        onClick={() => {
                                            // setDeleteErrorMessage('')
                                            // setCurrentId(v.id)
                                            // onOpen()
                                        }} 
                                    /> */}
                                </ButtonsListContainer>
                            </Flex>
                        </Flex>
                    )
                })}
            </Flex>
            {/* fim celular */}
            
            {/* desktop */}
            <Box
                background={["#fff", "#fff"]}
                padding={"20px 0px"}
                borderRadius={"10px"}
                display={["none", "inherit"]}
            >
                <TableContainer display={["none", "initial"]}>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Nome</Th>
                                <Th>Celular</Th>
                                <Th>E-mail</Th>
                                <Th padding={0} maxW={"min-content"}></Th>
                                <Th padding={0} maxW={"min-content"}></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((v, i) => {
                                return (
                                    <Tr key={i} _hover={{ background: "#eee"}}>
                                        <Td fontSize={12}>{v.name}</Td>
                                        <Td fontSize={12}>{v.phoneNumber === '' || v.phoneNumber === '+55' ? 'Não cadastrado' : v.phoneNumber}</Td>
                                        <Td fontSize={12}>{v.email === '' ? 'Não cadastrado' : v.email}</Td>
                                        
                                        <Td padding={0} maxW={"min-content"}>
                                            <Flex justifyContent={"center"}>
                                                {v.active ? <Button variant='link'>
                                                    <MdModeEdit 
                                                        cursor="pointer" 
                                                        fontSize={25}
                                                        onClick={async () => {
                                                            if(await hasPermission(5, 'Editar')){
                                                                dispatch(setCurrentDependent(v))
                                                                navigate('/dependent/update')
                                                            }else{
                                                                setShowAlertPermissionError(true)
                                                            }
                                                        }}
                                                    />
                                                </Button>
                                                :
                                                <></>}
                                            </Flex>
                                        </Td>
                                        <Td padding={0} maxW={"min-content"}>
                                            <Flex justifyContent={"center"}>
                                                <Button variant='link'>
                                                    <Switch 
                                                        id='active' 
                                                        isChecked={v.active}
                                                        marginBottom={"10px"}
                                                        onChange={(e) => {
                                                            setIsActive(v.active)
                                                            setIdToUse(v.id)
                                                            onOpen()
                                                        }} 
                                                    />
                                                </Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                            
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            {/* fim desktop */}

            <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Atenção!
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                {`Deseja realmente ${isActive? 'desativar' : 'ativar'} esse dependente?`}
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button
                                    background={"#d36262"}
                                    color={"#fff"}
                                    _hover={{
                                    _disabled: {
                                        background: "#d36262"
                                    }
                                    }}
                                    onClick={() => {
                                    onClose();
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button 
                                    colorScheme='teal'
                                    onClick={handleActiveDeactiveDependent} 
                                    ml={3}
                                >
                                    Confirmar
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>

            {/* <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Atenção!
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Deseja realmente excluir este contato?
                            {
                                deleteErrorMessage && deleteErrorMessage.length > 0 &&
                                <Alert status='error' style={{marginTop: 20, marginBottom: 10}}>
                                    <AlertIcon />
                                    <AlertDescription>{ deleteErrorMessage }</AlertDescription>
                                </Alert>
                            }
                            
                        </AlertDialogBody>

                        <AlertDialogFooter>
                        <Button
                            background={"#d36262"}
                            color={"#fff"}
                            ref={cancelRef}
                            _hover={{
                                _disabled: {
                                    background: "#d36262"
                                }
                            }}
                            onClick={() => {
                                setDeleteErrorMessage('')
                                onClose()
                            }}
                            isDisabled={disabledSaveButton}
                        >
                            Cancelar
                        </Button>
                        <Button 
                            colorScheme={"teal"} 
                            onClick={() => {
                                // deleteItem()
                            }} 
                            ml={3}
                            isDisabled={disabledSaveButton}
                        >
                            Confirmar
                        </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog> */}
        </Box>
    )
}