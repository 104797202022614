import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IData {
    id: number,
    name: string,
    phoneNumber: string,
    email: string,
    favorite: boolean,
    userId: number,
    clientId: number,
    rg: string,
    rne: string,
    birthDate: string,
    userName: string,
    gender: string,
    relationship: string,
    imageData: string,
    driver: boolean | null,
    foreigner: boolean | null,
}

const initialState = { 
    data: { 
        id: 0,
        name: '',
        phoneNumber: '',
        email: '',
        favorite: false,
        userId: 0,
        clientId: 0
  } as IData
}
  
export const dependentSlice = createSlice({
    name: 'dependent',
    initialState,
    reducers: {
        resetCurrentDependent: (state) => {
            state.data = initialState.data
        },
        setCurrentDependent: (state, action: PayloadAction<IData>) => {
            state.data = action.payload
        },
    },
})

export const { resetCurrentDependent, setCurrentDependent } = dependentSlice.actions

export default dependentSlice.reducer