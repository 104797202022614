import React from 'react';

import jwt_decode from "jwt-decode";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Text,
} from "@chakra-ui/react";

import { Content } from './stlyes'

import { GuestCard } from '../../components/ModalGuest/Components/GuestCard';

import { IUserData } from '../../../../../interfaces/IUserData';

interface IGuest {
  companyName: string
  contactMilestoneId: number
  unplannedMilestoneId: any
  contactFrequentWorkerId: any
  contactFrequentClosedPersonId: any
  attendance: string
  status: string
  informedOn: boolean
  guestReleaseType: {
    id: number
    name: string
  }
  contact: {
    id: number
    name: string
    isDeleted: boolean
  } | null
  internalGuest: {
    id: number
    name: string
    isDeleted: boolean
  } | null
  preRegistration: {
    id: number
    name: string
    rg: any
    attendance: string
    validated: string
    imageKeyValid: boolean
  }
  guest: any
  milestone: {
    id: number
    name: string
    autoAcceptPending: boolean
    preRegistrationRequired: boolean
  }
}

interface IModalGuest {
  isOpen: boolean
  onClose: () => void
  data: IGuest[]
}

export function ModalGuest({ isOpen, onClose, data }: IModalGuest) {
  const userToken = localStorage.getItem('access_portal:access_token');

  const decoded = jwt_decode(userToken as string) as IUserData;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Convidados</ModalHeader>
        <Content padding={0}>    
          <Tabs>
            <TabList>
              <Tab>Contatos</Tab>
              {data.filter(res => res?.internalGuest?.id && !res?.unplannedMilestoneId).length > 0  && (
                <Tab>{decoded.subModuleId === '4' ? 'Moradores' : 'Colaboradores'}</Tab>
              )}
              {data.filter(res => res?.unplannedMilestoneId).length > 0  && (
                <Tab>Outros</Tab>
              )}
            </TabList>

            <TabPanels>
              <TabPanel>
                {data.filter(res => !res?.internalGuest?.id && !res?.unplannedMilestoneId)?.map((v, i) => {
                  return (
                    <GuestCard
                      type={'Contato'}
                      data={v}
                      index={i}
                      key={`guest-${i}`}
                      borderBottom={data.length > 1 && (i < data.length-1)}
                    />
                  )
                })}
              </TabPanel>
              {data.filter(res => res?.internalGuest?.id && !res?.unplannedMilestoneId).length > 0 &&
                <TabPanel>
                  {data.filter(res => res?.internalGuest?.id && !res?.unplannedMilestoneId)?.map((v, i) => {
                    return (
                      <GuestCard 
                        type={decoded.subModuleId === '4' ? 'Morador' : 'Colaborador'}
                        data={v}
                        index={i}
                        key={`guest-${i}`}
                        borderBottom={data.length > 1 && (i < data.length-1)}
                      />
                    )
                  })}
                </TabPanel>
              }
              <TabPanel>
                {data.filter(res => {
                  //return !res?.internalGuest?.id && !res?.contactMilestoneId && res?.unplannedMilestoneId
                  return res?.unplannedMilestoneId
                })?.map((v, i) => {
                  return (
                    <GuestCard
                      type={'Outros'}
                      data={v}
                      index={i}
                      key={`guest-${i}`}
                      borderBottom={data.length > 1 && (i < data.length-1)}
                    />
                  )
                })}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Content>
        <ModalFooter
          display={"flex"}
          justifyContent={"space-between"}
          color={"#fff"}
        >
          <Button
            background={"#d36262"}
            color={'#fff'}
            mr={3}
            onClick={onClose}
            _hover={{
              _disabled: {
                background: "#d36262"
              }
            }}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}