import  React, { useEffect, useState } from 'react'
import { Container, ContainerWeekDays, SubContainerWeekDays, Title, WeekDayButton, TimeFieldLabel, WeekFooterLabel, ContainerFieldTime } from './styles'
import { Box, Card, CardBody, CardFooter, CardHeader, Heading, Stack, StackDivider } from '@chakra-ui/react'
import TimePicker from 'react-time-picker'

const allowedPropsDaySpan = {selected: false}


export function WeekDaysFamiliar ({setDiasDaSemanaSelecteds, diasSelectedEdit}: any){

    const [days, setDays] = useState<any>([])
    const [daysSelected, setDaysSelected] = useState<any>([])

    useEffect(() => {
        setDays([
            {name: "DOMINGO"},
            {name: "SEGUNDA-FEIRA"},
            {name: "TERÇA-FEIRA"},
            {name: "QUARTA-FEIRA"},
            {name: "QUINTA-FEIRA"},
            {name: "SEXTA-FEIRA"},
            {name: "SÁBADO"}
        ]);

        setTimeout(function() {
            if (diasSelectedEdit) {
                const arrayFormatado = [];

                for (let i = 0; i < diasSelectedEdit.length; i++) {
                    arrayFormatado.push({name: diasSelectedEdit[i]})
                }

                setDaysSelected(arrayFormatado);
            }
        }, 200);
    }, []) 

    return (
        <Container>
            {
                days.length > 0 &&
                <ContainerWeekDays>
                    <Title>Dias da semana</Title>
                    <SubContainerWeekDays>
                        <div className={'row1'}>
                        {
                            days.map((val: any, key: number) => {
                                return (
                                    <WeekDayButton
                                        selected={daysSelected.filter((v: any) => v.name?.toUpperCase() === val.name?.toUpperCase()).length > 0}
                                        key={key}
                                        onClick={() => {
                                            let newArr = []

                                            if(daysSelected?.filter((v: any) => v.name?.toUpperCase() === val.name?.toUpperCase()).length === 0){
                                                newArr = daysSelected

                                                if (val.name) {
                                                    newArr.push({ ...{name: val?.name?.toUpperCase()}, key: key })
                                                } else {
                                                    newArr.push({ ...val?.toUpperCase(), key: key })
                                                }
                                            }else{
                                                newArr = daysSelected?.filter((v: any) => v.name?.toUpperCase() !== val.name?.toUpperCase())
                                            }
                                            
                                            setDaysSelected([...newArr]);
                                            setDiasDaSemanaSelecteds([...newArr]);

                                        }}
                                    >
                                        {val.name?.substring(0, 3).toLowerCase()}
                                    </WeekDayButton>
                                )
                            })
                        }
                        </div>
                    </SubContainerWeekDays>
                </ContainerWeekDays>                
            }
        </Container>
    )
}