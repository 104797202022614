import React, { useRef, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { 
    Text,
    Button,
    FormLabel,
    Switch,
    Grid,
    GridItem,
    Flex,
    CircularProgress,
    Input,
} from '@chakra-ui/react'

import { IUserData } from '../../interfaces/IUserData'

import jwt_decode from "jwt-decode"

import apiTarefa from '../../services/apiTarefa'

import addTagOnTheWords from '../../helpers/addTagOnTheWords'

import { toast } from 'react-toastify'

import { MdSearch } from "react-icons/md"

export function Policies () {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [data, setData] = useState('')
    const [dataSearched, setDataSearched] = useState('')
    const [textToSearch, setTextToSearch] = useState('')

    useEffect(() => {

       init()

    },[])

    async function init() {
        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }

        const { data } = await apiTarefa.get(`User/getprivacypolicy`, config);

        setData(data)

        setDataSearched(data)
    }

    function getDataToShow(data: any){
        const parser = new DOMParser();

        const document = parser.parseFromString(data, "text/html");

        return document
    }

    function teste(){
        let value = textToSearch

        let textToUse = data

        if(value.trim() == ''){
            return toast.error('É necessário digitar um texto antes de pesquisar.')
        }

        if(value.length<1){
            setDataSearched(data)
        }

        let keyWords = ['body', 'p', 'div', 'span', 'class', '.']

        if(keyWords.indexOf(value) > 0) {
            return
          }


        let htmlSplit = textToUse.split('body')

        let newHtmlContent = addTagOnTheWords('span', value, htmlSplit[1])
    
        setDataSearched(htmlSplit[0]+'body'+ newHtmlContent+'body></html>')
    }

    return (
        <Grid templateColumns={["0% 100% 0%", "20% 60% 20%"]}>
            <GridItem colSpan={1} />
            <GridItem
                colSpan={1}
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <Text
                    fontSize={[28, 20]}
                    fontWeight={600}
                    color={"#444"}
                    marginBottom={"10px"}
                >
                    Políticas de Privacidade
                </Text>

                <Flex
                alignItems={'center'}
                w={'100%'}>

                    <Input 
                    margin={'10px 10px 10px 0px '}
                    
                    borderRadius={"4px"}
                    placeholder='Pesquise aqui'
                    backgroundColor={'#fff'}
                    onChange={(e) => {
                        setTextToSearch(e.target.value)
                        }}
                        >
                    </Input>
                    <Button
                    backgroundColor={'#319795'}
                    _hover={{backgroundColor: '#319795'}}
                    onClick={() => teste()}>
                        <MdSearch color={"#fff"} cursor="pointer" fontSize={25}/>
                    </Button>
                </Flex>

                <Flex
                    flexDir={"column"}
                    background={["inherit", "#fff"]}
                    w={"100%"}
                    padding={["0px", "20px"]}
                    borderRadius={"10px"}
                >
                    <iframe 
                        style={{height: '650px'}}
                        src={'data:text/html;charset=utf-8,' + encodeURI(dataSearched)}
                    >
                        
                    </iframe>
                </Flex>
            </GridItem>
            <GridItem colSpan={1} />
        </Grid>
    )
}