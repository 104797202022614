import React, { useEffect, useState, useRef } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { format, parseISO } from 'date-fns';

import jwt_decode from "jwt-decode";

import { toast } from "react-toastify";

import Select, { StylesConfig } from 'react-select';

import chroma from 'chroma-js';

import {
  Stack,
  Box,
  Text,
  Button,
  Radio, 
  RadioGroup,
  Input,
  Textarea,
  Grid,
  GridItem,
  Flex,
  FormLabel,
  CircularProgress,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { setCurrentBookingRequest, resetCurrentBookingRequest } from '../../../store/reducers/booking-request'

import { BoxWithBorder } from '../../../components/BoxWithBorder';

import { SelectContacts } from '../../../components/SelectContacts';

import { IUserData } from '../../../interfaces/IUserData';

import api from '../../../services/api';

import { ImageUpload } from '../../../components/ImageUpload';

import { PropsContatos } from '../../../interfaces/IContacts';

import { RootState } from '../../../store';

import { DateOrHoursEvent } from '../../BookingRequest/Form/components/DateOrHoursEvent';

interface IOptions {
  readonly value: number;
  readonly label: string;
  readonly guestReleaseType?: number;
}

type PropsTimesheets = {
  id: number,
  commonAreaTimesheetId: number,
  date: string,
  startTime: string,
  endTime: string,
  approval: "TRUE" | "FALSE"
}

type PropsLocals = {
  allDay: boolean
  allDayAllowed: boolean
  availableTimes: any;
  enableVirtualDoorman: boolean;
  value: string;
  label: string;
}

interface IGuest {
  selected?: boolean
  contactMilestoneId: number
  unplannedMilestoneId: any
  contactFrequentWorkerId: any
  contactFrequentClosedPersonId: any
  attendance: string
  status: string
  informedOn: boolean
  edit: boolean
  guestReleaseType: {
    id: number
    name: string
  }
  contact: {
    id: number
    name: string
  }
  internalGuest: {
    id: number
    name: string
  }
  preRegistration: {
    id: number
    name: string
    rg: any
    attendance: string
    validated: string
    imageKeyValid: boolean
  }
  guest: any
  milestone: {
    id: number
    name: string
    autoAcceptPending: boolean
    preRegistrationRequired: boolean
  },
  guestType: 'EXTERNAL' | 'INTERNAL';
  favorite: boolean;
}

interface IEvent {
  id: number
  name: string
  description: string
  startDate: string
  endDate: string
  guestCanEntry: false
  unit: {
    id: number
    name: string
  }
  apto: number
  bookingType: "Coletivo" | "Individual"
  floor: number
  privateLocalType: string
  visited: string
  intercom: number
  phoneNumber: string
  notifyUser: boolean
  client: {
    id: number
    name: string
  }
  local: {
    id: number
    name: string
    details: string
    commonAreaRuleId: number
  }
  milestone: {
    id: number
    name: string
    guest: boolean
    social: boolean
    preRegistrationRequired: boolean
    guestType: 'EXTERNAL' | 'INTERNAL' | 'BOTH'
  }
  deviceCalendarId: any
  subjectToCancellation: boolean
  guests: IGuest[]
  department: any
  departmentArea: any
  type: string
  data: string
  imageValid: boolean
  enableVirtualDoorman: boolean
  timesheets: PropsTimesheets[]
  allDay?: boolean,
}

type PropsAreaComuns = {
  localId: number,
  name: string,
  details: string,
  request: boolean,
}

type IAvailableBookingDates = {
  activeDays: string [],
  commonAreaRuleId: number,
  startDate: string,
  endDate: string,
  exceptionDate: string[],
  indisponibleDay: boolean
}

type TimesheetsProps = {
  id: number,
  date: string,
  startTime: string,
  endTime: string,
  approval: string,
  commonAreaTimesheetId: number,
}

export function EventEdit () {
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentTimeSelectRef = useRef<any>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

  const cancelRef =  useRef<HTMLButtonElement>(null);

  const dialogRef2 =  useRef<HTMLButtonElement>(null);

  const currentBookingRequest = useSelector((state: RootState) => state.bookingRequest.data)

  const [currentContactList, setCurrentContactList] = useState<PropsContatos[]>([]);

  const [title, setTitle] = useState('');

  const [rescheduling, setRescheduling] = useState<boolean>(false);

  const [description, setDescription] = useState('');

  const [subModuleId, setSubModuleId] = useState('0');

  const [messageNoLocals, setMessageNoLocals] = useState('');

  const [loading, setLoading] = useState(true);

  const [disabledButton, setDisabledButton] = useState(false);

  const [isCheckedFilterByDateAndHour, setIsCheckedFilterByDateAndHour] = useState(false);

  const [event, setEvent] = useState<IEvent>({
    id: 0,
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    guestCanEntry: false,
    unit: {
      id: 0,
      name: "",
    },
    apto: 0,
    floor: 0,
    privateLocalType: "",
    visited: "",
    intercom: 0,
    phoneNumber: "",
    notifyUser: false,
    client: {
      id: 0,
      name: "",
    },
    local: {
      id: 0,
      name: "",
      details: "",
      commonAreaRuleId: 0,
    },
    milestone: {
      id: 0,
      name: "",
      guest: false,
      social: false,
      preRegistrationRequired: false,
      guestType: 'EXTERNAL',
    },
    allDay: false,
    deviceCalendarId: null,
    subjectToCancellation: false,
    guests: [],
    department: null,
    departmentArea: null,
    type: "",
    data: "",
    imageValid: false,
    enableVirtualDoorman: false,
    bookingType: "Individual",
    timesheets: []
  });

  const [availableBookingDates, setAvailableBookingDates] = useState<IAvailableBookingDates>({
    activeDays: [],
    commonAreaRuleId: 0,
    startDate: '',
    endDate: '',
    exceptionDate: [],
    indisponibleDay: false
  });

  const [locals, setLocals] = useState<PropsLocals[]>([])

  const [imageEvent, setImageEvent] = useState("");

  const [areasComuns, setAreasComuns] = useState<PropsAreaComuns[]>([]);

  const [showButtonChangeReleaseType, setShowButtonChangeReleaseType] = useState(false);

  const [allDay, setAllDay] = useState(false);

  const [commonAreaRuleList, setCommonAreaRuleList] = useState([]);

  const [moreThanOneLocation, setMoreThanOneLocation] = useState(false);

  const [reloadLocalEHorarioDaReserva, setReloadLocalEHorarioDaReserva] = useState(true);

  useEffect(() => {
    async function getDetailsEvent() {
      const id = location && location.state && location.state.id;

      const locationTitle: string = location && location.state && location.state.title;

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
        }
      };

      const { data }:any = await api.get(`event/getdetails/${id}`, config);

      setEvent({
        ...data,
        bookingType: data.guests.length > 0 ? "Coletivo": "Individual",
        guests: data.guests.map((res: IGuest) => {
          return {
            ...res,
            edit: !!res.contactMilestoneId
          }
        })
      });

      dispatch(setCurrentBookingRequest({
        ...currentBookingRequest,
        milestoneId: data.milestone.id,
        commonAreaRuleId: data.local.commonAreaRuleId,
        localId: data.local.id,
        // reservationDate: data.startDate,
        unitId: data.unit.id,
        bookingType: data.milestone.social,
      }));

      const { data: response }: any = await api.get(`local/getwithrules`, config);

      if(data.milestone.id) {
        const virtualDoorman = ((data.milestone.guestType === 'EXTERNAL' || data.milestone.guestType === 'BOTH') && data.enableVirtualDoorman);

        setShowButtonChangeReleaseType(virtualDoorman)
      }

      setAreasComuns(response)

      setDescription(data.description)

      setTitle(data.name)

      setImageEvent(data.data ? "data:image/jpeg;base64," + data.data : "")

      setCurrentContactList(data.guests.map((res: any) => {
        return {
          edit: !!res.contactMilestoneId,
          id: res?.contact?.id || res?.internalGuest?.id,
          name: res?.contact?.name || res?.internalGuest.name,
          guestReleaseType: res.guestReleaseType.id,
          editable: false,
          selected: true,
          guestType: res?.contact?.id ? 'EXTERNAL' : 'INTERNAL'
        }
      }))

      if(locationTitle && locationTitle.length > 0) {
        setRescheduling((locationTitle === "Reagendamento de evento"));
      }

      setLoading(false)
    }

    async function getDetails() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
        }
      };

      const id = location && location.state && location.state.id;

      const { data: response }: any = await api.get(`local/getwithrules`, config);

      setAreasComuns(response);

      const { data } = await api.get(`/CommonAreaRequest/getByid/${id}`, config);

      const decoded = jwt_decode(localStorage.getItem('access_portal:access_token') as string) as IUserData;

      let horarios = data.timesheets.filter((res: any) => res.approval === "TRUE");

      const startDate: string = `${data.reservationDate.split("T")[0]}T${horarios[0].startTime}`;

      const endDate: string = `${data.reservationDate.split("T")[0]}T${horarios[horarios.length - 1].endTime}`;

      const newEvent = {
        ...event,
        unit: {
          id: data.unitId,
          name: data.unit
        },
        allDay: data.allDay,
        local: {
          id: data.localId,
          name: data.local,
          details: "",
        },
        client: {
          ...event.client,
          id: parseInt(decoded.client_id)
        },
        bookingType: data.milestoneGuest > 0 ? "Coletivo": "Individual",
        milestone: {
          ...event.milestone,
          id: data.milestoneId,
          guest: data.milestoneGuest,
          guestType: data.milestoneGuestType,
          social: data.milestoneSocial,
          name: data.milestone,
        },
        guests: data.guests && data.guests.length > 0 ? data.guests.map((res: IGuest) => {
          return {
            ...res,
            edit: !!res.contactMilestoneId
          }
        }): [],
        timesheets: data.timesheets.map((res: TimesheetsProps) => {
            return {
              commonAreaTimesheetId: res.commonAreaTimesheetId,
              date: res.date.split('T')[0]
            }
         }),
        startDate,
        endDate,
      } as IEvent

      setEvent(newEvent);

      setLoading(false);
    }

    if(location.state.id && !(location.state.title === 'Criação de evento')) {
      dispatch(resetCurrentBookingRequest());

      getDetailsEvent();
    } else {
      getDetails();
    }    
  }, [location && location.state && location.state.id]);

  useEffect(() => {
    if(event.local.commonAreaRuleId && (location && location.state && location.state.title === "Reagendamento de evento")){
      loadAvailableBookingDates();
    }
  }, [event.local.commonAreaRuleId]);

  useEffect(() => {
    if(currentBookingRequest.reservationDate && !isCheckedFilterByDateAndHour){
      if(!location?.state?.continue) {
        getAvailableBookingTimes();
      }

      loadLocals();
    }
  }, [currentBookingRequest.reservationDate]);

  useEffect(() => {
    if(reloadLocalEHorarioDaReserva) {
      setReloadLocalEHorarioDaReserva(false)
    }
  }, [currentBookingRequest.timesheets])

  async function loadAvailableBookingDates() {
    const userToken = localStorage.getItem('access_portal:access_token')

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }
    
    try{
      const { data } = await api.get(`CommonAreaRequest/GetAvailableBookingDates/${event.local.commonAreaRuleId}`, config)

      setAvailableBookingDates(data);
        
    }catch(e: any) {
        
    }
  }

  async function getAvailableBookingTimes() {  
    const userToken = localStorage.getItem('access_portal:access_token')

    const config = {
      headers: {
        Authorization: `Bearer ${ userToken }`
      }
    }

    try{
      const { data } = await api.get(`CommonAreaRequest/GetAvailableBookingTimes/${currentBookingRequest.commonAreaRuleId}/${currentBookingRequest.reservationDate}`, config)

      const val = data.timesheets.map((v: any) => {
        return {
          label: v.startTime.substring(0, 5) + ' - ' + v.endTime.substring(0, 5),
          value: v.id,
          startTime: v.startTime,
          endTime: v.endTime,
          ongoing: v.ongoing
        }
      });

      dispatch(setCurrentBookingRequest({
        ...currentBookingRequest, 
        availableBookingTimesBackup: val,
        availableBookingTimes: val
      }))
    }catch(e: any) {
      if(
        e &&
        e.response &&
        e.response.data &&
        e.response.data.Message
      ){
        toast.error(e.response.data.Message)
      }
    }
  }

  function getLocalName() {
    const id = event?.local.id;

    let name = '';
    let rules = '';
    let request = false

    for(let i = 0; i < areasComuns.length; i++){
      if(id == areasComuns[i].localId){
        name = areasComuns[i].name;
        rules = areasComuns[i].details;
        request = areasComuns[i].request;

        break
      }
    }

    return { name,  rules, request };
  }

  async function validation() {
    if(title === '' || title.length < 5){
      return toast.error('É obrigatório o título conter pelo menos 5 caracteres.')
    }

    if(description === '' || description.length < 20){
      return toast.error('É obrigatório a descrição conter pelo menos 20 caracteres.')
    }

    if(currentContactList.length < 1 && event.bookingType === 'Coletivo'){
      return toast.error('É obrigatório selecionar pelo menos um contato.')
    }

    if(location.state.title === "Reagendamento de evento" && currentBookingRequest.timesheets.length < 1) {
      return toast.error('É obrigatório selecionar pelo menos um horário.')
    }

    setDisabledButton(true)

    const decoded = jwt_decode(localStorage.getItem('access_portal:access_token') as string) as IUserData
    
    const timesheets: any = []

    const id = location && location.state && location.state.id;

    if(location.state.title === "Reagendamento de evento") {
      for (let i in currentBookingRequest.timesheets){
        console.log('isquedulini', currentBookingRequest?.timesheets[parseInt(i)])
        timesheets.push({
          commonAreaTimesheetId: currentBookingRequest?.timesheets[parseInt(i)]?.value, 
          date: currentBookingRequest?.reservationDate?.split(' ')[0],
          //date: currentBookingRequest?.timesheets[parseInt(i)].startTime
        })
      }
    } else {
      for (let i in event.timesheets){
        timesheets.push({
          commonAreaTimesheetId: event?.timesheets[parseInt(i)]?.commonAreaTimesheetId, 
          date: event?.timesheets[parseInt(i)].date
        })
      }
    }

    const startDate = location.state.title === "Reagendamento de evento"
      ? currentBookingRequest.reservationDate + ' ' + currentBookingRequest.timesheets[0].startTime.substring(0, 5)
      : event.startDate;
      
    const endDate = location.state.title === "Reagendamento de evento"
      ? currentBookingRequest.reservationDate + ' ' + currentBookingRequest.timesheets[currentBookingRequest.timesheets.length - 1].endTime.substring(0, 5)
      : event.endDate;

    let commonAreaRequest: any = {
      id: location.state.title === "Reagendamento de evento" ? 0 : id,
      allDay: event.allDay,
      automatic: !getLocalName().request,
      localId: event.local.id,
      milestoneId: event.milestone.id,
      timesheets,
      date: currentBookingRequest?.reservationDate,
      //date: event.allDay ? currentBookingRequest?.reservationDate?.split(' ')[0] : startDate,
    }

    if (!commonAreaRequest.allDay) {
      commonAreaRequest = {
        ...commonAreaRequest,
        timesheets
      }
    }

    let sendObj = {
      userId: parseInt(decoded.user_id),
      unitId: event.unit.id,
      name: title,
      description: description,
      fileKind: imageEvent.replace('data:image/jpeg;base64,', '').length > 0
        ? "base64"
        : null,
      fileData: imageEvent.replace('data:image/jpeg;base64,', '').length > 0
        ? imageEvent.replace('data:image/jpeg;base64,', '')
        : null,
      clientId: event.client.id,
      startDate,
      endDate,
      localId: event.local.id,
      milestoneId: event.milestone.id,
      contact: currentContactList.filter(res => res.guestType === 'EXTERNAL').map(v => {
        return {
          contactId: v.id,
          guestReleaseType: v.guestReleaseType
        };
      }),
      internalGuests: currentContactList.filter(res => res.guestType === 'INTERNAL').map(v => {
        return {
          userId: v.id,
          guestReleaseType: v.guestReleaseType
        };
      }),
      commonAreaRequest,
      allTimesReserved: currentBookingRequest.timesheets.length === currentBookingRequest.availableBookingTimes.length,//currentBookingRequest.allTimesReserved,
    };

    /*console.log("currentBookingRequest111", currentBookingRequest, sendObj);
    return false;*/

    setDisabledButton(true);

    if(!location.state.title) {
      sendObj = Object.assign(sendObj, { id: event.id })
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
      }
    }

    let url = location.state.title ? 'event/create' : "event/update";

    if(location.state.title === "Reagendamento de evento") {
      sendObj = Object.assign(JSON.parse(JSON.stringify(sendObj)), { OldEventId: event.id });

      url = 'event/Reschedule';
    }

    // return console.log('sendObj', sendObj)

    try {
      let response = await api.post(url, sendObj, config);

      if (response?.data?.message) {
        toast.success(response.data.message.replace(" # ", "\n\n"))
      } else {
        toast.success(location.state.title
          ? "Evento criado com sucesso!"
          : "Evento atualizado com sucesso!"
        )
      }

      setDisabledButton(false);

      if(location.state.title) {
        navigate("/home")
      } else {
        navigate(-1)
      }
    } catch (e: any) {
      setDisabledButton(false)
      if(
        e &&
        e.response &&
        e.response.data &&
        e.response.data.Message
      ){
        toast.error(e.response.data.Message)
      }
    }              
  }

  function getDateAfter4Months() {
    let date = new Date();

    date.setMonth(date.getMonth() + 4);

    return date;
  }

  function validateHoutFormat(value: string) {
    if(!(value.indexOf(':') > -1)) {
      return false;
    }
    
    const valueSplit = value.split(':')
    
    if(valueSplit.length !== 2){
      return false;
    }

    if(isNaN(parseInt(valueSplit[0])) || isNaN(parseInt(valueSplit[1]))){
      return false;
    }

    if(parseInt(valueSplit[0]) > 23 || parseInt(valueSplit[1]) > 59){
      return false;
    }   

    return true;
  }

  async function loadLocals() {
    const userToken = localStorage.getItem('access_portal:access_token');

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }
    
    try{
      let url = `event/getEventSpaceByMilestone/${currentBookingRequest.milestoneId}`;

      if(currentBookingRequest.reservationDate){
        url = `event/getEventSpaceByMilestone/${currentBookingRequest.milestoneId}/${currentBookingRequest.reservationDate}`;
      }

      const { data } = await api.get(url, config);

      setMoreThanOneLocation(data.length > 1);

      setLocals(data.map((v: any) => {
        return {
          label: v.local.name,
          value: v.local.id,
          enableVirtualDoorman: v.enableVirtualDoorman,
          allDayAllowed: currentBookingRequest.reservationDate ? v.allDayAllowed : true,
          allDay: currentBookingRequest.reservationDate ? v.allDay : true ,
        }
      }));

      const data2 = await api.get(`event/getEventSpaceByUnitGuest?unitId=${currentBookingRequest.unitId}&guest=${currentBookingRequest.bookingType === 'Coletivo'}`, config);

      setCommonAreaRuleList(data2.data.map((v: any) => {
        return {
          ...v.commonAreaRule,
          localId: v.local.id,
          localName: v.local.name,
          milestoneId: v.milestone.id
        }
      }));
    } catch(e: any) {
      toast.warn('Não existem espaços cadastrados para este tipo de reserva.');

      setLocals([]);
      
      dispatch(setCurrentBookingRequest({
        ...currentBookingRequest, 
        localId: '0'
      }));
    }
  }

  function handleChangeContactGuest(data: any) {
    setCurrentContactList(data)
  }

  if(loading) {
    return (
      <Flex
        position={"relative"}
        h={"calc(100vh - 175px)"}
        w={"100%"}
        top={"0px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
      </Flex>
    )
  }

  return (
    <Grid templateColumns={["0% 100% 0%", "25% 50% 25%"]}>
      <GridItem colSpan={1} />
      <GridItem
        colSpan={1}
        paddingTop={"20px"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <AlertDialog
          isOpen={isOpen2}
          leastDestructiveRef={dialogRef2}
          onClose={onClose2}
          closeOnEsc={!disabledButton}
          closeOnOverlayClick={!disabledButton}
        >
          <AlertDialogOverlay>
            <AlertDialogContent width={"90%"}>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Atenção!
              </AlertDialogHeader>
              <AlertDialogBody>
                Deseja reagendar esse evento?
              </AlertDialogBody>
              <AlertDialogFooter
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Button
                  background={"#d36262"}
                  color={"#fff"}
                  isDisabled={disabledButton}
                  onClick={onClose2}
                  _hover={{
                    _disabled: {
                      background: "#d36262"
                    }
                  }}
                >
                  Cancelar
                </Button>
                <Box>
                  <Button
                    onClick={validation}
                    color={"#65788a"}
                    isDisabled={disabledButton}
                    boxShadow={"0px 1px 3px #ccc"}
                  >
                    Sim
                  </Button>                   
                </Box>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Atenção!
              </AlertDialogHeader>
              <AlertDialogBody>
                De onde gostaria de obter a imagem?
              </AlertDialogBody>
              <AlertDialogFooter
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={["row", "initial"]}
              >
                <Button
                  background={"#d36262"}
                  color={"#fff"}
                  _hover={{
                    _disabled: {
                      background: "#d36262"
                    }
                  }}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Box
                  display={["flex", "initial"]}
                  paddingLeft={["10px", "0px"]}
                >
                  <Button
                    colorScheme='teal'
                    onClick={() => {
                      const inputFile: any = document.getElementById('folder');

                      inputFile.click();

                      onClose();
                    }}
                  >
                    Galeria
                  </Button>
                  <Button 
                    colorScheme='teal'
                    onClick={() => {
                      const inputFile: any = document.getElementById('cam');

                      inputFile.click();

                      onClose();
                    }} 
                    ml={3}
                  >
                    Câmera
                  </Button>
                </Box>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <Text
          fontSize={[28, 20]}
          fontWeight={600}
          color={"#444"}
          marginBottom={"10px"}
        >
          {location.state.title 
            ? location.state.title
            : "Edição de Evento"
          }
        </Text>
        <Flex
          flexDir={"column"}
          background={["inherit", "#fff"]}
          w={"100%"}
          paddingBottom={["0px", "20px !important"]}
          borderRadius={"10px"}
        >
          <BoxWithBorder
            title={'Dados da Unidade'}
            padding={["0px", "20px"]}
            margin={["0px", "20px"]}
            borderWidth={["0px", "1px"]}
          >
            <>
              <Box w={["100%", "50%"]}>
                <FormLabel
                  fontSize={"0.8em"}
                  color={"#777"}
                  marginBottom={"3px"}
                >
                  Unidade
                </FormLabel>
                <Input
                  placeholder={'Selecione...'}
                  isDisabled
                  value={event.unit.name}
                />
              </Box>
              {subModuleId === '5' && (
                <Box className={'box-item-container'}  >
                  <FormLabel
                    fontSize={"0.8em"}
                    color={"#777"}
                    marginBottom={"3px"}
                  >
                    Área
                  </FormLabel>
                  <Input
                    placeholder={'Selecione...'}
                    value={getLocalName().name}
                    isDisabled
                  />
                </Box>
              )}
            </>
          </BoxWithBorder>
          <BoxWithBorder
            title={'Dados da Reserva'}
            padding={["0px", "20px"]}
            margin={["0px", "20px"]}
            borderWidth={["0px", "1px"]}
            marginTop={["50px", "0px"]}
            display={"flex"}
            flexDirection={["column", "column"]}
          >
            <>
              <Box marginTop={["10px", "0px"]}>
                <RadioGroup 
                  onChange={() => {}} 
                  value={event.bookingType}
                  isDisabled={true}
                >
                  <Stack direction='row'>
                    <Radio value='Coletivo' borderColor={"#777"}>Coletivo</Radio>
                    <Radio value='Individual' borderColor={"#777"}>Individual</Radio>
                  </Stack>
                </RadioGroup>
              </Box>
              
              <Flex
                w={"100%"}
                flexDir={["column", "row"]}
                marginTop={"10px"}
              >
                <Box
                  w={["100%", "50%"]}
                  padding={["0px 2.5px", "0px 2.5px"]}
                >
                  <FormLabel
                    fontSize={"0.8em"}
                    color={"#777"}
                    marginBottom={"3px"}
                  >
                    Tipo de Reserva
                  </FormLabel>
                    <Input
                      placeholder={'Selecione...'}
                      value={event?.milestone.name}
                      isDisabled
                    />
                    <span className='message-error'>{messageNoLocals}</span>
                </Box>
                <Box
                  w={["100%", "50%"]}
                  marginTop={["10px", "0px"]}
                >
                  <FormLabel
                    fontSize={"0.8em"}
                    color={"#777"}
                    marginBottom={"3px"}
                  >
                    Espaço para Reserva
                  </FormLabel>
                  <Input
                    placeholder={'Selecione...'}
                    isDisabled
                    value={getLocalName().name}
                  />
                </Box>
              </Flex>
            </>
          </BoxWithBorder>

          {rescheduling && (
            <DateOrHoursEvent
              disabledButton={disabledButton}
              isCheckedFilterByDateAndHour={isCheckedFilterByDateAndHour}
              locals={locals}
              setLocals={setLocals}
              onChangeEndHourFilter={() => {}}
              onChangeStartHourFilter={() => {}}
              reloadLocalEHorarioDaReserva={reloadLocalEHorarioDaReserva}
              setCommonAreaRuleList={setCommonAreaRuleList}
              setMessageNoLocals={setMessageNoLocals}
              setReloadLocalEHorarioDaReserva={setReloadLocalEHorarioDaReserva}
              valueEndHourFilter={''}
              valueStartHourFilter={''}
            />
          )}

          <BoxWithBorder
            title={'Dados do Evento'}
            padding={["0px", "20px"]}
            margin={["0px", "20px"]}
            borderWidth={["0px", "1px"]}
            display={"flex"}
            marginTop={["50px", "0px"]}
            flexDir={["column"]}
          >
            <>
              <Box marginTop={"10px"}>
                <FormLabel
                  fontSize={"0.8em"}
                  color={"#777"}
                  marginBottom={"3px"}
                >
                  Título
                </FormLabel>
                <Input
                  isDisabled={rescheduling}
                  background={"#fff"}
                  placeholder={"Informe o título do seu evento"}
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </Box>
              <Box marginTop={"10px"}>
                <FormLabel
                  fontSize={"0.8em"}
                  color={"#777"}
                  marginBottom={"3px"}
                >
                  Descrição
                </FormLabel>
                <Textarea
                  isDisabled={rescheduling}
                  background={"#fff"}
                  placeholder={"Informe a descrição do seu evento"}
                  onChange={(e) =>setDescription(e.target.value)}
                  value={description}
                  resize={"none"}
                  maxLength={1000}
                  rows={5}
                />
              </Box>

              {event.bookingType === 'Coletivo' && (
                <Box marginTop={"10px"} w={"100%"}>
                  <FormLabel
                    fontSize={"0.8em"}
                    color={"#777"}
                    marginBottom={"3px"}
                  >
                    Adicione Convidado(s)
                  </FormLabel>

                  <SelectContacts
                    guestType={event.milestone.guestType}
                    isDisabled={location.state.title === "Reagendamento de evento"}
                    contactsSelecteds={event.guests.map((res) => {
                      return {
                        edit: true,
                        excludeOption: true,
                        id: res?.contact?.id || res?.internalGuest?.id,
                        name: res?.contact?.name || res?.internalGuest.name,
                        guestReleaseType: res.guestReleaseType.id,
                        editable: false,
                        selected: true,
                        guestType: res?.contact?.id ? 'EXTERNAL' : 'INTERNAL',
                        favorite: res.favorite,
                        contactMilestoneId: res.contactMilestoneId
                      }
                    })}
                    showButtonChangeReleaseType={showButtonChangeReleaseType}
                    saveFunction={handleChangeContactGuest}
                  />
                </Box>
              )}
            </>
          </BoxWithBorder>

          <BoxWithBorder
            title={'Imagem do evento'}
            padding={["0px", "20px"]}
            margin={["0px", "20px"]}
            borderWidth={["0px", "1px"]}
            display={"flex"}
            marginTop={["50px", "0px"]}
            flexDir={["column"]}
          >
            <ImageUpload
              disabled={true}
              initialDefaultImage={imageEvent}
              responseFunction={setImageEvent}
            />
          </BoxWithBorder>
          <Flex
            flexDir={["column-reverse", "row"]}
            justifyContent={["row", "space-between"]}
            marginTop={["20px", "0px"]}
            padding={["0px", "0px 20px"]}
          >
            <Button 
              colorScheme='teal' 
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
            <Button 
              isDisabled={disabledButton}
              colorScheme='teal' 
              onClick={location.state.title === "Reagendamento de evento" ? onOpen2 : validation}
              marginBottom={["10px", "0px"]}
            >
              Finalizar
            </Button>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem colSpan={1} />
    </Grid>
  )
}