import { initializeApp } from '@firebase/app';

import { getDatabase } from "@firebase/database"

import { isDev, isHml } from '../helpers/environment'

var config = {
    apiKey: 'AIzaSyAeadkuKscrEzcNC81HmVCwH-wWbzcQFMU',
    authDomain: 'onyx-principle-243720.firebaseapp.com',
    databaseURL: 'https://onyx-principle-243720.firebaseio.com',
    projectId: 'onyx-principle-243720',
    storageBucket: 'onyx-principle-243720.appspot.com',
    messagingSenderId: '569152389548',
    appId: '1:569152389548:web:2e0567527a4c896ab17a09',
}

if (
    isDev()
) {
    config = {
        apiKey: 'AIzaSyC3evcqNwc0TPAn2_3wm_CditU6ApMuvDE',
        authDomain: 'nicbrain-rtd-dev-6a495.firebaseapp.com',
        databaseURL: 'https://nicbrain-rtd-dev-6a495-default-rtdb.firebaseio.com',
        projectId: 'nicbrain-rtd-dev-6a495',
        storageBucket: 'nicbrain-rtd-dev-6a495.appspot.com',
        messagingSenderId: '1000559163994',
        appId: '1:1000559163994:web:0cda556b4b04912bd53663',
    }
    
}

if (
    isHml()
) {
    config = {
        apiKey: "AIzaSyD9ZF88JIxYC1m6bVUN7vP_cpjnl6WJHs4",
        authDomain: "nicbrain-homol.firebaseapp.com",
        databaseURL: "https://nicbrain-homol-default-rtdb.firebaseio.com",
        projectId: "nicbrain-homol",
        storageBucket: "nicbrain-homol.appspot.com",
        messagingSenderId: "1085119914126",
        appId: "1:1085119914126:web:81ce7779038b314e34a9df"
    }
}


const app = initializeApp(config);

export const FirebaseRealtimeDatabase = getDatabase(app)

