import React from 'react'

import { Container } from './styles'

import { Header } from '../Header'

export function Footer () {

    return (
        <Container>
            © Kobel { new Date().getFullYear() } | Todos os direitos reservados.
        </Container>
    )
}