import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';

import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Flex,
    CircularProgress,
} from "@chakra-ui/react"

import jwt_decode from "jwt-decode";

import { setCurrentUser } from '../../store/reducers/user';

import { IUserData } from '../../interfaces/IUserData'

import { MyDate } from './components/MyDate';

import { DependentsList } from './components/Dependents/List';

import { PetsList } from './components/Pets/List';

import { List as VehiclesList } from './components/Vehicles/list';

import { GuestList } from './components/Guests/List';

import api from '../../services/api';

export function MeusDados () {
    const dispatch = useDispatch();

    const [index, setIndex] = useState(0);

    const [subModuleId, setSubModuleId] = useState('');

    const [typeUser, setTypeUser] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    
    const location = useLocation();

    useEffect(() => {
        async function init() {
            const userToken = localStorage.getItem('access_portal:access_token');

            const decoded = jwt_decode(userToken as string) as IUserData;

            const { data: userData } = await api.get('user/' + decoded.user_id);

            const user = {
                userId: Number(decoded.user_id),
                name: userData.name.toLocaleLowerCase(),
                function: userData.userType,
                email: userData.email,
                phone: userData.phoneNumber,
                gender: userData.gender,
                type: userData.userType,
                cpf: userData?.cpf,
                rg: userData.rg,
                birthDate: userData.birthDate
                    ? userData.birthDate.split("T")[0]
                    : "",
                driver: userData.driver,
                picture: userData.fileData
                    ? "data:image/jpeg;base64," + userData.fileData.replace("data:image/jpeg;base64,", '')
                    : ""
            };

            dispatch(setCurrentUser(user));

            setTypeUser(userData.type);

            setSubModuleId(decoded.subModuleId);

            if(location && location.state && location.state.index) {
                setIndex(location.state.index);
            }

            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }

        init();
    }, []);

    if(isLoading) {
        return (
            <Flex
                position={"relative"}
                h={"calc(100vh - 175px)"}
                w={"100%"}
                top={"0px"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
            </Flex>
        )
    }

    return (
        <>
            <Tabs index={index} isLazy>
                <TabList
                    width={["100%", "100%"]}
                    overflowX={["auto", "visible"]}
                    overflowY={["hidden", "visible"]}
                    paddingBottom={["1px", "0px"]}
                >
                    <Tab id={"0"} onClick={(e: any) => setIndex(Number(e.target.id.split("-").pop()))}>Eu</Tab>

                    {subModuleId === '4' && typeUser !== 'Dependente' && (
                        <Tab id={"1"} onClick={(e: any) => setIndex(Number(e.target.id.split("-").pop()))}>Dependentes</Tab>
                    )}

                    {subModuleId === '4' && (
                        <Tab id={"2"} onClick={(e: any)=> setIndex(Number(e.target.id.split("-").pop()))}>Visit. Freq. e Prestadores</Tab>
                    )}

                    <Tab id={"3"} onClick={(e: any) => setIndex(Number(e.target.id.split("-").pop()))}>Veículos</Tab>
                    {subModuleId === '4' && (
                        <Tab id={"4"} onClick={(e: any) => setIndex(Number(e.target.id.split("-").pop()))}>Pets</Tab>
                    )}
                </TabList>
                <TabPanels>
                    <TabPanel padding={"0px"}>
                        <MyDate />
                    </TabPanel>

                    {subModuleId === '4' && typeUser !== 'Dependente' && (
                        <TabPanel>
                            <DependentsList />
                        </TabPanel>
                    )}

                    {subModuleId === '4' && (
                        <TabPanel>
                            <GuestList />
                        </TabPanel> 
                    )}
                         
                    <TabPanel padding={"0px"}>
                        <VehiclesList setIndex={setIndex} index={index} />
                    </TabPanel>
                   
                    {subModuleId === '4' && (
                        <TabPanel>
                            <PetsList />
                        </TabPanel> 
                    )}
                </TabPanels>
            </Tabs>
        </>        
    )
}

