import React, { useEffect, useState, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useLocation, useNavigate } from 'react-router-dom'

import { ref, set, onValue, off } from "@firebase/database"

import jwt_decode from "jwt-decode"

import { MdAccessTimeFilled } from "react-icons/md"

import { 
    Heading, 
    Box,
    Text,
    Button,
    Grid,
    GridItem,
    Flex,
    CircularProgress,
    Tag,
    TagLeftIcon,
    TagLabel,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Textarea,
    useDisclosure
} from '@chakra-ui/react'

import { toast } from "react-toastify";

import api from '../../../services/api'

import { RootState } from '../../../store'

import { setCurrentBookingRequest } from '../../../store/reducers/booking-request'

import { FirebaseRealtimeDatabase } from '../../../services/firebase'

import { IUserData } from '../../../interfaces/IUserData'

import { History } from './components/History'

import { Container } from './styles'


export function BookingRequestDetails () {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const dialogRef: any = useRef();
    
    const currentEvent = useSelector((state: RootState) => state.event.data)
    const currentBookingRequest = useSelector((state: RootState) => state.bookingRequest.data)

    const [loading, setLoading] = useState(true)

    const [subModuleId, setSubModuleId] = useState('0')

    const [userId, setUserId] = useState(0)

    const [newEvent, setNewEvent] = useState(false)

    const [motive, setMotive] = useState<any>("")

    useEffect(() => {
        if(location && location.state && location.state.id) {
            startFirebaseListener()
        }
    }, [location && location.state && location.state.id])

    async function getDetails() {
        try {
            const id = location && location.state && location.state.id;

            const userToken = localStorage.getItem('access_portal:access_token')

            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            }

            const decoded = jwt_decode(userToken as string) as IUserData

            setUserId(parseInt(decoded.user_id))

            setSubModuleId(decoded.subModuleId)

            const { data: response } = await api.get("user/verifydata", config)

            setNewEvent(response.newEvent)

            const { data } = await api.get(`/CommonAreaRequest/getByid/${id}`, config)
            const { data: response2 } = await api.get(`event/getEventSpaceByUnitGuest?unitId=${data.unitId}&guest=${data.milestoneGuest}`, config)
            //const commonAreaRuleId = response2.filter((v: any) => v.local.id === data.localId)[0].commonAreaRule.id

            // const { data: response3 } = await api.get(`CommonAreaRequest/GetAvailableBookingTimes/${commonAreaRuleId}/${data.reservationDate}`, config)
            
            dispatch(setCurrentBookingRequest({
                ...data,
                bookingType: data.milestoneGuest ? 'COLETIVO' : 'INDIVIDUAL',
                //commonAreaRuleId,
                availableBookingTimes: data.timesheets.map((v: any) => {
                    return {
                        label: v.startTime + ' ' + v.endTime,
                        value: v.id,
                        startTime: v.startTime,
                        endTime: v.endTime,
                        approval: v.approval
                    }
                })
            }))

            setLoading(false)
        } catch(err: any) {
            console.log('err', err)
            navigate(-1)

            toast.error(
                err &&
                err.response &&
                err.response.data &&
                err.response.data.Message
            )
        }
    }
    
    function getDateAndTime (val: string) {
        let format = val.split('T')
        let dateFormat = format[0].split('-')

        return {
            date: dateFormat[2] + '/' + dateFormat[1] + '/' + dateFormat[0],
            time: format[1].substring(0, 5)
        }
    }

    function formatStatus(data: string) {
        if (data.toUpperCase() === "OPEN") {
            return <Box color={"red"} fontWeight={"600"}>ABERTO</Box>;
        } else if (data.toUpperCase() === "ONGOING") {
            return <Box color={"#ffc107"} fontWeight={"600"}>EM ANDAMENTO</Box>;
        } else if (data.toUpperCase() === "CANCELLED") {
            return <Box color={"#dc3545"} fontWeight={"600"}>CANCELADO</Box>;
        } else if (data.toUpperCase() === "FINISHED") {
            return <Box color={"#007bff"} fontWeight={"600"}>FINALIZADO</Box>;
        } else if (data.toUpperCase() === "PENDENT") {
            return <Box color={"#6c757d"} fontWeight={"600"}>PENDENTE</Box>;
        } else if (data.toUpperCase() === "REFUSED") {
            return <Box color={"black"} fontWeight={"600"}>RECUSADO</Box>;
        } else if (data.toUpperCase() === "CLOSED") {
            return <Box color={"red"} fontWeight={"600"}>FECHADO</Box>;
        } else if (data.toUpperCase() === "REOPENED") {
            return <Box color={"#c617c6"} fontWeight={"600"}>REABERTO</Box>;
        } else if (data.toUpperCase() === "FORWARDED") {
            return <Box color={"blue"} fontWeight={"600"}>ENCAMINHADO</Box>;
        } else {
            return <Box color={"black"} fontWeight={"600"}>{data}</Box>;
        }
    }

    async function sendFirebase(status?: string) {
        const id = location && location.state && location.state.id;

        const userToken = localStorage.getItem('access_portal:access_token')

        const decoded = jwt_decode(userToken as string) as IUserData
        
        const selectedClient = decoded.client_id

        const clientId = selectedClient.split('#')[0]

        const data = new Date()

        let dia = data.getDate().toString().length == 1 ? '0' + data.getDate() : data.getDate()
        let mes = (data.getMonth() + 1).toString().length == 1 ? '0' + (data.getMonth() + 1) : (data.getMonth() + 1)
        let ano = data.getFullYear()

        const dataFormatted = dia + '-' + mes + '-' + ano

        set(
            ref( 
                FirebaseRealtimeDatabase, 
                '/new-commom-area-request/' + clientId + '/' + dataFormatted
            ),
            new Date().toString()
        )
        
        if(status) {
            set(
                ref( 
                    FirebaseRealtimeDatabase, 
                    '/commom-area-request-creation-or-change/' + clientId + '/' + id
                ),
                ''+status
            )
        }
    }

    async function answerRequest() {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
            }
        }
    
        const sendObject = {
            CommonAreaRequestId: currentBookingRequest.id,
            Status: "ONGOING",
            Description: "SOLICITAÇÃO EM ATENDIMENTO"
        }
    
        try {
            const { data } = await api.post(
                `/commonAreaRequest/ReservationRequestTreatment`, 
                sendObject,
                config
            );

            sendFirebase('ONGOING')
        } catch (err) {
          toast.error('Ocorreu um erro');
        }
    }

    async function handlerContinue() {
        const id = location && location.state && location.state.id;

        navigate("/event/edit", {
            state: {
                id,
                title: "Criação de evento",
                continue: true
            }
        })
    }

    function getPaid(paid: any, type?: boolean) {
        if (type) {
            if (paid === "TRUE") {
                return <Text style={{color: 'green', fontSize: 10}} padding={0} margin={0}>APROVADO</Text>;
            } else {
                return <Text style={{color: 'red', fontSize: 10}} padding={0} margin={0}>NÃO APROVADO</Text>;
            }
        }
    
        if (paid) {
            return <Text style={{color: 'green'}} padding={0} margin={0}>PAGO</Text>;
        } else {
            return <Text style={{color: 'red'}} padding={0} margin={0}>NÃO-PAGO</Text>;
        }
    };

    function getData(val: any) {
        if (val) {
            val = val.split('T')[0];
            val = val.split('-');
        
            return `${val[2]}/${val[1]}/${val[0]}`;
        }
    
        return '';
    };

    function stopListener() {
        const id = location && location.state && location.state.id;

        const userToken = localStorage.getItem('access_portal:access_token')

        const decoded = jwt_decode(userToken as string) as IUserData
        
        const selectedClient = decoded.client_id

        const clientId = selectedClient.split('#')[0]

        off(ref( 
            FirebaseRealtimeDatabase, 
            '/commom-area-request-creation-or-change/' + clientId + '/' + id,
        ))
    }

    function startFirebaseListener() {
        const id = location && location.state && location.state.id;

        const userToken = localStorage.getItem('access_portal:access_token')

        const decoded = jwt_decode(userToken as string) as IUserData
        
        const selectedClient = decoded.client_id

        const clientId = selectedClient.split('#')[0]

        onValue(ref(FirebaseRealtimeDatabase, '/commom-area-request-creation-or-change/' + clientId + '/' + id), (snapshot) => {
            getDetails()
        });
    }

    async function sendCancelation() {
        if(!motive){
            return toast.error("É necessário descrever o motivo.")
        }

        if(motive.length < 5){
            return toast.error('A descrição deve ter no mínimo 5 caracteres.')
        }

        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        }

        try {
            await api.post("/commonAreaRequest/ReservationRequestTreatment", {
                CommonAreaRequestId: currentBookingRequest.id,					
                Status: "CANCELLED",						
                Description: motive		
            }, config)

            sendFirebase()

            toast.success("Sua solicitação foi excluída com sucesso.")

            onClose2();

            navigate(-1)

            stopListener()
        } catch (error: any) {
            toast.error(error)
        }
    }

    if(loading) {
        return (
            <Flex
                position={"relative"}
                h={"calc(100vh - 175px)"}
                w={"100%"}
                top={"0px"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
            </Flex>
        )
    }

    return (
        <Grid templateColumns={["0% 100% 0%", "30% 40% 30%"]}>
            <AlertDialog
                isOpen={isOpen2}
                leastDestructiveRef={dialogRef}
                onClose={onClose2}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent width={"90%"}>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Atenção!
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Deseja realmente excluir essa solicitação?
                            <Textarea
                                placeholder={'Descreva a razão para essa solicitação ser excluida'}
                                onChange={(val: any) => {
                                    setMotive(val.target.value)
                                }}
                                defaultValue={motive}
                                maxLength={1000}
                                resize={"none"}
                                textTransform={"uppercase"}
                                rows={5}
                            />
                        </AlertDialogBody>
                        <AlertDialogFooter
                            display={"flex"}
                            justifyContent={"space-between"}
                        >
                            <Button
                                background={"#d36262"}
                                color={"#fff"}
                                onClick={async () => {
                                    onClose2();
                                }}
                                _hover={{
                                    _disabled: {
                                        background: "#d36262"
                                    }
                                }}
                            >
                                Não
                            </Button>
                            <Box>
                                <Button
                                    onClick={() => sendCancelation()}
                                    color={"#65788a"}
                                    boxShadow={"0px 1px 3px #ccc"}
                                >
                                    Sim
                                </Button>                   
                            </Box>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <GridItem colSpan={1} />
            <GridItem
                colSpan={1}
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <History
                    isOpen={isOpen}
                    onClose={onClose}
                    data={currentBookingRequest.history}
                />
                <Box w={"100%"}>
                    <Flex
                        marginTop={["10px", "40px"]}
                        flexDir={["column", "row"]}
                        justifyContent={["center", "space-between"]}
                        alignItems={["flex-end", "flex-end"]}
                    >
                        <Flex
                            justifyContent={["center", "flex-start"]}
                            alignItems={["", "flex-end"]}
                            width={["100%", "auto"]}
                            marginBottom={["40px", "0px"]}
                        >
                            <Text
                                fontSize={[28, 20]}
                                fontWeight={600}
                                color={"#444"}
                            >
                                Detalhes da Reserva
                            </Text>
                        </Flex>
                    </Flex>
                    <Container
                        flexDir={"column"}
                        padding={["0px", "20px"]}
                        borderRadius={"6px"}
                        background={["transparent", "#fff"]}
                    >
                        <Flex flexDir={"column"}>
                            {location.state.isAdmin && (currentBookingRequest.status.toUpperCase() === 'OPEN' ||
                            currentBookingRequest.status.toUpperCase() === 'ONGOING') && (
                                <Button
                                    onClick={answerRequest}
                                    color={"#65788a"}
                                >
                                    Atender Solicitação
                                </Button>
                            )}
                            <Button
                                onClick={handlerContinue}
                                marginTop={"20px"}
                                color={"#65788a"}
                                boxShadow={"0px 1px 3px #ccc"}
                                opacity={(
                                    (
                                        currentBookingRequest.requestBy &&
                                        currentBookingRequest.requestBy?.privateLocal?.requestById != userId
                                    ) &&
                                    newEvent == false && 
                                    currentBookingRequest.status.toUpperCase() !== 'CLOSED'
                                        ? 0.5
                                        : 1
                                )}
                                isDisabled={
                                    subModuleId === '5' ?
                                    (currentBookingRequest.requestBy &&
                                        currentBookingRequest.requestBy.id !== userId) ||
                                        newEvent === false ||
                                        currentBookingRequest.status.toUpperCase() !== 'CLOSED'
                                    :
                                    ((currentBookingRequest.requestBy &&
                                        currentBookingRequest.requestBy?.privateLocal?.requestById !== userId) ||
                                        newEvent === false ||
                                        currentBookingRequest.status.toUpperCase() !== 'CLOSED'
                                    )
                                }
                            >
                                Continuar
                            </Button>
                        </Flex>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Protocolo
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {currentBookingRequest.id}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Espaço de Reserva
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {currentBookingRequest.local}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Situação
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {formatStatus(currentBookingRequest.status)}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Horários
                            </Heading>
                            <Box pt='2' fontSize='sm'>
                                {currentBookingRequest.availableBookingTimes.map((res, index) => {
                                    return (
                                        <Tag
                                            key={"item_" + index}
                                            variant='subtle'
                                            background={["#dadada", "#fafafa"]}
                                            color={["#6f6f6f", "#0b6f83"]}
                                            padding={"12px"}
                                            marginTop={"10px"}
                                            marginRight={"10px"}
                                            boxShadow={"0px 1px 5px #e7e7e7"}
                                            width={["100%", "auto"]}
                                            justifyContent={["space-between", ""]}
                                            display={["flex", "inline-flex"]}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: 700,
                                                    display: "block",
                                                    marginRight: 20
                                                }}
                                            >
                                                {getData(currentBookingRequest.reservationDate||null)}
                                            </span>
                                            <Flex>
                                                <TagLeftIcon boxSize='12px' as={MdAccessTimeFilled} />
                                                <TagLabel>{res.startTime.substring(0, 5)} - {res.endTime.substring(0, 5)}</TagLabel>
                                            </Flex>
                                            <div
                                                style={{
                                                    fontWeight: 700,
                                                    display: "flex",
                                                    margin: 0,
                                                    marginLeft: '20px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexGrow: 1,
                                                }}
                                            >
                                                {getPaid(res.approval, true)}
                                            </div>
                                        </Tag>
                                    )
                                })}
                            </Box>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Data da Solicitação
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {getDateAndTime(currentBookingRequest.requestDate).date}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Solicitante
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {currentBookingRequest.requestBy?.name}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Condição
                            </Heading>
                            <Text pt='2' fontSize='sm' fontWeight={"700"}>
                                {getPaid(currentBookingRequest.paid)}
                            </Text>
                        </Box>
                        <Flex flexDir={"column"}>
                            <Heading size='xs' textTransform='uppercase' color="#777">
                                Histórico
                            </Heading>
                            <Button
                                marginTop={"10px"}
                                isDisabled={currentBookingRequest.history.length < 1}
                                onClick={onOpen}
                                _disabled={{
                                    opacity: 0.4
                                }}
                                color={"#65788a"}
                                boxShadow={"0px 1px 3px #ccc"}
                            >
                                {`Essa reserva possui ${currentBookingRequest.history.length} registro(s).`}
                            </Button>
                        </Flex>
                        <Flex marginTop={"10px !important"} flexDir={"column"}>
                            {currentBookingRequest.subjectToCancellation && 
                            !(currentBookingRequest.status.toUpperCase() === 'CANCELLED') && 
                            !currentBookingRequest.changeToEvent &&
                            (
                                <Button
                                    background={"#d36262"}
                                    color={"#fff"}
                                    onClick={onOpen2}
                                    _hover={{
                                        _disabled: {
                                            background: "#d36262"
                                        }
                                    }}
                                >
                                    Excluir
                                </Button>
                            )}
                        </Flex>
                        <Flex flexDir={["column", "row"]}>
                            <Button 
                                colorScheme='teal' 
                                size='md'
                                onClick={() => navigate(-1)}
                            >
                                Voltar
                            </Button>
                        </Flex>
                    </Container>
                </Box>
            </GridItem>
            <GridItem colSpan={1} />
        </Grid>
    )
}
