import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IData {
    id: number,
    contactId: number,
    rg: string,
    outsourced: boolean,
    companyId: number,
    functionName: string,
    driver: boolean,
    guestTypeEntrance: boolean,
    maxVisitTimeActive: boolean,
    startDate: string,
    endDate: string,
    gender: string,
    relationship: string,
    visitDays: any[],
    virtualDoorman: boolean,
    image: string,
}



const initialState = { 
    data: { 
        id: 0,
        contactId: 0,
        rg: '',
        outsourced: false,
        companyId: 0,
        functionName: '',
        driver: false,
        guestTypeEntrance: false,
        maxVisitTimeActive: false,
        startDate: '',
        endDate: '',
        gender: '',
        relationship: '',
        visitDays: [],
        virtualDoorman: false,
        image: '',
  } as IData
}
  
export const guestSlice = createSlice({
    name: 'guest',
    initialState,
    reducers: {
        resetCurrentGuest: (state) => {
            state.data = initialState.data
        },
        setCurrentGuest: (state, action: PayloadAction<IData>) => {
            state.data = action.payload
        },
    },
})

export const { resetCurrentGuest, setCurrentGuest } = guestSlice.actions

export default guestSlice.reducer