import styled from 'styled-components'

export const Container = styled.div`
    height: 45px;
    background-color: #309794;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: #FFF;
`
