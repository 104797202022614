export function expiredEventCheck(dateObject: any){
  dateObject = dateObject.split('T');

  let data = dateObject[0].split('-');

  let hora = dateObject[1].split(':');

  let dataEvento=new Date();

  dataEvento.setFullYear(data[0]);
  dataEvento.setMonth(data[1]-1);
  dataEvento.setDate(data[2]);
  dataEvento.setHours(hora[0], hora[1], hora[2], 0);

  if(dataEvento.getTime() < new Date().getTime()){
    return true;
  }

  return false
}