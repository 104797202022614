
const addTagOnTheWords = (tagName : string, addTagToThisWord : string, completeText : string) => {
    completeText = completeText + ' '
    
    addTagToThisWord = addTagToThisWord.split(/a|á|à|ã|â|A|À|Á|Ã|Â/g).join('[a|á|à|ã|â|A|À|Á|Ã|Â]')
    addTagToThisWord = addTagToThisWord.split(/e|é|è|ê|E|É|È|Ê/g).join('[e|é|è|ê|E|É|È|Ê]')
    addTagToThisWord = addTagToThisWord.split(/i|í|ì|î|I|Í|Ì|Î/g).join('[i|í|ì|î|I|Í|Ì|Î]')
    addTagToThisWord = addTagToThisWord.split(/o|ó|ò|ô|õ|O|Ó|Ò|Ô|Õ/g).join('[o|ó|ò|ô|õ|O|Ó|Ò|Ô|Õ]')
    addTagToThisWord = addTagToThisWord.split(/u|ú|ù|û|ü|U|Ú|Ù|Û|Ü/g).join('[u|ú|ù|û|ü|U|Ú|Ù|Û|Ü]')
    addTagToThisWord = addTagToThisWord.split(/c|ç|C|Ç/g).join('[c|ç|C|Ç]')
    addTagToThisWord = addTagToThisWord.split(/n|ñ|N|Ñ/g).join('[n|ñ|N|Ñ]')
    
    let addTagToThisWordRegex = new RegExp(addTagToThisWord, 'gi')
    let splitWord = completeText.split(addTagToThisWordRegex)

    let words = []
    for(let i in splitWord) {
        if((splitWord.length - 1) == parseInt(i)) continue
        let word = completeText.split(splitWord[i])[1].split(splitWord[parseInt(i) + 1])[0]
        if(!word) continue
        words.push(word)
    }

    let finalText = ''

    for(let i in splitWord) {
        if(words[parseInt(i)]){
            finalText += splitWord[i] + '<' + tagName + '>' + words[parseInt(i)] + '</' + tagName + '>'
        }else{
            finalText += splitWord[i]
        }
    }

    return finalText
}

export default addTagOnTheWords

