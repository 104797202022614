import { configureStore, combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

//reducers
import contact from './reducers/contact';
import pet from './reducers/pet';
import endpointError from './reducers/endpointError';
import loginStatus from './reducers/loginStatus';
import bookingRequest from './reducers/booking-request';
import event from './reducers/event';
import menuParameterization from './reducers/menuParameterization';
import notification from './reducers/notification';
import dependent from './reducers/dependent';
import guest from './reducers/guest';
import user from './reducers/user';
import visitCreate from './reducers/visitCreate';
import attendanceConfirmation from './reducers/attendanceConfirmation';

const rootReducer = combineReducers({
  contact,
  endpointError,
  loginStatus,
  bookingRequest,
  event,
  menuParameterization,
  notification,
  dependent,
  pet,
  guest,
  user,
  visitCreate,
  attendanceConfirmation,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>