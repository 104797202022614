import React from 'react';

import { Props } from 'react-input-mask';

import { Container } from './styles';

interface Overrided extends Omit<Props, 'mask'> {
  mask?: any
}

export function InputCPF(props: Overrided) {
  return (
    <Container {...props} />
  );
}