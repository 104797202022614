import styled from 'styled-components'


export const Container = styled.div`

  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  -webkit-padding-start: var(--chakra-space-4);
  --padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  --padding-inline-end: var(--chakra-space-4);
  -height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
  -height: 40px;

  background-color: #fff;

  align-items: center;


  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  @media screen and (min-width: 30em) {
    width: auto;
    --padding: 10px 10px;
  }

  input {
        height: 40px !important;
     
  }

  .PhoneInputCountryIcon.PhoneInputCountryIcon--border {
    margin-right: 10px;
  }

  .PhoneInputCountry {
    margin-right: 15px;
}
`