import React, { useState } from 'react'

import { useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import jwt_decode from "jwt-decode"

import { toast } from 'react-toastify'

import { 
    Box,
    Text,
    Button,
    FormControl,
    FormLabel,
    Switch,
    Input,
    Grid,
    GridItem,
    Flex,
    Select,
} from '@chakra-ui/react'

import { RootState } from '../../../../../store'

import { setCurrentPet } from '../../../../../store/reducers/pet'

import { validateEmail } from '../../../../../utils/validates'

import { InputPhone } from '../../../../../components/InputPhone'

import api from '../../../../../services/api'

import { IUserData } from '../../../../../interfaces/IUserData'
import { BoxWithBorder } from '../../../../../components/BoxWithBorder'
import { ImageUpload } from '../../../../../components/ImageUpload'
import { current } from '@reduxjs/toolkit'

export function PetsForm () {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const currentPet = useSelector((state: RootState) => state.pet.data)

    const [disabledSaveButton, setDisabledSaveButton] = useState(false)

    function getCreateOn () {
        let data = new Date();
        let mes = data.getMonth()+1>9 ? data.getMonth()+1 : '0' + (data.getMonth()+1)
        return data.getFullYear()+'-'+mes+'-'+data.getDate()
    }

    async function save() {
        if(currentPet.name.length === 0){
            return toast.error('O campo nome é obrigatório.')
        }

        if(currentPet.gender.length === 0){
            return toast.error('O campo sexo é obrigatório.')
        }

        if(currentPet.specie.length === 0){
            return toast.error('O campo tipo é obrigatório.')
        }

        /*if(currentPet.breed.length === 0){
            return toast.error('O campo raça é obrigatório.')
        }*/

        if(currentPet.size.length === 0){
            return toast.error('O campo tamanho é obrigatório.')
        }

        try{
            setDisabledSaveButton(true)

            const userToken = localStorage.getItem('access_portal:access_token')

            const decoded = jwt_decode(userToken as string) as IUserData
  
            const config = {
                headers: {
                    Authorization: `Bearer ${ userToken }`
                }
            }
            
            let toSend: any = {

                UserId: decoded.user_id,
                privateLocalId: decoded.private_local_id,
                Name: currentPet.name,
                Specie: currentPet.specie,
                Sex: currentPet.gender,
                Breed: currentPet.breed,
                Size: currentPet.size,
                ImageKey: currentPet.image,
                ImageType: 'base64',
                createOn: getCreateOn()
            }

            let url = '/UserAnimal/register'
            //Se for edição
            if(currentPet.id){
                toSend = {
                    ...toSend,
                    id: currentPet.id
                }
                url = '/UserAnimal/update'
            }

            await api.post(url, toSend, config)

            setDisabledSaveButton(false)

            toast.success(`Pet ${currentPet.id ? 'atualizado' : 'cadastrado'} com sucesso.`)
            
            
            navigate('/my-profile', {
                state: {
                  index: 4
                }
            });
        }catch(e: any) {
            setDisabledSaveButton(false)
            if(
                e &&
                e.response &&
                e.response.data &&
                e.response.data.Message
            ){
                toast.error(e.response.data.Message)
            }
        }
    }

    function getImage() {
        if(currentPet.image){
            const imageSplit = currentPet.image.split('base64,')
            return 'data:image/png;base64,' + imageSplit[imageSplit.length - 1]
        }

        return ''
    }

    return (
        <Grid templateColumns={["0% 100% 0%", "25% 50% 25%"]}>
            <GridItem colSpan={1} />
            <GridItem
                colSpan={1}
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <Text
                    fontSize={[28, 20]}
                    fontWeight={600}
                    color={"#444"}
                    marginBottom={"10px"}
                >
                    {currentPet.id ? 'Editar' : 'Cadastrar'} Pet
                </Text>

                <Flex
                    flexDir={"column"}
                    background={["inherit", "#fff"]}
                    w={"100%"}
                    padding={["0px", "20px"]}
                    borderRadius={"10px"}
                >
                    <Box marginBottom={"10px"}>
                        <FormLabel
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"3px"}
                        >
                            Nome
                        </FormLabel>
                        <Input
                            placeholder={"Digite o nome do seu melhor amigo :)"}
                            background={"#fff"}
                            onChange={(e) =>{
                                dispatch(setCurrentPet({
                                    ...currentPet,
                                    name: e.target.value
                                }))
                            }}
                            value={currentPet.name}
                        />
                    </Box>
                    <Box width={"100%"} marginBottom={"10px"}>
                        <FormLabel
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"3px"}
                        >
                            Sexo
                        </FormLabel>
                        <Select
                            width={"100%"}
                            background={"#fff"}
                            onChange={(e) => {
                                dispatch(setCurrentPet({
                                    ...currentPet,
                                    gender: e.target.value
                                }))
                            }}
                            value={currentPet.gender}
                        >
                            <option value='' disabled selected>Selecione o sexo</option>
                            <option value='Macho'>Macho</option>
                            <option value='Fêmea'>Fêmea</option>
                        </Select>
                    </Box>
                    <Box marginBottom={"10px"}>
                        <FormLabel
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"3px"}
                        >
                            Tipo
                        </FormLabel>
                        <Input
                            placeholder={'Cachorro, Gato, Coelho, Tartaruga; Você decide!'}
                            background={"#fff"}
                            onChange={(e) =>{
                                dispatch(setCurrentPet({
                                    ...currentPet,
                                    specie: e.target.value
                                }))
                            }}
                            value={currentPet.specie}
                        />
                    </Box>
                    <Box marginBottom={"10px"}>
                        <FormLabel
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"3px"}
                        >
                            Raça
                        </FormLabel>
                        <Input
                            placeholder={'Dê mais informações quanto ao seu amiguinho.'}
                            background={"#fff"}
                            onChange={(e) =>{
                                dispatch(setCurrentPet({
                                    ...currentPet,
                                    breed: e.target.value
                                }))
                            }}
                            value={currentPet.breed}
                        />
                    </Box>
                    <Box width={"100%"} marginBottom={"30px"}>
                        <FormLabel
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"3px"}
                        >
                            Tamanho
                        </FormLabel>
                        <Select
                            width={"100%"}
                            background={"#fff"}
                            onChange={(e) => {
                                dispatch(setCurrentPet({
                                    ...currentPet,
                                    size: e.target.value
                                }))
                            }}
                            value={currentPet.size}
                        >
                            <option value='' disabled selected>Selecione</option>
                            <option value='Pequeno'>Pequeno</option>
                            <option value='Médio'>Médio</option>
                            <option value='Grande'>Grande</option>
                        </Select>
                    </Box>
                    <BoxWithBorder
                        title={'Foto do Pet'}
                        padding={["0px", "20px"]}
                        margin={["0px", "20px"]}
                        borderWidth={["0px", "1px"]}
                        display={"flex"}
                        marginTop={["50px", "0px"]}
                        flexDir={["column"]}
                    >
                        <ImageUpload
                            initialDefaultImage={getImage()}
                            responseFunction={(val) => {
                                if(val){
                                    const imageSplit = val.split('base64,')

                                    dispatch(setCurrentPet({
                                        ...currentPet,
                                        image: imageSplit[imageSplit.length - 1]
                                    }))
                                }else{
                                    dispatch(setCurrentPet({
                                        ...currentPet,
                                        image: ''
                                    }))
                                }
                            }}
                        />
                    </BoxWithBorder>
                    <Flex
                        flexDir={["column-reverse", "row"]}
                        justifyContent={["initial", "space-between"]}
                        style={{marginTop: 20}}
                    >
                        <Button 
                            colorScheme='teal' 
                            onClick={() => navigate('/my-profile', {
                                state: {
                                  index: 4
                                }
                              })}
                            isDisabled={disabledSaveButton}
                        >
                            Voltar
                        </Button>
                        <Button 
                            colorScheme='teal' 
                            onClick={save}
                            marginBottom={["10px", "0px"]}
                            isDisabled={disabledSaveButton}
                        >
                            Salvar
                        </Button>
                    </Flex>
                </Flex>
            </GridItem>
            <GridItem colSpan={1} />
        </Grid>
    )
}
