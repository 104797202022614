import React from 'react';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Box,
} from "@chakra-ui/react";

interface IHistory {
  id: number
  status: string
  userId: number
  description: string
  createOn: Date
}

interface IModalHistory {
  isOpen: boolean
  onClose: () => void
  data: IHistory[]
}

export function History({ isOpen, onClose, data }: IModalHistory) {
  function getDataEHora(val: any) {
    if(val) {
      let date = val.split('T')[0];
      date = date.split('-');
      let hours =  val.split('T')[1].substr(0, 8);

      return `${date[2]}/${date[1]}/${date[0].substr(2)} - ${hours}`;
    }
  }

  function getStatus(data: string) {
    if (data.toUpperCase() === "OPEN") {
      return <Box color={"red"} fontWeight={"600"}>ABERTO</Box>;
    } else if (data.toUpperCase() === "ONGOING") {
      return <Box color={"#ffc107"} fontWeight={"600"}>EM ANDAMENTO</Box>;
    } else if (data.toUpperCase() === "CANCELLED") {
      return <Box color={"#dc3545"} fontWeight={"600"}>CANCELADO</Box>;
    } else if (data.toUpperCase() === "FINISHED") {
      return <Box color={"#007bff"} fontWeight={"600"}>FINALIZADO</Box>;
    } else if (data.toUpperCase() === "PENDENT") {
      return <Box color={"#6c757d"} fontWeight={"600"}>PENDENTE</Box>;
    } else if (data.toUpperCase() === "REFUSED") {
      return <Box color={"black"} fontWeight={"600"}>RECUSADO</Box>;
    } else if (data.toUpperCase() === "CLOSED") {
      return <Box color={"red"} fontWeight={"600"}>FECHADO</Box>;
    } else if (data.toUpperCase() === "REOPENED") {
      return <Box color={"#c617c6"} fontWeight={"600"}>REABERTO</Box>;
    } else if (data.toUpperCase() === "FORWARDED") {
      return <Box color={"blue"} fontWeight={"600"}>ENCAMINHADO</Box>;
    } else {
      return <Box color={"black"} fontWeight={"600"}>{data}</Box>;
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Histórico</ModalHeader>
        <ModalBody>
          {data.map((res, index) => {
            return (
              <Flex key={"item_"+index} flexDir={"column"} marginBottom={"20px"}>
                <Flex fontSize={"14"}>
                  <span>{getDataEHora(res.createOn)}</span>
                  <span style={{ marginLeft: 10 }}>{getStatus(res.status)}</span>
                </Flex>
                <Box marginTop={"5px"} fontWeight={600}>
                  {res.description}
                </Box>
              </Flex>
            )
          })}
        </ModalBody>
        <ModalFooter
          display={"flex"}
          justifyContent={"space-between"}
          color={"#fff"}
        >
          <Button
            background={"#d36262"}
            color="#fff"
            mr={3}
            onClick={onClose}
            _hover={{
              _disabled: {
                background: "#d36262"
              }
            }}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}