export function isDev() {
    if (
        window.location.href.split('//')[1].substring(0, 22) === 'portal.nicbrain.com.br' ||
        window.location.href.split('//')[1].substring(0, 33) === 'portal-nic-acesso.firebaseapp.com'
    ) {
        return false
    }

    return true
}

export function isHml() {
    if (
        window.location.href.split('//')[1].substring(0, 25) === 'hmlportal.nicbrain.com.br' ||
        window.location.href.split('//')[1].substring(0, 37) === 'hml-portal-nic-acesso.firebaseapp.com'
    ) {
        return true
    }

    return false
}