import React, { useRef, useState } from 'react';

import stc from 'string-to-color';

import { toast } from 'react-toastify';

import { FaCog, FaTimes, FaCheck, FaStar, FaTrashAlt } from "react-icons/fa";

import {
  Button,
  Flex,
  Select as SelectInput,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import jwt_decode from "jwt-decode";

import api from '../../../../services/api';

import { PropsContatos } from '../../../../interfaces/IContacts';

import { IUserData } from '../../../../interfaces/IUserData';

interface IExternalProps {
  data: PropsContatos[];
  contactsSelecteds: PropsContatos[];
  setData: (data: PropsContatos[]) => void;
  handleChangeTypeLiberaion: (e: any, i: number, typeGuest: 'EXTERNAL' | 'INTERNAL') => void;
  showButtonChangeReleaseType: boolean;
}

export function External({
  data,
  contactsSelecteds,
  setData,
  handleChangeTypeLiberaion,
  showButtonChangeReleaseType,
}: IExternalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  let dialogRef: any = useRef();

  const [index, setIndex] = useState<null | number>(null);

  const optionsTypeLiberation = [
    {
      label: 'SOLICITAR LIBERAÇÃO POR TELEFONE',
      value: 0,
    },
    {
      label: 'LIBERAR ENTRADA AUTOMATICAMENTE',
      value: 1,
    },
    {
      label: 'AGUARDAR NA PORTARIA E INFORMAR MORADOR VIA APP',
      value: 2,
    },
  ];

  async function favoriteContact(v: PropsContatos) {
    try {
      const userToken = localStorage.getItem('access_portal:access_token');

      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      };
      
      await api.post('/contact/favorite/' + v.id, config);
  
      setData(data.map((res: PropsContatos) => {
        return {
          ...res,
          favorite: res.id === v.id ? !v.favorite : res.favorite
        }
      }));
    } catch(err: any) {
      toast.error(
        err &&
        err.response &&
        err.response.data &&
        err.response.data.Message
      );
    }
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={dialogRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent width={"90%"}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Atenção!
            </AlertDialogHeader>
            <AlertDialogBody>
              Deseja remover este convidado?
            </AlertDialogBody>
            <AlertDialogFooter
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Button
                background={"#d36262"}
                color={"#fff"}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                onClick={async () => {
                  try {
                    let newData = JSON.parse(JSON.stringify(data));

                    const config = {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
                      }
                    }
  
                    await api.delete(`contactMilestone/delete/${newData[Number(index)].contactMilestoneId}`, config)
  
                    newData[Number(index)].selected = !newData[Number(index)].selected;
                    newData[Number(index)].edit = false;
                    newData[Number(index)].excludeOption = false;
  
                    if(showButtonChangeReleaseType) {
                      newData[Number(index)].guestReleaseType = 1;
                    }
                    
                    setData(newData);

                    toast.success('Convidado removido com sucesso!');
  
                    onClose();
                  } catch(err: any) {
                    toast.error('Não foi possível remover o convidado!');
                  }
                }}
              >
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex flexDir={"column"} minH={"200px"}>
        {data.map((v, i) => {
          return (
            <Flex
              key={"item_"+i}
              w={"100%"}
              flexDirection={"row"}
              background={v.selected ? "#eeeeee" : "#fff"}
              paddingTop={"10px"}
              paddingBottom={"10px"}
              marginBottom={"10px"}
              borderBottom={contactsSelecteds.length > 1 && (i < contactsSelecteds.length-1) ? "1px solid #eaeaea": "0px"}
              padding={"10px"}
              borderRadius={"4px"}
              position={'relative'}
              alignItems={"center"}
            >
              <Button
                variant={'link'}
                style={{
                  position: 'absolute',
                  width: '25px',
                  height: '25px',
                  top: '0px',
                  left: '0px',
                }}
                transition={'.4s'}
                opacity={0.8}
                _hover={{
                  opacity: 1,
                  transition: '.4s'
                }}
                onClick={() => favoriteContact(v)}
              >
                <FaStar size={25} color={v.favorite ? '#fff204' : '#888'} />
              </Button>
              <Flex>
                <Flex
                  borderRadius={"full"}
                  w={"50px"}
                  h={"50px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  background={v.selected ? "black" : stc(v.name)}
                  color={"#fff"}
                  _hover={{
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    if(!v.edit) {
                      let newData = JSON.parse(JSON.stringify(data))

                      newData[i].selected = !newData[i].selected

                      if(showButtonChangeReleaseType) {
                        newData[i].guestReleaseType = 1;
                      }
                      
                      setData(newData)
                    }
                  }}
                >
                  {v.selected
                    ? <FaCheck />
                    : v.name.substr(0, 1).toUpperCase()}
                </Flex>
              </Flex>
              <Flex
                flexDir={"column"}
                marginLeft={"10px"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                paddingRight={"18px"}
                flexGrow={1}
              >
                <span
                  style={{
                    fontSize: "16px",
                    color: "#444",
                    fontWeight: 600,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {v.name}
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {v.editable
                    ? (
                        <SelectInput
                          placeholder={'Selecione...'}
                          className="basic-single"
                          onChange={(e) => {
                            handleChangeTypeLiberaion(e, i, 'EXTERNAL')
                          }}
                          value={optionsTypeLiberation.filter(res => res.value === v.guestReleaseType)[0].value}
                          background={"#fff"}
                        >
                          {optionsTypeLiberation.map(res => {
                            return <option value={res.value}>{res.label}</option>
                          })}
                        </SelectInput>
                      )
                    : optionsTypeLiberation.filter(res => res.value === v.guestReleaseType).length > 0 &&
                      optionsTypeLiberation.filter(res => res.value === v.guestReleaseType)[0].label
                  }
                </span>
              </Flex>
              <Flex>
                {(v.selected && !showButtonChangeReleaseType) && (
                  <Button
                    onClick={() => {
                      let newData = JSON.parse(JSON.stringify(data))

                      newData[i].editable = !newData[i].editable
                      
                      setData(newData)
                    }}
                    colorScheme={"black"}
                    background={"black"}
                  >
                    {v.editable
                      ? <FaTimes />
                      : <FaCog />
                    }
                  </Button>
                )}
              </Flex>
              {v.selected && v.excludeOption && (
                <Button
                  variant={'link'}
                  position={'static'}
                  right={0}
                  top={'10px'}
                  onClick={() => {
                    onOpen();
                    setIndex(i);
                  }}
                >
                  <FaTrashAlt />
                </Button>
              )}
            </Flex>
          )
        })}
      </Flex>
    </>
  );
}