import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import jwt_decode from "jwt-decode"

import { toast } from 'react-toastify'

import { 
    Text,
    Button,
    FormLabel,
    Switch,
    Grid,
    GridItem,
    Flex,
    CircularProgress,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure
} from '@chakra-ui/react'

import { setMenuParameterization } from '../../store/reducers/menuParameterization'

import api from '../../services/api'

import { IUserData } from '../../interfaces/IUserData'

import { setCurrentUser } from '../../store/reducers/user';

export function Configuration () {
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)
    
    const [currentId, setCurrentId] = useState(0)

    const [checkBasicMode, setBasicMode] = useState(false)

    const [checkEmailMarketing, setCheckEmailMarketing] = useState(false)

    const [checkSendSMS, setCheckSendSMS] = useState(false)
    
    const [showSwitchFacial, setShowSwitchFacial] = useState(false)

    const [switchResetFacial, setSwitchResetFacial] = useState(false)

    const [initialValueFacial, setInitialValueFacial] = useState(false)

    const [showModalRestartLGPD, setShowModalRestartLGPD] = useState(false);

    const [disabledButton, setDisabledButton] = useState(false)

    const [disableButtonSave, setDisableButtonSave] = useState(false)

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        init()
    }, [])

    async function init() {
        try {
            const userToken = localStorage.getItem('access_portal:access_token')

            const decoded = jwt_decode(userToken as string) as IUserData
  
            const config = {
                headers: {
                    Authorization: `Bearer ${ userToken }`
                }
            }
            const { data } = await api.get(`AppMenuConfiguration/GetMenuConfiguration/${decoded.user_id}`, config);

            if(data.length > 0){
                setCurrentId(data[0].id)
                setBasicMode(data[0].basicMode)
                setCheckEmailMarketing(data[0].sendEmail)
                setCheckSendSMS(data[0].sendSms)
                if(data[0].facialLgpd != null){
                    setShowSwitchFacial(true)
                    setInitialValueFacial(data[0].facialLgpd)
                }
            }

            setLoading(false)
            
          } catch(error) {
            setLoading(false)
            toast.error('Não foi possível recuperar as suas configurações, por favor entre em contato com o suporte.')
          }
    }

    async function sendResponse(){
        //sendendpoint 
        setDisabledButton(true)
        const userToken = localStorage.getItem('access_portal:access_token')
        const decoded = jwt_decode(userToken as string) as IUserData

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        try {
            const { data } = await api.post('/user/ResetFacialByApp/', {}, config);

            setTimeout(() => {
                updateDataUser()
            }, 2000)
            
        } catch(e: any) {  
            setDisabledButton(false)
            toast.error(e.response.data.Message)
            onClose()
    }
    }

    async function updateDataUser(){
        const userToken = localStorage.getItem('access_portal:access_token')
        const decoded = jwt_decode(userToken as string) as IUserData

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        const { data: userData } = await api.get('user/' + decoded.user_id, config);

            dispatch(setCurrentUser({
                userId: Number(decoded.user_id),
                name: userData.name.toLocaleLowerCase(),
                function: userData.userType,
                email: userData.email,
                phone: userData.phoneNumber,
                gender: userData.gender,
                type: userData.userType,
                cpf: userData?.cpf,
                rg: userData.rg,
                birthDate: userData.birthDate
                    ? userData.birthDate.split("T")[0]
                    : "",
                driver: userData.driver,
                picture: userData.fileData
                    ? "data:image/jpeg;base64," + userData.fileData.replace("data:image/jpeg;base64,", '')
                    : ""
            }));

            onClose()
    }

    async function save() {
        setDisableButtonSave(true)
        
        try{
            const userToken = localStorage.getItem('access_portal:access_token')

            const decoded = jwt_decode(userToken as string) as IUserData
  
            const config = {
                headers: {
                    Authorization: `Bearer ${ userToken }`
                }
            }

            const toSend: any = {
                id: currentId,
                sendEmail: checkEmailMarketing,
                sendSms: checkSendSMS,
                userId: parseInt(decoded.user_id),
                clientId: decoded.client_id
            }

            await api.post('AppMenuConfiguration/Menu', toSend, config)

            toast.success(`Configurações atualizadas com sucesso.`)

            const { data: menuParameterization } = await api.get("login/menuParameterizationByUser", config);

            dispatch(setMenuParameterization(menuParameterization))

            setDisableButtonSave(false)
            
            navigate("/home")
        }catch(e: any) {
            setDisableButtonSave(false)
            if(
                e &&
                e.response &&
                e.response.data &&
                e.response.data.Message
            ){
                toast.error(e.response.data.Message)
            }
        }
    }

    if(loading) {
        return (
          <Flex
            position={"relative"}
            h={"calc(100vh - 175px)"}
            w={"100%"}
            top={"0px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
          </Flex>
        )
      }

    return (
        <Grid templateColumns={["0% 100% 0%", "35% 30% 35%"]}>
            <GridItem colSpan={1} />
            <GridItem
                colSpan={1}
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <Text
                    fontSize={[28, 20]}
                    fontWeight={600}
                    color={"#444"}
                    marginBottom={"10px"}
                >
                    Configurações
                </Text>

                <Flex
                    flexDir={"column"}
                    background={["inherit", "#fff"]}
                    w={"100%"}
                    padding={["0px", "20px"]}
                    borderRadius={"10px"}
                >
                    <Grid
                        templateColumns={["auto 32px", "auto auto"]}
                        width={["100%", "fit-content"]}
                        
                    >
                        <FormLabel
                            htmlFor='email-alerts'
                            mb='0'
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"10px"}
                        >
                            Modo Simplificado
                        </FormLabel>
                        <Switch 
                            id='favorite' 
                            isChecked={checkBasicMode}
                            marginBottom={"10px"}
                        />
                        
                        <FormLabel
                            htmlFor='email-alerts'
                            mb='0'
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"10px"}
                        >
                            Recebimento de e-mails marketing
                        </FormLabel>
                        <Switch 
                            id='favorite' 
                            onChange={(e) => {
                                setCheckEmailMarketing(e.target.checked)
                            }} 
                            isChecked={checkEmailMarketing}
                            marginBottom={"10px"}
                        />
                        
                        <FormLabel
                            htmlFor='email-alerts'
                            mb='0'
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"10px"}
                        >
                            Notificações enviadas por SMS
                        </FormLabel>
                        <Switch 
                            id='favorite' 
                            onChange={(e) => {
                                setCheckSendSMS(e.target.checked)
                            }} 
                            isChecked={checkSendSMS}
                            marginBottom={"10px"}
                        />

                        {showSwitchFacial && 
                        <>
                        <FormLabel
                            htmlFor='email-alerts'
                            mb='0'
                            fontSize={"0.8em"}
                            color={"#777"}
                            marginBottom={"10px"}
                            >
                            Reiniciar Fluxo (Biometria Facial)
                        </FormLabel>
                        <Switch 
                            disabled={disabledButton}
                            id='favorite' 
                            onChange={(e) => {
                                setShowModalRestartLGPD(true)
                                onOpen()
                                setSwitchResetFacial(true)
                            }} 
                            isChecked={switchResetFacial}
                            marginBottom={"10px"}
                            />
                            </>
                            }

                    <Modal 
                            isOpen={isOpen}
                            onClose={onClose}
                            closeOnOverlayClick={false}
                            size={"2xl"}
                            >
                            <ModalOverlay />
                            <ModalContent id={"modal"}>
                                <ModalHeader></ModalHeader>
                                <ModalBody
                                className='bodyCanvasPic'
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                >
                                {initialValueFacial ? 'Sua foto de perfil está em uso pelo recurso de biometria facial do seu empreendimento, tem certeza que deseja excluir a mesma cancelando seus acessos e reiniciando o fluxo de associação ?'
                                    : 'Tem certeza que deseja excluir sua foto de perfil e reiniciar o fluxo de associação entre a mesma e o recurso de biometria facial usado pelo seu empreendimento ?'}
                                </ModalBody>
                                <ModalFooter display={"flex"} justifyContent={"space-between"}>
                                    <Button
                                    background={"#d36262"}
                                    color={"#fff"}
                                    _hover={{
                                        _disabled: {
                                        background: "#d36262"
                                        }
                                    }}
                                    onClick={() => {
                                        setSwitchResetFacial(false)
                                        onClose()
                                    }}
                                    >
                                    Fechar
                                    </Button>
                                    <Button
                                    color={"#65788a"}
                                    boxShadow={"0px 1px 3px #ccc"}
                                    onClick={() => {
                                        sendResponse()

                                        onClose()
                                    }}
                                    >
                                    Salvar
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                            </Modal>
                    </Grid>
                    <Flex
                        flexDir={["column-reverse", "row"]}
                        justifyContent={["initial", "space-between"]}
                    >
                        <Button 
                            colorScheme='teal' 
                            onClick={save}
                            marginBottom={["10px", "0px"]}
                            isDisabled={disableButtonSave}
                        >
                            Salvar
                        </Button>
                    </Flex>
                </Flex>
            </GridItem>
            <GridItem colSpan={1} />
        </Grid>
    )
}
