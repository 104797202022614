import styled from 'styled-components'

export const  ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`


export const Logo = styled.img`
    width: 200px;
    margin-left: 20px;
`

