import React from 'react'

import InputMask from "react-input-mask";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";

interface IDateComponent {
    label: string,
    value: string,
    onChange: any,
    width: number,
    onBlur: any,
    disabled: boolean
}

export function DateComponent({label, value, onChange, onBlur, width, disabled}: IDateComponent){

    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <Input 
                style={{width : width}}
                as={InputMask} 
                mask="99/99/9999"
                maskChar={null} 
                value={value}
                onChange={(e) => {
                    onChange(e)
                }}
                onBlur={onBlur}
                disabled={disabled}
            />
        </FormControl>
    )
}