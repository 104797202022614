import styled from 'styled-components';

import {
    Box,
} from "@chakra-ui/react";

export const ContentFields = styled(Box)`
    > div + div {
        margin-top: 10px;
    }
`

export const MessageForImageValid = styled(Box)`
    background: #d3d3d3;
    padding: 4px 9px;
    font-size: 12px;
    color: #6a6a6a;
    font-weight: 700;
    margin-top: 10px;
    border-radius: 4px;
`;
