import React, { useState, useRef, useEffect, useCallback } from 'react';

import {
  Box,
  Image,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";

import {
  FaPencilAlt,
  FaTimes,
  FaUndo,
  FaRedo,
  FaRegImage,
  FaTrashAlt
} from 'react-icons/fa';

import Webcam from "react-webcam";

import AvatarEditor from 'react-avatar-editor';

import { DefaultAvatar } from '../../../../../../components/DefaultAvatar';

import {
  ModalEditerAvatar,
  ContentEditAvatar,
  HeaderEditAvatar,
  BodyEditAvatar,
  FooterEditAvatar,
} from './styles';


interface IAvatar {
  source?: string
  onUploadImage: Function
  onExcludeImage: Function
  cancellLGPD: boolean
  editPic: boolean
  exludePic: boolean
  validatePhotoObj: IValidPhoto
  isLoading: boolean
  setChangePhoto: Function
}

interface IValidPhoto {
  enable_facial: boolean | null
  image_valid: boolean | null
  image_key: string
  image_key_valid: boolean | null
  facial_by_app: boolean
  facial_by_app_opt_in_accepted: string
}

export function Avatar({
  source,
  onUploadImage,
  onExcludeImage,
  cancellLGPD,
  editPic,
  exludePic,
  isLoading,
  setChangePhoto,
}: IAvatar) {

  const [scale, setScale] = useState(1.2);

  const [rotate, setRotate] = useState(0);

  const [image, setImage] = useState('');

  const [height, setHeight] = useState(0);

  const [width, setWidth] = useState(0);

  const [showEditor, setShowEditor] = useState(false)

  let editorRef: any = useRef();

  const { isOpen, onOpen, onClose } = useDisclosure()

  const cancelRef =  useRef<HTMLButtonElement>(null)

  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure()

  const webcamRef: any = useRef(null);

  useEffect(() => {
    setHeight(window.innerHeight - 100);

    setWidth(window.innerWidth);
  }, []);

  function handlerFile(event: any) {
    const file: any = URL.createObjectURL(event.target.files[0]);

    setImage(file);
  }

  async function onClickSaveImageReducer() {
    const canvasScaled = editorRef.getImageScaledToCanvas();

    const base64 = canvasScaled.toDataURL();

    onUploadImage(base64)

    setImage("");
  }

  function checkDevice() { 
    if( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ){
      return true; // está utilizando celular
    } else {
      return false; // não é celular
    }
  }


  const capture = useCallback(() => {
    if(onUploadImage) {
      const imageSrc = webcamRef.current.getScreenshot();

      onUploadImage(imageSrc)
      setShowEditor(false)

      onClose()
    
      onClose2()
    }
    
  }, [webcamRef]);

  if(isLoading) {
    return <></>
  }

  if(showEditor) {
    return (
      <Box
        position={"absolute"}
        top={0}
        left={0}
        w={"100%"}
        h={"100%"}
      >
        <Modal 
          isOpen={isOpen2}
          onClose={onClose2}
          closeOnOverlayClick={false}
          size={"2xl"}
          closeOnEsc={false}
        >
          <ModalOverlay />
          <ModalContent id={"modal"}>
            <ModalHeader></ModalHeader>
            <ModalBody
              className='bodyCanvasPic'
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {isOpen && (
                <Webcam
                  mirrored={true}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                />
              )}
            </ModalBody>
            <ModalFooter
              display={"flex"}
              justifyContent={"space-between"}
              flexDir={"row-reverse"}
            >
              <Button 
                colorScheme='teal'
                onClick={capture}
                id='capture'
                ml={3}
              >
                Salvar
              </Button>
              <Button
                onClick={() => {
                  onClose2();

                  setShowEditor(false);

                  onClose();
                }}
                background={"#d36262"}
                color={"#fff"}
                _hover={{
                  _disabled: {
                    background: "#d36262"
                  }
                }}
              >
                Cancelar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    )
  }

  return (
    <>
      {image && (
        <ModalEditerAvatar>
          <ContentEditAvatar height={height-100} mobileWidth={width}>
            <HeaderEditAvatar>
              <h3>Cortar foto de perfil</h3>
              <button type="button" onClick={() => setImage("")}>
                <FaTimes />
              </button>
            </HeaderEditAvatar>
            <BodyEditAvatar>
              <AvatarEditor
                style={{
                  width: checkDevice() ? width-40 : 400,
                  height: checkDevice() ? width-40 : 400,
                }}
                image={image}
                ref={(editor: any) => (editorRef = editor)}
                width={checkDevice() ? width-40 : 400}
                height={checkDevice() ? width-40 : 400}
                border={10}
                color={[255, 255, 255, 0.6]}
                scale={scale}
                rotate={rotate}
                crossOrigin="Anonymous"
                borderRadius={checkDevice() ? width-40 : 400}
                disableHiDPIScaling
              />
            </BodyEditAvatar>
            <FooterEditAvatar>
              <Box
                display={"flex"}
                justifyContent={"center"}
                padding={"10px 0px"}
              >
                <Button
                  type="button"
                  onClick={() => setRotate(rotate - 90)}
                  colorScheme='teal'
                  marginRight={"10px"}
                >
                  <FaUndo />
                </Button>
                <Button
                  type="button"
                  onClick={() => setRotate(rotate + 90)}
                  colorScheme='teal'
                  marginLeft={"10px"}
                >
                  <FaRedo />
                </Button>
              </Box>
              <div className="sizeImageContent">
                <FaRegImage
                  size="20"
                  color={scale === 1 ? '#c3c2c7' : '#319795'}
                />
                <div className="div_slide">
                  <input
                    type="range"
                    min="1"
                    max="10"
                    onChange={(e: any )=> setScale(e.target.value)}
                    step="0.01"
                    value={scale}
                    className="slider"
                  />
                </div>

                <FaRegImage
                  size="30"
                  color={scale === 10 ? '#c3c2c7' : '#319795'}
                />
              </div>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                padding={"0px 10px 10px 10px"}
              >
                <Button
                  onClick={() => setImage("")}
                  background={"#d36262"}
                  color={"#fff"}
                  marginTop={"20px"}
                  _hover={{
                    _disabled: {
                      background: "#d36262"
                    }
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={onClickSaveImageReducer}
                  colorScheme="teal"
                  marginTop={"20px"}
                >
                  Salvar
                </Button>
              </Box>
            </FooterEditAvatar>
          </ContentEditAvatar>
        </ModalEditerAvatar>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Atenção!
          </AlertDialogHeader>
          <AlertDialogBody>
            De onde gostaria de obter a imagem?
            {cancellLGPD && (
              <Box
                marginTop={"20px"}
                fontSize={"13px"}
                padding={"10px"}
                background={"#e7e7e7"}
                borderRadius={"10px"}
                fontWeight={600}
                color={"#757575"}
              >
                Caso você prossiga sua foto não poderá ser usada na solução de autenticação biométrica do seu empreendimento.
              </Box>
            )}
          </AlertDialogBody>
          <AlertDialogFooter display={"flex"} justifyContent={"space-between"}>
            <Button
              background={"#d36262"}
              color={"#fff"}
              _hover={{
                _disabled: {
                  background: "#d36262"
                }
              }}
              onClick={() => {
                setChangePhoto(false)
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Box>
              <Button
                colorScheme='teal'
                onClick={() => {
                  setChangePhoto(true)

                  const inputFile: any = document.getElementById('folder');

                  inputFile.click();

                  onClose();
                }}
              >
                Galeria
              </Button>
              <Button 
                colorScheme='teal'
                onClick={() => {
                  if(checkDevice()) {
                    const inputFile: any = document.getElementById('cam');

                    inputFile.click();

                    onOpen3()

                    setTimeout(() => {
                      onOpen2();
                    }, 500)

                    return
                  }

                  setShowEditor(true)

                  setTimeout(() => {
                    onOpen2();
                  }, 500)
                }} 
                ml={3}
              >
                Câmera
              </Button>
            </Box>
          </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Box
        display={"flex"}
        justifyContent={"center"}
        marginTop={"20px"}
        position={"relative"}
      >
        <input
          id="cam"
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={e => handlerFile(e)}
          capture
        />
        <input
          id="folder"
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={e => handlerFile(e)}
        />
        
        {source
          ? <Image
              boxSize={["180px", "180px"]}
              objectFit='cover'
              src={source}
              alt={"Perfil"}
              display={"flex"}
              flexDirection={"row"}
              borderRadius={"full"}
              background={"#fff"}
              padding={"5px"}
            />
          : <DefaultAvatar boxSize={["180px", "180px"]} padding={"3px"} />
        }
        {exludePic && (
          <Button
            width={"40px"}
            height={"40px"}
            padding={"5px"}
            borderRadius={"full"}
            position={"absolute"}
            left={8}
            bottom={-3}
            boxShadow={"0px 0px 20px #00000036"}
            background={"#d36262"}
            color={"#fff"}
            _hover={{
              _disabled: {
                background: "#d36262"
              }
            }}
            onClick={() => {
              setChangePhoto(true)
              onExcludeImage()
            }}
          >
            <FaTrashAlt size={"15px"} />
          </Button>
        )}
        {editPic && (
          <Button
            width={!exludePic ? "60px": "40px"}
            height={!exludePic ? "60px": "40px"}
            padding={"5px"}
            borderRadius={"full"}
            position={"absolute"}
            right={!exludePic ? 0: 8}
            bottom={!exludePic ? 0: -3}
            boxShadow={"0px 0px 20px #00000036"}
            onClick={onOpen}
          >
            <FaPencilAlt size={"15px"} />
          </Button>
        )}
      </Box>
    </>
  );
}
