import styled from 'styled-components';

import { 
  Box, 
} from '@chakra-ui/react';

export const Container = styled(Box)`
  > div + div {
    margin-top: 40px;
  }
`;

export const ContainerLoad = styled.div`
  height: calc(100vh - 175px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`