import React, { useEffect, useState, useRef } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { format } from 'date-fns';

import {
    Stack,
    Box,
    Text,
    Button,
    Radio, 
    RadioGroup,
    Tag,
    TagLabel,
    TagLeftIcon,
    Input,
    Textarea,
    Grid,
    GridItem,
    Flex,
    FormLabel,
    CircularProgress,
    Checkbox,  
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
} from '@chakra-ui/react'

import chroma from 'chroma-js';

import ReactJsAlert from "reactjs-alert"

import { toast } from "react-toastify"

import { MdAccessTimeFilled, MdImage, MdDelete } from "react-icons/md"

import jwt_decode from "jwt-decode"

import { useSelector } from 'react-redux'

import Select, { SingleValue, StylesConfig } from 'react-select'

import { ref, set } from "@firebase/database"

import { RootState } from '../../../store'

import { ModalComponent } from '../../../components/Modal'

import { BoxWithBorder } from '../../../components/BoxWithBorder'

import api from '../../../services/api'

import { CalendarComponent }  from '../../../components/Calendar'

// import { setCurrentBookingRequest } from '../../../store/reducers/booking-request'

import { setCurrentVisitCreate, resetCurrentVisitCreate } from '../../../store/reducers/visitCreate'

import { FirebaseRealtimeDatabase } from '../../../services/firebase'

import { IUserData } from '../../../interfaces/IUserData'

import { SelectContacts } from '../../../components/SelectContacts'

import { ImageUpload } from '../../../components/ImageUpload'

import TimePicker from 'react-time-picker'

import { ContainerCalendar, SubContainerCalendar, ContainerHoursFilters, ContainerWeekDays } from './styles'

import { TrataGuest } from '../../../helpers/fieldsAdjustment';

import { PropsContatos } from '../../../interfaces/IContacts';
interface IUnits {
    id: string;
    name: string;
}

interface IOptions {
    readonly value: string;
    readonly label: string;
}

interface IAvailableBookingDates {
    activeDays: string [],
    commonAreaRuleId: number,
    startDate: string,
    endDate: string,
    exceptionDate: string[],
    indisponibleDay: boolean
}

type PropsLocals = {
    allDay: boolean
    allDayAllowed: boolean
    availableTimes: any;
    enableVirtualDoorman: boolean;
    value: string;
    label: string;
}

type PropsMilestone = {
    guestType?: string;
    value: string;
    label: string;
}

type PropsMilestones = {
    frequent: boolean,
    guest: boolean,
    guestType: string,
    id: number,
    name: string,
    social: boolean,
    visitServiceProviders: {
        activeDay: any[]
        openTimes: any[]
    }
    visitVirtualDoorman: boolean
}

export function VisitForm () {
    const location = useLocation()
    const areasSelectRef = useRef<any>(null)
    const collaboratorsNameSelectRef = useRef<any>(null)
    const currentTimeSelectRef = useRef<any>(null)
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const visitCreate = useSelector((state: RootState) => state.visitCreate.data)

    const [milestones, setMilestones] = useState<readonly IOptions []>([])

    const [ allMilestones, setAllMilestones] = useState<PropsMilestones[]>([])

    const { isOpen, onOpen, onClose } = useDisclosure()
    
    const [areas, setAreas] = useState<readonly IOptions []>([])
    
    const [locals, setLocals] = useState<PropsLocals[]>([])

    const cancelRef =  useRef<HTMLButtonElement>(null)
    
    const [commonAreaRuleList, setCommonAreaRuleList] = useState([])

    const [valueStartHourFilter, onChangeStartHourFilter] = useState<any>('')
    const [valueEndHourFilter, onChangeEndHourFilter] = useState<any>('')
    
    const [availableBookingDates, setAvailableBookingDates] = useState<IAvailableBookingDates>({
        activeDays: [],
        commonAreaRuleId: 0,
        startDate: '',
        endDate: '',
        exceptionDate: [],
        indisponibleDay: false
    })
    
    const [title, setTitle] = useState('')

    const [description, setDescription] = useState('')

    const [currentContactList, setCurrentContactList] = useState<PropsContatos[]>([])
    //const [tempCurrentContactList, setTempCurrentContactList] = useState<PropsContatos[]>([])

    
    const [waitEndpointResponse, setWaitEndpointResponse] = useState(false)


    const [subModuleId, setSubModuleId] = useState('0')


    const [loading, setLoading] = useState(true)

    const [disabledButton, setDisabledButton] = useState(false)


    const [showButtonChangeReleaseType, setShowButtonChangeReleaseType] = useState(false)

    const [imageEvent, setImageEvent] = useState("")

    const [weekDays, setWeekDays] = useState<any[]>([])
    const [enableDays, setEnableDays] = useState<any[]>([])

    const [isEdit, setIsEdit] = useState(false)

    const [ errorOneMoreTime, setErrorOneMoreTime ] = useState(false)

    const [ showModalWithoutMaxVisitTime, setShowModalWithoutMaxVisitTime] = useState(false)

    const [ showModalGuestConfirmation, setShowModalGuestConfirmation ] = useState(false);

    const [maxVisitTimeDateObject, setMaxVisitTimeDateObject] = useState(new Date())

    const [guestType, setGuestType] = useState('');

    const [maxOpenTime, setMaxOpenTime] = useState('');
    const [maxCloseTime, setMaxCloseTime] = useState('');

    useEffect(() => {
        dispatch(resetCurrentVisitCreate());
    }, [])



    useEffect(() => {
        async function getRules(){
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
                }
            }
            
            const { data }:any = await api.get(`GuestGeneralRule/get`, config);

            if(data.maxVisitTime && !isNaN(data.maxVisitTime) && data.maxVisitTime>0){
                let newMaxVisitTimeDateObject = maxVisitTimeDateObject;

                newMaxVisitTimeDateObject.setMonth(newMaxVisitTimeDateObject.getMonth() + data.maxVisitTime);

                setMaxVisitTimeDateObject(newMaxVisitTimeDateObject);
            } else if(data.maxVisitTime === 0){
                setShowModalWithoutMaxVisitTime(true);
            }   
        }

        getRules();
    }, []);

    useEffect(() => {
        async function getDetailsVisita(){
            const id = location && location.state && location.state.id;
            
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
                }
            }
        
            const { data }:any = await api.get(`visit/getdetails/${id}`, config);
       
            setCurrentContactList(data.guests.map((res: any) => {
                return {
                    id: res.contact.id,
                    name: res.contact.name,
                    guestReleaseType: res.guestReleaseType.id,
                    editable: false,
                    selected: true,
                    favorite: res.favorite
                }
            }));

            dispatch(setCurrentVisitCreate({
                id: data.id,
                milestoneId: data.milestone.id,
                weekDays: [],
                title: data.name,
                data: '',
                startTime: data.startDate?.split('T')[1],
                endTime: data.endDate?.split('T')[1],
                guests: data.guests,
                startDate: data.startDate?.split('T')[0],
                endDate: data.endDate?.split('T')[0],
                social: data.milestone.social,
                visitType: '',
                enableVirtualDoorman: false,
                guest: data.milestone.guest
            }));
        }

        if(location?.state?.id){
            setIsEdit(true)
            getDetailsVisita()
        }
    }, [location && location.state && location.state.id])

    useEffect(() => {
        setLoading(false)
    }, [])

    useEffect(() => {
        const userToken = localStorage.getItem('access_portal:access_token')

        const decoded = jwt_decode(userToken as string) as IUserData

        setSubModuleId(decoded.subModuleId)
        
        loadMilestones()
    }, [])

    async function loadMilestones() {
        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        
        try{
            const decoded = jwt_decode(userToken as string) as IUserData
                    
            const { data } = await api.get(`milestone/GetName?ownLocation=true&scheduled=true`, config)
            
            setAllMilestones(data)
            setMilestones(data.map((v: IUnits) => {
                return {
                    label: v.name,
                    value: v.id,
                }
            }))
            
        }catch(e: any) {
        }
    }

    function getDateObject (dateString: string, timeString: string) {
        let dateObj = new Date()
        
        let dateSplit = dateString.split('-')
        let timeSplit = timeString.split(':')


        dateObj.setDate(parseInt(dateSplit[2]))
        dateObj.setMonth(parseInt(dateSplit[1]) - 1)
        dateObj.setFullYear(parseInt(dateSplit[0]))
        dateObj.setHours(parseInt(timeSplit[0]))
        dateObj.setMinutes(parseInt(timeSplit[1]))
        dateObj.setSeconds(0)


        return dateObj
    }

    function validateTimeVersion2(milestone: any) {
        let diasDaSemana = [
          {name: 'DOMINGO', times: 0},
          {name: 'SEGUNDA-FEIRA', times: 0}, 
          {name: 'TERÇA-FEIRA', times: 0}, 
          {name: 'QUARTA-FEIRA', times: 0}, 
          {name: 'QUINTA-FEIRA', times: 0}, 
          {name: 'SEXTA-FEIRA', times: 0}, 
          {name: 'SÁBADO', times: 0} 
        ];

     
        let dataSplit = visitCreate.startDate.split('-')
        let dataObj = new Date();
        dataObj.setDate(parseInt(dataSplit[2]));
        dataObj.setMonth(parseInt(dataSplit[1]) - 1);
        dataObj.setFullYear(parseInt(dataSplit[0]));
        let day = diasDaSemana[dataObj.getDay()].name;

        let weekIndex = 0;

        //conta quantas vezes um dado dia se repete

        for (let i = 0; i < diasDaSemana.length; i++) {
            for (let j = 0; j < milestone.visitServiceProviders.activeDay.length; j++) {
                for (let k = 0; k < milestone.visitServiceProviders.activeDay[j].activeDays.length; k++) {
                    if (diasDaSemana[i].name === milestone.visitServiceProviders.activeDay[j].activeDays[k]) {
                        diasDaSemana[i].times++;
                    } 
                }
            }
        }


        for (let i = 0; i < milestone.visitServiceProviders.activeDay.length; i++ ) {
            for (let k = 0; k < milestone.visitServiceProviders.activeDay[i].activeDays.length; k++) {
                if (milestone.visitServiceProviders.activeDay[i].activeDays[k] === day) {
                    weekIndex = i;
                    //break;
                    const startTimeDate = new Date();
                    const startTimeSplit = visitCreate.startTime.split(':');
                    startTimeDate.setHours(parseInt(startTimeSplit[0]), parseInt(startTimeSplit[1]));
                    const endTimeDate = new Date();
                    const endTimeSplit = visitCreate.endTime.split(':');
                    endTimeDate.setHours(parseInt(endTimeSplit[0]), parseInt(endTimeSplit[1]));

                    const startTimeMilestoneDate = new Date();
                    const startTimeMilestoneSplit = milestone.visitServiceProviders.openTimes[weekIndex].openTime.split(':');
                    startTimeMilestoneDate.setHours(parseInt(startTimeMilestoneSplit[0]), parseInt(startTimeMilestoneSplit[1]));
                    const endTimeMilestoneDate = new Date();
                    const endTimeMilestoneSplit = milestone.visitServiceProviders.openTimes[weekIndex].closeTime.split(':');
                    endTimeMilestoneDate.setHours(parseInt(endTimeMilestoneSplit[0]), parseInt(endTimeMilestoneSplit[1]));


                    // horario dentro dos limites do milestone
                    if(startTimeDate < startTimeMilestoneDate || endTimeDate > endTimeMilestoneDate){
                        diasDaSemana[dataObj.getDay()].times--;
                    }
                }
            }
        }

        if (diasDaSemana[dataObj.getDay()].times === 0) {
            return false;
        }

        return true;
    }

    function onlyUnique(array: any) {
      let array2 = array;

      //percorre toda a array. para cada item:
      //-percorre toda a array a partir do item x. se um item y de id igual ao de x for achado, excluir y
      //-return

      let tamanhoTotalArray = array2.length;

      if (tamanhoTotalArray === 1) {
        return array2;
      }

      //return array2;

      for (let i = 0; i < tamanhoTotalArray; i++) {
        for (let j = i + 1; j < tamanhoTotalArray; j++) {
            if (array2[i].contactId === array2[j].contactId) {
                array2.splice(j, 1);
                tamanhoTotalArray--;
                j = i + 1;
            }
        }
      }


      return array2;
    }

    function validation() {

        let selectedContacts = []

        for(let i in currentContactList ){
            if(currentContactList[i].selected){
                selectedContacts.push(currentContactList[i])
            }
        }
        //tipo
        if(!visitCreate.milestoneId || visitCreate.milestoneId == ''){
            return toast.error('É necessário selecionar o tipo da visita.')
        }

        let selectedMilestoneDetails = allMilestones.filter((v: any) => {
            return v.id === parseInt(visitCreate.milestoneId);
        })[0];


        //titulo
        if(!visitCreate.title || visitCreate.title.trim().length == 0){
            return toast.error('É necessário informar o título da visita.')
        }

        //titulo 5 caracteres
        if(visitCreate.title.trim().length < 5){
            return toast.error('O título deve ter no mínimo 5 caracteres.')
        }

        
        //data inicio
        if(visitCreate.startDate.length<1){
            return toast.error('É necessário que selecione um dia de início.')
        }
        
        // data fim
        if(visitCreate.endDate.length<1){
            return toast.error('É necessário que selecione um dia de final.')
        }

        // horario inicio
        if(visitCreate.startTime.length<1){
            return toast.error('É necessário que selecione um horário de início.')
        }

        // horario fim
        if(visitCreate.endTime.length<1){
            return toast.error('É necessário que selecione um horário de final.')
        }

        if (!selectedMilestoneDetails.social) {
            // if (!validateTimeVersion3(selectedMilestoneDetails, visitCreate.startDate)) {
            if (!validateTimeVersion2(selectedMilestoneDetails)) {
                return toast.error('Devido à regra estabelecida pela empresa, não serão permitidas visitas comerciais neste horário!');
            }
        }

        //verifica se as horas de entrada e saida estao de acordo com os limites do marco selecionado
        
        //contatos
        //if(selectedContacts.length<1 && ){
        if(selectedContacts.length<1 && selectedMilestoneDetails.guestType !== 'NONE'){
            return toast.error('É necessário que selecione pelo menos um contato.')
            
        }

        setDisabledButton(true)

        //return false;

        sendEndpoint()               
    }

    async function sendEndpoint(){
        const userToken = localStorage.getItem('access_portal:access_token');

        const decoded = jwt_decode(userToken as string) as IUserData;

        let selectedContacts = [];

        for(let i in currentContactList ){
            if(currentContactList[i].selected){
                selectedContacts.push(currentContactList[i]);
            }
        }

        //colocar aqui o id e validação de editar
        let sendObject = {};

        if(isEdit){
            let contactMilestoneArray = selectedContacts.map(v => {
                return {
                    contactId: TrataGuest(v),
                    guestReleaseType: v.guestReleaseType,
                };
            });

            contactMilestoneArray = onlyUnique(contactMilestoneArray);

            const id = location && location.state && location.state.id;
        
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
                }
            };

            const { data }:any = await api.get(`visit/getdetails/${id}`, config);

            const contactMilestoneOld = data.guests.map((res: any) => {
                return {
                    contactId: res.contact.id
                }
            });

            let contactMilestoneArrayLength = contactMilestoneArray.length;

            for (let i = 0; i < contactMilestoneOld.length; i++) {
                for (let j = 0; j < contactMilestoneArrayLength; j++) {
                    if (contactMilestoneArray[j].contactId === contactMilestoneOld[i].contactId) {
                        contactMilestoneArray.splice(j, 1);
                        contactMilestoneArrayLength--;
                    }
                }
            }

            sendObject = {
                id: visitCreate.id,
                UserId: decoded.user_id,
                ClientId: decoded.client_id.split('#')[0],
                UnitId: Array.isArray(decoded.unit_id as string[]) ? decoded.unit_id[0] : decoded.unit_id,
                Title:  visitCreate.title,
                StartDate: formattedDate(visitCreate.startDate)+' '+visitCreate.startTime,
                EndDate: formattedDate(visitCreate.endDate)+' '+visitCreate.endTime,
                milestoneId: visitCreate.milestoneId,
                contactMilestone: contactMilestoneArray            
            };
        }else{
            sendObject = {
                UserId: decoded.user_id,
                ClientId: decoded.client_id.split('#')[0],
                UnitId: Array.isArray(decoded.unit_id as string[]) ? decoded.unit_id[0] : decoded.unit_id,
                Title:  visitCreate.title,
                StartDate: formattedDate(visitCreate.startDate)+' '+visitCreate.startTime,
                EndDate: formattedDate(visitCreate.endDate)+' '+visitCreate.endTime,
                milestoneId: visitCreate.milestoneId,
                contactMilestone: selectedContacts.map(v => {
                    return {
                        contactId: TrataGuest(v),
                        guestReleaseType: v.guestReleaseType,
                    };
                })
            };
        }
        
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
            }
        };
        
        let url = '';

        if(isEdit){
            if(decoded.subModuleId == '5'){
                url = 'visit/updateBusinessVisit'
            }else{
                url = 'visit/update'
            }
        }else{
            if(decoded.subModuleId == '5'){
                url = 'visit/createBusinessVisit'
            }else{
             url = `/visit/create`
            }
        }

        try {
            await api.post(
                url, 
                sendObject,
                config
            );

            toast.success(location.state?.id
                ? "Visita atualizada com sucesso!"
                : "Visita criada com sucesso!"
            )

            sendFirebase();

            setTimeout(() => {
                if(location.state?.id) {
                    navigate(-1);
                } else {
                    navigate(-1);
                }
            }, 4000);
        } catch (e: any) {
            setDisabledButton(false);

            if(
              e &&
              e.response &&
              e.response.data &&
              e.response.data.Message
            ){
              toast.error(e.response.data.Message);
            }
        } 
    }

    const formattedDate = (value:any) => {
        let splitVal = value.split('-');
    
        if (splitVal.length < 3) {
          splitVal = value.split('/');
        }
    
        let ano = splitVal[0];
    
        let mes = splitVal[1].length > 1 ? splitVal[1] : '0' + splitVal[1];
    
        let dia = splitVal[2].length > 1 ? splitVal[2] : '0' + splitVal[2];
    
        return ano + '-' + mes + '-' + dia;
    };

    function getLabelByValue(val: any, options: any): string {
        const result = options.filter((v: any) => v.value.toString() === val.toString());

        if(result.length > 0){
            return result[0].label;
        }

        return '';
    }
    
    function formatStringDateUSToBr(val: string) : string{
        let formatDate = val.split('-')
        return formatDate[2] + '/' + formatDate[1] + '/' + formatDate[0]
    }

    function sendFirebase () {
        
        let startDateSplit = visitCreate.startDate.split('-')
        
        let startDateToSend = startDateSplit[2] + '-' + startDateSplit[1] + '-' + startDateSplit[0]

        const userToken = localStorage.getItem('access_portal:access_token')
    
        const decoded = jwt_decode(userToken as string) as IUserData
        
        let selectedClient = decoded.client_id
    
        let clientId = selectedClient.split('#')[0]
        let date = new Date()
        let dia = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate()
        let mes = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
        let ano = date.getFullYear()
    
        let dataFormatted = dia + '-' + mes + '-' + ano
    
        set(
            ref(
                FirebaseRealtimeDatabase, 
                '/change-acess-control-janitor/' + startDateToSend
            ),
            new Date().toString()
        )

        set(
            ref(
                FirebaseRealtimeDatabase, 
                '/visit-creation-or-change/' + startDateToSend
            ),
            new Date().toString()
        )

        set(
            ref(
                FirebaseRealtimeDatabase, 
                '/calendar-creation-or-change/' + decoded.unit_id
            ),
            new Date().toString()
        )


        

    }

    function handleChangeContactGuest(data: any) {
        setCurrentContactList(data);
    }

    function getValueFile(data: string) {
        setImageEvent(data)
    }

    async function save() {
        setDisabledButton(true)

        const userToken = localStorage.getItem('access_portal:access_token')

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        
        setWaitEndpointResponse(true)

        const decoded = jwt_decode(userToken as string) as IUserData

        const userId = decoded.user_id

        const clientId = decoded.client_id

        const startDate = visitCreate.startDate + ' ' + visitCreate.startTime.substring(0, 5)
        
        const endDate = visitCreate.endDate + ' ' + visitCreate.endTime.substring(0, 5)

        
        let toSend: any = {
            requestById: userId,
            // localId: visitCreate.localId,
            milestoneId: visitCreate.milestoneId,
            // date: visitCreate.reservationDate,
            timesheets: visitCreate,
           
        }

       
    }

    const getDateAfter4Months = () => {
        let date = new Date()
        date.setMonth(date.getMonth() + 4)
        return date
    }

    const validateHoutFormat = (value: string) => {
        if(!(value.indexOf(':') > -1)) {
            return false
        }
        const valueSplit = value.split(':')
        if(valueSplit.length !== 2){
            return false
        }
        if(isNaN(parseInt(valueSplit[0])) || isNaN(parseInt(valueSplit[1]))){
            return false
        }
        if(
            parseInt(valueSplit[0]) > 23
            || parseInt(valueSplit[1]) > 59
        ){
            return false
        }   

        return true
    }

    function saveDiasDaSemana(val: any){
        // milestones
        let milestoneToUse = allMilestones.filter(res => res.id == val)

        let objDias:any = []
        if(!milestoneToUse || milestoneToUse.length === 0 || milestoneToUse.filter((v)=> !v.visitServiceProviders).length > 0){
            objDias.push({
                days: ['DOMINGO', 'SEGUNDA-FEIRA', 'TERÇA-FEIRA', 'QUARTA-FEIRA', 'QUINTA-FEIRA', 'SEXTA-FEIRA', 'SÁBADO'],
                times: {
                    openTime: '00:00',
                    closeTime: '23:59'
                  }
            })
        } else{
            let allDays = milestoneToUse[0].visitServiceProviders.activeDay
            let allTimes = milestoneToUse[0].visitServiceProviders.openTimes

            for(let i in allDays){
                let dia = {
                  days: allDays[i].activeDays,
                  times: allTimes[i]
                }
                objDias.push(dia)
              }

            }

            let enableDays:any = []
            for(let i in objDias){
                for(let j in objDias[i].days){
                    if(enableDays.filter((v:any) => v === objDias[i].days[j]).length===0){
                        enableDays.push(objDias[i].days[j])
                    }
                }
            }
            setEnableDays(enableDays)
            setWeekDays(objDias)
            dispatch(setCurrentVisitCreate({
              ...visitCreate,
              weekDays: objDias
            }))
          //  return objDias
    }

    useEffect(() => {
        if (visitCreate.id) {
            saveDiasDaSemana(visitCreate.milestoneId);
        }
    }, []);


    const verificaDiaDisponivel = (data: any) =>{
        let dataSplit = data.split('-')
        let dataObj = new Date();
        dataObj.setDate(dataSplit[2]);
        dataObj.setMonth(parseInt(dataSplit[1]) - 1);
        dataObj.setFullYear(dataSplit[0]);

        let diaDaSemana = dataObj.getDay();

        let temDisponivel = false;


        for(let i in weekDays){
            for(let j in weekDays[i].days){
              if(diaDaSemana == 0 && weekDays[i].days[j] == 'DOMINGO') { temDisponivel = true }
              if(diaDaSemana == 1 && weekDays[i].days[j] == 'SEGUNDA-FEIRA') { temDisponivel = true }
              if(diaDaSemana == 2 && weekDays[i].days[j] == 'TERÇA-FEIRA') { temDisponivel = true }
              if(diaDaSemana == 3 && weekDays[i].days[j] == 'QUARTA-FEIRA') { temDisponivel = true }
              if(diaDaSemana == 4 && weekDays[i].days[j] == 'QUINTA-FEIRA') { temDisponivel = true }
            if(diaDaSemana == 5 && weekDays[i].days[j] == 'SEXTA-FEIRA') { temDisponivel = true }
            if(diaDaSemana == 6 && weekDays[i].days[j] == 'SÁBADO') { temDisponivel = true }
            
          }
          }

          return temDisponivel;
    }

    function checkAvailableDate(date:any){
        let dataToUse = date
        if(!verificaDiaDisponivel(dataToUse)){

            setErrorOneMoreTime(true)
            return toast.error('Este dia não está disponível para este tipo de visita.')
        }
    }


    const handleDatePickedInicio = (date:any) =>{
        
        // let dataToUse = date
        //verificar dia disponivel
        if(!verificaDiaDisponivel(date)){
            setErrorOneMoreTime(true);

            return toast.error('Este dia não está disponível para este tipo de visita.')
        }else{
            //verifica com dia atual
            let hj = new Date()
            hj.setHours(0,0,0,0)

            let selec = new Date(date);

            selec.setHours(0,0,0,0)

            selec.setDate(selec.getDate() + 1);

            const dataI = selec.getTime()

            if(hj.getTime() > dataI && selec.getFullYear() > 2000){
                return toast.error('A data inicial não pode ser anterior a data atual.')
            }

            //verifica com data final
            const dataFI = new Date(visitCreate.endDate);
            dataFI.setHours(0,0,0,0);

            if(dataI > dataFI.getTime()){
                dispatch(setCurrentVisitCreate({
                    ...visitCreate,
                    endDate: String(date)
                }))
            }
            if(visitCreate.social){

                dispatch(setCurrentVisitCreate({
                    ...visitCreate,
                    startDate: String(date)
                }))
            }else{
                dispatch(setCurrentVisitCreate({
                    ...visitCreate,
                    startDate: String(date),
                    endDate: String(date)
                }))
            }

        }

    }

    const handleDatePickedFinal = (date:any) =>{
        // let dataToUse = date

        //verificar dia disponivel
        if(!verificaDiaDisponivel(date)){
            setErrorOneMoreTime(true)
            return toast.error('Este dia não está disponível para este tipo de visita.')
        }else{

            //verifica com dia atual
            let hj = new Date()
            hj.setHours(0,0,0,0)

            let selec = new Date(date)
            selec.setHours(0,0,0,0)
            selec.setDate(selec.getDate() + 1)
            const dataFI = selec.getTime()

            if(hj.getTime() > dataFI && selec.getFullYear() > 2000){
                return toast.error('A data final não pode ser anterior a data atual.')
            }

            //verifica com data inicial
            const dataI = new Date(visitCreate.startDate);
            dataI.setHours(0,0,0,0);
            
            if(dataFI < dataI.getTime() && selec.getFullYear() > 2000){
                return toast.error('A data final não pode ser anterior a data inicial.')
            }
            

            dispatch(setCurrentVisitCreate({
                ...visitCreate,
                endDate: String(date)
            }))

        }

    }

    const handleTimePickedInicio = (data: any) => {
        if(data){

            // verificar se a data do evento eh a data atual
            // caso sim
            //     verifica a hora ser menor que a atual
    
            let dataSelec = visitCreate.startDate.split('-')
            let selecHour = data.split(':')
            let d = new Date()
            
            var data1 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes());
            var data2 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), selecHour[0], selecHour[1]);

            let dataEscolhida = new Date(parseInt(dataSelec[0]), parseInt(dataSelec[1])-1, parseInt(dataSelec[2]))
            var dataHoje = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

            try {
                if(dataHoje.getFullYear() == dataEscolhida.getFullYear() &&
                    dataHoje.getMonth() == dataEscolhida.getMonth() &&
                    dataHoje.getDate() == dataEscolhida.getDate()
                    ){
                    //mesma data
                    if(data1 > data2){
                        return toast.error('O horário inicial do evento não pode ser menor do que o horário atual.')
                    } else {
                    dispatch(setCurrentVisitCreate({
                            ...visitCreate,
                            startTime: String(data)
                        })) 
                    }
                }else{
                    dispatch(setCurrentVisitCreate({
                        ...visitCreate,
                        startTime: String(data)
                    }))
                }

            } catch(e: any) {
                console.log("excecao", e)
            }
        }

    }

    const handleTimePickedFim = (data: any) => {

        if(data){

            let selecHour = data.split(':')
    
            let dataInicialSplit = visitCreate.startDate.split('-')
            let dataFinalSplit = visitCreate.endDate.split('-')
            let horaInicialSplit = visitCreate.startTime.split(':')
    
            const horaInicial = new Date()
    
            horaInicial.setDate(parseInt(dataInicialSplit[2]));
            horaInicial.setMonth(parseInt(dataInicialSplit[1])-1);
            horaInicial.setFullYear(parseInt(dataInicialSplit[0]));
            horaInicial.setHours(parseInt(horaInicialSplit[0]), parseInt(horaInicialSplit[1]), 0, 0);
    
            const horaFinal = new Date()
    
            horaFinal.setDate(parseInt(dataFinalSplit[2]));
            horaFinal.setMonth(parseInt(dataFinalSplit[1])-1);
            horaFinal.setFullYear(parseInt(dataFinalSplit[0]));
            horaFinal.setHours(selecHour[0], selecHour[1], 0, 0);
    
            if(horaInicial.getTime() > horaFinal.getTime()){
                return toast.error('O horário final não pode ser anterior ao horário inicial.')
            }else{
                dispatch(setCurrentVisitCreate({
                    ...visitCreate,
                    endTime: String(data)
                }))
            }
        }


    }

    const getIsSocial = (val: any) => {
        const result = allMilestones.filter((v: any) => v.id.toString() === val.toString())
       
        // if(result.length > 0){
        //     return result[0].label
        // }
        return result[0].social
    }

    const getDate =(val: any) => {
        let date =new Date(val)
        let year = date.getFullYear()
        let month = String(date.getMonth()+1)
        let day = date.getDate()
        if(parseInt(month) < 10){
            month = '0'+month
        }
        let toReturn = year+'-'+month+'-'+day

        return toReturn
    }

    if(loading) {
        return (
            <Flex
                position={"relative"}
                h={"calc(100vh - 175px)"}
                w={"100%"}
                top={"0px"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
            </Flex>
        )
    }

    return (
        <Grid templateColumns={["0% 100% 0%", "25% 50% 25%"]}>
            <GridItem colSpan={1} />
            <GridItem
                colSpan={1}
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                {/* <ReactJsAlert
                status={showModalWithoutMaxVisitTime}
                type={'error'}
                title={'Atenção!'}
                quotes={true}
                quote={'Para o seu condomínio não está configurado o controle de visitantes e assim não é possível usar esse recurso.'}
                autoCloseIn={20000}
                Close={() => navigate(-1)}
                button={'OK'}
            /> */}
            <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Atenção!
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        Para o seu condomínio não está configurado o controle de visitantes e assim não é possível usar esse recurso.
                       
                   
                    </AlertDialogBody>
            <AlertDialogFooter display={"flex"} justifyContent={"space-between"}>
           
            <Box>
              <Button
                colorScheme='teal'
                onClick={() => {
                    navigate(-1)
                    onClose();
                }}
              >
                Ok
              </Button>
              
            </Box>
          </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
          <ModalComponent
            title="Atenção"
            isOpen={showModalGuestConfirmation}
            onClose={() => {
                setShowModalGuestConfirmation(false)
            }}
            closeButtonLabel="Sim"
            confirmButtonLabel="Não"
            flexDir="row"
            closeButtonColor="#319795"
            confirmColorScheme="red"
            onConfirmButton={() => {
              
                validation();
                setShowModalGuestConfirmation(false)
            }}
          >
              Você tem o contato
              do visitante
              para esta visita de serviço?
          </ModalComponent>
                
                <Text
                    fontSize={[28, 20]}
                    fontWeight={600}
                    color={"#444"}
                    marginBottom={"10px"}
                >
                    Cadastro de Visita
                </Text>

                <Flex
                   flexDir={"column"}
                   background={["inherit", "#fff"]}
                   w={"100%"}
                   paddingBottom={["0px", "20px !important"]}
                   borderRadius={"10px"}
                >
                    <BoxWithBorder
                        title={'Dados da Visita'}
                        padding={["0px", "20px"]}
                        margin={["0px", "20px"]}
                        borderWidth={["0px", "1px"]}
                    >

                        <>
                            <Box w={["100%", "50%"]}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Tipo de visita
                                </FormLabel>
                                <Select<IOptions>
                                    placeholder={'Selecione...'}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(val: SingleValue<IOptions>) => {
                                        if(val){
                                            if(val.value.toString() !== visitCreate.milestoneId){
                                                saveDiasDaSemana(val.value)
                                                setErrorOneMoreTime(false)
                                                dispatch(setCurrentVisitCreate({
                                                    ...visitCreate,
                                                    milestoneId: val.value.toString(),
                                                    social: getIsSocial(val.value.toString())
                                                }))
                                            }
                                        }else{
                                            dispatch(resetCurrentVisitCreate())
                                            setCurrentContactList([])
                                        }

                                    }}
                                    value={{ value: visitCreate.milestoneId, label: getLabelByValue(visitCreate.milestoneId, milestones)}}
                                    isClearable={true}
                                    name="color"
                                    options={milestones}
                                    isDisabled={visitCreate.id != 0}
                                />
                            </Box>
                            <Box marginTop={"10px"}>
                                <FormLabel
                                    fontSize={"0.8em"}
                                    color={"#777"}
                                    marginBottom={"3px"}
                                >
                                    Dê um título para a visita:
                                </FormLabel>
                                <Input
                                    background={"#fff"}
                                    placeholder={"Título"}
                                    onChange={(e) =>{
                                        dispatch(setCurrentVisitCreate({
                                            ...visitCreate,
                                            title: e.target.value.toString()

                                        }))
                                    }}
                                    value={visitCreate.title}
                                />
                            </Box>
                        </>
                        </BoxWithBorder>
                        {visitCreate.milestoneId && (
                            <BoxWithBorder
                                title={'Data e Hora da Visita'}
                                padding={["0px", "20px"]}
                                margin={["0px", "20px"]}
                                borderWidth={["0px", "1px"]}
                            >
                                <>
                                    <Box width={"100%"}>
                                        {visitCreate.social && (
                                            <FormLabel
                                                fontSize={"0.8em"}
                                                color={"#777"}
                                                marginBottom={"3px"}
                                            >
                                                Data de início
                                        
                                            </FormLabel>
                                        )}
                                        <Input
                                            isDisabled={false}
                                            placeholder={'DD/MM/AAAA'}
                                            type={"date"}
                                            min={getDate(new Date())}
                                            max={getDate(maxVisitTimeDateObject)}
                                            onChange={(date) => {
                                                handleDatePickedInicio(date.target.value)
                                            }}
                                            value={visitCreate.startDate}
                                            background={"#fff"}
                                        />
                                    </Box>
                                    {visitCreate.social &&
                                    <Box width={"100%"}>
                                        <FormLabel
                                            fontSize={"0.8em"}
                                            color={"#777"}
                                            marginBottom={"3px"}
                                            marginTop={'5px'}
                                        >
                                            Data de fim
                                    
                                        </FormLabel>
                                        <Input
                                            isDisabled={false}
                                            placeholder={'DD/MM/AAAA'}
                                            type={"date"}
                                            min={getDate(new Date())}
                                            max={getDate(maxVisitTimeDateObject)}
                                            onChange={(date) => {
                                                handleDatePickedFinal(date.target.value)
                                            
                                                // setUserStatic({
                                                //     ...userStatic,
                                                //     birthDate: String(date.target.value)
                                                // })
                                            }}
                                            value={visitCreate.endDate}
                                            background={"#fff"}
                                        />
                                    </Box>}
                                    {!visitCreate.social && errorOneMoreTime && 
                                    <ContainerWeekDays>

                                        <Box width={"100%"}>
                                            <FormLabel
                                                fontSize={"0.8em"}
                                                color={"#777"}
                                                marginBottom={"3px"}
                                            >
                                                Dia(s) da semana pemitido(s):
                                        
                                            </FormLabel>
                                        {
                                            enableDays.map((v) => {
                                                return (
                                                    <FormLabel
                                                    marginTop={'10px'}
                                                    fontSize={"0.8em"}
                                                    color={"#777"}
                                                    marginBottom={"3px"}
                                                    >
                                                - { v }
                                        
                                            </FormLabel>
                                                )
                                            })
                                        }
                                        </Box>
                                        </ContainerWeekDays>

                                        }
                                    <Box>
                                    <FormLabel
                                            marginTop={'10px'}
                                            fontSize={"0.8em"}
                                            color={"#777"}
                                            // marginBottom={"3px"}
                                        >
                                            Horário da visita
                                    
                                        </FormLabel>
                                        <ContainerHoursFilters>
                                                    <div className='container-field'>
                                                        <span>De: </span>
                                                        <TimePicker 
                                                            disabled={false}
                                                            clearIcon={null}
                                                            onChange={(e) => {
                                                                handleTimePickedInicio(e)
                                                            }}
                                                            value={visitCreate.startTime} 
                                                            disableClock={true}
                                                            maxTime={'23:59'}
                                                            minTime={'00:00'}
                                                            />
                                                    </div>
                                                    <div className='container-field'>
                                                        <span>Até: </span>
                                                        <TimePicker 
                                                            disabled={false}
                                                            clearIcon={null}
                                                            onChange={(e) => {
                                                                handleTimePickedFim(e)
                                                                // onChangeEndHourFilter(e)
                                                            }}
                                                            value={visitCreate.endTime} 
                                                            disableClock={true}
                                                            maxTime={'23:59'}
                                                            minTime={'00:00'}
                                                            />
                                                    </div>

                                                

                                                </ContainerHoursFilters>
                                    </Box>
                                
                                </>
                            </BoxWithBorder>
                        )}

                    {visitCreate.milestoneId && (
                        <BoxWithBorder
                            title={'Convidados'}
                            padding={["0px", "20px"]}
                            margin={["15px", "20px"]}
                            borderWidth={["0px", "1px"]}
                        >
                            <SelectContacts
                                contactsSelecteds={currentContactList.map((res) => {
                                    return {
                                        id: res.id,
                                        name: res.name,
                                        guestReleaseType: res.guestReleaseType,
                                        editable: false,
                                        selected: true,
                                        guestType: 'EXTERNAL',
                                        edit: true,
                                        favorite: res.favorite
                                    };
                                })}
                                guestType={'EXTERNAL'}
                                showButtonChangeReleaseType={showButtonChangeReleaseType}
                                saveFunction={handleChangeContactGuest}
                            />
                        </BoxWithBorder>
                    )}
                    <Flex
                        flexDir={["column-reverse", "row"]}
                        justifyContent={["row", "space-between"]}
                        marginTop={["20px", "0px"]}
                        padding={["0px", "0px 20px"]}
                    >
                        <Button 
                            colorScheme='teal' 
                            isDisabled={disabledButton}
                            onClick={() => {
                                dispatch(resetCurrentVisitCreate())
                                navigate(-1)}}
                        >
                            Voltar
                        </Button>
                        <Button 
                            isDisabled={disabledButton}
                            colorScheme='teal' 
                            onClick={() => {
                                let selectedMilestoneDetails = allMilestones.filter((v: any) => {
                                    return v.id === parseInt(visitCreate.milestoneId);
                                })[0];
                                if (selectedMilestoneDetails.guestType === 'NONE' && currentContactList.length <= 0) {
                                    setShowModalGuestConfirmation(true);
                                } else {
                                    validation();
                                }
                            }}
                            marginBottom={["10px", "0px"]}
                        >
                            {"Finalizar"}
                        </Button>
                    </Flex>
                </Flex>
            </GridItem>
            <GridItem colSpan={1} />
        </Grid>
    )
}



