import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IData {
    active: boolean
    appMenuClient: any[]
    appMenuConfiguration: Boolean
    appMenuDefault: {
        advertise: Boolean,
        package: Boolean,
        takeWay: Boolean,
        pickUp: Boolean,
        openGate: Boolean
    }
    clientId: Number | null
    imageData: {
        data: String
        type: String
    }
    optInAccepted: Boolean
}

const initialState = {
    data: {
        active: false,
        appMenuClient: [],
        appMenuConfiguration: false,
        appMenuDefault: {
            advertise: false,
            package: false,
            takeWay: false,
            pickUp: false,
            openGate: false
        },
        clientId: null,
        imageData: {
            data: "",
            type: ""
        },
        optInAccepted: false
    } as IData
}
  
export const menuParameterizationSlice = createSlice({
    name: 'menuParameterization',
    initialState,
    reducers: {
        resetMenuParameterization: (state) => {
            state = initialState
        },
        setMenuParameterization: (state, action: PayloadAction<IData>) => {
            state.data = action.payload
        },
    },
})

export const { resetMenuParameterization, setMenuParameterization } = menuParameterizationSlice.actions

export default menuParameterizationSlice.reducer