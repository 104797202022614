import React, { useState, useRef, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ref, set } from "@firebase/database"

import {
    Grid,
    GridItem,
    Box,
    Text,
    Button,
    Input,
    Select,
    FormLabel,
    Radio,
    RadioGroup,
    Skeleton,
    Stack,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Flex,
    CircularProgress,
    useDisclosure,
} from "@chakra-ui/react";

import { cpf as cpfValidator } from 'cpf-cnpj-validator'; 

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import jwt_decode from 'jwt-decode'

import { FirebaseRealtimeDatabase } from '../../../../services/firebase'

import api from '../../../../services/api'

import { RootState } from '../../../../store';

import { setCurrentUser } from '../../../../store/reducers/user';

import { Avatar } from './components/Avatar';

import { UserPasswordReset } from './components/UserPasswordReset';

import { InputPhone } from '../../../../components/InputPhone';

import { InputCPF } from '../../../../components/InputCPF';

import { InputRg } from '../../../../components/InputRg';

import { hasPermission } from '../../../../utils/check-permission';

import { ContentFields, MessageForImageValid } from './styles'

interface IUserData {
    userId: number | null
    name: string
    function: string
    email: string
    phone: string
    gender: string
    cpf: string
    rg: string,
    birthDate: string
    driver: boolean | null
    picture: string,
    subModuleId?: string,
    type: string
}

interface IValidPhoto {
    enable_facial: boolean | null
    image_valid: boolean | null
    image_key: string
    image_key_valid: boolean | null
    facial_by_app: boolean
    facial_by_app_user: boolean
    facial_by_app_opt_in_accepted: string
}

interface IUserDataToken {
    user_id: number
    function: string
    client_id: string
}

export function MyDate () {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    let dialogRef: any = useRef();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const user = useSelector((state: RootState) => state.user.data)

    const [editPic, setEditPic] = useState(false);

    const [exludePic, setExludePic] = useState(false);

    const [canShowPhotoAlerts, setCanShowPhotoAlerts] = useState<string | null>(null);

    const [getUserImageKeyValid, setGetUserImageKeyValid] = useState<string | null>(null);

    const [showAlertPermissionError, setShowAlertPermissionError] = useState(false);

    const [validatePhotoObj, setValidatePhotoObj] = useState<IValidPhoto>({
        enable_facial: null,
        image_valid: null,
        image_key: "",
        image_key_valid: null,
        facial_by_app: false,
        facial_by_app_user: false,
        facial_by_app_opt_in_accepted: ""
    });

    const [cancellLGPD, setCancellLGPD] = useState(false);

    const [disableButtonSave, setDisableButtonSave] = useState(false);

    const [changePhoto, setChangePhoto] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [message, setMessage] = useState(false);

    const [closeButtonFacialPressed, setCloseButtonFacialPressed] = useState(false);

    const [userStatic, setUserStatic] = useState<IUserData>({
        userId: null,
        name: "",
        function: "",
        email: "",
        phone: "",
        gender: "",
        cpf: "",
        rg: "",
        birthDate: "",
        driver: null,
        picture: "",
        subModuleId: "",
        type: ''
    });

     const [loadingMiddlewarePhotoValidation, setLoadingMiddlewarePhotoValidation] = useState(false)

     const [showValidationMessage, setShowValidationMessage] = useState(true);

     const [semParar, setSemParar] = useState(false);

    useEffect(() => {
        async function init() {
            if(!(await hasPermission(11, 'Visualizar'))){
                navigate('/home')
            }

            setUserStatic(user);

            const userToken = localStorage.getItem('access_portal:access_token');

            const decoded = jwt_decode(userToken as string) as IUserDataToken
        
            const config = {
                headers: {
                    Authorization: `Bearer ${ userToken }`
                }
            }

            type UserGeneralRuleProps = {
                semParar: boolean;
            }

            const { data } : { data: UserGeneralRuleProps[] } = await api.get(`UserGeneralRule/UserGeneralRuleList/${decoded.client_id}`, config);

            setSemParar(data[0].semParar)
        }

        async function getHasUserDeniedUserPhoto() {
            try{
                const getCanShowPhotoAlerts : string | null = localStorage.getItem('@userGeneralRuleList');
        
                setCanShowPhotoAlerts(getCanShowPhotoAlerts);

                const userToken = localStorage.getItem('access_portal:access_token');
        
                const config = {
                    headers: {
                        Authorization: `Bearer ${ userToken }`
                    }
                }

               try {
                    const { data: imageData } = await api.get('user/getUserImageKeyValid', config);

                    setGetUserImageKeyValid(imageData?.imageKeyValid)
               } catch(err) {

               }
            }catch(e){
                
            }
        }

        init();
        getHasUserDeniedUserPhoto();
        populateValidatePhotoObj();
    }, []);

    useEffect(() => {
        const {
            enable_facial,
            facial_by_app,
            facial_by_app_user,
            image_key,
            image_key_valid,
            image_valid,
            facial_by_app_opt_in_accepted
        } = validatePhotoObj

        let newValueExcludePic = true
        let newValueEditPic = true

        if(newValueEditPic) {
            newValueEditPic = false;
            
            // cenario onde a lgpd nao foi aceita mas o usuario subiu uma foto
            if(facial_by_app_opt_in_accepted.length < 1) {
                newValueEditPic = true;
                newValueExcludePic = false;

                if(image_key.length > 0) {
                    newValueExcludePic = true;
                }
            } else {
                if(facial_by_app && facial_by_app_user) {
                    if(image_key_valid) {
                        newValueExcludePic = false;
                        newValueEditPic = false;

                        if(enable_facial && image_key && closeButtonFacialPressed) {
                            setMessage(true);
                        }
                    } else {
                        newValueExcludePic = true;
                        newValueEditPic = true;
                    }
                } else {
                    newValueExcludePic = userStatic.picture.length > 0;
                    newValueEditPic = true;
                }  
            }
        } else {
            newValueExcludePic = userStatic.picture.length > 0;
            newValueEditPic = true;
        }

        setExludePic(newValueExcludePic);

        setEditPic(newValueEditPic);
    }, [validatePhotoObj, userStatic]);

    async function populateValidatePhotoObj(sendToFirebase: boolean = false){
        let newValidatePhotoObj = {
            enable_facial: null,
            image_valid: null,
            image_key: "",
            image_key_valid: null,
            facial_by_app: false,
            facial_by_app_user: false,
            facial_by_app_opt_in_accepted: ""
        }
        
        const userToken = localStorage.getItem('access_portal:access_token');

        const decoded = jwt_decode(userToken as string) as IUserDataToken

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }
        
        try{
            const { data: response1 } = await api.get(`UserGeneralRule/UserGeneralRuleList/${decoded.client_id}`, config);

            newValidatePhotoObj.enable_facial = response1[0].enableFacial;
            newValidatePhotoObj.facial_by_app = response1[0].facialByApp

            const { data: response2 } = await api.get(`user/${decoded.user_id}`, config);

            newValidatePhotoObj.image_valid = response2.imageValid
            newValidatePhotoObj.image_key = response2.fileType ? response2.fileType: ""

            const { data: response3 } = await api.get(`user/getUserImageKeyValid`, config);

            newValidatePhotoObj.image_key_valid = response3.imageKeyValid;

            const { data: response4 } = await api.get('user/facialByApp', config);

            newValidatePhotoObj.facial_by_app_user = response4.facialByApp
            newValidatePhotoObj.facial_by_app_opt_in_accepted = response4.facialByAppOptInAccepted
                ? response4.facialByAppOptInAccepted
                : ""
            
            setValidatePhotoObj(newValidatePhotoObj);

            //exibir o modal da LGPD
            if(
                newValidatePhotoObj.facial_by_app &&
                newValidatePhotoObj.facial_by_app_opt_in_accepted.length < 1 &&
                newValidatePhotoObj.image_key.length < 1
            ) {
                onOpen();

                setShowValidationMessage(false);
            } else if(newValidatePhotoObj.facial_by_app != null){
                if(newValidatePhotoObj.facial_by_app == true){
                    setShowValidationMessage(true);
                }else{
                    setShowValidationMessage(false);
                }
            }

            if(sendToFirebase) {
                sendFirebase(newValidatePhotoObj);
            }

            setIsLoading(false);
        } catch(e) {
            
        }
    }

    async function sendFirebase(newValidatePhotoObj: IValidPhoto) {
        
        const { image_valid, image_key, facial_by_app, facial_by_app_user, facial_by_app_opt_in_accepted } = newValidatePhotoObj
        
        if(facial_by_app_user && (
            (facial_by_app && !image_key && !image_valid && facial_by_app_opt_in_accepted && facial_by_app_opt_in_accepted.length > 0)
            ||
            (image_key && !image_valid && facial_by_app_opt_in_accepted && facial_by_app_opt_in_accepted.length > 0 && facial_by_app)
        )){
            const userToken = localStorage.getItem('access_portal:access_token');

            const decoded = jwt_decode(userToken as string) as IUserDataToken

            const user_id = decoded.user_id;
            const clientId = decoded.client_id;

            const toSendFb = {
                userId: user_id,
                date: '' + new Date().toISOString()
            }

            set(
                ref( 
                    FirebaseRealtimeDatabase, 
                    `/user-image-updated/${clientId}/${user_id}`
                ),
                toSendFb
            )

            

        }
    }

    async function handlerUpdateMyData() {
        setDisableButtonSave(true);

        if(!userStatic.phone){
            return toast.error("O campo Celular é obrigatório.");
        }

        if(userStatic.phone.replace(/[^0-9]/g,'').length !== 13) {
            setDisableButtonSave(false);
            return toast.error("O Celular informado é inválido.");
        }

        if(semParar && !userStatic.cpf){
            setDisableButtonSave(false);
            return toast.error("O campo CPF é obrigatório.");
        }

        // 123.456.789-00

        if(!userStatic.rg){
            setDisableButtonSave(false);
            return toast.error("O campo RG é obrigatório.");
        }

        let rg = userStatic.rg;
        let cpf = userStatic.cpf;

        rg = rg.replace(/[.]/g,"");
        rg = rg.replace(/[-]/g,"");
        rg = rg.replace(/[_]/g,"");

        cpf = cpf.replace(/[.]/g,"");
        cpf = cpf.replace(/[-]/g,"");
        cpf = cpf.replace(/[_]/g,"");

        if(cpf.length > 0 && !cpfValidator.isValid(cpf)) {
            setDisableButtonSave(false);
            return toast.error("O CPF informado inválido.");
        }

        if(rg.length < 9) {
            setDisableButtonSave(false);
            return toast.error("O RG informado inválido.");
        }

        let picture = userStatic.picture;

        if(userStatic.picture.split('base64,').length === 2) {
            picture = userStatic.picture.split('base64,')[1];
        }


        const post = {
            ...userStatic,
            picture: picture,
            phoneNumber: userStatic.phone,
            cpf,
            rg,
        };
          
        try {    
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_portal:access_token')}`
                }
            };

            await api.post("user/updatemydata", post, config);


            dispatch(setCurrentUser(userStatic));

            setDisableButtonSave(false);
            
            toast.success("Dados atualizados com sucesso.");
        } catch (err: any) {
            setDisableButtonSave(false);

            if(
                err &&
                err.response &&
                err.response.data &&
                err.response.data.Message
            ){
                toast.error(err.response.data.Message);
            }
        }
    }

    async function onUploadImage(image: string) {
        try {
            const post = {
                type: image.length > 0 ? "base64"  : null,
                data: image.length > 0 ? image.split(",")[1] : "",
            };

            await api.post('user/upsertimage/' + userStatic.userId, post)
            populateValidatePhotoObj(true);

            setUserStatic({
                ...userStatic,
                picture: image
            }); 

            dispatch(setCurrentUser({
                ...user,
                picture: image
            }));

            setCancellLGPD(false);

            if(validatePhotoObj.facial_by_app &&
                validatePhotoObj.facial_by_app_opt_in_accepted.length > 1 && 
                validatePhotoObj.facial_by_app_user
            ) {
                setLoadingMiddlewarePhotoValidation(true);

                toast.info('Sua nova foto está sendo processada, aguarde alguns instantes...');


                setTimeout(() => {
                    populateValidatePhotoObj();
                }, 10000)
                

                setTimeout(() => {
                    setTimeout(() => {
                        
                        setLoadingMiddlewarePhotoValidation(false);

                        toast.success('Foto alterada com sucesso!');
                    }, 2000);
                    
                }, 10000);
            }else{
                populateValidatePhotoObj();

                toast.success('Foto alterada com sucesso!');
            }         
        } catch (err) {
            toast.error("Ocorreu um erro ao salvar a nova foto!");
        }
    }

    async function onExcludeImage() {
        const userToken = localStorage.getItem('access_portal:access_token');

        const decoded = jwt_decode(userToken as string) as IUserDataToken

        const config = {
            headers: {
                Authorization: `Bearer ${ userToken }`
            }
        }

        await api.get(
            `user/deleteImageByUser/${decoded.user_id}`,
            config
        );

        setUserStatic({
            ...userStatic,
            picture: ""
        });

        dispatch(setCurrentUser({
            ...user,
            picture: ""
        }));

        populateValidatePhotoObj();
    }

    function getMessage() {
        const userToken = localStorage.getItem('access_portal:access_token')
    
        const decoded = jwt_decode(userToken as string) as IUserData
    
        if(decoded.subModuleId === '5'){
          return "Sua foto atual está sendo usada pela solução de biometria facial de sua empresa.";
        }else{
          return "Sua foto atual está sendo usada pela solução de biometria facial de seu condomínio.";
        }
    }

    if(isLoading) {
        return (
            <Flex
                position={"relative"}
                h={"calc(100vh - 175px)"}
                w={"100%"}
                top={"0px"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <CircularProgress isIndeterminate color='teal' w={"20px"} h={"20px"} />
            </Flex>
        )
    }

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={dialogRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent width={"90%"}>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Atenção!
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Para controlar acessos o seu empreendimento utiliza uma solução de biometria facial. Deseja utilizar a mesma foto do seu perfil nesse aplicativo como a foto para autenticação biométrica?
                        </AlertDialogBody>
                        <AlertDialogFooter
                            display={"flex"}
                            justifyContent={"space-between"}
                        >
                            <Button
                                background={"#d36262"}
                                color={"#fff"}
                                _hover={{
                                    _disabled: {
                                        background: "#d36262"
                                    }
                                }}
                                onClick={async () => {
                                    if(validatePhotoObj.image_key.length < 1) {
                                        setCancellLGPD(true);
                                    }

                                    setCloseButtonFacialPressed(true)
                                    
                                    onClose();
                                }}
                            >
                                Cancelar
                            </Button>
                            <Box>
                                <Button
                                    onClick={async () => {
                                        await api.post(`user/FacialByAppOptInAccepted/${userStatic.userId}/true`);

                                        populateValidatePhotoObj();

                                        setCancellLGPD(false);
                                        
                                        onClose();

                                        setShowValidationMessage(true)
                                    }}
                                >
                                    Sim
                                </Button>
                                <Button 
                                    onClick={async () => {
                                        await api.post(`user/FacialByAppOptInAccepted/${userStatic.userId}/false`);

                                        setCancellLGPD(false);

                                        onClose();

                                        setShowValidationMessage(false)
                                    }} 
                                    ml={3}
                                >
                                    Não
                                </Button>
                            </Box>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <Grid
                templateColumns={["0% 100% 0%", "30% 40% 30%", "30% 40% 30%"]}
                gap={1}
            >
                <GridItem colSpan={1} />
                <GridItem
                    colSpan={1}
                    paddingTop={"20px"}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"} 
                >
                    {loadingMiddlewarePhotoValidation
                        ? <span style={{textAlign: 'center'}}>Sua nova foto está sendo processada, aguarde alguns instantes...</span>
                        :  (
                            <>
                                {validatePhotoObj.facial_by_app &&
                                validatePhotoObj.facial_by_app_user &&
                                validatePhotoObj.image_key.length > 1 &&
                                    validatePhotoObj.facial_by_app_opt_in_accepted.length > 0 && (
                                    <Box 
                                        textAlign={"center"}
                                        backgroundColor={validatePhotoObj.image_key_valid ? "#69b152": "#df5555"}
                                        marginTop={"10px"}
                                        fontSize={"12px"}
                                        width={"50%"}
                                        color={"#fff"}
                                        padding={"4px"}
                                        borderRadius={"4px"}
                                        fontWeight={600}
                                    >
                                        Foto {validatePhotoObj.image_key_valid
                                            ? 'válida' 
                                            : 'inválida'
                                        } para reconhecimento facial
                                    </Box>
                                )}
                                
                                {message && (
                                    <MessageForImageValid w={["50%", "30%"]}>
                                        {getMessage()}
                                    </MessageForImageValid>
                                )}

                                <Avatar
                                    source={userStatic.picture}
                                    setChangePhoto={setChangePhoto}
                                    onUploadImage={onUploadImage}
                                    onExcludeImage={onExcludeImage}
                                    cancellLGPD={cancellLGPD}
                                    validatePhotoObj={validatePhotoObj}
                                    isLoading={isLoading}
                                    editPic={editPic}
                                    exludePic={exludePic}
                                />
                            </>
                        )
                    } 
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        marginTop={"40px"}
                    >
                        <Text
                            fontSize={"2em"}
                            fontWeight={600}
                            textTransform={"capitalize"}
                        >{userStatic.name}</Text>
                        <Text
                            fontSize={"1.3em"}
                            fontWeight={600}
                            color={"#777"}
                        >{userStatic.function}</Text>

                        <UserPasswordReset />
                    </Box>
        
                    <ContentFields
                        marginTop={"60px"}
                        display={"flex"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        width={["100%", "60%"]}
                    >
                        <Box width={"100%"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                E-mail
                            </FormLabel>
                            <Input 
                                type='email' 
                                placeholder='exemplo@exemplo.com.br' 
                                value={userStatic.email}
                                onChange={(val) => {
                                    setUserStatic({
                                        ...userStatic,
                                        email: val.target.value
                                    })
                                }}
                                // disabled={!userStatic.email}
                                background={"#fff"}
                            />
                        </Box>
                        <Box width={"100%"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Celular
                            </FormLabel>
                            <InputPhone
                                // disabled={!userStatic.phone}
                                type='text' 
                                placeholder='+55 (xx) 9xxxx-xxxx' 
                                value={userStatic.phone}
                                onChange={(val) => {
                                    setUserStatic({
                                        ...userStatic,
                                        phone: val.target.value
                                    })
                                }} 
                                style={{ background: "#fff", borderRadius: "4px", width: "100%" }}
                            />
                        </Box>
                        <Box width={"100%"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Sexo
                            </FormLabel>
                            <Select
                                width={"100%"}
                                background={"#fff"}
                                onChange={(e) => {
                                    setUserStatic({
                                        ...userStatic,
                                        gender: e.target.value
                                    })
                                }}
                                value={userStatic.gender}
                                // disabled={!userStatic.gender}
                            >
                                <option value='' disabled selected>Selecione seu sexo</option>
                                <option value='Masculino'>Masculino</option>
                                <option value='Feminino'>Feminino</option>
                            </Select>
                        </Box>
                        <Box width={"100%"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                CPF
                            </FormLabel>
                            <InputCPF
                                type='text' 
                                placeholder='Digite aqui seu CPF' 
                                value={userStatic.cpf}
                                onChange={(val) => {
                                    setUserStatic({
                                        ...userStatic,
                                        cpf: val.target.value
                                    })
                                }}
                                style={{ background: "#fff", borderRadius: "4px", width: "100%" }}
                                // disabled={!userStatic.cpf}
                            />
                        </Box>
                        <Box width={"100%"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                RG
                            </FormLabel>
                            <Input
                                type='text' 
                                placeholder='Digite aqui seu RG' 
                                value={userStatic.rg}
                                onChange={(val) => {
                                    setUserStatic({
                                        ...userStatic,
                                        rg: val.target.value
                                    })
                                }}
                                style={{ background: "#fff", borderRadius: "4px", width: "100%" }}
                                // disabled={!userStatic.rg}
                            />
                        </Box>
                        <Box width={"100%"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Data de Nascimento
                          
                            </FormLabel>
                            <Input
                                placeholder={'DD/MM/AAAA'}
                                type={"date"}
                                onChange={(date) => {
                                    setUserStatic({
                                        ...userStatic,
                                        birthDate: String(date.target.value)
                                    })
                                }}
                                value={userStatic.birthDate}
                                background={"#fff"}
                            />
                        </Box>
                        <Box width={"100%"}>
                            <FormLabel
                                fontSize={"0.8em"}
                                color={"#777"}
                                marginBottom={"3px"}
                            >
                                Condutor
                            </FormLabel>
                            <RadioGroup
                                onChange={(val) => {
                                    setUserStatic({
                                        ...userStatic,
                                        driver: val === "1" ? true : false
                                    })
                                }}
                                value={userStatic.driver ? "1" : "2"}
                            >
                                <Stack direction='row'>
                                    <Radio value='1' borderColor={"#777"} disabled={userStatic.driver === null}>Sim</Radio>
                                    <Radio value='2' borderColor={"#777"} disabled={userStatic.driver === null}>Não</Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>
                        <Button
                            marginTop={"40px"}
                            colorScheme='teal'
                            onClick={async () => {
                                setDisableButtonSave(true);

                                if(await hasPermission(11, 'Editar')){
                                    handlerUpdateMyData()
                                }else{
                                    toast.error('Por favor, verificar o perfil de acesso!')
                                }
                            }}
                            width={"100%"}
                            isDisabled={(!userStatic.userId) || disableButtonSave}
                        >
                            Salvar
                        </Button>
                    </ContentFields>              
                </GridItem>
                <GridItem colSpan={1} />
            </Grid>
        </>        
    )
}
